// Generated from UniversalWorkoutLanguageParser.g4 by ANTLR 4.13.1

import * as antlr from "antlr4ng";
import { Token } from "antlr4ng";

import { UniversalWorkoutLanguageParserVisitor } from "./UniversalWorkoutLanguageParserVisitor.js";

// for running tests with parameters, TODO: discuss strategy for typed parameters in CI
// eslint-disable-next-line no-unused-vars
type int = number;


export class UniversalWorkoutLanguageParser extends antlr.Parser {
    public static readonly IGNORE_CHARS = 1;
    public static readonly WS = 2;
    public static readonly NL = 3;
    public static readonly NUMBER = 4;
    public static readonly FORTIME_KW = 5;
    public static readonly EVERY_KW = 6;
    public static readonly WORKOUT_SEPARATOR = 7;
    public static readonly INTO_KW = 8;
    public static readonly MOVEMENT_MARKER = 9;
    public static readonly CHOICE_KW = 10;
    public static readonly OPTION_KW = 11;
    public static readonly EMOM_KW = 12;
    public static readonly WOD_KW = 13;
    public static readonly DEF_KW = 14;
    public static readonly NEW_MOV_KW = 15;
    public static readonly REST_KW = 16;
    public static readonly MIN_KW = 17;
    public static readonly STRING = 18;
    public static readonly GENERIC_STRING = 19;
    public static readonly PAREN_L = 20;
    public static readonly LBRACE = 21;
    public static readonly RBRACE = 22;
    public static readonly COLON = 23;
    public static readonly INLINE_COMMENT = 24;
    public static readonly MULTILINE_COMMENT = 25;
    public static readonly WORKOUT_IDENTIFIER = 26;
    public static readonly GENERIC_SEPARATOR = 27;
    public static readonly ERROR_CHAR = 28;
    public static readonly WORKOUT_WS = 29;
    public static readonly WORKOUT_PAREN_L = 30;
    public static readonly WORKOUT_PAREN_R = 31;
    public static readonly WORKOUT_COLON = 32;
    public static readonly WORKOUT_COMMA = 33;
    public static readonly WORKOUT_QUOTE = 34;
    public static readonly WORKOUT_STRING = 35;
    public static readonly WORKOUT_ML_STRING = 36;
    public static readonly WORKOUT_TIME = 37;
    public static readonly WORKOUT_NUMBER = 38;
    public static readonly WORKOUT_UNIT_TIME = 39;
    public static readonly WORKOUT_IDEN = 40;
    public static readonly WORKOUT_ERR = 41;
    public static readonly CHOICE_WS = 42;
    public static readonly CHOICE_NAME = 43;
    public static readonly CHOICE_ERR = 44;
    public static readonly MOVEMENT_NAME_REP_MARKER = 45;
    public static readonly SIMPLE_MOVEMENT = 46;
    public static readonly MOVEMENT_NAME_REPS = 47;
    public static readonly MOVEMENT_NAME_COMPOENENT_SEPARATOR = 48;
    public static readonly COMPLEX_MOVEMENT = 49;
    public static readonly MOVEMENT_NAME_TERMINATOR = 50;
    public static readonly MOVEMENT_NAME_WS = 51;
    public static readonly MOVEMENT_ERR = 52;
    public static readonly MC_WS = 53;
    public static readonly MC_RANGE = 54;
    public static readonly MC_CLUSTER = 55;
    public static readonly MC_NUMBER = 56;
    public static readonly MC_MAX = 57;
    public static readonly AMRAP_UNIT_KW = 58;
    public static readonly MC_PAREN_L = 59;
    public static readonly MC_PAREN_R = 60;
    public static readonly MC_DASH = 61;
    public static readonly MC_CAL_KW = 62;
    public static readonly MC_POWER_KW = 63;
    public static readonly MC_TIME = 64;
    public static readonly MC_SET_SEPARATOR = 65;
    public static readonly MC_SLASH = 66;
    public static readonly REP_MARKER = 67;
    public static readonly TEMPO_MARKER = 68;
    public static readonly DURATION_MARKER = 69;
    public static readonly DISTANCE_MARKER = 70;
    public static readonly HEIGHT_MARKER = 71;
    public static readonly MOVEMENT_TERM = 72;
    public static readonly MC_PLACEHOLDER = 73;
    public static readonly MC_CONTENT = 74;
    public static readonly MOV_ERR = 75;
    public static readonly MC_TEMPO_WS = 76;
    public static readonly MC_TEMPO_PATTERN = 77;
    public static readonly DEF_WS = 78;
    public static readonly DEF_COMMENT = 79;
    public static readonly DEF_REGEX = 80;
    public static readonly DEF_IDENTIFIER = 81;
    public static readonly DEF_QUOTE = 82;
    public static readonly DEF_COLON = 83;
    public static readonly DEF_OPTIONAL = 84;
    public static readonly DEF_COMMA = 85;
    public static readonly DEF_PAREN_L = 86;
    public static readonly DEF_PAREN_R = 87;
    public static readonly DEF_BRACE_L = 88;
    public static readonly DEF_BRACE_R = 89;
    public static readonly DEF_SUPER_KW = 90;
    public static readonly DEF_TYPES = 91;
    public static readonly DEF_ENUM_KW = 92;
    public static readonly NEW_MOV_WS = 93;
    public static readonly NEW_MOV_QUOTE = 94;
    public static readonly NEW_MOV_IDENTIFIER = 95;
    public static readonly NEW_MOV_COLON = 96;
    public static readonly NEW_MOV_COMMA = 97;
    public static readonly NEW_MOV_SEMI = 98;
    public static readonly NEW_MOV_PARAM = 99;
    public static readonly NUMBER_WS = 100;
    public static readonly ROUNDS_WORD = 101;
    public static readonly SUPERSET_KW = 102;
    public static readonly FORTIME_SET_KW = 103;
    public static readonly TIME_UNIT = 104;
    public static readonly NUMBER_CONTEXT_ERR = 105;
    public static readonly FORTIME_WS = 106;
    public static readonly FORTIME_REPS = 107;
    public static readonly FORTIME_ERR = 108;
    public static readonly AMRAP_WS = 109;
    public static readonly AMRAP_KW = 110;
    public static readonly AMRAP_ERR = 111;
    public static readonly EMOM_X_SEP = 112;
    public static readonly EMOM_DASH = 113;
    public static readonly EMOM_COLON = 114;
    public static readonly EMOM_MIN_KW = 115;
    public static readonly EMOM_NUM = 116;
    public static readonly EMOM_WS = 117;
    public static readonly EMOM_NL = 118;
    public static readonly EMOM_ERR = 119;
    public static readonly REST_WS = 120;
    public static readonly REST_NL = 121;
    public static readonly REST_AS_NEEDED = 122;
    public static readonly REST_NUMBER = 123;
    public static readonly REST_UNIT = 124;
    public static readonly REST_SLASH = 125;
    public static readonly REST_SUPERSET = 126;
    public static readonly REST_EMOM = 127;
    public static readonly REST_RATIO = 128;
    public static readonly REST_ERR = 129;
    public static readonly REST_AFTER_UNIT_WS = 130;
    public static readonly REST_AFTER_UNIT_NL = 131;
    public static readonly REST_AFTER_UNIT_OTHER = 132;
    public static readonly REST_AFTER_VALUE_WS = 133;
    public static readonly REST_AFTER_VALUE_NL = 134;
    public static readonly REST_AFTER_VALUE_OTHER = 135;
    public static readonly REST_EMOM_WS = 136;
    public static readonly REST_EMOM_ERR = 137;
    public static readonly REST_RATIO_WS = 138;
    public static readonly REST_RATIO_ERR = 139;
    public static readonly EVERY_WS = 140;
    public static readonly EVERY_NL = 141;
    public static readonly EVERY_NUMBER = 142;
    public static readonly EVERY_X = 143;
    public static readonly EVERY_UNIT = 144;
    public static readonly EVERY_FOR = 145;
    public static readonly EVERY_SETS = 146;
    public static readonly EVERY_ERR = 147;
    public static readonly EVERY_AFTER_SETS_WS = 148;
    public static readonly EVERY_AFTER_SETS_FOR = 149;
    public static readonly EVERY_AFTER_SETS_NL = 150;
    public static readonly EVERY_AFTER_SETS_ERR = 151;
    public static readonly EVERY_GOAL_STRING_WS = 152;
    public static readonly EVERY_GOAL_STRING = 153;
    public static readonly EVERY_GOAL_STRING_NL = 154;
    public static readonly EVERY_GOAL_STRING_ERR = 155;
    public static readonly RULE_error = 0;
    public static readonly RULE_sets = 1;
    public static readonly RULE_reps = 2;
    public static readonly RULE_rep_value = 3;
    public static readonly RULE_amrap_value = 4;
    public static readonly RULE_mov_placeholder = 5;
    public static readonly RULE_calories = 6;
    public static readonly RULE_power = 7;
    public static readonly RULE_load_value = 8;
    public static readonly RULE_load = 9;
    public static readonly RULE_tempo = 10;
    public static readonly RULE_duration = 11;
    public static readonly RULE_distance = 12;
    public static readonly RULE_height = 13;
    public static readonly RULE_complex_movement = 14;
    public static readonly RULE_movement_name = 15;
    public static readonly RULE_movement = 16;
    public static readonly RULE_comment = 17;
    public static readonly RULE_workout_iden = 18;
    public static readonly RULE_workout_attr_id = 19;
    public static readonly RULE_workout_attr_val_str = 20;
    public static readonly RULE_workout_attr_val_number = 21;
    public static readonly RULE_workout_attr_val_time = 22;
    public static readonly RULE_workout_attr_val = 23;
    public static readonly RULE_workout_attribute = 24;
    public static readonly RULE_workout_comment = 25;
    public static readonly RULE_workout = 26;
    public static readonly RULE_rest_time_value = 27;
    public static readonly RULE_emom_rest_value = 28;
    public static readonly RULE_ratio_rest_value = 29;
    public static readonly RULE_rest_value = 30;
    public static readonly RULE_secondary_rest_value = 31;
    public static readonly RULE_shorthand_strength = 32;
    public static readonly RULE_shorthand_generic = 33;
    public static readonly RULE_shorthand_fortime = 34;
    public static readonly RULE_intermediate_movement = 35;
    public static readonly RULE_final_movement = 36;
    public static readonly RULE_shorthand_amrap = 37;
    public static readonly RULE_shorthand_every = 38;
    public static readonly RULE_any_workout = 39;
    public static readonly RULE_alternation = 40;
    public static readonly RULE_option_block_title = 41;
    public static readonly RULE_option_title = 42;
    public static readonly RULE_option = 43;
    public static readonly RULE_option_block = 44;
    public static readonly RULE_emom = 45;
    public static readonly RULE_wod = 46;
    public static readonly RULE_def_id = 47;
    public static readonly RULE_def_super_kw = 48;
    public static readonly RULE_def_super_param = 49;
    public static readonly RULE_def_super = 50;
    public static readonly RULE_def_param_name = 51;
    public static readonly RULE_def_param_enum_entry = 52;
    public static readonly RULE_def_param_enum = 53;
    public static readonly RULE_def_param_type = 54;
    public static readonly RULE_def_param = 55;
    public static readonly RULE_definition = 56;
    public static readonly RULE_defs = 57;
    public static readonly RULE_new_movement_name = 58;
    public static readonly RULE_new_movement_params = 59;
    public static readonly RULE_new_movement = 60;
    public static readonly RULE_day = 61;

    public static readonly literalNames = [
        null, null, null, null, null, null, null, null, "'into'", null, 
        null, null, null, null, "'define'", "'newMovement'", null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, "'M'", null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        "'?'", null, null, null, null, null, null, null, "'enum'", null, 
        null, null, null, null, "';'"
    ];

    public static readonly symbolicNames = [
        null, "IGNORE_CHARS", "WS", "NL", "NUMBER", "FORTIME_KW", "EVERY_KW", 
        "WORKOUT_SEPARATOR", "INTO_KW", "MOVEMENT_MARKER", "CHOICE_KW", 
        "OPTION_KW", "EMOM_KW", "WOD_KW", "DEF_KW", "NEW_MOV_KW", "REST_KW", 
        "MIN_KW", "STRING", "GENERIC_STRING", "PAREN_L", "LBRACE", "RBRACE", 
        "COLON", "INLINE_COMMENT", "MULTILINE_COMMENT", "WORKOUT_IDENTIFIER", 
        "GENERIC_SEPARATOR", "ERROR_CHAR", "WORKOUT_WS", "WORKOUT_PAREN_L", 
        "WORKOUT_PAREN_R", "WORKOUT_COLON", "WORKOUT_COMMA", "WORKOUT_QUOTE", 
        "WORKOUT_STRING", "WORKOUT_ML_STRING", "WORKOUT_TIME", "WORKOUT_NUMBER", 
        "WORKOUT_UNIT_TIME", "WORKOUT_IDEN", "WORKOUT_ERR", "CHOICE_WS", 
        "CHOICE_NAME", "CHOICE_ERR", "MOVEMENT_NAME_REP_MARKER", "SIMPLE_MOVEMENT", 
        "MOVEMENT_NAME_REPS", "MOVEMENT_NAME_COMPOENENT_SEPARATOR", "COMPLEX_MOVEMENT", 
        "MOVEMENT_NAME_TERMINATOR", "MOVEMENT_NAME_WS", "MOVEMENT_ERR", 
        "MC_WS", "MC_RANGE", "MC_CLUSTER", "MC_NUMBER", "MC_MAX", "AMRAP_UNIT_KW", 
        "MC_PAREN_L", "MC_PAREN_R", "MC_DASH", "MC_CAL_KW", "MC_POWER_KW", 
        "MC_TIME", "MC_SET_SEPARATOR", "MC_SLASH", "REP_MARKER", "TEMPO_MARKER", 
        "DURATION_MARKER", "DISTANCE_MARKER", "HEIGHT_MARKER", "MOVEMENT_TERM", 
        "MC_PLACEHOLDER", "MC_CONTENT", "MOV_ERR", "MC_TEMPO_WS", "MC_TEMPO_PATTERN", 
        "DEF_WS", "DEF_COMMENT", "DEF_REGEX", "DEF_IDENTIFIER", "DEF_QUOTE", 
        "DEF_COLON", "DEF_OPTIONAL", "DEF_COMMA", "DEF_PAREN_L", "DEF_PAREN_R", 
        "DEF_BRACE_L", "DEF_BRACE_R", "DEF_SUPER_KW", "DEF_TYPES", "DEF_ENUM_KW", 
        "NEW_MOV_WS", "NEW_MOV_QUOTE", "NEW_MOV_IDENTIFIER", "NEW_MOV_COLON", 
        "NEW_MOV_COMMA", "NEW_MOV_SEMI", "NEW_MOV_PARAM", "NUMBER_WS", "ROUNDS_WORD", 
        "SUPERSET_KW", "FORTIME_SET_KW", "TIME_UNIT", "NUMBER_CONTEXT_ERR", 
        "FORTIME_WS", "FORTIME_REPS", "FORTIME_ERR", "AMRAP_WS", "AMRAP_KW", 
        "AMRAP_ERR", "EMOM_X_SEP", "EMOM_DASH", "EMOM_COLON", "EMOM_MIN_KW", 
        "EMOM_NUM", "EMOM_WS", "EMOM_NL", "EMOM_ERR", "REST_WS", "REST_NL", 
        "REST_AS_NEEDED", "REST_NUMBER", "REST_UNIT", "REST_SLASH", "REST_SUPERSET", 
        "REST_EMOM", "REST_RATIO", "REST_ERR", "REST_AFTER_UNIT_WS", "REST_AFTER_UNIT_NL", 
        "REST_AFTER_UNIT_OTHER", "REST_AFTER_VALUE_WS", "REST_AFTER_VALUE_NL", 
        "REST_AFTER_VALUE_OTHER", "REST_EMOM_WS", "REST_EMOM_ERR", "REST_RATIO_WS", 
        "REST_RATIO_ERR", "EVERY_WS", "EVERY_NL", "EVERY_NUMBER", "EVERY_X", 
        "EVERY_UNIT", "EVERY_FOR", "EVERY_SETS", "EVERY_ERR", "EVERY_AFTER_SETS_WS", 
        "EVERY_AFTER_SETS_FOR", "EVERY_AFTER_SETS_NL", "EVERY_AFTER_SETS_ERR", 
        "EVERY_GOAL_STRING_WS", "EVERY_GOAL_STRING", "EVERY_GOAL_STRING_NL", 
        "EVERY_GOAL_STRING_ERR"
    ];
    public static readonly ruleNames = [
        "error", "sets", "reps", "rep_value", "amrap_value", "mov_placeholder", 
        "calories", "power", "load_value", "load", "tempo", "duration", 
        "distance", "height", "complex_movement", "movement_name", "movement", 
        "comment", "workout_iden", "workout_attr_id", "workout_attr_val_str", 
        "workout_attr_val_number", "workout_attr_val_time", "workout_attr_val", 
        "workout_attribute", "workout_comment", "workout", "rest_time_value", 
        "emom_rest_value", "ratio_rest_value", "rest_value", "secondary_rest_value", 
        "shorthand_strength", "shorthand_generic", "shorthand_fortime", 
        "intermediate_movement", "final_movement", "shorthand_amrap", "shorthand_every", 
        "any_workout", "alternation", "option_block_title", "option_title", 
        "option", "option_block", "emom", "wod", "def_id", "def_super_kw", 
        "def_super_param", "def_super", "def_param_name", "def_param_enum_entry", 
        "def_param_enum", "def_param_type", "def_param", "definition", "defs", 
        "new_movement_name", "new_movement_params", "new_movement", "day",
    ];

    public get grammarFileName(): string { return "UniversalWorkoutLanguageParser.g4"; }
    public get literalNames(): (string | null)[] { return UniversalWorkoutLanguageParser.literalNames; }
    public get symbolicNames(): (string | null)[] { return UniversalWorkoutLanguageParser.symbolicNames; }
    public get ruleNames(): string[] { return UniversalWorkoutLanguageParser.ruleNames; }
    public get serializedATN(): number[] { return UniversalWorkoutLanguageParser._serializedATN; }

    protected createFailedPredicateException(predicate?: string, message?: string): antlr.FailedPredicateException {
        return new antlr.FailedPredicateException(this, predicate, message);
    }

    public constructor(input: antlr.TokenStream) {
        super(input);
        this.interpreter = new antlr.ParserATNSimulator(this, UniversalWorkoutLanguageParser._ATN, UniversalWorkoutLanguageParser.decisionsToDFA, new antlr.PredictionContextCache());
    }
    public error(): ErrorContext {
        let localContext = new ErrorContext(this.context, this.state);
        this.enterRule(localContext, 0, UniversalWorkoutLanguageParser.RULE_error);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 124;
            this.match(UniversalWorkoutLanguageParser.ERROR_CHAR);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public sets(): SetsContext {
        let localContext = new SetsContext(this.context, this.state);
        this.enterRule(localContext, 2, UniversalWorkoutLanguageParser.RULE_sets);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 126;
            localContext._sets_number = this.tokenStream.LT(1);
            _la = this.tokenStream.LA(1);
            if(!(((((_la - 54)) & ~0x1F) === 0 && ((1 << (_la - 54)) & 524293) !== 0))) {
                localContext._sets_number = this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public reps(): RepsContext {
        let localContext = new RepsContext(this.context, this.state);
        this.enterRule(localContext, 4, UniversalWorkoutLanguageParser.RULE_reps);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 128;
            this.match(UniversalWorkoutLanguageParser.REP_MARKER);
            this.state = 129;
            localContext._rep_value = this.rep_value();
            localContext._values.push(localContext._rep_value!);
            this.state = 134;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 65) {
                {
                {
                this.state = 130;
                this.match(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
                this.state = 131;
                localContext._rep_value = this.rep_value();
                localContext._values.push(localContext._rep_value!);
                }
                }
                this.state = 136;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public rep_value(): Rep_valueContext {
        let localContext = new Rep_valueContext(this.context, this.state);
        this.enterRule(localContext, 6, UniversalWorkoutLanguageParser.RULE_rep_value);
        try {
            this.state = 143;
            this.errorHandler.sync(this);
            switch (this.tokenStream.LA(1)) {
            case UniversalWorkoutLanguageParser.MC_NUMBER:
                this.enterOuterAlt(localContext, 1);
                {
                this.state = 137;
                this.match(UniversalWorkoutLanguageParser.MC_NUMBER);
                }
                break;
            case UniversalWorkoutLanguageParser.MC_MAX:
                this.enterOuterAlt(localContext, 2);
                {
                this.state = 138;
                this.match(UniversalWorkoutLanguageParser.MC_MAX);
                }
                break;
            case UniversalWorkoutLanguageParser.MC_CLUSTER:
                this.enterOuterAlt(localContext, 3);
                {
                this.state = 139;
                this.match(UniversalWorkoutLanguageParser.MC_CLUSTER);
                }
                break;
            case UniversalWorkoutLanguageParser.AMRAP_UNIT_KW:
                this.enterOuterAlt(localContext, 4);
                {
                this.state = 140;
                this.amrap_value();
                }
                break;
            case UniversalWorkoutLanguageParser.MC_RANGE:
                this.enterOuterAlt(localContext, 5);
                {
                this.state = 141;
                this.match(UniversalWorkoutLanguageParser.MC_RANGE);
                }
                break;
            case UniversalWorkoutLanguageParser.MC_PLACEHOLDER:
                this.enterOuterAlt(localContext, 6);
                {
                this.state = 142;
                this.match(UniversalWorkoutLanguageParser.MC_PLACEHOLDER);
                }
                break;
            default:
                throw new antlr.NoViableAltException(this);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public amrap_value(): Amrap_valueContext {
        let localContext = new Amrap_valueContext(this.context, this.state);
        this.enterRule(localContext, 8, UniversalWorkoutLanguageParser.RULE_amrap_value);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 145;
            this.match(UniversalWorkoutLanguageParser.AMRAP_UNIT_KW);
            this.state = 153;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 59) {
                {
                this.state = 146;
                this.match(UniversalWorkoutLanguageParser.MC_PAREN_L);
                this.state = 150;
                this.errorHandler.sync(this);
                switch (this.tokenStream.LA(1)) {
                case UniversalWorkoutLanguageParser.MC_DASH:
                    {
                    this.state = 147;
                    this.match(UniversalWorkoutLanguageParser.MC_DASH);
                    this.state = 148;
                    this.match(UniversalWorkoutLanguageParser.MC_NUMBER);
                    }
                    break;
                case UniversalWorkoutLanguageParser.MC_NUMBER:
                    {
                    this.state = 149;
                    this.match(UniversalWorkoutLanguageParser.MC_NUMBER);
                    }
                    break;
                default:
                    throw new antlr.NoViableAltException(this);
                }
                this.state = 152;
                this.match(UniversalWorkoutLanguageParser.MC_PAREN_R);
                }
            }

            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public mov_placeholder(): Mov_placeholderContext {
        let localContext = new Mov_placeholderContext(this.context, this.state);
        this.enterRule(localContext, 10, UniversalWorkoutLanguageParser.RULE_mov_placeholder);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 155;
            this.match(UniversalWorkoutLanguageParser.MC_PLACEHOLDER);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public calories(): CaloriesContext {
        let localContext = new CaloriesContext(this.context, this.state);
        this.enterRule(localContext, 12, UniversalWorkoutLanguageParser.RULE_calories);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 157;
            localContext.__tset128 = this.tokenStream.LT(1);
            _la = this.tokenStream.LA(1);
            if(!(((((_la - 54)) & ~0x1F) === 0 && ((1 << (_la - 54)) & 524301) !== 0))) {
                localContext.__tset128 = this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            localContext._cals.push(localContext.__tset128!);
            this.state = 162;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 65) {
                {
                {
                this.state = 158;
                this.match(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
                this.state = 159;
                localContext.__tset143 = this.tokenStream.LT(1);
                _la = this.tokenStream.LA(1);
                if(!(((((_la - 54)) & ~0x1F) === 0 && ((1 << (_la - 54)) & 524301) !== 0))) {
                    localContext.__tset143 = this.errorHandler.recoverInline(this);
                }
                else {
                    this.errorHandler.reportMatch(this);
                    this.consume();
                }
                localContext._cals.push(localContext.__tset143!);
                }
                }
                this.state = 164;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            this.state = 165;
            this.match(UniversalWorkoutLanguageParser.MC_CAL_KW);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public power(): PowerContext {
        let localContext = new PowerContext(this.context, this.state);
        this.enterRule(localContext, 14, UniversalWorkoutLanguageParser.RULE_power);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 167;
            localContext.__tset163 = this.tokenStream.LT(1);
            _la = this.tokenStream.LA(1);
            if(!(((((_la - 54)) & ~0x1F) === 0 && ((1 << (_la - 54)) & 524301) !== 0))) {
                localContext.__tset163 = this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            localContext._powers.push(localContext.__tset163!);
            this.state = 172;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 65) {
                {
                {
                this.state = 168;
                this.match(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
                this.state = 169;
                localContext.__tset178 = this.tokenStream.LT(1);
                _la = this.tokenStream.LA(1);
                if(!(((((_la - 54)) & ~0x1F) === 0 && ((1 << (_la - 54)) & 524301) !== 0))) {
                    localContext.__tset178 = this.errorHandler.recoverInline(this);
                }
                else {
                    this.errorHandler.reportMatch(this);
                    this.consume();
                }
                localContext._powers.push(localContext.__tset178!);
                }
                }
                this.state = 174;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            this.state = 175;
            this.match(UniversalWorkoutLanguageParser.MC_POWER_KW);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public load_value(): Load_valueContext {
        let localContext = new Load_valueContext(this.context, this.state);
        this.enterRule(localContext, 16, UniversalWorkoutLanguageParser.RULE_load_value);
        try {
            this.state = 184;
            this.errorHandler.sync(this);
            switch (this.interpreter.adaptivePredict(this.tokenStream, 6, this.context) ) {
            case 1:
                this.enterOuterAlt(localContext, 1);
                {
                this.state = 177;
                this.match(UniversalWorkoutLanguageParser.MC_NUMBER);
                this.state = 178;
                this.match(UniversalWorkoutLanguageParser.MC_SLASH);
                this.state = 179;
                this.match(UniversalWorkoutLanguageParser.MC_NUMBER);
                }
                break;
            case 2:
                this.enterOuterAlt(localContext, 2);
                {
                this.state = 180;
                this.match(UniversalWorkoutLanguageParser.MC_NUMBER);
                }
                break;
            case 3:
                this.enterOuterAlt(localContext, 3);
                {
                this.state = 181;
                this.match(UniversalWorkoutLanguageParser.MC_MAX);
                }
                break;
            case 4:
                this.enterOuterAlt(localContext, 4);
                {
                this.state = 182;
                this.match(UniversalWorkoutLanguageParser.MC_PLACEHOLDER);
                }
                break;
            case 5:
                this.enterOuterAlt(localContext, 5);
                {
                this.state = 183;
                this.match(UniversalWorkoutLanguageParser.MC_RANGE);
                }
                break;
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public load(): LoadContext {
        let localContext = new LoadContext(this.context, this.state);
        this.enterRule(localContext, 18, UniversalWorkoutLanguageParser.RULE_load);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 186;
            this.match(UniversalWorkoutLanguageParser.MC_DASH);
            this.state = 187;
            localContext._load_value = this.load_value();
            localContext._loads.push(localContext._load_value!);
            this.state = 192;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 65) {
                {
                {
                this.state = 188;
                this.match(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
                this.state = 189;
                localContext._load_value = this.load_value();
                localContext._loads.push(localContext._load_value!);
                }
                }
                this.state = 194;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            this.state = 195;
            localContext._load_unit = this.match(UniversalWorkoutLanguageParser.MC_CONTENT);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public tempo(): TempoContext {
        let localContext = new TempoContext(this.context, this.state);
        this.enterRule(localContext, 20, UniversalWorkoutLanguageParser.RULE_tempo);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 197;
            this.match(UniversalWorkoutLanguageParser.TEMPO_MARKER);
            this.state = 199;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            do {
                {
                {
                this.state = 198;
                localContext._MC_TEMPO_PATTERN = this.match(UniversalWorkoutLanguageParser.MC_TEMPO_PATTERN);
                localContext._tempos.push(localContext._MC_TEMPO_PATTERN!);
                }
                }
                this.state = 201;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            } while (_la === 77);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public duration(): DurationContext {
        let localContext = new DurationContext(this.context, this.state);
        this.enterRule(localContext, 22, UniversalWorkoutLanguageParser.RULE_duration);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 203;
            this.match(UniversalWorkoutLanguageParser.DURATION_MARKER);
            this.state = 204;
            localContext.__tset262 = this.tokenStream.LT(1);
            _la = this.tokenStream.LA(1);
            if(!(((((_la - 54)) & ~0x1F) === 0 && ((1 << (_la - 54)) & 524301) !== 0))) {
                localContext.__tset262 = this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            localContext._durations.push(localContext.__tset262!);
            this.state = 209;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 65) {
                {
                {
                this.state = 205;
                this.match(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
                this.state = 206;
                localContext.__tset277 = this.tokenStream.LT(1);
                _la = this.tokenStream.LA(1);
                if(!(((((_la - 54)) & ~0x1F) === 0 && ((1 << (_la - 54)) & 524301) !== 0))) {
                    localContext.__tset277 = this.errorHandler.recoverInline(this);
                }
                else {
                    this.errorHandler.reportMatch(this);
                    this.consume();
                }
                localContext._durations.push(localContext.__tset277!);
                }
                }
                this.state = 211;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            this.state = 212;
            localContext._duration_unit = this.match(UniversalWorkoutLanguageParser.MC_CONTENT);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public distance(): DistanceContext {
        let localContext = new DistanceContext(this.context, this.state);
        this.enterRule(localContext, 24, UniversalWorkoutLanguageParser.RULE_distance);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 214;
            this.match(UniversalWorkoutLanguageParser.DISTANCE_MARKER);
            this.state = 215;
            localContext.__tset301 = this.tokenStream.LT(1);
            _la = this.tokenStream.LA(1);
            if(!(((((_la - 54)) & ~0x1F) === 0 && ((1 << (_la - 54)) & 524301) !== 0))) {
                localContext.__tset301 = this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            localContext._distances.push(localContext.__tset301!);
            this.state = 220;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 65) {
                {
                {
                this.state = 216;
                this.match(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
                this.state = 217;
                localContext.__tset316 = this.tokenStream.LT(1);
                _la = this.tokenStream.LA(1);
                if(!(((((_la - 54)) & ~0x1F) === 0 && ((1 << (_la - 54)) & 524301) !== 0))) {
                    localContext.__tset316 = this.errorHandler.recoverInline(this);
                }
                else {
                    this.errorHandler.reportMatch(this);
                    this.consume();
                }
                localContext._distances.push(localContext.__tset316!);
                }
                }
                this.state = 222;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            this.state = 223;
            localContext._distance_unit = this.match(UniversalWorkoutLanguageParser.MC_CONTENT);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public height(): HeightContext {
        let localContext = new HeightContext(this.context, this.state);
        this.enterRule(localContext, 26, UniversalWorkoutLanguageParser.RULE_height);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 225;
            this.match(UniversalWorkoutLanguageParser.HEIGHT_MARKER);
            this.state = 226;
            localContext.__tset340 = this.tokenStream.LT(1);
            _la = this.tokenStream.LA(1);
            if(!(((((_la - 56)) & ~0x1F) === 0 && ((1 << (_la - 56)) & 131075) !== 0))) {
                localContext.__tset340 = this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            localContext._heights.push(localContext.__tset340!);
            this.state = 231;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 65) {
                {
                {
                this.state = 227;
                this.match(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
                this.state = 228;
                localContext.__tset353 = this.tokenStream.LT(1);
                _la = this.tokenStream.LA(1);
                if(!(((((_la - 56)) & ~0x1F) === 0 && ((1 << (_la - 56)) & 131075) !== 0))) {
                    localContext.__tset353 = this.errorHandler.recoverInline(this);
                }
                else {
                    this.errorHandler.reportMatch(this);
                    this.consume();
                }
                localContext._heights.push(localContext.__tset353!);
                }
                }
                this.state = 233;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            this.state = 234;
            localContext._height_unit = this.match(UniversalWorkoutLanguageParser.MC_CONTENT);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public complex_movement(): Complex_movementContext {
        let localContext = new Complex_movementContext(this.context, this.state);
        this.enterRule(localContext, 28, UniversalWorkoutLanguageParser.RULE_complex_movement);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 237;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 47) {
                {
                this.state = 236;
                localContext._MOVEMENT_NAME_REPS = this.match(UniversalWorkoutLanguageParser.MOVEMENT_NAME_REPS);
                localContext._mov_reps.push(localContext._MOVEMENT_NAME_REPS!);
                }
            }

            this.state = 239;
            localContext._SIMPLE_MOVEMENT = this.match(UniversalWorkoutLanguageParser.SIMPLE_MOVEMENT);
            localContext._mov_name.push(localContext._SIMPLE_MOVEMENT!);
            this.state = 247;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 48) {
                {
                {
                this.state = 240;
                this.match(UniversalWorkoutLanguageParser.MOVEMENT_NAME_COMPOENENT_SEPARATOR);
                this.state = 242;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
                if (_la === 47) {
                    {
                    this.state = 241;
                    localContext._MOVEMENT_NAME_REPS = this.match(UniversalWorkoutLanguageParser.MOVEMENT_NAME_REPS);
                    localContext._mov_reps.push(localContext._MOVEMENT_NAME_REPS!);
                    }
                }

                this.state = 244;
                localContext._SIMPLE_MOVEMENT = this.match(UniversalWorkoutLanguageParser.SIMPLE_MOVEMENT);
                localContext._mov_name.push(localContext._SIMPLE_MOVEMENT!);
                }
                }
                this.state = 249;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public movement_name(): Movement_nameContext {
        let localContext = new Movement_nameContext(this.context, this.state);
        this.enterRule(localContext, 30, UniversalWorkoutLanguageParser.RULE_movement_name);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 252;
            this.errorHandler.sync(this);
            switch (this.interpreter.adaptivePredict(this.tokenStream, 15, this.context) ) {
            case 1:
                {
                this.state = 250;
                this.match(UniversalWorkoutLanguageParser.SIMPLE_MOVEMENT);
                }
                break;
            case 2:
                {
                this.state = 251;
                this.complex_movement();
                }
                break;
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public movement(): MovementContext {
        let localContext = new MovementContext(this.context, this.state);
        this.enterRule(localContext, 32, UniversalWorkoutLanguageParser.RULE_movement);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 254;
            this.match(UniversalWorkoutLanguageParser.MOVEMENT_MARKER);
            this.state = 255;
            this.movement_name();
            this.state = 256;
            this.match(UniversalWorkoutLanguageParser.MOVEMENT_NAME_TERMINATOR);
            this.state = 272;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (((((_la - 54)) & ~0x1F) === 0 && ((1 << (_la - 54)) & 778381) !== 0)) {
                {
                this.state = 268;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
                do {
                    {
                    {
                    this.state = 266;
                    this.errorHandler.sync(this);
                    switch (this.interpreter.adaptivePredict(this.tokenStream, 16, this.context) ) {
                    case 1:
                        {
                        this.state = 257;
                        this.reps();
                        }
                        break;
                    case 2:
                        {
                        this.state = 258;
                        this.load();
                        }
                        break;
                    case 3:
                        {
                        this.state = 259;
                        this.tempo();
                        }
                        break;
                    case 4:
                        {
                        this.state = 260;
                        this.duration();
                        }
                        break;
                    case 5:
                        {
                        this.state = 261;
                        this.distance();
                        }
                        break;
                    case 6:
                        {
                        this.state = 262;
                        this.sets();
                        }
                        break;
                    case 7:
                        {
                        this.state = 263;
                        this.calories();
                        }
                        break;
                    case 8:
                        {
                        this.state = 264;
                        this.power();
                        }
                        break;
                    case 9:
                        {
                        this.state = 265;
                        this.height();
                        }
                        break;
                    }
                    }
                    }
                    this.state = 270;
                    this.errorHandler.sync(this);
                    _la = this.tokenStream.LA(1);
                } while (((((_la - 54)) & ~0x1F) === 0 && ((1 << (_la - 54)) & 778381) !== 0));
                }
            }

            this.state = 275;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            do {
                {
                {
                this.state = 274;
                this.match(UniversalWorkoutLanguageParser.MOVEMENT_TERM);
                }
                }
                this.state = 277;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            } while (_la === 72);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public comment(): CommentContext {
        let localContext = new CommentContext(this.context, this.state);
        this.enterRule(localContext, 34, UniversalWorkoutLanguageParser.RULE_comment);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 279;
            _la = this.tokenStream.LA(1);
            if(!(_la === 24 || _la === 25)) {
            this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout_iden(): Workout_idenContext {
        let localContext = new Workout_idenContext(this.context, this.state);
        this.enterRule(localContext, 36, UniversalWorkoutLanguageParser.RULE_workout_iden);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 281;
            this.match(UniversalWorkoutLanguageParser.WORKOUT_IDENTIFIER);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout_attr_id(): Workout_attr_idContext {
        let localContext = new Workout_attr_idContext(this.context, this.state);
        this.enterRule(localContext, 38, UniversalWorkoutLanguageParser.RULE_workout_attr_id);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 283;
            this.match(UniversalWorkoutLanguageParser.WORKOUT_IDEN);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout_attr_val_str(): Workout_attr_val_strContext {
        let localContext = new Workout_attr_val_strContext(this.context, this.state);
        this.enterRule(localContext, 40, UniversalWorkoutLanguageParser.RULE_workout_attr_val_str);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 285;
            _la = this.tokenStream.LA(1);
            if(!(_la === 35 || _la === 36)) {
            this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout_attr_val_number(): Workout_attr_val_numberContext {
        let localContext = new Workout_attr_val_numberContext(this.context, this.state);
        this.enterRule(localContext, 42, UniversalWorkoutLanguageParser.RULE_workout_attr_val_number);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 287;
            this.match(UniversalWorkoutLanguageParser.WORKOUT_NUMBER);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout_attr_val_time(): Workout_attr_val_timeContext {
        let localContext = new Workout_attr_val_timeContext(this.context, this.state);
        this.enterRule(localContext, 44, UniversalWorkoutLanguageParser.RULE_workout_attr_val_time);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 289;
            _la = this.tokenStream.LA(1);
            if(!(_la === 37 || _la === 39)) {
            this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout_attr_val(): Workout_attr_valContext {
        let localContext = new Workout_attr_valContext(this.context, this.state);
        this.enterRule(localContext, 46, UniversalWorkoutLanguageParser.RULE_workout_attr_val);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 291;
            _la = this.tokenStream.LA(1);
            if(!(((((_la - 35)) & ~0x1F) === 0 && ((1 << (_la - 35)) & 31) !== 0))) {
            this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout_attribute(): Workout_attributeContext {
        let localContext = new Workout_attributeContext(this.context, this.state);
        this.enterRule(localContext, 48, UniversalWorkoutLanguageParser.RULE_workout_attribute);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 293;
            this.workout_attr_id();
            this.state = 294;
            this.match(UniversalWorkoutLanguageParser.WORKOUT_COLON);
            this.state = 295;
            this.workout_attr_val();
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout_comment(): Workout_commentContext {
        let localContext = new Workout_commentContext(this.context, this.state);
        this.enterRule(localContext, 50, UniversalWorkoutLanguageParser.RULE_workout_comment);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 297;
            _la = this.tokenStream.LA(1);
            if(!(_la === 24 || _la === 25)) {
            this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout(): WorkoutContext {
        let localContext = new WorkoutContext(this.context, this.state);
        this.enterRule(localContext, 52, UniversalWorkoutLanguageParser.RULE_workout);
        let _la: number;
        try {
            let alternative: number;
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 299;
            this.workout_iden();
            this.state = 301;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 35) {
                {
                this.state = 300;
                this.match(UniversalWorkoutLanguageParser.WORKOUT_STRING);
                }
            }

            this.state = 303;
            this.match(UniversalWorkoutLanguageParser.WORKOUT_PAREN_L);
            this.state = 305;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 40) {
                {
                this.state = 304;
                localContext._workout_attribute = this.workout_attribute();
                localContext._attrs.push(localContext._workout_attribute!);
                }
            }

            this.state = 311;
            this.errorHandler.sync(this);
            alternative = this.interpreter.adaptivePredict(this.tokenStream, 22, this.context);
            while (alternative !== 2 && alternative !== antlr.ATN.INVALID_ALT_NUMBER) {
                if (alternative === 1) {
                    {
                    {
                    this.state = 307;
                    this.match(UniversalWorkoutLanguageParser.WORKOUT_COMMA);
                    this.state = 308;
                    localContext._workout_attribute = this.workout_attribute();
                    localContext._attrs.push(localContext._workout_attribute!);
                    }
                    }
                }
                this.state = 313;
                this.errorHandler.sync(this);
                alternative = this.interpreter.adaptivePredict(this.tokenStream, 22, this.context);
            }
            this.state = 315;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 33) {
                {
                this.state = 314;
                this.match(UniversalWorkoutLanguageParser.WORKOUT_COMMA);
                }
            }

            this.state = 317;
            this.match(UniversalWorkoutLanguageParser.WORKOUT_PAREN_R);
            this.state = 322;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while ((((_la) & ~0x1F) === 0 && ((1 << _la) & 50332160) !== 0)) {
                {
                this.state = 320;
                this.errorHandler.sync(this);
                switch (this.tokenStream.LA(1)) {
                case UniversalWorkoutLanguageParser.MOVEMENT_MARKER:
                    {
                    this.state = 318;
                    localContext._movement = this.movement();
                    localContext._movements.push(localContext._movement!);
                    }
                    break;
                case UniversalWorkoutLanguageParser.INLINE_COMMENT:
                case UniversalWorkoutLanguageParser.MULTILINE_COMMENT:
                    {
                    this.state = 319;
                    localContext._workout_comment = this.workout_comment();
                    localContext._comments.push(localContext._workout_comment!);
                    }
                    break;
                default:
                    throw new antlr.NoViableAltException(this);
                }
                }
                this.state = 324;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public rest_time_value(): Rest_time_valueContext {
        let localContext = new Rest_time_valueContext(this.context, this.state);
        this.enterRule(localContext, 54, UniversalWorkoutLanguageParser.RULE_rest_time_value);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 325;
            this.match(UniversalWorkoutLanguageParser.REST_NUMBER);
            this.state = 326;
            this.match(UniversalWorkoutLanguageParser.REST_UNIT);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public emom_rest_value(): Emom_rest_valueContext {
        let localContext = new Emom_rest_valueContext(this.context, this.state);
        this.enterRule(localContext, 56, UniversalWorkoutLanguageParser.RULE_emom_rest_value);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 328;
            this.match(UniversalWorkoutLanguageParser.REST_EMOM);
            this.state = 329;
            this.match(UniversalWorkoutLanguageParser.REST_NUMBER);
            this.state = 330;
            this.match(UniversalWorkoutLanguageParser.REST_UNIT);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public ratio_rest_value(): Ratio_rest_valueContext {
        let localContext = new Ratio_rest_valueContext(this.context, this.state);
        this.enterRule(localContext, 58, UniversalWorkoutLanguageParser.RULE_ratio_rest_value);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 332;
            this.match(UniversalWorkoutLanguageParser.REST_RATIO);
            this.state = 333;
            this.match(UniversalWorkoutLanguageParser.REST_NUMBER);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public rest_value(): Rest_valueContext {
        let localContext = new Rest_valueContext(this.context, this.state);
        this.enterRule(localContext, 60, UniversalWorkoutLanguageParser.RULE_rest_value);
        let _la: number;
        try {
            this.state = 361;
            this.errorHandler.sync(this);
            switch (this.tokenStream.LA(1)) {
            case UniversalWorkoutLanguageParser.REST_AS_NEEDED:
                this.enterOuterAlt(localContext, 1);
                {
                this.state = 335;
                this.match(UniversalWorkoutLanguageParser.REST_AS_NEEDED);
                this.state = 338;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
                if (_la === 125) {
                    {
                    this.state = 336;
                    this.match(UniversalWorkoutLanguageParser.REST_SLASH);
                    this.state = 337;
                    this.secondary_rest_value();
                    }
                }

                }
                break;
            case UniversalWorkoutLanguageParser.REST_NUMBER:
                this.enterOuterAlt(localContext, 2);
                {
                this.state = 340;
                this.rest_time_value();
                this.state = 343;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
                if (_la === 125) {
                    {
                    this.state = 341;
                    this.match(UniversalWorkoutLanguageParser.REST_SLASH);
                    this.state = 342;
                    this.secondary_rest_value();
                    }
                }

                }
                break;
            case UniversalWorkoutLanguageParser.REST_SUPERSET:
                this.enterOuterAlt(localContext, 3);
                {
                this.state = 345;
                this.match(UniversalWorkoutLanguageParser.REST_SUPERSET);
                this.state = 348;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
                if (_la === 125) {
                    {
                    this.state = 346;
                    this.match(UniversalWorkoutLanguageParser.REST_SLASH);
                    this.state = 347;
                    this.secondary_rest_value();
                    }
                }

                }
                break;
            case UniversalWorkoutLanguageParser.REST_EMOM:
                this.enterOuterAlt(localContext, 4);
                {
                this.state = 350;
                this.emom_rest_value();
                this.state = 353;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
                if (_la === 125) {
                    {
                    this.state = 351;
                    this.match(UniversalWorkoutLanguageParser.REST_SLASH);
                    this.state = 352;
                    this.secondary_rest_value();
                    }
                }

                }
                break;
            case UniversalWorkoutLanguageParser.REST_RATIO:
                this.enterOuterAlt(localContext, 5);
                {
                this.state = 355;
                this.ratio_rest_value();
                this.state = 358;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
                if (_la === 125) {
                    {
                    this.state = 356;
                    this.match(UniversalWorkoutLanguageParser.REST_SLASH);
                    this.state = 357;
                    this.secondary_rest_value();
                    }
                }

                }
                break;
            case UniversalWorkoutLanguageParser.EOF:
            case UniversalWorkoutLanguageParser.NUMBER:
            case UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR:
            case UniversalWorkoutLanguageParser.INTO_KW:
            case UniversalWorkoutLanguageParser.MOVEMENT_MARKER:
            case UniversalWorkoutLanguageParser.OPTION_KW:
            case UniversalWorkoutLanguageParser.WOD_KW:
            case UniversalWorkoutLanguageParser.REST_KW:
            case UniversalWorkoutLanguageParser.RBRACE:
            case UniversalWorkoutLanguageParser.INLINE_COMMENT:
            case UniversalWorkoutLanguageParser.MULTILINE_COMMENT:
                this.enterOuterAlt(localContext, 6);
                // tslint:disable-next-line:no-empty
                {
                }
                break;
            default:
                throw new antlr.NoViableAltException(this);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public secondary_rest_value(): Secondary_rest_valueContext {
        let localContext = new Secondary_rest_valueContext(this.context, this.state);
        this.enterRule(localContext, 62, UniversalWorkoutLanguageParser.RULE_secondary_rest_value);
        try {
            this.state = 368;
            this.errorHandler.sync(this);
            switch (this.tokenStream.LA(1)) {
            case UniversalWorkoutLanguageParser.REST_AS_NEEDED:
                this.enterOuterAlt(localContext, 1);
                {
                this.state = 363;
                this.match(UniversalWorkoutLanguageParser.REST_AS_NEEDED);
                }
                break;
            case UniversalWorkoutLanguageParser.REST_NUMBER:
                this.enterOuterAlt(localContext, 2);
                {
                this.state = 364;
                this.rest_time_value();
                }
                break;
            case UniversalWorkoutLanguageParser.REST_SUPERSET:
                this.enterOuterAlt(localContext, 3);
                {
                this.state = 365;
                this.match(UniversalWorkoutLanguageParser.REST_SUPERSET);
                }
                break;
            case UniversalWorkoutLanguageParser.REST_EMOM:
                this.enterOuterAlt(localContext, 4);
                {
                this.state = 366;
                this.emom_rest_value();
                }
                break;
            case UniversalWorkoutLanguageParser.REST_RATIO:
                this.enterOuterAlt(localContext, 5);
                {
                this.state = 367;
                this.ratio_rest_value();
                }
                break;
            default:
                throw new antlr.NoViableAltException(this);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public shorthand_strength(): Shorthand_strengthContext {
        let localContext = new Shorthand_strengthContext(this.context, this.state);
        this.enterRule(localContext, 64, UniversalWorkoutLanguageParser.RULE_shorthand_strength);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 371;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 18) {
                {
                this.state = 370;
                localContext._name = this.match(UniversalWorkoutLanguageParser.STRING);
                }
            }

            this.state = 375;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 16) {
                {
                this.state = 373;
                this.match(UniversalWorkoutLanguageParser.REST_KW);
                this.state = 374;
                this.rest_value();
                }
            }

            this.state = 377;
            localContext._movement = this.movement();
            localContext._movements.push(localContext._movement!);
            this.state = 384;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while ((((_la) & ~0x1F) === 0 && ((1 << _la) & 50397696) !== 0)) {
                {
                this.state = 382;
                this.errorHandler.sync(this);
                switch (this.tokenStream.LA(1)) {
                case UniversalWorkoutLanguageParser.REST_KW:
                    {
                    {
                    this.state = 378;
                    this.match(UniversalWorkoutLanguageParser.REST_KW);
                    this.state = 379;
                    this.rest_value();
                    }
                    }
                    break;
                case UniversalWorkoutLanguageParser.INLINE_COMMENT:
                case UniversalWorkoutLanguageParser.MULTILINE_COMMENT:
                    {
                    this.state = 380;
                    localContext._workout_comment = this.workout_comment();
                    localContext._comments.push(localContext._workout_comment!);
                    }
                    break;
                case UniversalWorkoutLanguageParser.MOVEMENT_MARKER:
                    {
                    this.state = 381;
                    localContext._movement = this.movement();
                    localContext._movements.push(localContext._movement!);
                    }
                    break;
                default:
                    throw new antlr.NoViableAltException(this);
                }
                }
                this.state = 386;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public shorthand_generic(): Shorthand_genericContext {
        let localContext = new Shorthand_genericContext(this.context, this.state);
        this.enterRule(localContext, 66, UniversalWorkoutLanguageParser.RULE_shorthand_generic);
        let _la: number;
        try {
            let alternative: number;
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 388;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 18) {
                {
                this.state = 387;
                this.match(UniversalWorkoutLanguageParser.STRING);
                }
            }

            this.state = 390;
            this.match(UniversalWorkoutLanguageParser.GENERIC_STRING);
            this.state = 406;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 20) {
                {
                this.state = 391;
                this.match(UniversalWorkoutLanguageParser.PAREN_L);
                this.state = 393;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
                if (_la === 40) {
                    {
                    this.state = 392;
                    localContext._workout_attribute = this.workout_attribute();
                    localContext._attrs.push(localContext._workout_attribute!);
                    }
                }

                this.state = 399;
                this.errorHandler.sync(this);
                alternative = this.interpreter.adaptivePredict(this.tokenStream, 39, this.context);
                while (alternative !== 2 && alternative !== antlr.ATN.INVALID_ALT_NUMBER) {
                    if (alternative === 1) {
                        {
                        {
                        this.state = 395;
                        this.match(UniversalWorkoutLanguageParser.WORKOUT_COMMA);
                        this.state = 396;
                        localContext._workout_attribute = this.workout_attribute();
                        localContext._attrs.push(localContext._workout_attribute!);
                        }
                        }
                    }
                    this.state = 401;
                    this.errorHandler.sync(this);
                    alternative = this.interpreter.adaptivePredict(this.tokenStream, 39, this.context);
                }
                this.state = 403;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
                if (_la === 33) {
                    {
                    this.state = 402;
                    this.match(UniversalWorkoutLanguageParser.WORKOUT_COMMA);
                    }
                }

                this.state = 405;
                this.match(UniversalWorkoutLanguageParser.WORKOUT_PAREN_R);
                }
            }

            this.state = 412;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while ((((_la) & ~0x1F) === 0 && ((1 << _la) & 50332160) !== 0)) {
                {
                this.state = 410;
                this.errorHandler.sync(this);
                switch (this.tokenStream.LA(1)) {
                case UniversalWorkoutLanguageParser.MOVEMENT_MARKER:
                    {
                    this.state = 408;
                    localContext._movement = this.movement();
                    localContext._movements.push(localContext._movement!);
                    }
                    break;
                case UniversalWorkoutLanguageParser.INLINE_COMMENT:
                case UniversalWorkoutLanguageParser.MULTILINE_COMMENT:
                    {
                    this.state = 409;
                    localContext._workout_comment = this.workout_comment();
                    localContext._comments.push(localContext._workout_comment!);
                    }
                    break;
                default:
                    throw new antlr.NoViableAltException(this);
                }
                }
                this.state = 414;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public shorthand_fortime(): Shorthand_fortimeContext {
        let localContext = new Shorthand_fortimeContext(this.context, this.state);
        this.enterRule(localContext, 68, UniversalWorkoutLanguageParser.RULE_shorthand_fortime);
        let _la: number;
        try {
            let alternative: number;
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 417;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 4) {
                {
                this.state = 415;
                localContext._rounds_num = this.match(UniversalWorkoutLanguageParser.NUMBER);
                this.state = 416;
                localContext._rounds_word = this.match(UniversalWorkoutLanguageParser.ROUNDS_WORD);
                }
            }

            this.state = 419;
            this.match(UniversalWorkoutLanguageParser.FORTIME_KW);
            this.state = 421;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 107) {
                {
                this.state = 420;
                localContext._rep_sequence = this.match(UniversalWorkoutLanguageParser.FORTIME_REPS);
                }
            }

            {
            this.state = 426;
            this.errorHandler.sync(this);
            alternative = this.interpreter.adaptivePredict(this.tokenStream, 46, this.context);
            while (alternative !== 2 && alternative !== antlr.ATN.INVALID_ALT_NUMBER) {
                if (alternative === 1) {
                    {
                    {
                    this.state = 423;
                    this.intermediate_movement();
                    }
                    }
                }
                this.state = 428;
                this.errorHandler.sync(this);
                alternative = this.interpreter.adaptivePredict(this.tokenStream, 46, this.context);
            }
            }
            this.state = 429;
            this.final_movement();
            this.state = 432;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 4) {
                {
                this.state = 430;
                localContext._sets_num = this.match(UniversalWorkoutLanguageParser.NUMBER);
                this.state = 431;
                this.match(UniversalWorkoutLanguageParser.FORTIME_SET_KW);
                }
            }

            this.state = 437;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 24 || _la === 25) {
                {
                {
                this.state = 434;
                localContext._workout_comment = this.workout_comment();
                localContext._comments.push(localContext._workout_comment!);
                }
                }
                this.state = 439;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public intermediate_movement(): Intermediate_movementContext {
        let localContext = new Intermediate_movementContext(this.context, this.state);
        this.enterRule(localContext, 70, UniversalWorkoutLanguageParser.RULE_intermediate_movement);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 440;
            localContext._movement = this.movement();
            localContext._movements.push(localContext._movement!);
            this.state = 443;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 16) {
                {
                this.state = 441;
                this.match(UniversalWorkoutLanguageParser.REST_KW);
                this.state = 442;
                this.rest_value();
                }
            }

            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public final_movement(): Final_movementContext {
        let localContext = new Final_movementContext(this.context, this.state);
        this.enterRule(localContext, 72, UniversalWorkoutLanguageParser.RULE_final_movement);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 445;
            localContext._movement = this.movement();
            localContext._movements.push(localContext._movement!);
            this.state = 453;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 16) {
                {
                this.state = 446;
                this.match(UniversalWorkoutLanguageParser.REST_KW);
                this.state = 451;
                this.errorHandler.sync(this);
                switch (this.interpreter.adaptivePredict(this.tokenStream, 50, this.context) ) {
                case 1:
                    {
                    this.state = 447;
                    localContext._component_rest = this.rest_value();
                    this.state = 448;
                    this.match(UniversalWorkoutLanguageParser.INTO_KW);
                    }
                    break;
                case 2:
                    {
                    this.state = 450;
                    localContext._round_rest = this.rest_value();
                    }
                    break;
                }
                }
            }

            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public shorthand_amrap(): Shorthand_amrapContext {
        let localContext = new Shorthand_amrapContext(this.context, this.state);
        this.enterRule(localContext, 74, UniversalWorkoutLanguageParser.RULE_shorthand_amrap);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 455;
            this.match(UniversalWorkoutLanguageParser.NUMBER);
            this.state = 456;
            this.match(UniversalWorkoutLanguageParser.TIME_UNIT);
            this.state = 457;
            this.match(UniversalWorkoutLanguageParser.AMRAP_KW);
            this.state = 458;
            localContext._movement = this.movement();
            localContext._movements.push(localContext._movement!);
            this.state = 463;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while ((((_la) & ~0x1F) === 0 && ((1 << _la) & 50332160) !== 0)) {
                {
                this.state = 461;
                this.errorHandler.sync(this);
                switch (this.tokenStream.LA(1)) {
                case UniversalWorkoutLanguageParser.INLINE_COMMENT:
                case UniversalWorkoutLanguageParser.MULTILINE_COMMENT:
                    {
                    this.state = 459;
                    localContext._workout_comment = this.workout_comment();
                    localContext._comments.push(localContext._workout_comment!);
                    }
                    break;
                case UniversalWorkoutLanguageParser.MOVEMENT_MARKER:
                    {
                    this.state = 460;
                    localContext._movement = this.movement();
                    localContext._movements.push(localContext._movement!);
                    }
                    break;
                default:
                    throw new antlr.NoViableAltException(this);
                }
                }
                this.state = 465;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public shorthand_every(): Shorthand_everyContext {
        let localContext = new Shorthand_everyContext(this.context, this.state);
        this.enterRule(localContext, 76, UniversalWorkoutLanguageParser.RULE_shorthand_every);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 466;
            this.match(UniversalWorkoutLanguageParser.EVERY_KW);
            this.state = 467;
            localContext._time_value = this.match(UniversalWorkoutLanguageParser.EVERY_NUMBER);
            this.state = 468;
            localContext._time_unit = this.match(UniversalWorkoutLanguageParser.EVERY_UNIT);
            this.state = 469;
            this.match(UniversalWorkoutLanguageParser.EVERY_X);
            this.state = 470;
            localContext._sets_every = this.match(UniversalWorkoutLanguageParser.EVERY_NUMBER);
            this.state = 479;
            this.errorHandler.sync(this);
            switch (this.tokenStream.LA(1)) {
            case UniversalWorkoutLanguageParser.EVERY_SETS:
                {
                this.state = 471;
                this.match(UniversalWorkoutLanguageParser.EVERY_SETS);
                this.state = 474;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
                if (_la === 149) {
                    {
                    this.state = 472;
                    this.match(UniversalWorkoutLanguageParser.EVERY_AFTER_SETS_FOR);
                    this.state = 473;
                    localContext._goal = this.match(UniversalWorkoutLanguageParser.EVERY_GOAL_STRING);
                    }
                }

                }
                break;
            case UniversalWorkoutLanguageParser.EVERY_FOR:
                {
                this.state = 476;
                this.match(UniversalWorkoutLanguageParser.EVERY_FOR);
                this.state = 477;
                localContext._goal = this.match(UniversalWorkoutLanguageParser.EVERY_GOAL_STRING);
                }
                break;
            case UniversalWorkoutLanguageParser.EOF:
            case UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR:
            case UniversalWorkoutLanguageParser.MOVEMENT_MARKER:
            case UniversalWorkoutLanguageParser.OPTION_KW:
            case UniversalWorkoutLanguageParser.WOD_KW:
            case UniversalWorkoutLanguageParser.RBRACE:
            case UniversalWorkoutLanguageParser.INLINE_COMMENT:
            case UniversalWorkoutLanguageParser.MULTILINE_COMMENT:
                // tslint:disable-next-line:no-empty
                {
                }
                break;
            default:
                throw new antlr.NoViableAltException(this);
            }
            this.state = 485;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while ((((_la) & ~0x1F) === 0 && ((1 << _la) & 50332160) !== 0)) {
                {
                this.state = 483;
                this.errorHandler.sync(this);
                switch (this.tokenStream.LA(1)) {
                case UniversalWorkoutLanguageParser.MOVEMENT_MARKER:
                    {
                    this.state = 481;
                    localContext._movement = this.movement();
                    localContext._movements.push(localContext._movement!);
                    }
                    break;
                case UniversalWorkoutLanguageParser.INLINE_COMMENT:
                case UniversalWorkoutLanguageParser.MULTILINE_COMMENT:
                    {
                    this.state = 482;
                    localContext._workout_comment = this.workout_comment();
                    localContext._comments.push(localContext._workout_comment!);
                    }
                    break;
                default:
                    throw new antlr.NoViableAltException(this);
                }
                }
                this.state = 487;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public any_workout(): Any_workoutContext {
        let localContext = new Any_workoutContext(this.context, this.state);
        this.enterRule(localContext, 78, UniversalWorkoutLanguageParser.RULE_any_workout);
        try {
            this.state = 495;
            this.errorHandler.sync(this);
            switch (this.interpreter.adaptivePredict(this.tokenStream, 58, this.context) ) {
            case 1:
                this.enterOuterAlt(localContext, 1);
                {
                this.state = 488;
                this.workout();
                }
                break;
            case 2:
                this.enterOuterAlt(localContext, 2);
                {
                this.state = 489;
                this.shorthand_strength();
                }
                break;
            case 3:
                this.enterOuterAlt(localContext, 3);
                {
                this.state = 490;
                this.shorthand_generic();
                }
                break;
            case 4:
                this.enterOuterAlt(localContext, 4);
                {
                this.state = 491;
                this.shorthand_fortime();
                }
                break;
            case 5:
                this.enterOuterAlt(localContext, 5);
                {
                this.state = 492;
                this.shorthand_amrap();
                }
                break;
            case 6:
                this.enterOuterAlt(localContext, 6);
                {
                this.state = 493;
                this.emom();
                }
                break;
            case 7:
                this.enterOuterAlt(localContext, 7);
                {
                this.state = 494;
                this.shorthand_every();
                }
                break;
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public alternation(): AlternationContext {
        let localContext = new AlternationContext(this.context, this.state);
        this.enterRule(localContext, 80, UniversalWorkoutLanguageParser.RULE_alternation);
        let _la: number;
        try {
            let alternative: number;
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 497;
            this.match(UniversalWorkoutLanguageParser.NUMBER);
            this.state = 498;
            this.match(UniversalWorkoutLanguageParser.SUPERSET_KW);
            this.state = 499;
            this.match(UniversalWorkoutLanguageParser.LBRACE);
            this.state = 503;
            this.errorHandler.sync(this);
            alternative = this.interpreter.adaptivePredict(this.tokenStream, 59, this.context);
            while (alternative !== 2 && alternative !== antlr.ATN.INVALID_ALT_NUMBER) {
                if (alternative === 1) {
                    {
                    {
                    this.state = 500;
                    this.match(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
                    }
                    }
                }
                this.state = 505;
                this.errorHandler.sync(this);
                alternative = this.interpreter.adaptivePredict(this.tokenStream, 59, this.context);
            }
            this.state = 507;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if ((((_la) & ~0x1F) === 0 && ((1 << _la) & 67965552) !== 0)) {
                {
                this.state = 506;
                localContext._any_workout = this.any_workout();
                localContext._workouts.push(localContext._any_workout!);
                }
            }

            this.state = 517;
            this.errorHandler.sync(this);
            alternative = this.interpreter.adaptivePredict(this.tokenStream, 62, this.context);
            while (alternative !== 2 && alternative !== antlr.ATN.INVALID_ALT_NUMBER) {
                if (alternative === 1) {
                    {
                    {
                    this.state = 510;
                    this.errorHandler.sync(this);
                    _la = this.tokenStream.LA(1);
                    do {
                        {
                        {
                        this.state = 509;
                        this.match(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
                        }
                        }
                        this.state = 512;
                        this.errorHandler.sync(this);
                        _la = this.tokenStream.LA(1);
                    } while (_la === 7);
                    this.state = 514;
                    localContext._any_workout = this.any_workout();
                    localContext._workouts.push(localContext._any_workout!);
                    }
                    }
                }
                this.state = 519;
                this.errorHandler.sync(this);
                alternative = this.interpreter.adaptivePredict(this.tokenStream, 62, this.context);
            }
            this.state = 523;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 7) {
                {
                {
                this.state = 520;
                this.match(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
                }
                }
                this.state = 525;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            this.state = 526;
            this.match(UniversalWorkoutLanguageParser.RBRACE);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public option_block_title(): Option_block_titleContext {
        let localContext = new Option_block_titleContext(this.context, this.state);
        this.enterRule(localContext, 82, UniversalWorkoutLanguageParser.RULE_option_block_title);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 528;
            this.match(UniversalWorkoutLanguageParser.CHOICE_KW);
            this.state = 530;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 43) {
                {
                this.state = 529;
                this.match(UniversalWorkoutLanguageParser.CHOICE_NAME);
                }
            }

            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public option_title(): Option_titleContext {
        let localContext = new Option_titleContext(this.context, this.state);
        this.enterRule(localContext, 84, UniversalWorkoutLanguageParser.RULE_option_title);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 532;
            this.match(UniversalWorkoutLanguageParser.OPTION_KW);
            this.state = 533;
            this.match(UniversalWorkoutLanguageParser.CHOICE_NAME);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public option(): OptionContext {
        let localContext = new OptionContext(this.context, this.state);
        this.enterRule(localContext, 86, UniversalWorkoutLanguageParser.RULE_option);
        let _la: number;
        try {
            let alternative: number;
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 535;
            this.match(UniversalWorkoutLanguageParser.OPTION_KW);
            this.state = 536;
            this.match(UniversalWorkoutLanguageParser.CHOICE_NAME);
            this.state = 537;
            this.match(UniversalWorkoutLanguageParser.COLON);
            this.state = 540;
            this.errorHandler.sync(this);
            switch (this.interpreter.adaptivePredict(this.tokenStream, 65, this.context) ) {
            case 1:
                {
                this.state = 538;
                this.any_workout();
                }
                break;
            case 2:
                {
                this.state = 539;
                this.alternation();
                }
                break;
            }
            this.state = 549;
            this.errorHandler.sync(this);
            alternative = this.interpreter.adaptivePredict(this.tokenStream, 67, this.context);
            while (alternative !== 2 && alternative !== antlr.ATN.INVALID_ALT_NUMBER) {
                if (alternative === 1) {
                    {
                    {
                    this.state = 542;
                    this.match(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
                    this.state = 545;
                    this.errorHandler.sync(this);
                    switch (this.interpreter.adaptivePredict(this.tokenStream, 66, this.context) ) {
                    case 1:
                        {
                        this.state = 543;
                        this.any_workout();
                        }
                        break;
                    case 2:
                        {
                        this.state = 544;
                        this.alternation();
                        }
                        break;
                    }
                    }
                    }
                }
                this.state = 551;
                this.errorHandler.sync(this);
                alternative = this.interpreter.adaptivePredict(this.tokenStream, 67, this.context);
            }
            this.state = 553;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 7) {
                {
                this.state = 552;
                this.match(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
                }
            }

            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public option_block(): Option_blockContext {
        let localContext = new Option_blockContext(this.context, this.state);
        this.enterRule(localContext, 88, UniversalWorkoutLanguageParser.RULE_option_block);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 555;
            this.match(UniversalWorkoutLanguageParser.CHOICE_KW);
            this.state = 557;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 43) {
                {
                this.state = 556;
                this.match(UniversalWorkoutLanguageParser.CHOICE_NAME);
                }
            }

            this.state = 559;
            this.match(UniversalWorkoutLanguageParser.LBRACE);
            this.state = 561;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            do {
                {
                {
                this.state = 560;
                localContext._option = this.option();
                localContext._options.push(localContext._option!);
                }
                }
                this.state = 563;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            } while (_la === 11);
            this.state = 565;
            this.match(UniversalWorkoutLanguageParser.RBRACE);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public emom(): EmomContext {
        let localContext = new EmomContext(this.context, this.state);
        this.enterRule(localContext, 90, UniversalWorkoutLanguageParser.RULE_emom);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 567;
            this.match(UniversalWorkoutLanguageParser.EMOM_KW);
            this.state = 569;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 112) {
                {
                this.state = 568;
                this.match(UniversalWorkoutLanguageParser.EMOM_X_SEP);
                }
            }

            this.state = 571;
            localContext._emom_low_bound = this.match(UniversalWorkoutLanguageParser.EMOM_NUM);
            this.state = 574;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 113) {
                {
                this.state = 572;
                this.match(UniversalWorkoutLanguageParser.EMOM_DASH);
                this.state = 573;
                localContext._emom_high_bound = this.match(UniversalWorkoutLanguageParser.EMOM_NUM);
                }
            }

            this.state = 577;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 115) {
                {
                this.state = 576;
                this.match(UniversalWorkoutLanguageParser.EMOM_MIN_KW);
                }
            }

            this.state = 579;
            this.match(UniversalWorkoutLanguageParser.EMOM_COLON);
            this.state = 596;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 17) {
                {
                {
                this.state = 580;
                this.match(UniversalWorkoutLanguageParser.MIN_KW);
                this.state = 584;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
                while (_la === 24 || _la === 25) {
                    {
                    {
                    this.state = 581;
                    localContext._workout_comment = this.workout_comment();
                    localContext._comments.push(localContext._workout_comment!);
                    }
                    }
                    this.state = 586;
                    this.errorHandler.sync(this);
                    _la = this.tokenStream.LA(1);
                }
                this.state = 587;
                localContext._movement = this.movement();
                localContext._movements.push(localContext._movement!);
                this.state = 591;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
                while (_la === 24 || _la === 25) {
                    {
                    {
                    this.state = 588;
                    localContext._workout_comment = this.workout_comment();
                    localContext._comments.push(localContext._workout_comment!);
                    }
                    }
                    this.state = 593;
                    this.errorHandler.sync(this);
                    _la = this.tokenStream.LA(1);
                }
                }
                }
                this.state = 598;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public wod(): WodContext {
        let localContext = new WodContext(this.context, this.state);
        this.enterRule(localContext, 92, UniversalWorkoutLanguageParser.RULE_wod);
        let _la: number;
        try {
            let alternative: number;
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 608;
            this.errorHandler.sync(this);
            alternative = this.interpreter.adaptivePredict(this.tokenStream, 78, this.context);
            while (alternative !== 2 && alternative !== antlr.ATN.INVALID_ALT_NUMBER) {
                if (alternative === 1) {
                    {
                    {
                    this.state = 602;
                    this.errorHandler.sync(this);
                    switch (this.interpreter.adaptivePredict(this.tokenStream, 77, this.context) ) {
                    case 1:
                        {
                        this.state = 599;
                        this.any_workout();
                        }
                        break;
                    case 2:
                        {
                        this.state = 600;
                        this.option_block();
                        }
                        break;
                    case 3:
                        {
                        this.state = 601;
                        this.alternation();
                        }
                        break;
                    }
                    this.state = 604;
                    this.match(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
                    }
                    }
                }
                this.state = 610;
                this.errorHandler.sync(this);
                alternative = this.interpreter.adaptivePredict(this.tokenStream, 78, this.context);
            }
            this.state = 614;
            this.errorHandler.sync(this);
            switch (this.interpreter.adaptivePredict(this.tokenStream, 79, this.context) ) {
            case 1:
                {
                this.state = 611;
                this.any_workout();
                }
                break;
            case 2:
                {
                this.state = 612;
                this.option_block();
                }
                break;
            case 3:
                {
                this.state = 613;
                this.alternation();
                }
                break;
            }
            this.state = 617;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 7) {
                {
                this.state = 616;
                this.match(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
                }
            }

            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_id(): Def_idContext {
        let localContext = new Def_idContext(this.context, this.state);
        this.enterRule(localContext, 94, UniversalWorkoutLanguageParser.RULE_def_id);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 619;
            this.match(UniversalWorkoutLanguageParser.DEF_IDENTIFIER);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_super_kw(): Def_super_kwContext {
        let localContext = new Def_super_kwContext(this.context, this.state);
        this.enterRule(localContext, 96, UniversalWorkoutLanguageParser.RULE_def_super_kw);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 621;
            this.match(UniversalWorkoutLanguageParser.DEF_SUPER_KW);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_super_param(): Def_super_paramContext {
        let localContext = new Def_super_paramContext(this.context, this.state);
        this.enterRule(localContext, 98, UniversalWorkoutLanguageParser.RULE_def_super_param);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 623;
            this.match(UniversalWorkoutLanguageParser.DEF_REGEX);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_super(): Def_superContext {
        let localContext = new Def_superContext(this.context, this.state);
        this.enterRule(localContext, 100, UniversalWorkoutLanguageParser.RULE_def_super);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 625;
            this.def_super_kw();
            this.state = 626;
            this.match(UniversalWorkoutLanguageParser.DEF_PAREN_L);
            this.state = 628;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 80) {
                {
                this.state = 627;
                this.def_super_param();
                }
            }

            this.state = 630;
            this.match(UniversalWorkoutLanguageParser.DEF_PAREN_R);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_param_name(): Def_param_nameContext {
        let localContext = new Def_param_nameContext(this.context, this.state);
        this.enterRule(localContext, 102, UniversalWorkoutLanguageParser.RULE_def_param_name);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 632;
            this.match(UniversalWorkoutLanguageParser.DEF_REGEX);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_param_enum_entry(): Def_param_enum_entryContext {
        let localContext = new Def_param_enum_entryContext(this.context, this.state);
        this.enterRule(localContext, 104, UniversalWorkoutLanguageParser.RULE_def_param_enum_entry);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 634;
            this.match(UniversalWorkoutLanguageParser.DEF_IDENTIFIER);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_param_enum(): Def_param_enumContext {
        let localContext = new Def_param_enumContext(this.context, this.state);
        this.enterRule(localContext, 106, UniversalWorkoutLanguageParser.RULE_def_param_enum);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 636;
            localContext._def_param_enum_entry = this.def_param_enum_entry();
            localContext._def_param_enum_entries.push(localContext._def_param_enum_entry!);
            this.state = 641;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 85) {
                {
                {
                this.state = 637;
                this.match(UniversalWorkoutLanguageParser.DEF_COMMA);
                this.state = 638;
                localContext._def_param_enum_entry = this.def_param_enum_entry();
                localContext._def_param_enum_entries.push(localContext._def_param_enum_entry!);
                }
                }
                this.state = 643;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_param_type(): Def_param_typeContext {
        let localContext = new Def_param_typeContext(this.context, this.state);
        this.enterRule(localContext, 108, UniversalWorkoutLanguageParser.RULE_def_param_type);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 650;
            this.errorHandler.sync(this);
            switch (this.tokenStream.LA(1)) {
            case UniversalWorkoutLanguageParser.DEF_TYPES:
                {
                this.state = 644;
                this.match(UniversalWorkoutLanguageParser.DEF_TYPES);
                }
                break;
            case UniversalWorkoutLanguageParser.DEF_ENUM_KW:
                {
                {
                this.state = 645;
                this.match(UniversalWorkoutLanguageParser.DEF_ENUM_KW);
                this.state = 646;
                this.match(UniversalWorkoutLanguageParser.DEF_PAREN_L);
                this.state = 647;
                this.def_param_enum();
                this.state = 648;
                this.match(UniversalWorkoutLanguageParser.DEF_PAREN_R);
                }
                }
                break;
            default:
                throw new antlr.NoViableAltException(this);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_param(): Def_paramContext {
        let localContext = new Def_paramContext(this.context, this.state);
        this.enterRule(localContext, 110, UniversalWorkoutLanguageParser.RULE_def_param);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 652;
            this.def_param_name();
            this.state = 653;
            this.match(UniversalWorkoutLanguageParser.DEF_COLON);
            {
            this.state = 654;
            this.def_param_type();
            }
            this.state = 656;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 84) {
                {
                this.state = 655;
                this.match(UniversalWorkoutLanguageParser.DEF_OPTIONAL);
                }
            }

            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public definition(): DefinitionContext {
        let localContext = new DefinitionContext(this.context, this.state);
        this.enterRule(localContext, 112, UniversalWorkoutLanguageParser.RULE_definition);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 658;
            this.match(UniversalWorkoutLanguageParser.DEF_KW);
            this.state = 659;
            this.def_id();
            this.state = 662;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 83) {
                {
                this.state = 660;
                this.match(UniversalWorkoutLanguageParser.DEF_COLON);
                this.state = 661;
                this.def_super();
                }
            }

            this.state = 664;
            this.match(UniversalWorkoutLanguageParser.DEF_BRACE_L);
            this.state = 665;
            this.def_param();
            this.state = 670;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 85) {
                {
                {
                this.state = 666;
                this.match(UniversalWorkoutLanguageParser.DEF_COMMA);
                this.state = 667;
                this.def_param();
                }
                }
                this.state = 672;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            this.state = 673;
            this.match(UniversalWorkoutLanguageParser.DEF_BRACE_R);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public defs(): DefsContext {
        let localContext = new DefsContext(this.context, this.state);
        this.enterRule(localContext, 114, UniversalWorkoutLanguageParser.RULE_defs);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 681;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while ((((_la) & ~0x1F) === 0 && ((1 << _la) & 49164) !== 0)) {
                {
                this.state = 679;
                this.errorHandler.sync(this);
                switch (this.tokenStream.LA(1)) {
                case UniversalWorkoutLanguageParser.DEF_KW:
                    {
                    this.state = 675;
                    this.definition();
                    }
                    break;
                case UniversalWorkoutLanguageParser.NEW_MOV_KW:
                    {
                    this.state = 676;
                    this.new_movement();
                    }
                    break;
                case UniversalWorkoutLanguageParser.WS:
                    {
                    this.state = 677;
                    this.match(UniversalWorkoutLanguageParser.WS);
                    }
                    break;
                case UniversalWorkoutLanguageParser.NL:
                    {
                    this.state = 678;
                    this.match(UniversalWorkoutLanguageParser.NL);
                    }
                    break;
                default:
                    throw new antlr.NoViableAltException(this);
                }
                }
                this.state = 683;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            this.state = 684;
            this.match(UniversalWorkoutLanguageParser.EOF);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public new_movement_name(): New_movement_nameContext {
        let localContext = new New_movement_nameContext(this.context, this.state);
        this.enterRule(localContext, 116, UniversalWorkoutLanguageParser.RULE_new_movement_name);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 686;
            this.match(UniversalWorkoutLanguageParser.NEW_MOV_IDENTIFIER);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public new_movement_params(): New_movement_paramsContext {
        let localContext = new New_movement_paramsContext(this.context, this.state);
        this.enterRule(localContext, 118, UniversalWorkoutLanguageParser.RULE_new_movement_params);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 688;
            localContext._NEW_MOV_PARAM = this.match(UniversalWorkoutLanguageParser.NEW_MOV_PARAM);
            localContext._nmp.push(localContext._NEW_MOV_PARAM!);
            {
            this.state = 689;
            this.match(UniversalWorkoutLanguageParser.NEW_MOV_COMMA);
            this.state = 690;
            localContext._NEW_MOV_PARAM = this.match(UniversalWorkoutLanguageParser.NEW_MOV_PARAM);
            localContext._nmp.push(localContext._NEW_MOV_PARAM!);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public new_movement(): New_movementContext {
        let localContext = new New_movementContext(this.context, this.state);
        this.enterRule(localContext, 120, UniversalWorkoutLanguageParser.RULE_new_movement);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 692;
            this.match(UniversalWorkoutLanguageParser.NEW_MOV_KW);
            this.state = 693;
            this.new_movement_name();
            this.state = 694;
            this.match(UniversalWorkoutLanguageParser.NEW_MOV_COLON);
            this.state = 695;
            this.new_movement_params();
            this.state = 696;
            this.match(UniversalWorkoutLanguageParser.NEW_MOV_SEMI);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public day(): DayContext {
        let localContext = new DayContext(this.context, this.state);
        this.enterRule(localContext, 122, UniversalWorkoutLanguageParser.RULE_day);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 699;
            this.errorHandler.sync(this);
            switch (this.interpreter.adaptivePredict(this.tokenStream, 89, this.context) ) {
            case 1:
                {
                this.state = 698;
                this.match(UniversalWorkoutLanguageParser.WOD_KW);
                }
                break;
            }
            this.state = 702;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if ((((_la) & ~0x1F) === 0 && ((1 << _la) & 67966576) !== 0)) {
                {
                this.state = 701;
                localContext._wod = this.wod();
                localContext._wods.push(localContext._wod!);
                }
            }

            this.state = 710;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 13) {
                {
                {
                this.state = 704;
                this.match(UniversalWorkoutLanguageParser.WOD_KW);
                this.state = 706;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
                if ((((_la) & ~0x1F) === 0 && ((1 << _la) & 67966576) !== 0)) {
                    {
                    this.state = 705;
                    localContext._wod = this.wod();
                    localContext._wods.push(localContext._wod!);
                    }
                }

                }
                }
                this.state = 712;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            this.state = 713;
            this.match(UniversalWorkoutLanguageParser.EOF);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }

    public static readonly _serializedATN: number[] = [
        4,1,155,716,2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,2,4,7,4,2,5,7,5,2,6,
        7,6,2,7,7,7,2,8,7,8,2,9,7,9,2,10,7,10,2,11,7,11,2,12,7,12,2,13,7,
        13,2,14,7,14,2,15,7,15,2,16,7,16,2,17,7,17,2,18,7,18,2,19,7,19,2,
        20,7,20,2,21,7,21,2,22,7,22,2,23,7,23,2,24,7,24,2,25,7,25,2,26,7,
        26,2,27,7,27,2,28,7,28,2,29,7,29,2,30,7,30,2,31,7,31,2,32,7,32,2,
        33,7,33,2,34,7,34,2,35,7,35,2,36,7,36,2,37,7,37,2,38,7,38,2,39,7,
        39,2,40,7,40,2,41,7,41,2,42,7,42,2,43,7,43,2,44,7,44,2,45,7,45,2,
        46,7,46,2,47,7,47,2,48,7,48,2,49,7,49,2,50,7,50,2,51,7,51,2,52,7,
        52,2,53,7,53,2,54,7,54,2,55,7,55,2,56,7,56,2,57,7,57,2,58,7,58,2,
        59,7,59,2,60,7,60,2,61,7,61,1,0,1,0,1,1,1,1,1,2,1,2,1,2,1,2,5,2,
        133,8,2,10,2,12,2,136,9,2,1,3,1,3,1,3,1,3,1,3,1,3,3,3,144,8,3,1,
        4,1,4,1,4,1,4,1,4,3,4,151,8,4,1,4,3,4,154,8,4,1,5,1,5,1,6,1,6,1,
        6,5,6,161,8,6,10,6,12,6,164,9,6,1,6,1,6,1,7,1,7,1,7,5,7,171,8,7,
        10,7,12,7,174,9,7,1,7,1,7,1,8,1,8,1,8,1,8,1,8,1,8,1,8,3,8,185,8,
        8,1,9,1,9,1,9,1,9,5,9,191,8,9,10,9,12,9,194,9,9,1,9,1,9,1,10,1,10,
        4,10,200,8,10,11,10,12,10,201,1,11,1,11,1,11,1,11,5,11,208,8,11,
        10,11,12,11,211,9,11,1,11,1,11,1,12,1,12,1,12,1,12,5,12,219,8,12,
        10,12,12,12,222,9,12,1,12,1,12,1,13,1,13,1,13,1,13,5,13,230,8,13,
        10,13,12,13,233,9,13,1,13,1,13,1,14,3,14,238,8,14,1,14,1,14,1,14,
        3,14,243,8,14,1,14,5,14,246,8,14,10,14,12,14,249,9,14,1,15,1,15,
        3,15,253,8,15,1,16,1,16,1,16,1,16,1,16,1,16,1,16,1,16,1,16,1,16,
        1,16,1,16,3,16,267,8,16,4,16,269,8,16,11,16,12,16,270,3,16,273,8,
        16,1,16,4,16,276,8,16,11,16,12,16,277,1,17,1,17,1,18,1,18,1,19,1,
        19,1,20,1,20,1,21,1,21,1,22,1,22,1,23,1,23,1,24,1,24,1,24,1,24,1,
        25,1,25,1,26,1,26,3,26,302,8,26,1,26,1,26,3,26,306,8,26,1,26,1,26,
        5,26,310,8,26,10,26,12,26,313,9,26,1,26,3,26,316,8,26,1,26,1,26,
        1,26,5,26,321,8,26,10,26,12,26,324,9,26,1,27,1,27,1,27,1,28,1,28,
        1,28,1,28,1,29,1,29,1,29,1,30,1,30,1,30,3,30,339,8,30,1,30,1,30,
        1,30,3,30,344,8,30,1,30,1,30,1,30,3,30,349,8,30,1,30,1,30,1,30,3,
        30,354,8,30,1,30,1,30,1,30,3,30,359,8,30,1,30,3,30,362,8,30,1,31,
        1,31,1,31,1,31,1,31,3,31,369,8,31,1,32,3,32,372,8,32,1,32,1,32,3,
        32,376,8,32,1,32,1,32,1,32,1,32,1,32,5,32,383,8,32,10,32,12,32,386,
        9,32,1,33,3,33,389,8,33,1,33,1,33,1,33,3,33,394,8,33,1,33,1,33,5,
        33,398,8,33,10,33,12,33,401,9,33,1,33,3,33,404,8,33,1,33,3,33,407,
        8,33,1,33,1,33,5,33,411,8,33,10,33,12,33,414,9,33,1,34,1,34,3,34,
        418,8,34,1,34,1,34,3,34,422,8,34,1,34,5,34,425,8,34,10,34,12,34,
        428,9,34,1,34,1,34,1,34,3,34,433,8,34,1,34,5,34,436,8,34,10,34,12,
        34,439,9,34,1,35,1,35,1,35,3,35,444,8,35,1,36,1,36,1,36,1,36,1,36,
        1,36,3,36,452,8,36,3,36,454,8,36,1,37,1,37,1,37,1,37,1,37,1,37,5,
        37,462,8,37,10,37,12,37,465,9,37,1,38,1,38,1,38,1,38,1,38,1,38,1,
        38,1,38,3,38,475,8,38,1,38,1,38,1,38,3,38,480,8,38,1,38,1,38,5,38,
        484,8,38,10,38,12,38,487,9,38,1,39,1,39,1,39,1,39,1,39,1,39,1,39,
        3,39,496,8,39,1,40,1,40,1,40,1,40,5,40,502,8,40,10,40,12,40,505,
        9,40,1,40,3,40,508,8,40,1,40,4,40,511,8,40,11,40,12,40,512,1,40,
        5,40,516,8,40,10,40,12,40,519,9,40,1,40,5,40,522,8,40,10,40,12,40,
        525,9,40,1,40,1,40,1,41,1,41,3,41,531,8,41,1,42,1,42,1,42,1,43,1,
        43,1,43,1,43,1,43,3,43,541,8,43,1,43,1,43,1,43,3,43,546,8,43,5,43,
        548,8,43,10,43,12,43,551,9,43,1,43,3,43,554,8,43,1,44,1,44,3,44,
        558,8,44,1,44,1,44,4,44,562,8,44,11,44,12,44,563,1,44,1,44,1,45,
        1,45,3,45,570,8,45,1,45,1,45,1,45,3,45,575,8,45,1,45,3,45,578,8,
        45,1,45,1,45,1,45,5,45,583,8,45,10,45,12,45,586,9,45,1,45,1,45,5,
        45,590,8,45,10,45,12,45,593,9,45,5,45,595,8,45,10,45,12,45,598,9,
        45,1,46,1,46,1,46,3,46,603,8,46,1,46,1,46,5,46,607,8,46,10,46,12,
        46,610,9,46,1,46,1,46,1,46,3,46,615,8,46,1,46,3,46,618,8,46,1,47,
        1,47,1,48,1,48,1,49,1,49,1,50,1,50,1,50,3,50,629,8,50,1,50,1,50,
        1,51,1,51,1,52,1,52,1,53,1,53,1,53,5,53,640,8,53,10,53,12,53,643,
        9,53,1,54,1,54,1,54,1,54,1,54,1,54,3,54,651,8,54,1,55,1,55,1,55,
        1,55,3,55,657,8,55,1,56,1,56,1,56,1,56,3,56,663,8,56,1,56,1,56,1,
        56,1,56,5,56,669,8,56,10,56,12,56,672,9,56,1,56,1,56,1,57,1,57,1,
        57,1,57,5,57,680,8,57,10,57,12,57,683,9,57,1,57,1,57,1,58,1,58,1,
        59,1,59,1,59,1,59,1,60,1,60,1,60,1,60,1,60,1,60,1,61,3,61,700,8,
        61,1,61,3,61,703,8,61,1,61,1,61,3,61,707,8,61,5,61,709,8,61,10,61,
        12,61,712,9,61,1,61,1,61,1,61,0,0,62,0,2,4,6,8,10,12,14,16,18,20,
        22,24,26,28,30,32,34,36,38,40,42,44,46,48,50,52,54,56,58,60,62,64,
        66,68,70,72,74,76,78,80,82,84,86,88,90,92,94,96,98,100,102,104,106,
        108,110,112,114,116,118,120,122,0,7,3,0,54,54,56,56,73,73,3,0,54,
        54,56,57,73,73,2,0,56,57,73,73,1,0,24,25,1,0,35,36,2,0,37,37,39,
        39,1,0,35,39,778,0,124,1,0,0,0,2,126,1,0,0,0,4,128,1,0,0,0,6,143,
        1,0,0,0,8,145,1,0,0,0,10,155,1,0,0,0,12,157,1,0,0,0,14,167,1,0,0,
        0,16,184,1,0,0,0,18,186,1,0,0,0,20,197,1,0,0,0,22,203,1,0,0,0,24,
        214,1,0,0,0,26,225,1,0,0,0,28,237,1,0,0,0,30,252,1,0,0,0,32,254,
        1,0,0,0,34,279,1,0,0,0,36,281,1,0,0,0,38,283,1,0,0,0,40,285,1,0,
        0,0,42,287,1,0,0,0,44,289,1,0,0,0,46,291,1,0,0,0,48,293,1,0,0,0,
        50,297,1,0,0,0,52,299,1,0,0,0,54,325,1,0,0,0,56,328,1,0,0,0,58,332,
        1,0,0,0,60,361,1,0,0,0,62,368,1,0,0,0,64,371,1,0,0,0,66,388,1,0,
        0,0,68,417,1,0,0,0,70,440,1,0,0,0,72,445,1,0,0,0,74,455,1,0,0,0,
        76,466,1,0,0,0,78,495,1,0,0,0,80,497,1,0,0,0,82,528,1,0,0,0,84,532,
        1,0,0,0,86,535,1,0,0,0,88,555,1,0,0,0,90,567,1,0,0,0,92,608,1,0,
        0,0,94,619,1,0,0,0,96,621,1,0,0,0,98,623,1,0,0,0,100,625,1,0,0,0,
        102,632,1,0,0,0,104,634,1,0,0,0,106,636,1,0,0,0,108,650,1,0,0,0,
        110,652,1,0,0,0,112,658,1,0,0,0,114,681,1,0,0,0,116,686,1,0,0,0,
        118,688,1,0,0,0,120,692,1,0,0,0,122,699,1,0,0,0,124,125,5,28,0,0,
        125,1,1,0,0,0,126,127,7,0,0,0,127,3,1,0,0,0,128,129,5,67,0,0,129,
        134,3,6,3,0,130,131,5,65,0,0,131,133,3,6,3,0,132,130,1,0,0,0,133,
        136,1,0,0,0,134,132,1,0,0,0,134,135,1,0,0,0,135,5,1,0,0,0,136,134,
        1,0,0,0,137,144,5,56,0,0,138,144,5,57,0,0,139,144,5,55,0,0,140,144,
        3,8,4,0,141,144,5,54,0,0,142,144,5,73,0,0,143,137,1,0,0,0,143,138,
        1,0,0,0,143,139,1,0,0,0,143,140,1,0,0,0,143,141,1,0,0,0,143,142,
        1,0,0,0,144,7,1,0,0,0,145,153,5,58,0,0,146,150,5,59,0,0,147,148,
        5,61,0,0,148,151,5,56,0,0,149,151,5,56,0,0,150,147,1,0,0,0,150,149,
        1,0,0,0,151,152,1,0,0,0,152,154,5,60,0,0,153,146,1,0,0,0,153,154,
        1,0,0,0,154,9,1,0,0,0,155,156,5,73,0,0,156,11,1,0,0,0,157,162,7,
        1,0,0,158,159,5,65,0,0,159,161,7,1,0,0,160,158,1,0,0,0,161,164,1,
        0,0,0,162,160,1,0,0,0,162,163,1,0,0,0,163,165,1,0,0,0,164,162,1,
        0,0,0,165,166,5,62,0,0,166,13,1,0,0,0,167,172,7,1,0,0,168,169,5,
        65,0,0,169,171,7,1,0,0,170,168,1,0,0,0,171,174,1,0,0,0,172,170,1,
        0,0,0,172,173,1,0,0,0,173,175,1,0,0,0,174,172,1,0,0,0,175,176,5,
        63,0,0,176,15,1,0,0,0,177,178,5,56,0,0,178,179,5,66,0,0,179,185,
        5,56,0,0,180,185,5,56,0,0,181,185,5,57,0,0,182,185,5,73,0,0,183,
        185,5,54,0,0,184,177,1,0,0,0,184,180,1,0,0,0,184,181,1,0,0,0,184,
        182,1,0,0,0,184,183,1,0,0,0,185,17,1,0,0,0,186,187,5,61,0,0,187,
        192,3,16,8,0,188,189,5,65,0,0,189,191,3,16,8,0,190,188,1,0,0,0,191,
        194,1,0,0,0,192,190,1,0,0,0,192,193,1,0,0,0,193,195,1,0,0,0,194,
        192,1,0,0,0,195,196,5,74,0,0,196,19,1,0,0,0,197,199,5,68,0,0,198,
        200,5,77,0,0,199,198,1,0,0,0,200,201,1,0,0,0,201,199,1,0,0,0,201,
        202,1,0,0,0,202,21,1,0,0,0,203,204,5,69,0,0,204,209,7,1,0,0,205,
        206,5,65,0,0,206,208,7,1,0,0,207,205,1,0,0,0,208,211,1,0,0,0,209,
        207,1,0,0,0,209,210,1,0,0,0,210,212,1,0,0,0,211,209,1,0,0,0,212,
        213,5,74,0,0,213,23,1,0,0,0,214,215,5,70,0,0,215,220,7,1,0,0,216,
        217,5,65,0,0,217,219,7,1,0,0,218,216,1,0,0,0,219,222,1,0,0,0,220,
        218,1,0,0,0,220,221,1,0,0,0,221,223,1,0,0,0,222,220,1,0,0,0,223,
        224,5,74,0,0,224,25,1,0,0,0,225,226,5,71,0,0,226,231,7,2,0,0,227,
        228,5,65,0,0,228,230,7,2,0,0,229,227,1,0,0,0,230,233,1,0,0,0,231,
        229,1,0,0,0,231,232,1,0,0,0,232,234,1,0,0,0,233,231,1,0,0,0,234,
        235,5,74,0,0,235,27,1,0,0,0,236,238,5,47,0,0,237,236,1,0,0,0,237,
        238,1,0,0,0,238,239,1,0,0,0,239,247,5,46,0,0,240,242,5,48,0,0,241,
        243,5,47,0,0,242,241,1,0,0,0,242,243,1,0,0,0,243,244,1,0,0,0,244,
        246,5,46,0,0,245,240,1,0,0,0,246,249,1,0,0,0,247,245,1,0,0,0,247,
        248,1,0,0,0,248,29,1,0,0,0,249,247,1,0,0,0,250,253,5,46,0,0,251,
        253,3,28,14,0,252,250,1,0,0,0,252,251,1,0,0,0,253,31,1,0,0,0,254,
        255,5,9,0,0,255,256,3,30,15,0,256,272,5,50,0,0,257,267,3,4,2,0,258,
        267,3,18,9,0,259,267,3,20,10,0,260,267,3,22,11,0,261,267,3,24,12,
        0,262,267,3,2,1,0,263,267,3,12,6,0,264,267,3,14,7,0,265,267,3,26,
        13,0,266,257,1,0,0,0,266,258,1,0,0,0,266,259,1,0,0,0,266,260,1,0,
        0,0,266,261,1,0,0,0,266,262,1,0,0,0,266,263,1,0,0,0,266,264,1,0,
        0,0,266,265,1,0,0,0,267,269,1,0,0,0,268,266,1,0,0,0,269,270,1,0,
        0,0,270,268,1,0,0,0,270,271,1,0,0,0,271,273,1,0,0,0,272,268,1,0,
        0,0,272,273,1,0,0,0,273,275,1,0,0,0,274,276,5,72,0,0,275,274,1,0,
        0,0,276,277,1,0,0,0,277,275,1,0,0,0,277,278,1,0,0,0,278,33,1,0,0,
        0,279,280,7,3,0,0,280,35,1,0,0,0,281,282,5,26,0,0,282,37,1,0,0,0,
        283,284,5,40,0,0,284,39,1,0,0,0,285,286,7,4,0,0,286,41,1,0,0,0,287,
        288,5,38,0,0,288,43,1,0,0,0,289,290,7,5,0,0,290,45,1,0,0,0,291,292,
        7,6,0,0,292,47,1,0,0,0,293,294,3,38,19,0,294,295,5,32,0,0,295,296,
        3,46,23,0,296,49,1,0,0,0,297,298,7,3,0,0,298,51,1,0,0,0,299,301,
        3,36,18,0,300,302,5,35,0,0,301,300,1,0,0,0,301,302,1,0,0,0,302,303,
        1,0,0,0,303,305,5,30,0,0,304,306,3,48,24,0,305,304,1,0,0,0,305,306,
        1,0,0,0,306,311,1,0,0,0,307,308,5,33,0,0,308,310,3,48,24,0,309,307,
        1,0,0,0,310,313,1,0,0,0,311,309,1,0,0,0,311,312,1,0,0,0,312,315,
        1,0,0,0,313,311,1,0,0,0,314,316,5,33,0,0,315,314,1,0,0,0,315,316,
        1,0,0,0,316,317,1,0,0,0,317,322,5,31,0,0,318,321,3,32,16,0,319,321,
        3,50,25,0,320,318,1,0,0,0,320,319,1,0,0,0,321,324,1,0,0,0,322,320,
        1,0,0,0,322,323,1,0,0,0,323,53,1,0,0,0,324,322,1,0,0,0,325,326,5,
        123,0,0,326,327,5,124,0,0,327,55,1,0,0,0,328,329,5,127,0,0,329,330,
        5,123,0,0,330,331,5,124,0,0,331,57,1,0,0,0,332,333,5,128,0,0,333,
        334,5,123,0,0,334,59,1,0,0,0,335,338,5,122,0,0,336,337,5,125,0,0,
        337,339,3,62,31,0,338,336,1,0,0,0,338,339,1,0,0,0,339,362,1,0,0,
        0,340,343,3,54,27,0,341,342,5,125,0,0,342,344,3,62,31,0,343,341,
        1,0,0,0,343,344,1,0,0,0,344,362,1,0,0,0,345,348,5,126,0,0,346,347,
        5,125,0,0,347,349,3,62,31,0,348,346,1,0,0,0,348,349,1,0,0,0,349,
        362,1,0,0,0,350,353,3,56,28,0,351,352,5,125,0,0,352,354,3,62,31,
        0,353,351,1,0,0,0,353,354,1,0,0,0,354,362,1,0,0,0,355,358,3,58,29,
        0,356,357,5,125,0,0,357,359,3,62,31,0,358,356,1,0,0,0,358,359,1,
        0,0,0,359,362,1,0,0,0,360,362,1,0,0,0,361,335,1,0,0,0,361,340,1,
        0,0,0,361,345,1,0,0,0,361,350,1,0,0,0,361,355,1,0,0,0,361,360,1,
        0,0,0,362,61,1,0,0,0,363,369,5,122,0,0,364,369,3,54,27,0,365,369,
        5,126,0,0,366,369,3,56,28,0,367,369,3,58,29,0,368,363,1,0,0,0,368,
        364,1,0,0,0,368,365,1,0,0,0,368,366,1,0,0,0,368,367,1,0,0,0,369,
        63,1,0,0,0,370,372,5,18,0,0,371,370,1,0,0,0,371,372,1,0,0,0,372,
        375,1,0,0,0,373,374,5,16,0,0,374,376,3,60,30,0,375,373,1,0,0,0,375,
        376,1,0,0,0,376,377,1,0,0,0,377,384,3,32,16,0,378,379,5,16,0,0,379,
        383,3,60,30,0,380,383,3,50,25,0,381,383,3,32,16,0,382,378,1,0,0,
        0,382,380,1,0,0,0,382,381,1,0,0,0,383,386,1,0,0,0,384,382,1,0,0,
        0,384,385,1,0,0,0,385,65,1,0,0,0,386,384,1,0,0,0,387,389,5,18,0,
        0,388,387,1,0,0,0,388,389,1,0,0,0,389,390,1,0,0,0,390,406,5,19,0,
        0,391,393,5,20,0,0,392,394,3,48,24,0,393,392,1,0,0,0,393,394,1,0,
        0,0,394,399,1,0,0,0,395,396,5,33,0,0,396,398,3,48,24,0,397,395,1,
        0,0,0,398,401,1,0,0,0,399,397,1,0,0,0,399,400,1,0,0,0,400,403,1,
        0,0,0,401,399,1,0,0,0,402,404,5,33,0,0,403,402,1,0,0,0,403,404,1,
        0,0,0,404,405,1,0,0,0,405,407,5,31,0,0,406,391,1,0,0,0,406,407,1,
        0,0,0,407,412,1,0,0,0,408,411,3,32,16,0,409,411,3,50,25,0,410,408,
        1,0,0,0,410,409,1,0,0,0,411,414,1,0,0,0,412,410,1,0,0,0,412,413,
        1,0,0,0,413,67,1,0,0,0,414,412,1,0,0,0,415,416,5,4,0,0,416,418,5,
        101,0,0,417,415,1,0,0,0,417,418,1,0,0,0,418,419,1,0,0,0,419,421,
        5,5,0,0,420,422,5,107,0,0,421,420,1,0,0,0,421,422,1,0,0,0,422,426,
        1,0,0,0,423,425,3,70,35,0,424,423,1,0,0,0,425,428,1,0,0,0,426,424,
        1,0,0,0,426,427,1,0,0,0,427,429,1,0,0,0,428,426,1,0,0,0,429,432,
        3,72,36,0,430,431,5,4,0,0,431,433,5,103,0,0,432,430,1,0,0,0,432,
        433,1,0,0,0,433,437,1,0,0,0,434,436,3,50,25,0,435,434,1,0,0,0,436,
        439,1,0,0,0,437,435,1,0,0,0,437,438,1,0,0,0,438,69,1,0,0,0,439,437,
        1,0,0,0,440,443,3,32,16,0,441,442,5,16,0,0,442,444,3,60,30,0,443,
        441,1,0,0,0,443,444,1,0,0,0,444,71,1,0,0,0,445,453,3,32,16,0,446,
        451,5,16,0,0,447,448,3,60,30,0,448,449,5,8,0,0,449,452,1,0,0,0,450,
        452,3,60,30,0,451,447,1,0,0,0,451,450,1,0,0,0,452,454,1,0,0,0,453,
        446,1,0,0,0,453,454,1,0,0,0,454,73,1,0,0,0,455,456,5,4,0,0,456,457,
        5,104,0,0,457,458,5,110,0,0,458,463,3,32,16,0,459,462,3,50,25,0,
        460,462,3,32,16,0,461,459,1,0,0,0,461,460,1,0,0,0,462,465,1,0,0,
        0,463,461,1,0,0,0,463,464,1,0,0,0,464,75,1,0,0,0,465,463,1,0,0,0,
        466,467,5,6,0,0,467,468,5,142,0,0,468,469,5,144,0,0,469,470,5,143,
        0,0,470,479,5,142,0,0,471,474,5,146,0,0,472,473,5,149,0,0,473,475,
        5,153,0,0,474,472,1,0,0,0,474,475,1,0,0,0,475,480,1,0,0,0,476,477,
        5,145,0,0,477,480,5,153,0,0,478,480,1,0,0,0,479,471,1,0,0,0,479,
        476,1,0,0,0,479,478,1,0,0,0,480,485,1,0,0,0,481,484,3,32,16,0,482,
        484,3,50,25,0,483,481,1,0,0,0,483,482,1,0,0,0,484,487,1,0,0,0,485,
        483,1,0,0,0,485,486,1,0,0,0,486,77,1,0,0,0,487,485,1,0,0,0,488,496,
        3,52,26,0,489,496,3,64,32,0,490,496,3,66,33,0,491,496,3,68,34,0,
        492,496,3,74,37,0,493,496,3,90,45,0,494,496,3,76,38,0,495,488,1,
        0,0,0,495,489,1,0,0,0,495,490,1,0,0,0,495,491,1,0,0,0,495,492,1,
        0,0,0,495,493,1,0,0,0,495,494,1,0,0,0,496,79,1,0,0,0,497,498,5,4,
        0,0,498,499,5,102,0,0,499,503,5,21,0,0,500,502,5,7,0,0,501,500,1,
        0,0,0,502,505,1,0,0,0,503,501,1,0,0,0,503,504,1,0,0,0,504,507,1,
        0,0,0,505,503,1,0,0,0,506,508,3,78,39,0,507,506,1,0,0,0,507,508,
        1,0,0,0,508,517,1,0,0,0,509,511,5,7,0,0,510,509,1,0,0,0,511,512,
        1,0,0,0,512,510,1,0,0,0,512,513,1,0,0,0,513,514,1,0,0,0,514,516,
        3,78,39,0,515,510,1,0,0,0,516,519,1,0,0,0,517,515,1,0,0,0,517,518,
        1,0,0,0,518,523,1,0,0,0,519,517,1,0,0,0,520,522,5,7,0,0,521,520,
        1,0,0,0,522,525,1,0,0,0,523,521,1,0,0,0,523,524,1,0,0,0,524,526,
        1,0,0,0,525,523,1,0,0,0,526,527,5,22,0,0,527,81,1,0,0,0,528,530,
        5,10,0,0,529,531,5,43,0,0,530,529,1,0,0,0,530,531,1,0,0,0,531,83,
        1,0,0,0,532,533,5,11,0,0,533,534,5,43,0,0,534,85,1,0,0,0,535,536,
        5,11,0,0,536,537,5,43,0,0,537,540,5,23,0,0,538,541,3,78,39,0,539,
        541,3,80,40,0,540,538,1,0,0,0,540,539,1,0,0,0,541,549,1,0,0,0,542,
        545,5,7,0,0,543,546,3,78,39,0,544,546,3,80,40,0,545,543,1,0,0,0,
        545,544,1,0,0,0,546,548,1,0,0,0,547,542,1,0,0,0,548,551,1,0,0,0,
        549,547,1,0,0,0,549,550,1,0,0,0,550,553,1,0,0,0,551,549,1,0,0,0,
        552,554,5,7,0,0,553,552,1,0,0,0,553,554,1,0,0,0,554,87,1,0,0,0,555,
        557,5,10,0,0,556,558,5,43,0,0,557,556,1,0,0,0,557,558,1,0,0,0,558,
        559,1,0,0,0,559,561,5,21,0,0,560,562,3,86,43,0,561,560,1,0,0,0,562,
        563,1,0,0,0,563,561,1,0,0,0,563,564,1,0,0,0,564,565,1,0,0,0,565,
        566,5,22,0,0,566,89,1,0,0,0,567,569,5,12,0,0,568,570,5,112,0,0,569,
        568,1,0,0,0,569,570,1,0,0,0,570,571,1,0,0,0,571,574,5,116,0,0,572,
        573,5,113,0,0,573,575,5,116,0,0,574,572,1,0,0,0,574,575,1,0,0,0,
        575,577,1,0,0,0,576,578,5,115,0,0,577,576,1,0,0,0,577,578,1,0,0,
        0,578,579,1,0,0,0,579,596,5,114,0,0,580,584,5,17,0,0,581,583,3,50,
        25,0,582,581,1,0,0,0,583,586,1,0,0,0,584,582,1,0,0,0,584,585,1,0,
        0,0,585,587,1,0,0,0,586,584,1,0,0,0,587,591,3,32,16,0,588,590,3,
        50,25,0,589,588,1,0,0,0,590,593,1,0,0,0,591,589,1,0,0,0,591,592,
        1,0,0,0,592,595,1,0,0,0,593,591,1,0,0,0,594,580,1,0,0,0,595,598,
        1,0,0,0,596,594,1,0,0,0,596,597,1,0,0,0,597,91,1,0,0,0,598,596,1,
        0,0,0,599,603,3,78,39,0,600,603,3,88,44,0,601,603,3,80,40,0,602,
        599,1,0,0,0,602,600,1,0,0,0,602,601,1,0,0,0,603,604,1,0,0,0,604,
        605,5,7,0,0,605,607,1,0,0,0,606,602,1,0,0,0,607,610,1,0,0,0,608,
        606,1,0,0,0,608,609,1,0,0,0,609,614,1,0,0,0,610,608,1,0,0,0,611,
        615,3,78,39,0,612,615,3,88,44,0,613,615,3,80,40,0,614,611,1,0,0,
        0,614,612,1,0,0,0,614,613,1,0,0,0,615,617,1,0,0,0,616,618,5,7,0,
        0,617,616,1,0,0,0,617,618,1,0,0,0,618,93,1,0,0,0,619,620,5,81,0,
        0,620,95,1,0,0,0,621,622,5,90,0,0,622,97,1,0,0,0,623,624,5,80,0,
        0,624,99,1,0,0,0,625,626,3,96,48,0,626,628,5,86,0,0,627,629,3,98,
        49,0,628,627,1,0,0,0,628,629,1,0,0,0,629,630,1,0,0,0,630,631,5,87,
        0,0,631,101,1,0,0,0,632,633,5,80,0,0,633,103,1,0,0,0,634,635,5,81,
        0,0,635,105,1,0,0,0,636,641,3,104,52,0,637,638,5,85,0,0,638,640,
        3,104,52,0,639,637,1,0,0,0,640,643,1,0,0,0,641,639,1,0,0,0,641,642,
        1,0,0,0,642,107,1,0,0,0,643,641,1,0,0,0,644,651,5,91,0,0,645,646,
        5,92,0,0,646,647,5,86,0,0,647,648,3,106,53,0,648,649,5,87,0,0,649,
        651,1,0,0,0,650,644,1,0,0,0,650,645,1,0,0,0,651,109,1,0,0,0,652,
        653,3,102,51,0,653,654,5,83,0,0,654,656,3,108,54,0,655,657,5,84,
        0,0,656,655,1,0,0,0,656,657,1,0,0,0,657,111,1,0,0,0,658,659,5,14,
        0,0,659,662,3,94,47,0,660,661,5,83,0,0,661,663,3,100,50,0,662,660,
        1,0,0,0,662,663,1,0,0,0,663,664,1,0,0,0,664,665,5,88,0,0,665,670,
        3,110,55,0,666,667,5,85,0,0,667,669,3,110,55,0,668,666,1,0,0,0,669,
        672,1,0,0,0,670,668,1,0,0,0,670,671,1,0,0,0,671,673,1,0,0,0,672,
        670,1,0,0,0,673,674,5,89,0,0,674,113,1,0,0,0,675,680,3,112,56,0,
        676,680,3,120,60,0,677,680,5,2,0,0,678,680,5,3,0,0,679,675,1,0,0,
        0,679,676,1,0,0,0,679,677,1,0,0,0,679,678,1,0,0,0,680,683,1,0,0,
        0,681,679,1,0,0,0,681,682,1,0,0,0,682,684,1,0,0,0,683,681,1,0,0,
        0,684,685,5,0,0,1,685,115,1,0,0,0,686,687,5,95,0,0,687,117,1,0,0,
        0,688,689,5,99,0,0,689,690,5,97,0,0,690,691,5,99,0,0,691,119,1,0,
        0,0,692,693,5,15,0,0,693,694,3,116,58,0,694,695,5,96,0,0,695,696,
        3,118,59,0,696,697,5,98,0,0,697,121,1,0,0,0,698,700,5,13,0,0,699,
        698,1,0,0,0,699,700,1,0,0,0,700,702,1,0,0,0,701,703,3,92,46,0,702,
        701,1,0,0,0,702,703,1,0,0,0,703,710,1,0,0,0,704,706,5,13,0,0,705,
        707,3,92,46,0,706,705,1,0,0,0,706,707,1,0,0,0,707,709,1,0,0,0,708,
        704,1,0,0,0,709,712,1,0,0,0,710,708,1,0,0,0,710,711,1,0,0,0,711,
        713,1,0,0,0,712,710,1,0,0,0,713,714,5,0,0,1,714,123,1,0,0,0,93,134,
        143,150,153,162,172,184,192,201,209,220,231,237,242,247,252,266,
        270,272,277,301,305,311,315,320,322,338,343,348,353,358,361,368,
        371,375,382,384,388,393,399,403,406,410,412,417,421,426,432,437,
        443,451,453,461,463,474,479,483,485,495,503,507,512,517,523,530,
        540,545,549,553,557,563,569,574,577,584,591,596,602,608,614,617,
        628,641,650,656,662,670,679,681,699,702,706,710
    ];

    private static __ATN: antlr.ATN;
    public static get _ATN(): antlr.ATN {
        if (!UniversalWorkoutLanguageParser.__ATN) {
            UniversalWorkoutLanguageParser.__ATN = new antlr.ATNDeserializer().deserialize(UniversalWorkoutLanguageParser._serializedATN);
        }

        return UniversalWorkoutLanguageParser.__ATN;
    }


    private static readonly vocabulary = new antlr.Vocabulary(UniversalWorkoutLanguageParser.literalNames, UniversalWorkoutLanguageParser.symbolicNames, []);

    public override get vocabulary(): antlr.Vocabulary {
        return UniversalWorkoutLanguageParser.vocabulary;
    }

    private static readonly decisionsToDFA = UniversalWorkoutLanguageParser._ATN.decisionToState.map( (ds: antlr.DecisionState, index: number) => new antlr.DFA(ds, index) );
}

export class ErrorContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public ERROR_CHAR(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.ERROR_CHAR, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_error;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitError) {
            return visitor.visitError(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class SetsContext extends antlr.ParserRuleContext {
    public _sets_number?: Token | null;
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public MC_NUMBER(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_NUMBER, 0);
    }
    public MC_PLACEHOLDER(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_PLACEHOLDER, 0);
    }
    public MC_RANGE(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_RANGE, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_sets;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitSets) {
            return visitor.visitSets(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class RepsContext extends antlr.ParserRuleContext {
    public _rep_value?: Rep_valueContext;
    public _values: Rep_valueContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public REP_MARKER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.REP_MARKER, 0)!;
    }
    public rep_value(): Rep_valueContext[];
    public rep_value(i: number): Rep_valueContext | null;
    public rep_value(i?: number): Rep_valueContext[] | Rep_valueContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Rep_valueContext);
        }

        return this.getRuleContext(i, Rep_valueContext);
    }
    public MC_SET_SEPARATOR(): antlr.TerminalNode[];
    public MC_SET_SEPARATOR(i: number): antlr.TerminalNode | null;
    public MC_SET_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_reps;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitReps) {
            return visitor.visitReps(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Rep_valueContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public MC_NUMBER(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_NUMBER, 0);
    }
    public MC_MAX(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_MAX, 0);
    }
    public MC_CLUSTER(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_CLUSTER, 0);
    }
    public amrap_value(): Amrap_valueContext | null {
        return this.getRuleContext(0, Amrap_valueContext);
    }
    public MC_RANGE(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_RANGE, 0);
    }
    public MC_PLACEHOLDER(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_PLACEHOLDER, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_rep_value;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitRep_value) {
            return visitor.visitRep_value(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Amrap_valueContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public AMRAP_UNIT_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.AMRAP_UNIT_KW, 0)!;
    }
    public MC_PAREN_L(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_PAREN_L, 0);
    }
    public MC_PAREN_R(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_PAREN_R, 0);
    }
    public MC_DASH(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_DASH, 0);
    }
    public MC_NUMBER(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_NUMBER, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_amrap_value;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitAmrap_value) {
            return visitor.visitAmrap_value(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Mov_placeholderContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public MC_PLACEHOLDER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.MC_PLACEHOLDER, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_mov_placeholder;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitMov_placeholder) {
            return visitor.visitMov_placeholder(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class CaloriesContext extends antlr.ParserRuleContext {
    public _MC_NUMBER?: Token | null;
    public _cals: antlr.Token[] = [];
    public _MC_MAX?: Token | null;
    public _MC_PLACEHOLDER?: Token | null;
    public _MC_RANGE?: Token | null;
    public __tset128?: Token | null;
    public __tset143?: Token | null;
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public MC_CAL_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.MC_CAL_KW, 0)!;
    }
    public MC_NUMBER(): antlr.TerminalNode[];
    public MC_NUMBER(i: number): antlr.TerminalNode | null;
    public MC_NUMBER(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_NUMBER);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_NUMBER, i);
    	}
    }
    public MC_MAX(): antlr.TerminalNode[];
    public MC_MAX(i: number): antlr.TerminalNode | null;
    public MC_MAX(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_MAX);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_MAX, i);
    	}
    }
    public MC_PLACEHOLDER(): antlr.TerminalNode[];
    public MC_PLACEHOLDER(i: number): antlr.TerminalNode | null;
    public MC_PLACEHOLDER(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_PLACEHOLDER);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_PLACEHOLDER, i);
    	}
    }
    public MC_RANGE(): antlr.TerminalNode[];
    public MC_RANGE(i: number): antlr.TerminalNode | null;
    public MC_RANGE(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_RANGE);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_RANGE, i);
    	}
    }
    public MC_SET_SEPARATOR(): antlr.TerminalNode[];
    public MC_SET_SEPARATOR(i: number): antlr.TerminalNode | null;
    public MC_SET_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_calories;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitCalories) {
            return visitor.visitCalories(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class PowerContext extends antlr.ParserRuleContext {
    public _MC_NUMBER?: Token | null;
    public _powers: antlr.Token[] = [];
    public _MC_MAX?: Token | null;
    public _MC_PLACEHOLDER?: Token | null;
    public _MC_RANGE?: Token | null;
    public __tset163?: Token | null;
    public __tset178?: Token | null;
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public MC_POWER_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.MC_POWER_KW, 0)!;
    }
    public MC_NUMBER(): antlr.TerminalNode[];
    public MC_NUMBER(i: number): antlr.TerminalNode | null;
    public MC_NUMBER(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_NUMBER);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_NUMBER, i);
    	}
    }
    public MC_MAX(): antlr.TerminalNode[];
    public MC_MAX(i: number): antlr.TerminalNode | null;
    public MC_MAX(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_MAX);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_MAX, i);
    	}
    }
    public MC_PLACEHOLDER(): antlr.TerminalNode[];
    public MC_PLACEHOLDER(i: number): antlr.TerminalNode | null;
    public MC_PLACEHOLDER(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_PLACEHOLDER);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_PLACEHOLDER, i);
    	}
    }
    public MC_RANGE(): antlr.TerminalNode[];
    public MC_RANGE(i: number): antlr.TerminalNode | null;
    public MC_RANGE(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_RANGE);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_RANGE, i);
    	}
    }
    public MC_SET_SEPARATOR(): antlr.TerminalNode[];
    public MC_SET_SEPARATOR(i: number): antlr.TerminalNode | null;
    public MC_SET_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_power;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitPower) {
            return visitor.visitPower(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Load_valueContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public MC_NUMBER(): antlr.TerminalNode[];
    public MC_NUMBER(i: number): antlr.TerminalNode | null;
    public MC_NUMBER(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_NUMBER);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_NUMBER, i);
    	}
    }
    public MC_SLASH(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_SLASH, 0);
    }
    public MC_MAX(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_MAX, 0);
    }
    public MC_PLACEHOLDER(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_PLACEHOLDER, 0);
    }
    public MC_RANGE(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_RANGE, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_load_value;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitLoad_value) {
            return visitor.visitLoad_value(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class LoadContext extends antlr.ParserRuleContext {
    public _load_value?: Load_valueContext;
    public _loads: Load_valueContext[] = [];
    public _load_unit?: Token | null;
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public MC_DASH(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.MC_DASH, 0)!;
    }
    public load_value(): Load_valueContext[];
    public load_value(i: number): Load_valueContext | null;
    public load_value(i?: number): Load_valueContext[] | Load_valueContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Load_valueContext);
        }

        return this.getRuleContext(i, Load_valueContext);
    }
    public MC_CONTENT(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.MC_CONTENT, 0)!;
    }
    public MC_SET_SEPARATOR(): antlr.TerminalNode[];
    public MC_SET_SEPARATOR(i: number): antlr.TerminalNode | null;
    public MC_SET_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_load;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitLoad) {
            return visitor.visitLoad(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class TempoContext extends antlr.ParserRuleContext {
    public _MC_TEMPO_PATTERN?: Token | null;
    public _tempos: antlr.Token[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public TEMPO_MARKER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.TEMPO_MARKER, 0)!;
    }
    public MC_TEMPO_PATTERN(): antlr.TerminalNode[];
    public MC_TEMPO_PATTERN(i: number): antlr.TerminalNode | null;
    public MC_TEMPO_PATTERN(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_TEMPO_PATTERN);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_TEMPO_PATTERN, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_tempo;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitTempo) {
            return visitor.visitTempo(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class DurationContext extends antlr.ParserRuleContext {
    public _MC_NUMBER?: Token | null;
    public _durations: antlr.Token[] = [];
    public _MC_MAX?: Token | null;
    public _MC_PLACEHOLDER?: Token | null;
    public _MC_RANGE?: Token | null;
    public __tset262?: Token | null;
    public __tset277?: Token | null;
    public _duration_unit?: Token | null;
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DURATION_MARKER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DURATION_MARKER, 0)!;
    }
    public MC_CONTENT(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.MC_CONTENT, 0)!;
    }
    public MC_NUMBER(): antlr.TerminalNode[];
    public MC_NUMBER(i: number): antlr.TerminalNode | null;
    public MC_NUMBER(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_NUMBER);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_NUMBER, i);
    	}
    }
    public MC_MAX(): antlr.TerminalNode[];
    public MC_MAX(i: number): antlr.TerminalNode | null;
    public MC_MAX(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_MAX);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_MAX, i);
    	}
    }
    public MC_PLACEHOLDER(): antlr.TerminalNode[];
    public MC_PLACEHOLDER(i: number): antlr.TerminalNode | null;
    public MC_PLACEHOLDER(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_PLACEHOLDER);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_PLACEHOLDER, i);
    	}
    }
    public MC_RANGE(): antlr.TerminalNode[];
    public MC_RANGE(i: number): antlr.TerminalNode | null;
    public MC_RANGE(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_RANGE);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_RANGE, i);
    	}
    }
    public MC_SET_SEPARATOR(): antlr.TerminalNode[];
    public MC_SET_SEPARATOR(i: number): antlr.TerminalNode | null;
    public MC_SET_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_duration;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDuration) {
            return visitor.visitDuration(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class DistanceContext extends antlr.ParserRuleContext {
    public _MC_NUMBER?: Token | null;
    public _distances: antlr.Token[] = [];
    public _MC_MAX?: Token | null;
    public _MC_PLACEHOLDER?: Token | null;
    public _MC_RANGE?: Token | null;
    public __tset301?: Token | null;
    public __tset316?: Token | null;
    public _distance_unit?: Token | null;
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DISTANCE_MARKER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DISTANCE_MARKER, 0)!;
    }
    public MC_CONTENT(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.MC_CONTENT, 0)!;
    }
    public MC_NUMBER(): antlr.TerminalNode[];
    public MC_NUMBER(i: number): antlr.TerminalNode | null;
    public MC_NUMBER(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_NUMBER);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_NUMBER, i);
    	}
    }
    public MC_MAX(): antlr.TerminalNode[];
    public MC_MAX(i: number): antlr.TerminalNode | null;
    public MC_MAX(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_MAX);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_MAX, i);
    	}
    }
    public MC_PLACEHOLDER(): antlr.TerminalNode[];
    public MC_PLACEHOLDER(i: number): antlr.TerminalNode | null;
    public MC_PLACEHOLDER(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_PLACEHOLDER);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_PLACEHOLDER, i);
    	}
    }
    public MC_RANGE(): antlr.TerminalNode[];
    public MC_RANGE(i: number): antlr.TerminalNode | null;
    public MC_RANGE(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_RANGE);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_RANGE, i);
    	}
    }
    public MC_SET_SEPARATOR(): antlr.TerminalNode[];
    public MC_SET_SEPARATOR(i: number): antlr.TerminalNode | null;
    public MC_SET_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_distance;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDistance) {
            return visitor.visitDistance(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class HeightContext extends antlr.ParserRuleContext {
    public _MC_NUMBER?: Token | null;
    public _heights: antlr.Token[] = [];
    public _MC_MAX?: Token | null;
    public _MC_PLACEHOLDER?: Token | null;
    public __tset340?: Token | null;
    public __tset353?: Token | null;
    public _height_unit?: Token | null;
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public HEIGHT_MARKER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.HEIGHT_MARKER, 0)!;
    }
    public MC_CONTENT(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.MC_CONTENT, 0)!;
    }
    public MC_NUMBER(): antlr.TerminalNode[];
    public MC_NUMBER(i: number): antlr.TerminalNode | null;
    public MC_NUMBER(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_NUMBER);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_NUMBER, i);
    	}
    }
    public MC_MAX(): antlr.TerminalNode[];
    public MC_MAX(i: number): antlr.TerminalNode | null;
    public MC_MAX(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_MAX);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_MAX, i);
    	}
    }
    public MC_PLACEHOLDER(): antlr.TerminalNode[];
    public MC_PLACEHOLDER(i: number): antlr.TerminalNode | null;
    public MC_PLACEHOLDER(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_PLACEHOLDER);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_PLACEHOLDER, i);
    	}
    }
    public MC_SET_SEPARATOR(): antlr.TerminalNode[];
    public MC_SET_SEPARATOR(i: number): antlr.TerminalNode | null;
    public MC_SET_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_height;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitHeight) {
            return visitor.visitHeight(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Complex_movementContext extends antlr.ParserRuleContext {
    public _MOVEMENT_NAME_REPS?: Token | null;
    public _mov_reps: antlr.Token[] = [];
    public _SIMPLE_MOVEMENT?: Token | null;
    public _mov_name: antlr.Token[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public SIMPLE_MOVEMENT(): antlr.TerminalNode[];
    public SIMPLE_MOVEMENT(i: number): antlr.TerminalNode | null;
    public SIMPLE_MOVEMENT(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.SIMPLE_MOVEMENT);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.SIMPLE_MOVEMENT, i);
    	}
    }
    public MOVEMENT_NAME_COMPOENENT_SEPARATOR(): antlr.TerminalNode[];
    public MOVEMENT_NAME_COMPOENENT_SEPARATOR(i: number): antlr.TerminalNode | null;
    public MOVEMENT_NAME_COMPOENENT_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MOVEMENT_NAME_COMPOENENT_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MOVEMENT_NAME_COMPOENENT_SEPARATOR, i);
    	}
    }
    public MOVEMENT_NAME_REPS(): antlr.TerminalNode[];
    public MOVEMENT_NAME_REPS(i: number): antlr.TerminalNode | null;
    public MOVEMENT_NAME_REPS(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MOVEMENT_NAME_REPS);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MOVEMENT_NAME_REPS, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_complex_movement;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitComplex_movement) {
            return visitor.visitComplex_movement(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Movement_nameContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public SIMPLE_MOVEMENT(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.SIMPLE_MOVEMENT, 0);
    }
    public complex_movement(): Complex_movementContext | null {
        return this.getRuleContext(0, Complex_movementContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_movement_name;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitMovement_name) {
            return visitor.visitMovement_name(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class MovementContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public MOVEMENT_MARKER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.MOVEMENT_MARKER, 0)!;
    }
    public movement_name(): Movement_nameContext {
        return this.getRuleContext(0, Movement_nameContext)!;
    }
    public MOVEMENT_NAME_TERMINATOR(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.MOVEMENT_NAME_TERMINATOR, 0)!;
    }
    public MOVEMENT_TERM(): antlr.TerminalNode[];
    public MOVEMENT_TERM(i: number): antlr.TerminalNode | null;
    public MOVEMENT_TERM(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MOVEMENT_TERM);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MOVEMENT_TERM, i);
    	}
    }
    public reps(): RepsContext[];
    public reps(i: number): RepsContext | null;
    public reps(i?: number): RepsContext[] | RepsContext | null {
        if (i === undefined) {
            return this.getRuleContexts(RepsContext);
        }

        return this.getRuleContext(i, RepsContext);
    }
    public load(): LoadContext[];
    public load(i: number): LoadContext | null;
    public load(i?: number): LoadContext[] | LoadContext | null {
        if (i === undefined) {
            return this.getRuleContexts(LoadContext);
        }

        return this.getRuleContext(i, LoadContext);
    }
    public tempo(): TempoContext[];
    public tempo(i: number): TempoContext | null;
    public tempo(i?: number): TempoContext[] | TempoContext | null {
        if (i === undefined) {
            return this.getRuleContexts(TempoContext);
        }

        return this.getRuleContext(i, TempoContext);
    }
    public duration(): DurationContext[];
    public duration(i: number): DurationContext | null;
    public duration(i?: number): DurationContext[] | DurationContext | null {
        if (i === undefined) {
            return this.getRuleContexts(DurationContext);
        }

        return this.getRuleContext(i, DurationContext);
    }
    public distance(): DistanceContext[];
    public distance(i: number): DistanceContext | null;
    public distance(i?: number): DistanceContext[] | DistanceContext | null {
        if (i === undefined) {
            return this.getRuleContexts(DistanceContext);
        }

        return this.getRuleContext(i, DistanceContext);
    }
    public sets(): SetsContext[];
    public sets(i: number): SetsContext | null;
    public sets(i?: number): SetsContext[] | SetsContext | null {
        if (i === undefined) {
            return this.getRuleContexts(SetsContext);
        }

        return this.getRuleContext(i, SetsContext);
    }
    public calories(): CaloriesContext[];
    public calories(i: number): CaloriesContext | null;
    public calories(i?: number): CaloriesContext[] | CaloriesContext | null {
        if (i === undefined) {
            return this.getRuleContexts(CaloriesContext);
        }

        return this.getRuleContext(i, CaloriesContext);
    }
    public power(): PowerContext[];
    public power(i: number): PowerContext | null;
    public power(i?: number): PowerContext[] | PowerContext | null {
        if (i === undefined) {
            return this.getRuleContexts(PowerContext);
        }

        return this.getRuleContext(i, PowerContext);
    }
    public height(): HeightContext[];
    public height(i: number): HeightContext | null;
    public height(i?: number): HeightContext[] | HeightContext | null {
        if (i === undefined) {
            return this.getRuleContexts(HeightContext);
        }

        return this.getRuleContext(i, HeightContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_movement;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitMovement) {
            return visitor.visitMovement(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class CommentContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public INLINE_COMMENT(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.INLINE_COMMENT, 0);
    }
    public MULTILINE_COMMENT(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MULTILINE_COMMENT, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_comment;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitComment) {
            return visitor.visitComment(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Workout_idenContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public WORKOUT_IDENTIFIER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_IDENTIFIER, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout_iden;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout_iden) {
            return visitor.visitWorkout_iden(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Workout_attr_idContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public WORKOUT_IDEN(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_IDEN, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout_attr_id;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout_attr_id) {
            return visitor.visitWorkout_attr_id(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Workout_attr_val_strContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public WORKOUT_ML_STRING(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_ML_STRING, 0);
    }
    public WORKOUT_STRING(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_STRING, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout_attr_val_str;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout_attr_val_str) {
            return visitor.visitWorkout_attr_val_str(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Workout_attr_val_numberContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public WORKOUT_NUMBER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_NUMBER, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout_attr_val_number;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout_attr_val_number) {
            return visitor.visitWorkout_attr_val_number(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Workout_attr_val_timeContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public WORKOUT_TIME(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_TIME, 0);
    }
    public WORKOUT_UNIT_TIME(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_UNIT_TIME, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout_attr_val_time;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout_attr_val_time) {
            return visitor.visitWorkout_attr_val_time(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Workout_attr_valContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public WORKOUT_ML_STRING(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_ML_STRING, 0);
    }
    public WORKOUT_STRING(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_STRING, 0);
    }
    public WORKOUT_TIME(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_TIME, 0);
    }
    public WORKOUT_UNIT_TIME(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_UNIT_TIME, 0);
    }
    public WORKOUT_NUMBER(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_NUMBER, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout_attr_val;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout_attr_val) {
            return visitor.visitWorkout_attr_val(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Workout_attributeContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public workout_attr_id(): Workout_attr_idContext {
        return this.getRuleContext(0, Workout_attr_idContext)!;
    }
    public WORKOUT_COLON(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_COLON, 0)!;
    }
    public workout_attr_val(): Workout_attr_valContext {
        return this.getRuleContext(0, Workout_attr_valContext)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout_attribute;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout_attribute) {
            return visitor.visitWorkout_attribute(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Workout_commentContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public INLINE_COMMENT(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.INLINE_COMMENT, 0);
    }
    public MULTILINE_COMMENT(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MULTILINE_COMMENT, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout_comment;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout_comment) {
            return visitor.visitWorkout_comment(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class WorkoutContext extends antlr.ParserRuleContext {
    public _workout_attribute?: Workout_attributeContext;
    public _attrs: Workout_attributeContext[] = [];
    public _movement?: MovementContext;
    public _movements: MovementContext[] = [];
    public _workout_comment?: Workout_commentContext;
    public _comments: Workout_commentContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public workout_iden(): Workout_idenContext {
        return this.getRuleContext(0, Workout_idenContext)!;
    }
    public WORKOUT_PAREN_L(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_PAREN_L, 0)!;
    }
    public WORKOUT_PAREN_R(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_PAREN_R, 0)!;
    }
    public WORKOUT_STRING(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_STRING, 0);
    }
    public WORKOUT_COMMA(): antlr.TerminalNode[];
    public WORKOUT_COMMA(i: number): antlr.TerminalNode | null;
    public WORKOUT_COMMA(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.WORKOUT_COMMA);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_COMMA, i);
    	}
    }
    public workout_attribute(): Workout_attributeContext[];
    public workout_attribute(i: number): Workout_attributeContext | null;
    public workout_attribute(i?: number): Workout_attributeContext[] | Workout_attributeContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Workout_attributeContext);
        }

        return this.getRuleContext(i, Workout_attributeContext);
    }
    public movement(): MovementContext[];
    public movement(i: number): MovementContext | null;
    public movement(i?: number): MovementContext[] | MovementContext | null {
        if (i === undefined) {
            return this.getRuleContexts(MovementContext);
        }

        return this.getRuleContext(i, MovementContext);
    }
    public workout_comment(): Workout_commentContext[];
    public workout_comment(i: number): Workout_commentContext | null;
    public workout_comment(i?: number): Workout_commentContext[] | Workout_commentContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Workout_commentContext);
        }

        return this.getRuleContext(i, Workout_commentContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout) {
            return visitor.visitWorkout(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Rest_time_valueContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public REST_NUMBER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.REST_NUMBER, 0)!;
    }
    public REST_UNIT(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.REST_UNIT, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_rest_time_value;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitRest_time_value) {
            return visitor.visitRest_time_value(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Emom_rest_valueContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public REST_EMOM(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.REST_EMOM, 0)!;
    }
    public REST_NUMBER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.REST_NUMBER, 0)!;
    }
    public REST_UNIT(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.REST_UNIT, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_emom_rest_value;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitEmom_rest_value) {
            return visitor.visitEmom_rest_value(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Ratio_rest_valueContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public REST_RATIO(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.REST_RATIO, 0)!;
    }
    public REST_NUMBER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.REST_NUMBER, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_ratio_rest_value;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitRatio_rest_value) {
            return visitor.visitRatio_rest_value(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Rest_valueContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public REST_AS_NEEDED(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.REST_AS_NEEDED, 0);
    }
    public REST_SLASH(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.REST_SLASH, 0);
    }
    public secondary_rest_value(): Secondary_rest_valueContext | null {
        return this.getRuleContext(0, Secondary_rest_valueContext);
    }
    public rest_time_value(): Rest_time_valueContext | null {
        return this.getRuleContext(0, Rest_time_valueContext);
    }
    public REST_SUPERSET(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.REST_SUPERSET, 0);
    }
    public emom_rest_value(): Emom_rest_valueContext | null {
        return this.getRuleContext(0, Emom_rest_valueContext);
    }
    public ratio_rest_value(): Ratio_rest_valueContext | null {
        return this.getRuleContext(0, Ratio_rest_valueContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_rest_value;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitRest_value) {
            return visitor.visitRest_value(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Secondary_rest_valueContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public REST_AS_NEEDED(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.REST_AS_NEEDED, 0);
    }
    public rest_time_value(): Rest_time_valueContext | null {
        return this.getRuleContext(0, Rest_time_valueContext);
    }
    public REST_SUPERSET(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.REST_SUPERSET, 0);
    }
    public emom_rest_value(): Emom_rest_valueContext | null {
        return this.getRuleContext(0, Emom_rest_valueContext);
    }
    public ratio_rest_value(): Ratio_rest_valueContext | null {
        return this.getRuleContext(0, Ratio_rest_valueContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_secondary_rest_value;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitSecondary_rest_value) {
            return visitor.visitSecondary_rest_value(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Shorthand_strengthContext extends antlr.ParserRuleContext {
    public _name?: Token | null;
    public _movement?: MovementContext;
    public _movements: MovementContext[] = [];
    public _workout_comment?: Workout_commentContext;
    public _comments: Workout_commentContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public movement(): MovementContext[];
    public movement(i: number): MovementContext | null;
    public movement(i?: number): MovementContext[] | MovementContext | null {
        if (i === undefined) {
            return this.getRuleContexts(MovementContext);
        }

        return this.getRuleContext(i, MovementContext);
    }
    public REST_KW(): antlr.TerminalNode[];
    public REST_KW(i: number): antlr.TerminalNode | null;
    public REST_KW(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.REST_KW);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.REST_KW, i);
    	}
    }
    public rest_value(): Rest_valueContext[];
    public rest_value(i: number): Rest_valueContext | null;
    public rest_value(i?: number): Rest_valueContext[] | Rest_valueContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Rest_valueContext);
        }

        return this.getRuleContext(i, Rest_valueContext);
    }
    public STRING(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.STRING, 0);
    }
    public workout_comment(): Workout_commentContext[];
    public workout_comment(i: number): Workout_commentContext | null;
    public workout_comment(i?: number): Workout_commentContext[] | Workout_commentContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Workout_commentContext);
        }

        return this.getRuleContext(i, Workout_commentContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_shorthand_strength;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitShorthand_strength) {
            return visitor.visitShorthand_strength(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Shorthand_genericContext extends antlr.ParserRuleContext {
    public _workout_attribute?: Workout_attributeContext;
    public _attrs: Workout_attributeContext[] = [];
    public _movement?: MovementContext;
    public _movements: MovementContext[] = [];
    public _workout_comment?: Workout_commentContext;
    public _comments: Workout_commentContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public GENERIC_STRING(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.GENERIC_STRING, 0)!;
    }
    public STRING(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.STRING, 0);
    }
    public PAREN_L(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.PAREN_L, 0);
    }
    public WORKOUT_PAREN_R(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_PAREN_R, 0);
    }
    public movement(): MovementContext[];
    public movement(i: number): MovementContext | null;
    public movement(i?: number): MovementContext[] | MovementContext | null {
        if (i === undefined) {
            return this.getRuleContexts(MovementContext);
        }

        return this.getRuleContext(i, MovementContext);
    }
    public workout_comment(): Workout_commentContext[];
    public workout_comment(i: number): Workout_commentContext | null;
    public workout_comment(i?: number): Workout_commentContext[] | Workout_commentContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Workout_commentContext);
        }

        return this.getRuleContext(i, Workout_commentContext);
    }
    public WORKOUT_COMMA(): antlr.TerminalNode[];
    public WORKOUT_COMMA(i: number): antlr.TerminalNode | null;
    public WORKOUT_COMMA(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.WORKOUT_COMMA);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_COMMA, i);
    	}
    }
    public workout_attribute(): Workout_attributeContext[];
    public workout_attribute(i: number): Workout_attributeContext | null;
    public workout_attribute(i?: number): Workout_attributeContext[] | Workout_attributeContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Workout_attributeContext);
        }

        return this.getRuleContext(i, Workout_attributeContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_shorthand_generic;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitShorthand_generic) {
            return visitor.visitShorthand_generic(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Shorthand_fortimeContext extends antlr.ParserRuleContext {
    public _rounds_num?: Token | null;
    public _rounds_word?: Token | null;
    public _rep_sequence?: Token | null;
    public _sets_num?: Token | null;
    public _workout_comment?: Workout_commentContext;
    public _comments: Workout_commentContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public FORTIME_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.FORTIME_KW, 0)!;
    }
    public final_movement(): Final_movementContext {
        return this.getRuleContext(0, Final_movementContext)!;
    }
    public FORTIME_SET_KW(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.FORTIME_SET_KW, 0);
    }
    public NUMBER(): antlr.TerminalNode[];
    public NUMBER(i: number): antlr.TerminalNode | null;
    public NUMBER(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.NUMBER);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.NUMBER, i);
    	}
    }
    public ROUNDS_WORD(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.ROUNDS_WORD, 0);
    }
    public FORTIME_REPS(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.FORTIME_REPS, 0);
    }
    public workout_comment(): Workout_commentContext[];
    public workout_comment(i: number): Workout_commentContext | null;
    public workout_comment(i?: number): Workout_commentContext[] | Workout_commentContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Workout_commentContext);
        }

        return this.getRuleContext(i, Workout_commentContext);
    }
    public intermediate_movement(): Intermediate_movementContext[];
    public intermediate_movement(i: number): Intermediate_movementContext | null;
    public intermediate_movement(i?: number): Intermediate_movementContext[] | Intermediate_movementContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Intermediate_movementContext);
        }

        return this.getRuleContext(i, Intermediate_movementContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_shorthand_fortime;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitShorthand_fortime) {
            return visitor.visitShorthand_fortime(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Intermediate_movementContext extends antlr.ParserRuleContext {
    public _movement?: MovementContext;
    public _movements: MovementContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public movement(): MovementContext {
        return this.getRuleContext(0, MovementContext)!;
    }
    public REST_KW(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.REST_KW, 0);
    }
    public rest_value(): Rest_valueContext | null {
        return this.getRuleContext(0, Rest_valueContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_intermediate_movement;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitIntermediate_movement) {
            return visitor.visitIntermediate_movement(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Final_movementContext extends antlr.ParserRuleContext {
    public _movement?: MovementContext;
    public _movements: MovementContext[] = [];
    public _component_rest?: Rest_valueContext;
    public _round_rest?: Rest_valueContext;
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public movement(): MovementContext {
        return this.getRuleContext(0, MovementContext)!;
    }
    public REST_KW(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.REST_KW, 0);
    }
    public INTO_KW(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.INTO_KW, 0);
    }
    public rest_value(): Rest_valueContext | null {
        return this.getRuleContext(0, Rest_valueContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_final_movement;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitFinal_movement) {
            return visitor.visitFinal_movement(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Shorthand_amrapContext extends antlr.ParserRuleContext {
    public _movement?: MovementContext;
    public _movements: MovementContext[] = [];
    public _workout_comment?: Workout_commentContext;
    public _comments: Workout_commentContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public NUMBER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.NUMBER, 0)!;
    }
    public TIME_UNIT(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.TIME_UNIT, 0)!;
    }
    public AMRAP_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.AMRAP_KW, 0)!;
    }
    public movement(): MovementContext[];
    public movement(i: number): MovementContext | null;
    public movement(i?: number): MovementContext[] | MovementContext | null {
        if (i === undefined) {
            return this.getRuleContexts(MovementContext);
        }

        return this.getRuleContext(i, MovementContext);
    }
    public workout_comment(): Workout_commentContext[];
    public workout_comment(i: number): Workout_commentContext | null;
    public workout_comment(i?: number): Workout_commentContext[] | Workout_commentContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Workout_commentContext);
        }

        return this.getRuleContext(i, Workout_commentContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_shorthand_amrap;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitShorthand_amrap) {
            return visitor.visitShorthand_amrap(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Shorthand_everyContext extends antlr.ParserRuleContext {
    public _time_value?: Token | null;
    public _time_unit?: Token | null;
    public _sets_every?: Token | null;
    public _goal?: Token | null;
    public _movement?: MovementContext;
    public _movements: MovementContext[] = [];
    public _workout_comment?: Workout_commentContext;
    public _comments: Workout_commentContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public EVERY_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.EVERY_KW, 0)!;
    }
    public EVERY_X(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.EVERY_X, 0)!;
    }
    public EVERY_NUMBER(): antlr.TerminalNode[];
    public EVERY_NUMBER(i: number): antlr.TerminalNode | null;
    public EVERY_NUMBER(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.EVERY_NUMBER);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.EVERY_NUMBER, i);
    	}
    }
    public EVERY_UNIT(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.EVERY_UNIT, 0)!;
    }
    public EVERY_SETS(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.EVERY_SETS, 0);
    }
    public EVERY_FOR(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.EVERY_FOR, 0);
    }
    public EVERY_GOAL_STRING(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.EVERY_GOAL_STRING, 0);
    }
    public movement(): MovementContext[];
    public movement(i: number): MovementContext | null;
    public movement(i?: number): MovementContext[] | MovementContext | null {
        if (i === undefined) {
            return this.getRuleContexts(MovementContext);
        }

        return this.getRuleContext(i, MovementContext);
    }
    public workout_comment(): Workout_commentContext[];
    public workout_comment(i: number): Workout_commentContext | null;
    public workout_comment(i?: number): Workout_commentContext[] | Workout_commentContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Workout_commentContext);
        }

        return this.getRuleContext(i, Workout_commentContext);
    }
    public EVERY_AFTER_SETS_FOR(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.EVERY_AFTER_SETS_FOR, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_shorthand_every;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitShorthand_every) {
            return visitor.visitShorthand_every(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Any_workoutContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public workout(): WorkoutContext | null {
        return this.getRuleContext(0, WorkoutContext);
    }
    public shorthand_strength(): Shorthand_strengthContext | null {
        return this.getRuleContext(0, Shorthand_strengthContext);
    }
    public shorthand_generic(): Shorthand_genericContext | null {
        return this.getRuleContext(0, Shorthand_genericContext);
    }
    public shorthand_fortime(): Shorthand_fortimeContext | null {
        return this.getRuleContext(0, Shorthand_fortimeContext);
    }
    public shorthand_amrap(): Shorthand_amrapContext | null {
        return this.getRuleContext(0, Shorthand_amrapContext);
    }
    public emom(): EmomContext | null {
        return this.getRuleContext(0, EmomContext);
    }
    public shorthand_every(): Shorthand_everyContext | null {
        return this.getRuleContext(0, Shorthand_everyContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_any_workout;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitAny_workout) {
            return visitor.visitAny_workout(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class AlternationContext extends antlr.ParserRuleContext {
    public _any_workout?: Any_workoutContext;
    public _workouts: Any_workoutContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public NUMBER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.NUMBER, 0)!;
    }
    public SUPERSET_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.SUPERSET_KW, 0)!;
    }
    public LBRACE(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.LBRACE, 0)!;
    }
    public RBRACE(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.RBRACE, 0)!;
    }
    public WORKOUT_SEPARATOR(): antlr.TerminalNode[];
    public WORKOUT_SEPARATOR(i: number): antlr.TerminalNode | null;
    public WORKOUT_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR, i);
    	}
    }
    public any_workout(): Any_workoutContext[];
    public any_workout(i: number): Any_workoutContext | null;
    public any_workout(i?: number): Any_workoutContext[] | Any_workoutContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Any_workoutContext);
        }

        return this.getRuleContext(i, Any_workoutContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_alternation;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitAlternation) {
            return visitor.visitAlternation(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Option_block_titleContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public CHOICE_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.CHOICE_KW, 0)!;
    }
    public CHOICE_NAME(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.CHOICE_NAME, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_option_block_title;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitOption_block_title) {
            return visitor.visitOption_block_title(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Option_titleContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public OPTION_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.OPTION_KW, 0)!;
    }
    public CHOICE_NAME(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.CHOICE_NAME, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_option_title;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitOption_title) {
            return visitor.visitOption_title(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class OptionContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public OPTION_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.OPTION_KW, 0)!;
    }
    public CHOICE_NAME(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.CHOICE_NAME, 0)!;
    }
    public COLON(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.COLON, 0)!;
    }
    public any_workout(): Any_workoutContext[];
    public any_workout(i: number): Any_workoutContext | null;
    public any_workout(i?: number): Any_workoutContext[] | Any_workoutContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Any_workoutContext);
        }

        return this.getRuleContext(i, Any_workoutContext);
    }
    public alternation(): AlternationContext[];
    public alternation(i: number): AlternationContext | null;
    public alternation(i?: number): AlternationContext[] | AlternationContext | null {
        if (i === undefined) {
            return this.getRuleContexts(AlternationContext);
        }

        return this.getRuleContext(i, AlternationContext);
    }
    public WORKOUT_SEPARATOR(): antlr.TerminalNode[];
    public WORKOUT_SEPARATOR(i: number): antlr.TerminalNode | null;
    public WORKOUT_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_option;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitOption) {
            return visitor.visitOption(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Option_blockContext extends antlr.ParserRuleContext {
    public _option?: OptionContext;
    public _options: OptionContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public CHOICE_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.CHOICE_KW, 0)!;
    }
    public LBRACE(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.LBRACE, 0)!;
    }
    public RBRACE(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.RBRACE, 0)!;
    }
    public CHOICE_NAME(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.CHOICE_NAME, 0);
    }
    public option(): OptionContext[];
    public option(i: number): OptionContext | null;
    public option(i?: number): OptionContext[] | OptionContext | null {
        if (i === undefined) {
            return this.getRuleContexts(OptionContext);
        }

        return this.getRuleContext(i, OptionContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_option_block;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitOption_block) {
            return visitor.visitOption_block(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class EmomContext extends antlr.ParserRuleContext {
    public _emom_low_bound?: Token | null;
    public _emom_high_bound?: Token | null;
    public _workout_comment?: Workout_commentContext;
    public _comments: Workout_commentContext[] = [];
    public _movement?: MovementContext;
    public _movements: MovementContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public EMOM_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.EMOM_KW, 0)!;
    }
    public EMOM_COLON(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.EMOM_COLON, 0)!;
    }
    public EMOM_NUM(): antlr.TerminalNode[];
    public EMOM_NUM(i: number): antlr.TerminalNode | null;
    public EMOM_NUM(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.EMOM_NUM);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.EMOM_NUM, i);
    	}
    }
    public EMOM_X_SEP(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.EMOM_X_SEP, 0);
    }
    public EMOM_DASH(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.EMOM_DASH, 0);
    }
    public EMOM_MIN_KW(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.EMOM_MIN_KW, 0);
    }
    public MIN_KW(): antlr.TerminalNode[];
    public MIN_KW(i: number): antlr.TerminalNode | null;
    public MIN_KW(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MIN_KW);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MIN_KW, i);
    	}
    }
    public movement(): MovementContext[];
    public movement(i: number): MovementContext | null;
    public movement(i?: number): MovementContext[] | MovementContext | null {
        if (i === undefined) {
            return this.getRuleContexts(MovementContext);
        }

        return this.getRuleContext(i, MovementContext);
    }
    public workout_comment(): Workout_commentContext[];
    public workout_comment(i: number): Workout_commentContext | null;
    public workout_comment(i?: number): Workout_commentContext[] | Workout_commentContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Workout_commentContext);
        }

        return this.getRuleContext(i, Workout_commentContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_emom;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitEmom) {
            return visitor.visitEmom(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class WodContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public any_workout(): Any_workoutContext[];
    public any_workout(i: number): Any_workoutContext | null;
    public any_workout(i?: number): Any_workoutContext[] | Any_workoutContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Any_workoutContext);
        }

        return this.getRuleContext(i, Any_workoutContext);
    }
    public option_block(): Option_blockContext[];
    public option_block(i: number): Option_blockContext | null;
    public option_block(i?: number): Option_blockContext[] | Option_blockContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Option_blockContext);
        }

        return this.getRuleContext(i, Option_blockContext);
    }
    public alternation(): AlternationContext[];
    public alternation(i: number): AlternationContext | null;
    public alternation(i?: number): AlternationContext[] | AlternationContext | null {
        if (i === undefined) {
            return this.getRuleContexts(AlternationContext);
        }

        return this.getRuleContext(i, AlternationContext);
    }
    public WORKOUT_SEPARATOR(): antlr.TerminalNode[];
    public WORKOUT_SEPARATOR(i: number): antlr.TerminalNode | null;
    public WORKOUT_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_wod;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWod) {
            return visitor.visitWod(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_idContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DEF_IDENTIFIER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_IDENTIFIER, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_id;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_id) {
            return visitor.visitDef_id(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_super_kwContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DEF_SUPER_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_SUPER_KW, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_super_kw;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_super_kw) {
            return visitor.visitDef_super_kw(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_super_paramContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DEF_REGEX(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_REGEX, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_super_param;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_super_param) {
            return visitor.visitDef_super_param(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_superContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public def_super_kw(): Def_super_kwContext {
        return this.getRuleContext(0, Def_super_kwContext)!;
    }
    public DEF_PAREN_L(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_PAREN_L, 0)!;
    }
    public DEF_PAREN_R(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_PAREN_R, 0)!;
    }
    public def_super_param(): Def_super_paramContext | null {
        return this.getRuleContext(0, Def_super_paramContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_super;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_super) {
            return visitor.visitDef_super(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_param_nameContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DEF_REGEX(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_REGEX, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_param_name;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_param_name) {
            return visitor.visitDef_param_name(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_param_enum_entryContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DEF_IDENTIFIER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_IDENTIFIER, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_param_enum_entry;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_param_enum_entry) {
            return visitor.visitDef_param_enum_entry(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_param_enumContext extends antlr.ParserRuleContext {
    public _def_param_enum_entry?: Def_param_enum_entryContext;
    public _def_param_enum_entries: Def_param_enum_entryContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public def_param_enum_entry(): Def_param_enum_entryContext[];
    public def_param_enum_entry(i: number): Def_param_enum_entryContext | null;
    public def_param_enum_entry(i?: number): Def_param_enum_entryContext[] | Def_param_enum_entryContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Def_param_enum_entryContext);
        }

        return this.getRuleContext(i, Def_param_enum_entryContext);
    }
    public DEF_COMMA(): antlr.TerminalNode[];
    public DEF_COMMA(i: number): antlr.TerminalNode | null;
    public DEF_COMMA(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.DEF_COMMA);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.DEF_COMMA, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_param_enum;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_param_enum) {
            return visitor.visitDef_param_enum(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_param_typeContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DEF_TYPES(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_TYPES, 0);
    }
    public DEF_ENUM_KW(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_ENUM_KW, 0);
    }
    public DEF_PAREN_L(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_PAREN_L, 0);
    }
    public def_param_enum(): Def_param_enumContext | null {
        return this.getRuleContext(0, Def_param_enumContext);
    }
    public DEF_PAREN_R(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_PAREN_R, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_param_type;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_param_type) {
            return visitor.visitDef_param_type(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_paramContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public def_param_name(): Def_param_nameContext {
        return this.getRuleContext(0, Def_param_nameContext)!;
    }
    public DEF_COLON(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_COLON, 0)!;
    }
    public def_param_type(): Def_param_typeContext | null {
        return this.getRuleContext(0, Def_param_typeContext);
    }
    public DEF_OPTIONAL(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_OPTIONAL, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_param;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_param) {
            return visitor.visitDef_param(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class DefinitionContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DEF_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_KW, 0)!;
    }
    public def_id(): Def_idContext {
        return this.getRuleContext(0, Def_idContext)!;
    }
    public DEF_BRACE_L(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_BRACE_L, 0)!;
    }
    public def_param(): Def_paramContext[];
    public def_param(i: number): Def_paramContext | null;
    public def_param(i?: number): Def_paramContext[] | Def_paramContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Def_paramContext);
        }

        return this.getRuleContext(i, Def_paramContext);
    }
    public DEF_BRACE_R(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_BRACE_R, 0)!;
    }
    public DEF_COLON(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_COLON, 0);
    }
    public def_super(): Def_superContext | null {
        return this.getRuleContext(0, Def_superContext);
    }
    public DEF_COMMA(): antlr.TerminalNode[];
    public DEF_COMMA(i: number): antlr.TerminalNode | null;
    public DEF_COMMA(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.DEF_COMMA);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.DEF_COMMA, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_definition;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDefinition) {
            return visitor.visitDefinition(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class DefsContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public EOF(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.EOF, 0)!;
    }
    public definition(): DefinitionContext[];
    public definition(i: number): DefinitionContext | null;
    public definition(i?: number): DefinitionContext[] | DefinitionContext | null {
        if (i === undefined) {
            return this.getRuleContexts(DefinitionContext);
        }

        return this.getRuleContext(i, DefinitionContext);
    }
    public new_movement(): New_movementContext[];
    public new_movement(i: number): New_movementContext | null;
    public new_movement(i?: number): New_movementContext[] | New_movementContext | null {
        if (i === undefined) {
            return this.getRuleContexts(New_movementContext);
        }

        return this.getRuleContext(i, New_movementContext);
    }
    public WS(): antlr.TerminalNode[];
    public WS(i: number): antlr.TerminalNode | null;
    public WS(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.WS);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.WS, i);
    	}
    }
    public NL(): antlr.TerminalNode[];
    public NL(i: number): antlr.TerminalNode | null;
    public NL(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.NL);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.NL, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_defs;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDefs) {
            return visitor.visitDefs(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class New_movement_nameContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public NEW_MOV_IDENTIFIER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.NEW_MOV_IDENTIFIER, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_new_movement_name;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitNew_movement_name) {
            return visitor.visitNew_movement_name(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class New_movement_paramsContext extends antlr.ParserRuleContext {
    public _NEW_MOV_PARAM?: Token | null;
    public _nmp: antlr.Token[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public NEW_MOV_PARAM(): antlr.TerminalNode[];
    public NEW_MOV_PARAM(i: number): antlr.TerminalNode | null;
    public NEW_MOV_PARAM(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.NEW_MOV_PARAM);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.NEW_MOV_PARAM, i);
    	}
    }
    public NEW_MOV_COMMA(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.NEW_MOV_COMMA, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_new_movement_params;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitNew_movement_params) {
            return visitor.visitNew_movement_params(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class New_movementContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public NEW_MOV_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.NEW_MOV_KW, 0)!;
    }
    public new_movement_name(): New_movement_nameContext {
        return this.getRuleContext(0, New_movement_nameContext)!;
    }
    public NEW_MOV_COLON(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.NEW_MOV_COLON, 0)!;
    }
    public new_movement_params(): New_movement_paramsContext {
        return this.getRuleContext(0, New_movement_paramsContext)!;
    }
    public NEW_MOV_SEMI(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.NEW_MOV_SEMI, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_new_movement;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitNew_movement) {
            return visitor.visitNew_movement(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class DayContext extends antlr.ParserRuleContext {
    public _wod?: WodContext;
    public _wods: WodContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public EOF(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.EOF, 0)!;
    }
    public WOD_KW(): antlr.TerminalNode[];
    public WOD_KW(i: number): antlr.TerminalNode | null;
    public WOD_KW(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.WOD_KW);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.WOD_KW, i);
    	}
    }
    public wod(): WodContext[];
    public wod(i: number): WodContext | null;
    public wod(i?: number): WodContext[] | WodContext | null {
        if (i === undefined) {
            return this.getRuleContexts(WodContext);
        }

        return this.getRuleContext(i, WodContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_day;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDay) {
            return visitor.visitDay(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
