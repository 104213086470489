import { Workout } from '../@monaco-uwl/UWLVisitor/Workout';
import { logger } from '../Logger';

import { EmomComponent } from './parser.types';
import { normalizeUnit, prepareNotes } from './utils';

export function parseUWLEmom(w: Workout, prefix: string): EmomComponent {
  const lowerBound =
    w.attrs['emom_low_bound'] === '' ? null : Number.parseInt(w.attrs['emom_low_bound']);
  const upperBound =
    w.attrs['emom_high_bound'] === '' ? null : Number.parseInt(w.attrs['emom_high_bound']);

  if (lowerBound == null) {
    logger.error('EMOM Parser: lower bound cannot be empty!');
  }

  const lowerRounds =
    lowerBound === null
      ? -1
      : lowerBound % w.movements.length !== 0
        ? -1
        : lowerBound / w.movements.length;
  const upperRounds =
    upperBound === null
      ? null
      : upperBound % w.movements.length !== 0
        ? -1
        : upperBound / w.movements.length;

  logger.debug('EMOM Debug :: ', { lowerBound, upperBound, lowerRounds, upperRounds });

  // Normalize units for each movement
  w.movements.forEach((movement) => normalizeUnit(movement));

  const e: EmomComponent = {
    type: 'Emom',
    movements: w.movements,
    low_bound: lowerBound ?? 0,
    up_bound: upperBound,
    rounds_low: lowerRounds, //
    rounds_high: upperRounds,
    name: '',
    notes: prepareNotes(w.notes),
    is_superset: false,
    should_be_inside_superset: false,
    prefix: prefix,
  };
  return e;
}
