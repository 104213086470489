import { RestType } from '../../../../wodupParser/wodup.types';

import { formatSecondsToTime } from './timeUtils';

export const formatRestType = (type: RestType, duration?: number): string => {
  switch (type) {
    case RestType.AsNeeded:
      return 'As needed';
    case RestType.Fixed:
      return duration ? formatSecondsToTime(duration) : '00:00';
    case RestType.Superset:
      return 'Superset (no rest)';
    case RestType.Ratio:
      return `Rest ${duration || 1} times the set's duration`;
    case RestType.Emom:
      return `On a running clock, start a set every ${duration ? formatSecondsToTime(duration) : '00:00'}`;
    default:
      return 'Not specified';
  }
};
