import { useCallback } from 'react';

import { Session } from '../generalParser/parser.types';
import { logger } from '../Logger';

interface UseComponentsDragDropProps {
  content: string;
  onContentChange: (newContent: string) => void;
}

export const useComponentsDragDrop = ({ content, onContentChange }: UseComponentsDragDropProps) => {
  const handleComponentsChange = useCallback(
    (newComponents: Session['components'], dragInfo?: { from: number; to: number }) => {
    if (!dragInfo) return;

    const contentLines: string[] = [];
    let currentLine = '';
    let insideBraces = 0;

    for (let i = 0; i < content.length; i++) {
      const char = content[i];
      
      if (char === '{') insideBraces++;
      if (char === '}') insideBraces--;
      
      if (char === '+' && insideBraces === 0) {
        contentLines.push(currentLine);
        currentLine = '';
      } else {
        currentLine += char;
      }
    }
    
    if (currentLine) {
      contentLines.push(currentLine);
    }

    if (contentLines[dragInfo.from] && contentLines[dragInfo.to]) {
      const [removed] = contentLines.splice(dragInfo.from, 1);
      contentLines.splice(dragInfo.to, 0, removed);
      if (dragInfo.from === 0) {
        contentLines[dragInfo.to] = '\n' + contentLines[dragInfo.to];
        contentLines[dragInfo.from] = contentLines[dragInfo.from].replace('\n', '');
      }
      if (dragInfo.from === contentLines.length - 1) {
        contentLines[dragInfo.to] = contentLines[dragInfo.to].replace(/\n+$/, '') + '\n';
      }
      if (dragInfo.to === 0) {
        contentLines[dragInfo.to] = contentLines[dragInfo.to].replace('\n', '');
        contentLines[1] = '\n' + contentLines[1];
      }
    }
    const newContent = contentLines.join('+');

    onContentChange(newContent);

    },
    [content, onContentChange]
  );

  return handleComponentsChange;
};
