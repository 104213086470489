// The logger wraps the console, which itself uses any
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

export enum LogLevel {
  ERROR = 0,
  WARN = 1,
  INFO = 2,
  DEBUG = 3,
}

export default class Logger {
  private level: LogLevel;

  private parseLogLevel(val?: string): LogLevel {
    switch (val?.toUpperCase().trim() ?? '') {
      case 'ERROR':
        return LogLevel.ERROR;
      case 'WARN':
        return LogLevel.WARN;
      case 'INFO':
        return LogLevel.INFO;
      case 'DEBUG':
        return LogLevel.DEBUG;
      default:
        if (process.env.NODE_ENV !== 'test') {
          console.warn("Logger :: config error -- couldn't get log level, defaulting to ERROR");
        }
        return LogLevel.ERROR;
    }
  }

  constructor() {
    this.level = this.getDefaultLogLevel();
  }

  private getDefaultLogLevel(): LogLevel {
    switch (process.env.NODE_ENV) {
      case 'production':
        return LogLevel.ERROR;
      case 'test':
        return LogLevel.INFO;
      case 'development':
        return this.parseLogLevel(process.env.REACT_APP_LOG_LEVEL);
      default:
        return LogLevel.ERROR;
    }
  }

  private shouldLog(ll: LogLevel) {
    return ll <= this.level;
  }

  public error(...params: any[]): void {
    if (this.shouldLog(LogLevel.ERROR)) {
      console.error(...params);
    }
  }
  public warn(...params: any[]): void {
    if (this.shouldLog(LogLevel.WARN)) {
      console.warn(...params);
    }
  }
  public info(...params: any[]): void {
    if (this.shouldLog(LogLevel.INFO)) {
      console.info(...params);
    }
  }
  public debug(...params: any[]): void {
    if (this.shouldLog(LogLevel.DEBUG)) {
      console.debug(...params);
    }
  }

  public log(logLevel: LogLevel, ...params: any[]): void {
    switch (logLevel) {
      case LogLevel.DEBUG:
        this.debug(...params);
        break;
      case LogLevel.INFO:
        this.info(...params);
        break;
      case LogLevel.WARN:
        this.warn(...params);
        break;
      case LogLevel.ERROR:
        this.error(...params);
        break;
      default:
        break;
    }
  }

  public getLogLevel() : LogLevel {
    return this.level;
  } 
}
export const logger = new Logger();
