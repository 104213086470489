import { ParserRuleContext, TerminalNode, Token } from 'antlr4ng';
import { MarkerSeverity } from 'monaco-editor';

import movementsData from '../../../assets/basic_movements.json';
import { logger } from '../../../Logger';
import { movementCache } from '../../../services/MovementCache';
import { Movement as MovementFromService } from '../../../services/movementsService';
import {
  MovementContext,
  Final_movementContext,
  Intermediate_movementContext,
} from '../../antlr/.antlr/UniversalWorkoutLanguageParser';
import { mapToMovementModels } from '../../utils/getMovementsFromFE';
import { UWLError } from '../../UWLErrorHandler';
import { PATTERNS, cleanTempoString } from '../constants';
import { Movement } from '../Movement';
import { MovementAttrs } from '../types';

import { UNIT_RULES, isValidUnit } from './../../rules';

let availableMovements: MovementFromService[] = [];

async function updateAvailableMovements() {
  availableMovements =
    process.env.REACT_APP_USE_MOVEMENTS_FROM_FE === 'true'
      ? mapToMovementModels(movementsData)
      : await movementCache.getMovements();
}

updateAvailableMovements().catch((error) =>
  logger.error('Failed to update movements data:', error),
);
export class MovementParser {
  constructor(private errors: UWLError[]) {}

  public parseMovement(
    ctx: MovementContext | Final_movementContext | Intermediate_movementContext,
  ): Movement {
    const movementCtx = ctx instanceof MovementContext ? ctx : ctx._movement;

    if (!movementCtx) {
      throw new Error('Invalid movement context');
    }

    const [names, mults] = this.parseMovementName(movementCtx);
    const attributes = this.parseMovementAttributes(movementCtx);
    const isComplex = names.length >= 2 && mults.length >= 2;
    return new Movement(names, mults, attributes, undefined, undefined, isComplex);
  }

  private parseMovementName(movementCtx: MovementContext): [string[], number[]] {
    const names: string[] = [];
    const mults: number[] = [];

    const movementName = movementCtx.movement_name();
    const simpleMovement = movementName.SIMPLE_MOVEMENT();

    if (simpleMovement) {
      const name = simpleMovement.getText().trim();
      names.push(name);
      this.validateMovement(name, simpleMovement.symbol, availableMovements);
    } else {
      const complex = movementName.complex_movement();
      if (complex && complex._mov_name) {
        let currentRepsIndex = 0;

        for (let i = 0; i < complex._mov_name.length; i++) {
          const name = complex._mov_name[i].text;

          const hasRepsBeforeMovement =
            currentRepsIndex < complex._mov_reps?.length &&
            complex._mov_reps[currentRepsIndex]?.line === complex._mov_name[i].line &&
            complex._mov_reps[currentRepsIndex]?.column < complex._mov_name[i].column;

          const reps = hasRepsBeforeMovement
            ? (complex._mov_reps[currentRepsIndex++]?.text ?? '')
            : '';

          if (name) {
            const trimmedName = name.trim();
            names.push(trimmedName);
            mults.push(reps ? Number.parseInt(reps) : 1);
            this.validateMovement(trimmedName, complex._mov_name[i], availableMovements);
          }
        }
      }
    }
    return [names, mults];
  }

  private validateMovement(
    name: string,
    token: { line: number; column: number; text?: string },
    availableMovements: MovementFromService[],
  ): void {
    if (name.match(PATTERNS.PLACEHOLDER)) {
      this.errors.push({
        startLineNumber: token.line,
        endLineNumber: token.line,
        startColumn: token.column + 1,
        endColumn: token.column + (token.text?.length ?? name.length) + 1,
        message: `This movement is a placeholder and should be replaced.`,
        severity: MarkerSeverity.Hint,
        code: 'Movement Placeholder',
      });
      return;
    }
    if (!availableMovements.some((m) => m.name.toLowerCase() === name.toLowerCase())) {
      this.errors.push({
        startLineNumber: token.line,
        endLineNumber: token.line,
        startColumn: token.column + 1,
        endColumn: token.column + (token.text?.length ?? name.length) + 1,
        message: `Movement '${name}' not found in the library. Click "Quick Fix" below or click "command + .(dot)" to replace it with most similar movement or add it in Library tab`,
        severity: MarkerSeverity.Warning,
        code: 'Movement Not Found',
      });
    }
  }

  private parseMovementUnits(movement: MovementContext): {
    load_unit?: string;
    distance_unit?: string;
    duration_unit?: string;
    height_unit?: string;
  } {
    const loadUnit =
      movement.load().length > 0 && movement.load()[0].MC_CONTENT()
        ? movement.load()[0].MC_CONTENT().getText()
        : undefined;

    const distanceUnit =
      movement.distance().length > 0 && movement.distance()[0].MC_CONTENT()
        ? movement.distance()[0].MC_CONTENT().getText()
        : undefined;

    const durationUnit =
      movement.duration().length > 0 && movement.duration()[0].MC_CONTENT()
        ? movement.duration()[0].MC_CONTENT().getText()
        : undefined;

    const heightUnit =
      movement.height().length > 0 && movement.height()[0].MC_CONTENT()
        ? movement.height()[0].MC_CONTENT().getText()
        : undefined;

    if (loadUnit !== undefined) {
      const loads = movement.load()[0]._loads;
      for (const load of loads) {
        const loadText = load.getText();

        if (loadText?.match(PATTERNS.PLACEHOLDER)) {
          if (load.start) {
            this.errors.push(this.generatePlaceholderWarning(load.start));
          }
          continue;
        }

        if (loadText.includes('/')) {
          const parts = loadText.split('/');
          const isValid = parts.every((part) => part === 'M' || part.match(PATTERNS.FLOAT));

          if (!isValid) {
            const startLine = load.start?.line ?? 0;
            const endLine = load.stop?.line ?? startLine;
            const startColumn = load.start?.column ?? 0;
            const endColumn = (load.stop?.column ?? 0) + (load.stop?.text?.length ?? 0);

            this.errors.push({
              startLineNumber: startLine,
              endLineNumber: endLine,
              startColumn: startColumn + 1,
              endColumn: endColumn + 1,
              message: `"Load" should be either a number or 'M' for maximum`,
              severity: MarkerSeverity.Error,
            });
          }
        }
        // For simple values, check if it's a valid number or M
        else if (!(loadText.match(PATTERNS.FLOAT) || loadText === 'M')) {
          const startLine = load.start?.line ?? 0;
          const endLine = load.stop?.line ?? startLine;
          const startColumn = load.start?.column ?? 0;
          const endColumn = (load.stop?.column ?? 0) + (load.stop?.text?.length ?? 0);

          this.errors.push({
            startLineNumber: startLine,
            endLineNumber: endLine,
            startColumn: startColumn + 1,
            endColumn: endColumn + 1,
            message: `"Load" should be either a number or 'M' for maximum`,
            severity: MarkerSeverity.Error,
          });
        }
      }

      if (!isValidUnit(loadUnit, 'LOAD')) {
        this.errors.push({
          startLineNumber: movement.load()[0].MC_CONTENT().symbol.line,
          endLineNumber: movement.load()[0].MC_CONTENT().symbol.line,
          startColumn: movement.load()[0].MC_CONTENT().symbol.column + 1,
          endColumn:
            movement.load()[0].MC_CONTENT().symbol.column +
            movement.load()[0].MC_CONTENT().getText().length +
            1,
          message: `"${movement.load()[0].MC_CONTENT().getText().trim()}" is not a valid load unit\nShould be one of ${UNIT_RULES.LOAD.accepted.join(', ')}`,
          severity: MarkerSeverity.Error,
        });
      }
    }

    if (heightUnit !== undefined) {
      if (!isValidUnit(heightUnit, 'HEIGHT')) {
        this.errors.push({
          startLineNumber: movement.height()[0].MC_CONTENT().symbol.line,
          endLineNumber: movement.height()[0].MC_CONTENT().symbol.line,
          startColumn: movement.height()[0].MC_CONTENT().symbol.column + 1,
          endColumn:
            movement.height()[0].MC_CONTENT().symbol.column +
            movement.height()[0].MC_CONTENT().getText().length +
            1,
          message: `"${movement.height()[0].MC_CONTENT().getText().trim()}" is not a valid height unit\nShould be one of ${UNIT_RULES.HEIGHT.accepted.join(', ')}`,
          severity: MarkerSeverity.Error,
        });
      }
    }

    if (distanceUnit !== undefined) {
      if (!isValidUnit(distanceUnit, 'DISTANCE')) {
        this.errors.push({
          startLineNumber: movement.distance()[0].MC_CONTENT().symbol.line,
          endLineNumber: movement.distance()[0].MC_CONTENT().symbol.line,
          startColumn: movement.distance()[0].MC_CONTENT().symbol.column + 1,
          endColumn:
            movement.distance()[0].MC_CONTENT().symbol.column +
            movement.distance()[0].MC_CONTENT().getText().length +
            1,
          message: `"${movement.distance()[0].MC_CONTENT().getText().trim()}" is not a valid distance unit\nShould be one of ${UNIT_RULES.DISTANCE.accepted.join(', ')}`,
          severity: MarkerSeverity.Error,
        });
      }
    }

    if (durationUnit !== undefined) {
      if (!isValidUnit(durationUnit, 'TIME')) {
        this.errors.push({
          startLineNumber: movement.duration()[0].MC_CONTENT().symbol.line,
          endLineNumber: movement.duration()[0].MC_CONTENT().symbol.line,
          startColumn: movement.duration()[0].MC_CONTENT().symbol.column + 1,
          endColumn:
            movement.duration()[0].MC_CONTENT().symbol.column +
            movement.duration()[0].MC_CONTENT().getText().length +
            1,
          message: `"${movement.duration()[0].MC_CONTENT().getText().trim()}" is not a valid time unit\nShould be one of ${UNIT_RULES.TIME.accepted.join(', ')}`,
          severity: MarkerSeverity.Error,
        });
      }
    }

    return {
      load_unit: loadUnit,
      distance_unit: distanceUnit,
      duration_unit: durationUnit,
      height_unit: heightUnit,
    };
  }

  private checkForDuplicateAttribute(name: string, attributes: ParserRuleContext[]): UWLError[] {
    const errors: UWLError[] = [];

    for (let i = 1; i < attributes.length; i++) {
      const offender = attributes[i];
      if (offender.start === null || offender.stop === null) {
        continue;
      }
      errors.push({
        startLineNumber: offender.start.line,
        endLineNumber: offender.stop.line,
        startColumn: offender.start.column + 1,
        endColumn: offender.stop.column + (offender.stop.text?.length ?? 0) + 1,
        message: `Duplicate movement attribute "${name}".\nOnly the first value will be used.`,
        severity: MarkerSeverity.Warning,
      });
    }

    return errors;
  }

  private parseMovementReps(movement: MovementContext): string[] | undefined {
    const errors: UWLError[] = [];
    if (movement.reps().length === 0) {
      return undefined;
    }
    errors.push(...this.checkForDuplicateAttribute('Reps', movement.reps()));

    const repsContext = movement.reps()[0];
    const result: string[] = [];

    for (const repValue of repsContext.rep_value()) {
      const placeholderToken = repValue.MC_PLACEHOLDER();
      const rangeToken = repValue.MC_RANGE();
      const numberToken = repValue.MC_NUMBER();
      const maxToken = repValue.MC_MAX();
      const amrapValue = repValue.amrap_value();
      const clusterToken = repValue.MC_CLUSTER();

      if (placeholderToken) {
        errors.push(this.generatePlaceholderWarning(placeholderToken));
        result.push(placeholderToken.getText());
      } else if (rangeToken) {
        result.push(rangeToken.getText());
      } else if (clusterToken) {
        const clusterText = clusterToken.getText();
        const numbers = clusterText.split('.');
        result.push(`(${numbers.join('-')})`);
      } else if (numberToken) {
        result.push(numberToken.getText());
      } else if (maxToken) {
        result.push('X');
      } else if (amrapValue) {
        const number = amrapValue.MC_NUMBER();
        if (number) {
          const numberText = number.getText();
          const dashToken = amrapValue.MC_DASH();
          const value = dashToken ? `-${numberText}` : numberText;
          result.push(`AMRAP(${value})`);
        } else {
          result.push('AMRAP');
        }
      }
    }

    this.errors.push(...errors);
    return result;
  }

  private parseMovementLoad(movement: MovementContext): string[] | undefined {
    this.errors.push(...this.checkForDuplicateAttribute('Load', movement.load()));

    if (movement.load().length === 0) {
      return undefined;
    }

    // Extract male loads (default loads)
    const result = movement.load()[0]._loads.map((item) => {
      const loadText = item.getText();

      // If it contains a slash, take only the first part (male load)
      if (loadText.includes('/')) {
        const parts = loadText.split('/');
        return parts[0] === 'M' ? 'X' : parts[0];
      }

      // Otherwise return the whole value
      return loadText === 'M' ? 'X' : loadText;
    });

    return result;
  }

  private parseMovementLoadFemale(movement: MovementContext): string[] | undefined {
    if (movement.load().length === 0) {
      return undefined;
    }

    // Filter out loads that don't have a slash (no female values)
    const femaleLoads = movement
      .load()[0]
      ._loads.filter((item) => item.getText().includes('/'))
      .map((item) => {
        const parts = item.getText().split('/');
        if (parts.length < 2) return undefined; // Should not happen given the filter

        return parts[1] === 'M' ? 'X' : parts[1];
      })
      .filter((value): value is string => value !== undefined);

    // If no female loads found, return undefined
    return femaleLoads.length > 0 ? femaleLoads : undefined;
  }

  private parseMovementTempo(movement: MovementContext): string[] | undefined {
    const errors: UWLError[] = [];
    if (movement.tempo().length === 0) {
      return undefined;
    }
    errors.push(...this.checkForDuplicateAttribute('Tempo', movement.tempo()));
    for (const tempo of movement.tempo()[0]._tempos) {
      if (tempo.text?.match(PATTERNS.PLACEHOLDER)) {
        errors.push(this.generatePlaceholderWarning(tempo));
        continue;
      }

      const cleanedTempo = tempo.text ? cleanTempoString(tempo.text) : '';
      if (!cleanedTempo.match(PATTERNS.TEMPO)) {
        errors.push({
          startLineNumber: tempo.line,
          endLineNumber: tempo.line,
          startColumn: tempo.column + 1,
          endColumn: tempo.column + (tempo.text?.length ?? 0) + 1,
          message: `Tempo should be between 1 and 4 characters long and should be composed of digits 0-9 and the letters 'A' and 'X'`,
          severity: MarkerSeverity.Error,
        });
      }
    }

    this.errors.push(...errors);

    return movement.tempo().length === 0
      ? undefined
      : movement.tempo()[0]._tempos?.map((item) => {
          const cleanedTempo = item.text ? cleanTempoString(item.text) : '';
          return cleanedTempo.replace(/[ax]/g, (char) => char.toUpperCase()).substring(0, 4);
        });
  }

  private parseMovementDistance(movement: MovementContext): string[] | undefined {
    const errors: UWLError[] = [];
    errors.push(...this.checkForDuplicateAttribute('Distance', movement.distance()));

    if (movement.distance().length === 0) {
      return undefined;
    }

    for (const dist of movement.distance()[0]._distances) {
      if (dist.text?.match(PATTERNS.PLACEHOLDER)) {
        errors.push(this.generatePlaceholderWarning(dist));
      }
      if (!(dist.text?.match(PATTERNS.FLOAT) || dist.text?.match(PATTERNS.MAX))) {
        errors.push({
          startLineNumber: dist.line,
          endLineNumber: dist.line,
          startColumn: dist.column + 1,
          endColumn: dist.column + (dist.text?.length ?? 0) + 1,
          message: `"Distance" should be either an Number or 'M' for maximize`,
          severity: MarkerSeverity.Error,
        });
      }
    }

    this.errors.push(...errors);

    return movement.distance().length === 0
      ? undefined
      : movement
          .distance()[0]
          ._distances.map((item) => (item.text === 'M' ? 'X' : (item.text ?? '')));
  }

  private parseMovementDuration(movement: MovementContext): string[] | undefined {
    const errors: UWLError[] = [];
    errors.push(...this.checkForDuplicateAttribute('Duration', movement.duration()));

    if (movement.duration().length === 0) {
      return undefined;
    }

    for (const duration of movement.duration()[0]._durations) {
      if (duration.text?.match(PATTERNS.PLACEHOLDER)) {
        errors.push(this.generatePlaceholderWarning(duration));
        continue;
      }
      if (!(duration.text?.match(PATTERNS.FLOAT) || duration.text?.match(PATTERNS.MAX))) {
        errors.push({
          startLineNumber: duration.line,
          endLineNumber: duration.line,
          startColumn: duration.column + 1,
          endColumn: duration.column + (duration.text?.length ?? 0) + 1,
          message: `"Duration" should be either a Number or 'M' for maximum`,
          severity: MarkerSeverity.Error,
        });
      }
    }

    this.errors.push(...errors);

    return movement.duration().length === 0
      ? undefined
      : movement
          .duration()[0]
          ._durations.map((item) => (item.text === 'M' ? 'X' : (item.text ?? '')));
  }

  private parseMovementCalories(movement: MovementContext): string[] | undefined {
    const errors: UWLError[] = [];
    errors.push(...this.checkForDuplicateAttribute('Calories', movement.calories()));

    if (movement.calories().length === 0) {
      return undefined;
    }

    for (const cal of movement.calories()[0]._cals) {
      if (cal.text?.match(PATTERNS.PLACEHOLDER)) {
        errors.push(this.generatePlaceholderWarning(cal));
        continue;
      }
      if (!(cal.text?.match(PATTERNS.FLOAT) || cal.text?.match(PATTERNS.MAX))) {
        errors.push({
          startLineNumber: cal.line,
          endLineNumber: cal.line,
          startColumn: cal.column + 1,
          endColumn: cal.column + (cal.text?.length ?? 0) + 1,
          message: `"Calories" should be either a Number or 'M' for maximize`,
          severity: MarkerSeverity.Error,
        });
      }
    }

    this.errors.push(...errors);

    return movement
      .calories()[0]
      ._cals.map((item) => (item.text === 'M' ? 'X' : (item.text ?? '')));
  }

  private parseMovementPower(movement: MovementContext): string[] | undefined {
    const errors: UWLError[] = [];
    errors.push(...this.checkForDuplicateAttribute('Power', movement.power()));

    if (movement.power().length === 0) {
      return undefined;
    }

    for (const power of movement.power()[0]._powers) {
      if (power.text?.match(PATTERNS.PLACEHOLDER)) {
        errors.push(this.generatePlaceholderWarning(power));
        continue;
      }
      if (!(power.text?.match(PATTERNS.FLOAT) || power.text?.match(PATTERNS.MAX))) {
        errors.push({
          startLineNumber: power.line,
          endLineNumber: power.line,
          startColumn: power.column + 1,
          endColumn: power.column + (power.text?.length ?? 0) + 1,
          message: `"Power" should be either a Number or 'M' for maximize`,
          severity: MarkerSeverity.Error,
        });
      }
    }

    this.errors.push(...errors);

    return movement.power()[0]._powers.map((item) => (item.text === 'M' ? 'X' : (item.text ?? '')));
  }

  private parseMovementHeight(movement: MovementContext): string[] | undefined {
    const errors: UWLError[] = [];
    errors.push(...this.checkForDuplicateAttribute('Height', movement.height()));

    if (movement.height().length === 0) {
      return undefined;
    }

    for (const height of movement.height()[0]._heights) {
      if (height.text?.match(PATTERNS.PLACEHOLDER)) {
        errors.push(this.generatePlaceholderWarning(height));
        continue;
      }
      if (!(height.text?.match(PATTERNS.FLOAT) || height.text?.match(PATTERNS.MAX))) {
        errors.push({
          startLineNumber: height.line,
          endLineNumber: height.line,
          startColumn: height.column + 1,
          endColumn: height.column + (height.text?.length ?? 0) + 1,
          message: `"Height" should be either a Number or 'M' for maximize`,
          severity: MarkerSeverity.Error,
        });
      }
    }

    this.errors.push(...errors);

    return movement
      .height()[0]
      ._heights.map((item) => (item.text === 'M' ? 'X' : (item.text ?? '')));
  }

  private parseMovementAttributes(movement: MovementContext): MovementAttrs {
    const units = this.parseMovementUnits(movement);
    return {
      reps: this.parseMovementReps(movement),
      load: this.parseMovementLoad(movement),
      load_female: this.parseMovementLoadFemale(movement),
      tempo: this.parseMovementTempo(movement),
      distance: this.parseMovementDistance(movement),
      duration: this.parseMovementDuration(movement),
      power: this.parseMovementPower(movement),
      sets: this.parseMovementSets(movement),
      calories: this.parseMovementCalories(movement),
      height: this.parseMovementHeight(movement),
      load_unit: units.load_unit,
      distance_unit: units.distance_unit,
      duration_unit: units.duration_unit,
      height_unit: units.height_unit,
    };
  }

  private parseMovementSets(movement: MovementContext): string | undefined {
    const errors: UWLError[] = [];
    const setsContexts = movement.sets();

    if (!setsContexts || setsContexts.length === 0) {
      return undefined;
    }

    errors.push(...this.checkForDuplicateAttribute('Sets', setsContexts));

    const firstSetsContext = setsContexts[0];

    const placeholder = firstSetsContext.MC_PLACEHOLDER();
    if (placeholder !== null) {
      errors.push(this.generatePlaceholderWarning(placeholder));
      return undefined;
    }

    const text = firstSetsContext.getText();

    this.errors.push(...errors);

    return text;
  }

  private generatePlaceholderWarning(placeholderNode: TerminalNode | Token): UWLError {
    if (placeholderNode instanceof TerminalNode) {
      return {
        startLineNumber: placeholderNode.getSymbol().line,
        endLineNumber: placeholderNode.getSymbol().line,
        startColumn: placeholderNode.getSymbol().column + 1,
        endColumn:
          placeholderNode.getSymbol().column + (placeholderNode.getSymbol().text?.length ?? 0) + 1,
        message: `Unresolved placeholder`,
        severity: MarkerSeverity.Warning,
      };
    } else {
      return {
        startLineNumber: placeholderNode.line,
        endLineNumber: placeholderNode.line,
        startColumn: placeholderNode.column + 1,
        endColumn: placeholderNode.column + (placeholderNode.text?.length ?? 0) + 1,
        message: `Unresolved placeholder`,
        severity: MarkerSeverity.Warning,
      };
    }
  }
}
