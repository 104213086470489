import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import { Box, Paper, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

import { Session, SessionType, StrengthComponent } from '../../../generalParser/parser.types';
import { logger } from '../../../Logger';
import { styles } from '../WodUpPreview.styles';

import { AmrapPreview } from './AmrapPreview/AmrapPreview';
import { EmomPreview } from './EmomPreview/EmomPreview';
import { EveryPreview } from './EveryPreview/EveryPreview';
import { ForTimePreview } from './ForTimePreview/ForTimePreview';
import { GenericPreview } from './GenericPreview';
import { StrengthPreview } from './StrengthPreview/StrengthPreview';
import { SupersetPreview } from './SupersetPreview';

interface SessionPreviewProps {
  session: Session;
  onComponentsChange?: (
    newComponents: Session['components'],
    dragInfo?: { from: number; to: number },
  ) => void;
}

export const SessionPreview: React.FC<SessionPreviewProps> = ({ session, onComponentsChange }) => {
  const [components, setComponents] = useState(session.components);

  useEffect(() => {
    setComponents(session.components);
  }, [session.components]);

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (!destination) return;
    if (destination.index === source.index) return;

    const newComponents = Array.from(components);
    const [removed] = newComponents.splice(source.index, 1);
    newComponents.splice(destination.index, 0, removed);
    setComponents(newComponents);

    logger.debug('Dragged component:', {
      component: removed,
      from: source.index,
      to: destination.index,
    });

    if (onComponentsChange) {
      onComponentsChange(newComponents, { from: source.index, to: destination.index });
    }
  };

  const processedComponents = components.reduce<
    { node: React.ReactNode; originalIndices: number[] }[]
  >((acc, component, index) => {
    if (
      index > 0 &&
      component.should_be_inside_superset &&
      component.superset_group_id === components[index - 1].superset_group_id
    ) {
      return acc;
    }

    if (!component.should_be_inside_superset) {
      acc.push({
        node: (
          <Draggable key={`regular-${index}`} draggableId={`regular-${index}`} index={acc.length}>
            {(provided, snapshot) => (
              <Box
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                sx={{
                  ...styles.componentBox,
                  opacity: snapshot.isDragging ? 0.8 : 1,
                  position: 'relative',
                }}
              >
                <ControlCameraIcon sx={styles.dragHandleIcon} />
                {component.type === 'Generic' && (
                  <GenericPreview component={component} sessionType={session.type} />
                )}
                {component.type === 'Strength' && (
                  <StrengthPreview component={component} sessionType={session.type} />
                )}
                {component.type === 'ForTime' && (
                  <ForTimePreview component={component} sessionType={session.type} />
                )}
                {component.type === 'Amrap' && (
                  <AmrapPreview component={component} sessionType={session.type} />
                )}
                {component.type === 'Emom' && (
                  <EmomPreview component={component} sessionType={session.type} />
                )}
                {component.type === 'Every' && (
                  <EveryPreview component={component} sessionType={session.type} />
                )}
              </Box>
            )}
          </Draggable>
        ),
        originalIndices: [index],
      });
      return acc;
    }

    const supersetComponents: StrengthComponent[] = [];
    const groupIndices: number[] = [];
    let currentIndex = index;

    while (
      currentIndex < components.length &&
      components[currentIndex].superset_group_id === component.superset_group_id &&
      components[currentIndex].type === 'Strength'
    ) {
      supersetComponents.push(components[currentIndex] as StrengthComponent);
      groupIndices.push(currentIndex);
      currentIndex++;
    }

    if (supersetComponents.length > 1) {
      const groupIndex = acc.length;
      acc.push({
        node: (
          <Draggable
            key={`superset-${component.superset_group_id}`}
            draggableId={`superset-${component.superset_group_id}`}
            index={groupIndex}
          >
            {(provided, snapshot) => (
              <Box
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                sx={{
                  ...styles.componentBox,
                  opacity: snapshot.isDragging ? 0.8 : 1,
                  position: 'relative',
                }}
              >
                <ControlCameraIcon sx={styles.dragHandleIcon} />
                <SupersetPreview components={supersetComponents} sessionType={session.type} />
              </Box>
            )}
          </Draggable>
        ),
        originalIndices: groupIndices,
      });
    } else {
      acc.push({
        node: (
          <Draggable key={`regular-${index}`} draggableId={`regular-${index}`} index={acc.length}>
            {(provided, snapshot) => (
              <Box
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                sx={{
                  ...styles.componentBox,
                  opacity: snapshot.isDragging ? 0.8 : 1,
                  position: 'relative',
                }}
              >
                <ControlCameraIcon sx={styles.dragHandleIcon} />
                <StrengthPreview component={supersetComponents[0]} sessionType={session.type} />
              </Box>
            )}
          </Draggable>
        ),
        originalIndices: [index],
      });
    }

    return acc;
  }, []);

  return (
    <Paper
      elevation={3}
      sx={{
        ...styles.sessionPaper,
        ...(session.type === SessionType.AM ? styles.amSession : styles.pmSession),
        mb: 2,
      }}
    >
      <Typography variant="h6" sx={styles.sessionTitle}>
        {session.type === SessionType.AM ? (
          <>
            <WbSunnyOutlinedIcon sx={styles.sessionIcon} />
            AM Session
          </>
        ) : (
          <>
            <DarkModeOutlinedIcon sx={styles.sessionIcon} />
            PM Session
          </>
        )}
      </Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={`session-${session.type}`}>
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              {processedComponents.map(({ node }, index) => (
                <div key={`component-${index}`}>{node}</div>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Paper>
  );
};
