export const styles = {
  helpButton: {
    position: 'absolute',
    bottom: '16px',
    right: '16px',
    zIndex: 1,
    fontSize: '20px',
    backgroundColor: '#7E0012',
    fontFamily: '"Bebas Neue", sans-serif',
    '&:hover': {
      backgroundColor: '#9E0016'
    }
  },
  correctionBox: {
    position: 'absolute',
    bottom: '16px',
    right: '16px',
    p: 2,
    zIndex: 1,
  },
  explanationBox: {
    backgroundColor: {
      error: '#ffebee',
      default: '#f5f5f5',
    },
    padding: '10px',
    fontSize: '14px',
    borderRadius: '8px',
    position: 'relative',
    marginBottom: '15px',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: '-10px',
      right: '20px',
      borderWidth: '10px 10px 0',
      borderStyle: 'solid',
      borderColor: '#f5f5f5 transparent transparent',
    },
  },
  actionButtons: {
    display: 'flex',
    gap: 2,
    justifyContent: 'flex-end',
  },
  acceptButton: {
    mt: 1,
    backgroundColor: '#203F00',
    fontSize: '18px',
    fontFamily: '"Bebas Neue", sans-serif',
    '&:hover': {
      backgroundColor: '#2a5400'
    }
  },
  rejectButton: {
    mt: 1,
    color: '#ffffff',
    border: 0,
    fontSize: '18px',
    backgroundColor: '#7E0012',
    fontFamily: '"Bebas Neue", sans-serif',
    '&:hover': {
      backgroundColor: '#9E0016'
    }
  },
} as const;
