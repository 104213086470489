import { SxProps, Theme } from '@mui/material';

export const styles = {
  paper: {
    mt: 8,
    mb: 8,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    bgcolor: 'white',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: '0 0 15px 5px gray',
    },
  },

  logo: {
    width: '100px',
    marginBottom: '20px',
  },

  avatar: {
    m: 1,
    bgcolor: 'black',
  },

  title: {
    color: 'black',
    fontFamily: 'Bebas Neue',
    fontSize: '2rem',
  },

  form: {
    mt: 1,
  },

  textField: {
    '& .MuiInputBase-root': {
      color: 'black',
      '&.Mui-focused': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiFilledInput-root': {
      backgroundColor: 'rgba(255, 4, 60, 0.04)',
      '&:hover': {
        backgroundColor: 'rgba(255, 4, 60, 0.06)',
      },
      '&.Mui-focused': {
        backgroundColor: 'rgba(255, 4, 60, 0.04)',
      },
    },
    '& .MuiInputLabel-root': { color: 'black' },
    '& .MuiInputLabel-root.Mui-focused': { color: 'black' },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },

  submitButton: (isSubmitting: boolean): SxProps<Theme> => ({
    mt: 3.5,
    mb: 2.5,
    bgcolor: isSubmitting ? 'grey' : '#ff043c',
    color: 'white',
    fontFamily: 'Bebas Neue',
    fontSize: '1.5rem',
    width: '50%',
    display: 'block',
    mx: 'auto',
  }),

  loginLink: {
    textAlign: 'center',
    '& a': {
      color: '#ff043c',
    },
  },
} as const;
