import api from '../api/base';
import { ProgramSettings, UserSettings } from '../types/settings';

const USER_SETTINGS_API = '/api/settings/user';
const PROGRAM_SETTINGS_API = '/api/settings/program';

class UserSettingsService {
  public async fetchUserSettings(): Promise<UserSettings> {
    const response = await api.get<UserSettings>(USER_SETTINGS_API);
    return response.data;
  }

  public async setUserSettings(settings: UserSettings): Promise<void> {
    await api.put<UserSettings>(USER_SETTINGS_API, settings);
  }

  public async resetUserSettings(): Promise<void> {
    await api.delete(USER_SETTINGS_API);
  }
}

class ProgramSettingsService {
  public async fetchProgramSettings(program_id: number): Promise<ProgramSettings> {
    const response = await api.get<ProgramSettings>(`${PROGRAM_SETTINGS_API}/${program_id}`);
    return response.data;
  }

  public async setProgramSettings(program_id: number, settings: ProgramSettings): Promise<void> {
    await api.put<ProgramSettings>(`${PROGRAM_SETTINGS_API}/${program_id}`, settings);
  }

  public async resetProgramSettings(program_id: number): Promise<void> {
    await api.delete(`${PROGRAM_SETTINGS_API}/${program_id}`);
  }
}

export const userSettingsService = new UserSettingsService();
export const programSettingsService = new ProgramSettingsService();
