import {
  ExpandMore,
  ExpandLess,
  Edit,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from '@mui/icons-material';
import { Box, Typography, IconButton, Collapse, TextField, Button } from '@mui/material';
import { useEffect, useState } from 'react';

import { useDebouncedSave } from '../../hooks/useDebouncedSave';
import { useWeeks } from '../../hooks/useWeeks';
import { userPreferencesService } from '../../services/userPreferencesService';
import { ProgramCycle } from '../../types/program';
import { WeekGrid } from '../WeekGrid/WeekGrid';
import { WeekManager } from '../WeekManager/WeekManager';

import { cycleStyles } from './Cycle.styles';
import { CycleProps } from './Cycle.types';

export const Cycle: React.FC<CycleProps> = ({
  programId,
  cycleId,
  cycleName,
  cycleOperations,
  index,
  isFirst,
  isLast,
  onMoveUp,
  onMoveDown,
  monacoSettings,
  initiallyExpanded = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(initiallyExpanded);
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(cycleName);
  const [cycleData, setCycleData] = useState<ProgramCycle | null>(null);

  const { weeks, setWeeks, addWeeks, removeWeeks } = useWeeks(2);
  const debouncedSave = useDebouncedSave(programId, cycleId);

  const shade = (index % 3) * 60;

  useEffect(() => {
    if (initiallyExpanded && !isExpanded) {
      handleInitialExpand();
    }
  }, [initiallyExpanded]);

  const handleInitialExpand = async () => {
    setIsExpanded(true);
    try {
      const data = await cycleOperations.fetchCycle(cycleId);
      setCycleData(data);
      setWeeks(cycleOperations.getInitialWeeks(data.days));
      userPreferencesService.addOpenCycle(programId, cycleId);
    } catch (error) {
      setWeeks(2);
    }
  };

  const handleToggle = async () => {
    const willExpand = !isExpanded;
    setIsExpanded(willExpand);

    if (willExpand) {
      try {
        const data = await cycleOperations.fetchCycle(cycleId);
        setCycleData(data);
        setWeeks(cycleOperations.getInitialWeeks(data.days));
        userPreferencesService.addOpenCycle(programId, cycleId);
      } catch (error) {
        setWeeks(2);
      }
    } else {
      userPreferencesService.removeOpenCycle(programId, cycleId);
    }
  };

  const handleWeeksChange = (weekChange: number) => {
    if (weekChange > 0) {
      addWeeks(weekChange);
    } else if (weekChange < 0) {
      removeWeeks(Math.abs(weekChange));
    }
  };

  const handleNameSubmit = async () => {
    try {
      await cycleOperations.handleNameSubmit(cycleId, editedName, cycleName);
    } catch (error) {
      setEditedName(cycleName);
    }
    setIsEditing(false);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleNameSubmit();
    } else if (e.key === 'Escape') {
      setIsEditing(false);
      setEditedName(cycleName);
    }
  };

  const handleCycleRemove = () => cycleOperations.handleCycleRemove(cycleId);

  return (
    <Box sx={cycleStyles.cycleContainer}>
      <Box sx={cycleStyles.cycleHeader(shade)}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', mr: 1 }}>
            <IconButton
              onClick={onMoveUp}
              disabled={isFirst}
              size="small"
              sx={cycleStyles.arrowButton}
            >
              <KeyboardArrowUp />
            </IconButton>
            <IconButton
              onClick={onMoveDown}
              disabled={isLast}
              size="small"
              sx={cycleStyles.arrowButton}
            >
              <KeyboardArrowDown />
            </IconButton>
          </Box>
          {isEditing ? (
            <TextField
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
              onBlur={handleNameSubmit}
              onKeyDown={handleKeyPress}
              autoFocus
              size="small"
              sx={cycleStyles.nameInput}
            />
          ) : (
            <Box sx={cycleStyles.titleContainer}>
              <Typography variant="h6" sx={cycleStyles.cycleTitle}>
                {cycleName}
              </Typography>
              <IconButton
                onClick={() => setIsEditing(true)}
                size="small"
                sx={cycleStyles.editButton}
              >
                <Edit fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>
        <IconButton onClick={handleToggle} size="large" sx={cycleStyles.expandButton}>
          {isExpanded ? <ExpandLess sx={{ fontSize: 40 }} /> : <ExpandMore sx={{ fontSize: 40 }} />}
        </IconButton>
      </Box>

      <Collapse in={isExpanded}>
        <Box sx={cycleStyles.cycleContent}>
          <WeekGrid
            weeks={weeks}
            onDayContentChange={debouncedSave}
            initialDaysData={cycleData?.days || []}
            monacoSettings={monacoSettings}
          />
          <Box sx={cycleStyles.cycleFooter}>
            <Box>
              <Button variant="outlined" sx={cycleStyles.deleteButton} onClick={handleCycleRemove}>
                Delete Cycle
              </Button>
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <WeekManager onWeeksChange={handleWeeksChange} />
            </Box>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};
