import { Movement } from '../@monaco-uwl/UWLVisitor/Movement';
import { Workout } from '../@monaco-uwl/UWLVisitor/Workout';
import { RestType } from '../wodupParser/wodup.types';

import { ForTimeComponent } from './parser.types';
import { normalizeUnit, prepareNotes } from './utils';

function prepareName(rounds: number | undefined): string {
  if (rounds === 1) {
    return `${rounds} Round For Time`;
  }
  return `${rounds} Rounds For Time`;
}

function isMultipleRounds(rounds: string | undefined): boolean {
  if (!rounds) return false;
  const roundsNum = parseInt(rounds);
  return !isNaN(roundsNum) && roundsNum >= 1;
}

function normalizeMovementAttributes(movement: Movement, rounds: number): Movement {
  if (!movement || !movement.attributes) {
    return movement;
  }

  const attributes = movement.attributes;
  const arrayKeys = ['reps', 'load', 'distance', 'duration', 'tempo', 'power', 'calories'] as const;

  const nonEmptyArrays = arrayKeys
    .filter((key) => {
      const value = attributes[key];
      return Array.isArray(value) && value.length > 0;
    })
    .map((key) => ({
      key,
      value: attributes[key] as string[],
    }));

  if (nonEmptyArrays.length === 0) {
    return movement;
  }

  // Always normalize to the number of rounds
  const targetLength = rounds;

  nonEmptyArrays.forEach(({ key, value }) => {
    if (value.length < targetLength) {
      // Extend array if too short
      const lastValue = value[value.length - 1];
      const extension = new Array(targetLength - value.length).fill(lastValue);
      attributes[key] = [...value, ...extension];
    } else if (value.length > targetLength) {
      // Truncate array if too long
      attributes[key] = value.slice(0, targetLength);
    }
  });

  return movement;
}

export function parseUWLForTime(w: Workout, prefix: string): ForTimeComponent {
  const rounds = parseInt(w.attrs.rounds);

  // Normalize each movement's attributes to match the number of rounds
  const normalizedMovements =
    w.movements?.map((movement) => normalizeMovementAttributes(movement, rounds)) ?? [];

  // Normalize units for each movement
  normalizedMovements.forEach((movement) => normalizeUnit(movement));

  return {
    type: 'ForTime',
    is_superset: w.is_superset,
    should_be_inside_superset: false,
    prefix: prefix,
    name: prepareName(rounds),
    rounds: rounds,
    movements: normalizedMovements,
    roundRestType: w.attrs.round_rest_type as RestType,
    roundRestDuration: w.attrs.round_rest_duration,
    componentRestType: w.attrs.component_rest_type as RestType,
    componentRestDuration: w.attrs.component_rest_duration,
    notes: prepareNotes(w.notes),
    isMultipleRounds: isMultipleRounds(w.attrs.rounds),
  };
}

/* 

1- No rest for time (CrossFit) 
4 rounds for time
 > # movement (load where applicable)
 > # movement (load where applicable)
 > # movement (load where applicable)
*Notes

2- Interval style 1 - For time (CrossFit w/ embedded rest)
4 rounds for time
 > # movement (load where applicable)
 > # movement (load where applicable)
rest exactly 2 min into
 3 rounds for time
 > # movement (load where applicable)
 > # movement (load where applicable)

3- Interval style 2 - multiple movements with rest between
For time:
> # movement (load where applicable)
rest 30 sec
> # movement (load where applicable)
rest 30 sec
> # movement (load where applicable)
rest 45 sec
> # movement (load where applicable)

4- Interval style 3 - multiple movements with rest between for multiple sets
For time:
> # movement (load where applicable)
rest 30 sec
> # movement (load where applicable)
rest 30 sec
> # movement (load where applicable)
rest 45 sec
> # movement (load where applicable)
rest # min
x# sets
*Notes

5- Interval style 4 - multiple rounds for multiple sets
3 rounds, for time:
 > # movement (load where applicable)
 > # movement (load where applicable)
rest # min
x# sets
*Notes
*/
