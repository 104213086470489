import { Workout } from '../@monaco-uwl/UWLVisitor/Workout';

import { EveryComponent } from './parser.types';
import { normalizeUnit, prepareNotes } from './utils';

export function parseUWLEvery(w: Workout, prefix: string): EveryComponent {
  // Normalize units for each movement
  w.movements.forEach((movement) => normalizeUnit(movement));

  return {
    type: 'Every',
    name: w.name ?? '',
    notes: prepareNotes(w.notes),
    is_superset: w.is_superset,
    should_be_inside_superset: false,
    movements: w.movements,
    time_value: Number(w.attrs.time_value),
    sets: Number(w.attrs.sets),
    goal: w.attrs.goal,
    prefix,
  };
}
