import { Box, Grid } from '@mui/material';
import React from 'react';

import Day from '../Day/Day';

import { styles } from './WeekGrid.styles';
import { WeekGridProps } from './WeekGrid.types';

const DAYS_PER_WEEK = 7;

export const WeekGrid: React.FC<WeekGridProps> = ({
  weeks,
  onDayContentChange,
  initialDaysData,
  monacoSettings,
}) => {
  const getDayNumber = (weekIndex: number, dayIndex: number): number =>
    weekIndex * DAYS_PER_WEEK + dayIndex + 1;

  const getInitialContent = (weekIndex: number, dayNumber: number): string =>
    initialDaysData.find((day) => day.weekNumber === weekIndex + 1 && day.dayNumber === dayNumber)
      ?.content || '';

  const DayCell: React.FC<{ weekIndex: number; dayIndex: number }> = ({ weekIndex, dayIndex }) => {
    const dayNumber = getDayNumber(weekIndex, dayIndex);
    const initialContent = getInitialContent(weekIndex, dayNumber);

    return (
      <Day
        key={dayNumber}
        weekNumber={weekIndex + 1}
        dayNumber={dayNumber}
        isFirstInRow={dayIndex === 0}
        initialContent={initialContent}
        onContentChange={(content) => onDayContentChange(weekIndex + 1, dayNumber, content)}
        monacoSettings={monacoSettings}
      />
    );
  };

  const WeekRow: React.FC<{ weekIndex: number }> = ({ weekIndex }) => (
    <Grid container key={weekIndex}>
      {Array.from({ length: DAYS_PER_WEEK }, (_, dayIndex) => (
        <DayCell key={dayIndex} weekIndex={weekIndex} dayIndex={dayIndex} />
      ))}
    </Grid>
  );

  return (
    <Box sx={styles.container}>
      {Array.from({ length: weeks }, (_, weekIndex) => (
        <WeekRow key={weekIndex} weekIndex={weekIndex} />
      ))}
    </Box>
  );
};