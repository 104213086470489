export const headerStyles = {
  wrapper: {
    p: 1,
    borderLeft: 0.5,
    borderRight: 0.5,
    borderColor: 'white',
    bgcolor: 'grey.200',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: 'black',
    fontFamily: '"Bebas Neue", sans-serif',
  },
  text: {
    fontWeight: 'bold',
    color: 'white',
    fontFamily: '"Bebas Neue", sans-serif',
    fontSize: '20px',
  },
  dayText: {
    fontWeight: 'bold',
    marginLeft: 'auto',
    color: 'white',
    fontFamily: '"Bebas Neue", sans-serif',
    fontSize: '20px',
  },
};

export const contentStyles = {
  wrapper: {
    p: 1,
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '250px',
    position: 'relative',
  },
};

export const paperStyles = {
  root: {
    bgcolor: 'background.paper',
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
};

export const monacoMimicStyles = {
  width: '100%',
  height: '100%',
  overflow: 'auto',
  whiteSpace: 'pre-wrap',
  fontFamily: 'Arial, sans-serif',
  fontSize: '12px',
  padding: '4px',
  lineHeight: '18px',
  color: '#000000',
  backgroundColor: '#ffffff',
  outline: 'none',
};

export const monacoContentStyles = {
  wrapper: {
    flexGrow: 1,
    borderColor: 'black',
    borderLeft: 0.5,
    borderRight: 0.5,
    display: 'flex',
    width: '100%',
    height: '250px',
    overflow: 'hidden',
    position: 'relative',
    paddingBottom: 1,
  }
};
