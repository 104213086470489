import { Movement } from './Movement';
import { Dict, WodComponent } from './types';

export class Workout extends WodComponent {
  name: string | null;
  type: string;
  notes: string;
  attrs: Dict<string>;
  movements: Movement[];
  text: string;
  is_superset = false;
  setsFromAlternation = 0;

  constructor(
    name: string | null,
    type: string,
    text: string | null,
    notes: string | null,
    attrs: Dict<string>,
    movements: Movement[],
  ) {
    super();
    this.name = name;
    this.type = type;
    this.text = text || '';
    this.notes = notes || '';
    this.attrs = attrs;
    this.movements = movements;
  }

  public movementsToString(): string[] {
    const res: string[] = [];
    for (const m of this.movements) {
      const movement = m.getFullName();
      res.push(movement);
    }
    return res;
  }
}
