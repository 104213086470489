import { editor, Position } from 'monaco-editor';

import { PATTERNS } from '../types';

/**
 * Different workout contexts supported by the application.
 */
export type WorkoutContext = 'standard' | 'amrap' | 'emom' | 'every' | 'fortime' | 'superset';

/**
 * Checks if the cursor is inside a superset block in the text.
 */
export function isInsideSupersetBlock(fullText: string, cursorPosition: number): boolean {
  const textUntilCursor = fullText.substring(0, cursorPosition);

  const lastOpenBraceIndex = textUntilCursor.lastIndexOf('{');

  if (lastOpenBraceIndex === -1) {
    return false;
  }

  const textAfterCursor = fullText.substring(cursorPosition);
  const nextCloseBraceIndex = textAfterCursor.indexOf('}');

  if (nextCloseBraceIndex === -1) {
    return false;
  }

  const textBeforeBrace = fullText.substring(0, lastOpenBraceIndex);
  const supersetMatch = /\b\d*\s*[Ss]uperset(s)?\s*$/.test(textBeforeBrace.trim());

  return supersetMatch;
}

/**
 * Extracts the current workout component based on cursor position.
 */
export function extractCurrentWorkoutComponent(fullText: string, cursorPosition: number): string {
  if (isInsideSupersetBlock(fullText, cursorPosition)) {
    const textUntilCursor = fullText.substring(0, cursorPosition);
    const lastOpenBraceIndex = textUntilCursor.lastIndexOf('{');

    const textBeforeBrace = fullText.substring(0, lastOpenBraceIndex + 1);

    const supersetMatch = textBeforeBrace.match(/\b\d*\s*[Ss]uperset(s)?\s*\{$/);

    if (supersetMatch) {
      const startIndex = supersetMatch.index || 0;
      return textBeforeBrace.substring(startIndex).trim();
    }
  }

  const textUntilCursor = fullText.substring(0, cursorPosition);

  const lastSeparatorIndex = textUntilCursor.lastIndexOf('+');

  const nextSeparatorIndex = fullText.indexOf('+', cursorPosition);
  const endIndex = nextSeparatorIndex >= 0 ? nextSeparatorIndex : fullText.length;

  return lastSeparatorIndex >= 0
    ? fullText.substring(lastSeparatorIndex + 1, endIndex).trim()
    : fullText.substring(0, endIndex).trim();
}

/**
 * Detects the workout context based on the cursor position and surrounding text.
 */
export function detectWorkoutContext(model: editor.ITextModel, position: Position): WorkoutContext {
  const fullText = model.getValue();
  const cursorOffset = model.getOffsetAt(position);

  if (isInsideSupersetBlock(fullText, cursorOffset)) {
    return 'superset';
  }

  const currentComponent = extractCurrentWorkoutComponent(fullText, cursorOffset);

  if (PATTERNS.WORKOUT_TYPES.AMRAP.test(currentComponent)) {
    return 'amrap';
  }

  if (PATTERNS.WORKOUT_TYPES.EMOM.test(currentComponent)) {
    return 'emom';
  }

  if (PATTERNS.WORKOUT_TYPES.FORTIME.test(currentComponent)) {
    return 'fortime';
  }

  if (PATTERNS.WORKOUT_TYPES.EVERY.test(currentComponent)) {
    return 'every';
  }

  if (PATTERNS.WORKOUT_TYPES.SUPERSET.test(currentComponent)) {
    return 'superset';
  }

  return 'standard';
}
