import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';

import { movementCountStyles } from './TemplateTooltip.styles';
import { MovementCountOption } from './types';

interface MovementCountSelectorProps {
  onSelectCount: (count: number) => void;
}

const MovementCountSelector: React.FC<MovementCountSelectorProps> = ({ onSelectCount }) => {
  const [selectedCount, setSelectedCount] = useState<number | null>(null);

  const countOptions: MovementCountOption[] = [
    { count: 2, label: '2' },
    { count: 3, label: '3' },
    { count: 4, label: '4' },
    { count: 5, label: '5' },
    { count: 6, label: '6' },
    { count: 7, label: '7' },
    { count: 8, label: '8' },
  ];

  const handleCountClick = (count: number) => {
    setSelectedCount(count);
    onSelectCount(count);
  };

  return (
    <Box sx={movementCountStyles.container}>
      <Typography variant="subtitle2" sx={movementCountStyles.title}>
        Number of movements:
      </Typography>
      <Box sx={movementCountStyles.countsContainer}>
        {countOptions.map((option) => (
          <Box
            key={option.count}
            sx={{
              ...movementCountStyles.countButton,
              ...(selectedCount === option.count ? movementCountStyles.countButtonSelected : {}),
            }}
            onClick={() => handleCountClick(option.count)}
          >
            {option.label}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default MovementCountSelector;
