// Enums
export enum LoadUnit {
  OneRM = '%1rm',
  Bodyweight = '%bodyweight',
  Lbs = 'lbs',
  Kg = 'kg',
  Pood = 'pood',
}

export enum DurationUnit {
  Seconds = 'seconds',
  Minutes = 'minutes',
}

export enum DistanceUnit {
  Feet = 'feet',
  Yards = 'yards',
  Miles = 'miles',
  Meters = 'meters',
  Km = 'km',
}

export enum HeightUnit {
  Inches = 'in',
  Centimeters = 'cm',
}

export enum RestType {
  AsNeeded = 'as_needed', // do not require duration
  // emom requires duration, very similar to fixed but represented in wodup as
  // if we have 4 sets and emom set to 30 it will print:
  // every 30 seconds for 2 minutes (4 sets)
  Emom = 'emom',
  // ratio requires duration,
  // for example 30 means 30 times the set's duration
  Ratio = 'ratio',
  Superset = 'superset', // do not require duration
  Fixed = 'fixed', // require duration
}

export enum ScoreBy {
  None = 'none',
  TimeAsc = 'time_asc',
  TimeDesc = 'time_desc',
  RepsAsc = 'reps_asc',
  RepsDesc = 'reps_desc',
  RoundsRepsAsc = 'rounds_reps_asc',
  RoundsRepsDesc = 'rounds_reps_desc',
  DistanceAsc = 'distance_asc',
  DistanceDesc = 'distance_desc',
  WeightAsc = 'weight_asc',
  WeightDesc = 'weight_desc',
  PeakWattsDesc = 'peak_watts_desc',
  MaxSpeedDesc = 'max_speed_desc',
  PointsAsc = 'points_asc',
  PointsDesc = 'points_desc',
}

export interface Movement {
  id: string;
  reps?: string;
  load?: string;
  load_unit?: LoadUnit;
  duration?: string;
  duration_unit?: DurationUnit;
  distance?: string;
  distance_unit?: DistanceUnit;
  tempo?: string;
}

// Rest types
export interface Rest {
  type: RestType;
  duration?: string | number;
}

export interface StrengthWorkoutDetails {
  type: 'Strength';
  movements: Movement[];
  rest: Rest;
}

export interface GenericWorkoutDetails {
  type: 'Generic';
  name: string;
  score_by: ScoreBy;
  description: string;
  movements: Movement[];
  time_cap?: number;
}

export interface WorkoutComponent {
  is_superset: boolean;
  notes?: string;
  order: number;
  prefix: string;
  workout: {
    details: StrengthWorkoutDetails | GenericWorkoutDetails;
    type: string;
  };
}

export interface WOD {
  components: WorkoutComponent[];
  name: string;
  occurs_on: string;
  order: number;
  program_id: string;
  publish_at: string;
}
