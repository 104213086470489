import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingPopup from '../../components/LoadingPopup/LoadingPopup';
import { logger } from '../../Logger';
import { authService } from '../../services/authService';
import { movementCache } from '../../services/MovementCache';
import { tokenService } from '../../services/tokenService';

interface AuthContextType {
  token: string;
  loginAction: (username: string, password: string) => Promise<void>;
  logoutAction: () => void;
}

const AuthContext = createContext<AuthContextType>({
  token: '',
  loginAction: async () => {
    throw new Error('Login action not implemented');
  },
  logoutAction: () => {
    throw new Error('Logout action not implemented');
  },
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [token, setToken] = useState(tokenService.getToken() || '');
  const [_isInitialized, setIsInitialized] = useState(false);
  const [isLoadingMovements, setIsLoadingMovements] = useState(false);
  const navigate = useNavigate();

  const loginAction = async (username: string, password: string) => {
    const accessToken = await authService.login(username, password);
    setToken(accessToken);
    tokenService.setToken(accessToken);
    setIsLoadingMovements(true);
    try {
      await movementCache.initialize();
      } catch (error) {
      logger.error('Failed to initialize movement cache:', error);
      } finally {
      setIsLoadingMovements(false);
      }
    navigate('/programs');
  };

  const logoutAction = useCallback(() => {
    setToken('');
    tokenService.removeToken();
    navigate('/login');
  }, [navigate]);

  useEffect(() => {
    const initializeAuth = async () => {
      const currentToken = tokenService.getToken();

      if (currentToken && !tokenService.isTokenExpired(currentToken)) {
        setToken(currentToken);
        try {
          await movementCache.initialize();
        } catch (error) {
          logger.error('Failed to initialize movement cache:', error);
          logoutAction();
          return;
        }
      }
      setIsInitialized(true);
    };

    initializeAuth();
  }, [logoutAction]);

  return (
    <AuthContext.Provider value={{ token, loginAction, logoutAction }}>
      {children}
      {isLoadingMovements && <LoadingPopup />}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
