import { Monaco } from '@monaco-editor/react';
import { editor, IRange, languages } from 'monaco-editor';

import { mapToMovementModels } from '../../../@monaco-uwl/utils/getMovementsFromFE';
import { getClosestMovementName } from '../../../@monaco-uwl/UWLVisitor/utils';
import movementsData from '../../../assets/basic_movements.json';
import { movementCache } from '../../../services/MovementCache';
import { Movement } from '../../../services/movementsService';

export const createCodeActionProvider = () => ({
  provideCodeActions(
    model: editor.ITextModel,
    _range: IRange,
    context: languages.CodeActionContext,
  ) {
    const actions: languages.CodeAction[] = [];
    
    (async () => {
      const availableMovements: Movement[] =
        process.env.REACT_APP_USE_MOVEMENTS_FROM_FE === 'true'
          ? mapToMovementModels(movementsData)
          : await movementCache.getMovements();

    const relevantDiagnostics = context.markers.filter(
      (marker) => marker.code === 'Movement Not Found',
    );

    relevantDiagnostics.forEach((diagnostic) => {
      const incorrectMovement = model.getValueInRange(diagnostic);
      const correctMovementName = getClosestMovementName(
        incorrectMovement,
        availableMovements.map((m) => m.name),
      );

      if (correctMovementName) {
        actions.push({
          title: `Replace with ${correctMovementName}`,
          kind: 'quickfix',
          diagnostics: [diagnostic],
          edit: {
            edits: [
              {
                resource: model.uri,
                textEdit: {
                  range: diagnostic,
                  text: correctMovementName,
                },
                versionId: model.getVersionId(),
              },
            ],
          },
          isPreferred: true,
        });
      }
    });
  })();

    return {
      actions,
      dispose: () => {
        // Clean up if necessary
      },
    };
  },
});

export const createDecorations = (
  monacoInstance: Monaco,
  lineCount: number,
  correctionLength: number,
) => [
  {
    range: new monacoInstance.Range(1, 1, lineCount - correctionLength - 1, 1),
    options: {
      isWholeLine: true,
      className: 'redBackground',
      linesDecorationsClassName: 'myRedBackground',
    },
  },
  {
    range: new monacoInstance.Range(lineCount - correctionLength, 1, lineCount, 1),
    options: {
      isWholeLine: true,
      className: 'greenBackground',
      linesDecorationsClassName: 'myGreenBackground',
    },
  },
];

export const updateDecorations = (
  editor: editor.IStandaloneCodeEditor,
  monacoInstance: Monaco,
  decorationsCollection: editor.IEditorDecorationsCollection,
  correctionLength: number,
) => {
  const lineCount = editor.getModel()?.getLineCount() || 0;
  const decorations = createDecorations(monacoInstance, lineCount, correctionLength);
  decorationsCollection.set(decorations);
};
