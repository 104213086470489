export const styles = {
  dialog: {
    '& .MuiDialog-paper': {
      height: '80vh',
      width: '80vw',
      maxWidth: 'none !important',
      maxHeight: 'none',
    },
  },
  dialogContent: {
    backgroundColor: '#F5F5F5',
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',
    margin: '0px',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    overflow: 'hidden',
    borderRadius: '0px',
  },
  editPaper: {
    flex: 3,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    padding: '4px',
    border: '1px solid #ccc',
    borderRadius: '0px',
  },
  divider: {
    height: 'auto',
    margin: '0 20px',
  },
  debugButton: {
    position: 'absolute',
    top: '10px',
    right: '20px',
    backgroundColor: '#ffffff',
    color: '#000000',
    fontFamily: '"Bebas Neue", sans-serif',
    fontSize: '16px',
  },
  editorBox: {
    flex: 1,
    overflow: 'hidden',
    marginTop: '10px',
    paddingLeft: '10px',
  },
  editorTitleBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    padding: '2px 0px 5px 10px',
  },
  editorTitleIcon: {
    marginLeft: 0,
  },
  editorInnerBox: {
    position: 'relative',
    height: '100%',
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  tooltip: {
    position: 'absolute',
    top: 100,
    left: 150,
    backgroundColor: 'white',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 1,
    padding: 2,
    zIndex: 1300,
  },
} as const;
