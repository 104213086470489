import { Movement } from '../../../../@monaco-uwl/UWLVisitor/Movement';
import { MovementDefaults } from '../../WodUpPreview.types';

export const getMovementName = (
  movement: Movement,
  hasUniformTempo: boolean,
  tempo: string[],
): string => {
  if (!movement?.names?.length) return MovementDefaults.UNNAMED_MOVEMENT;

  const baseName =
    movement.isComplex && movement.mults
      ? movement.names.map((name, index) => `${movement.mults[index]} ${name}`).join(' + ')
      : movement.names.join(' + ');

  return hasUniformTempo ? `${baseName} (${tempo[0]})` : baseName;
};
