import { GenericComponent } from '../generalParser/parser.types';

import { getMovementIdByName } from './utils';
import { Movement, ScoreBy, WorkoutComponent } from './wodup.types';

export function mapGenericComponentToWodUp(component: GenericComponent): WorkoutComponent {
  return {
    is_superset: component.is_superset,
    notes: component.notes,
    order: 0, //number of component in session
    prefix: component.prefix,
    workout: {
      type: 'Generic',
      details: {
        type: 'Generic',
        name: component.name,
        score_by: component.score_by as ScoreBy,
        description: component.description,
        movements: mapMovements(component.movements),
        time_cap: component.time_cap,
      },
    },
  };
}

function mapMovements(movementNames: string[]): Movement[] {
  return movementNames.map((movementName) => ({
    id: getMovementIdByName(movementName),
  }));
}
