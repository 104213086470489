import {
  LoadUnit,
  DurationUnit,
  DistanceUnit,
  HeightUnit,
  ScoreBy,
  RestType,
} from '../wodupParser/wodup.types';

type UnitMappings = {
  LOAD: { [key: string]: LoadUnit };
  DISTANCE: { [key: string]: DistanceUnit };
  TIME: { [key: string]: DurationUnit };
  HEIGHT: { [key: string]: HeightUnit };
  SCORE: { [key: string]: ScoreBy };
  REST: { [key: string]: RestType };
};

// suggested is used for autocomplete alternatives. The first one in the list will be the default.

export const UNIT_RULES = {
  LOAD: {
    mappings: {
      lbs: LoadUnit.Lbs,
      lb: LoadUnit.Lbs,
      kg: LoadUnit.Kg,
      kgs: LoadUnit.Kg,
      pood: LoadUnit.Pood,
      '%rm': LoadUnit.OneRM,
      '%1rm': LoadUnit.OneRM,
      '%bw': LoadUnit.Bodyweight,
      '%bodyweight': LoadUnit.Bodyweight,
    } as UnitMappings['LOAD'],
    accepted: ['lbs', 'lb', 'kg', 'kgs', 'pood', '%rm', '%1rm', '%bw', '%bodyweight'],
    suggested: ['lbs', 'kg', '%rm', '%bodyweight'],
  },

  DISTANCE: {
    mappings: {
      ft: DistanceUnit.Feet,
      feet: DistanceUnit.Feet,
      yd: DistanceUnit.Yards,
      yards: DistanceUnit.Yards,
      mi: DistanceUnit.Miles,
      miles: DistanceUnit.Miles,
      m: DistanceUnit.Meters,
      meters: DistanceUnit.Meters,
      km: DistanceUnit.Km,
      kilometers: DistanceUnit.Km,
    } as UnitMappings['DISTANCE'],
    accepted: ['ft', 'feet', 'yd', 'yards', 'mi', 'miles', 'm', 'meters', 'km', 'kilometers'],
    suggested: ['ft', 'yards', 'miles', 'm', 'km'],
  },

  TIME: {
    mappings: {
      s: DurationUnit.Seconds,
      sec: DurationUnit.Seconds,
      seconds: DurationUnit.Seconds,
      m: DurationUnit.Minutes,
      min: DurationUnit.Minutes,
      minutes: DurationUnit.Minutes,
    } as UnitMappings['TIME'],
    accepted: ['s', 'sec', 'seconds', 'm', 'min', 'minutes'],
    suggested: ['s', 'min'],
  },

  HEIGHT: {
    mappings: {
      in: HeightUnit.Inches,
      centimeters: HeightUnit.Centimeters,
      cm: HeightUnit.Centimeters,
    } as UnitMappings['HEIGHT'],
    accepted: ['inches', 'cm', 'centimeters'],
    suggested: ['inches', 'cm'],
  },

  SCORE: {
    mappings: {
      'not scored': ScoreBy.None,
      'time (lower is better)': ScoreBy.TimeDesc,
      'time (higher is better)': ScoreBy.TimeAsc,
      'reps (higher is better)': ScoreBy.RepsDesc,
      'reps (lower is better)': ScoreBy.RepsAsc,
      'rounds and reps (higher is better)': ScoreBy.RoundsRepsDesc,
      'rounds and reps (lower is better)': ScoreBy.RoundsRepsAsc,
      'distance (higher is better)': ScoreBy.DistanceDesc,
      'distance (lower is better)': ScoreBy.DistanceAsc,
      'weight (higher is better)': ScoreBy.WeightDesc,
      'weight (lower is better)': ScoreBy.WeightAsc,
      'points / anything else (higher is better)': ScoreBy.PointsDesc,
      'points / anything else (lower is better)': ScoreBy.PointsAsc,
      'peak watts': ScoreBy.PeakWattsDesc,
      'max speed': ScoreBy.MaxSpeedDesc,
    } as UnitMappings['SCORE'],
    accepted: [
      'not scored',
      'time (lower is better)',
      'time (higher is better)',
      'reps (higher is better)',
      'reps (lower is better)',
      'rounds and reps (higher is better)',
      'rounds and reps (lower is better)',
      'distance (higher is better)',
      'distance (lower is better)',
      'weight (higher is better)',
      'weight (lower is better)',
      'points / anything else (higher is better)',
      'points / anything else (lower is better)',
      'peak watts',
      'max speed',
    ],
  },

  REST: {
    mappings: {
      'as needed': RestType.AsNeeded,
      asneeded: RestType.AsNeeded,
      'as-needed': RestType.AsNeeded,
      as_needed: RestType.AsNeeded,
      'As Needed': RestType.AsNeeded,
      emom: RestType.Emom,
      ratio: RestType.Ratio,
      superset: RestType.Superset,
      fixed: RestType.Fixed,
    } as UnitMappings['REST'],
    accepted: [
      'as needed',
      'As Needed',
      'asneeded',
      'as-needed',
      'as_needed',
      'emom',
      'ratio',
      'superset',
      'fixed',
    ],
  },
} as const;

export function isValidUnit(value: string, unitType: keyof typeof UNIT_RULES): boolean {
  const normalizedValue = value.toLowerCase().trim();
  return (UNIT_RULES[unitType].accepted as readonly string[]).includes(normalizedValue);
}
