import { useState, useCallback } from 'react';

import { logger } from '../Logger';
import { cycleService } from '../services/cycleService';
import { ProgramCycle, ProgramDay } from '../types/program';

export interface CycleOperations {
  fetchCycle: (cycleId: number) => Promise<ProgramCycle>;
  updateCycleName: (cycleId: number, newName: string) => Promise<void>;
  deleteCycle: (cycleId: number) => Promise<void>;
  getInitialWeeks: (days: ProgramDay[]) => number;
  handleNameSubmit: (cycleId: number, newName: string, oldName: string) => Promise<void>;
  handleCycleRemove: (cycleId: number) => Promise<void>;
}

export const useCycle = (
  programId: string,
): {
  cycles: Array<{ id: number; name: string; order: number }>;
  isLoading: boolean;
  cycleOperations: CycleOperations;
  addCycle: () => Promise<void>;
  updateCycleName: (cycleId: number, newName: string) => void;
  removeCycle: (cycleId: number) => void;
  fetchProgramCycles: (id: string) => Promise<void>;
  updateCycleOrder: (
    firstCycleId: number,
    firstCycleNewOrder: number,
    secondCycleId: number,
    secondCycleNewOrder: number,
  ) => Promise<void>;
} => {
  const [cycles, setCycles] = useState<Array<{ id: number; name: string; order: number }>>([]);
  const [isLoading, setIsLoading] = useState(false);

  const addCycle = async () => {
    setIsLoading(true);
    const maxId = Math.max(...cycles.map((cycle) => cycle.id), 0);
    const maxOrder = Math.max(...cycles.map((cycle) => cycle.order), 0);
    const tempCycle = {
      id: maxId + 1,
      name: `Cycle ${cycles.length + 1}`,
      order: maxOrder + 1,
    };
    setCycles([...cycles, tempCycle]);

    try {
      const response = await cycleService.createCycle(programId, tempCycle.name);
      setCycles((prevCycles) =>
        prevCycles.map((cycle) =>
          cycle.id === tempCycle.id ? { ...cycle, id: response.id, order: response.order } : cycle,
        ),
      );
    } catch (error) {
      logger.error('Error creating new cycle:', error);
      setCycles(cycles);
    } finally {
      setIsLoading(false);
    }
  };

  const updateCycleName = (cycleId: number, newName: string) => {
    setCycles((prevCycles) =>
      prevCycles.map((cycle) => (cycle.id === cycleId ? { ...cycle, name: newName } : cycle)),
    );
  };

  const removeCycle = (cycleId: number) => {
    setCycles((prevCycles) => prevCycles.filter((cycle) => cycle.id !== cycleId));
  };

  const fetchProgramCycles = useCallback(async (id: string) => {
    try {
      const response = await cycleService.fetchProgram(id);
      setCycles(
        response.cycles
          .map((cycle: ProgramCycle) => ({
            id: cycle.id,
            name: cycle.name,
            order: cycle.order,
          }))
          .sort((a, b) => a.order - b.order),
      );
    } catch (error: unknown) {
      if (error instanceof Error) {
        logger.error('Error fetching program data:', error.message);
      } else {
        logger.error('Error fetching program data:', error);
      }
    }
  }, []);

  const updateCycleOrder = async (
    firstCycleId: number,
    firstCycleNewOrder: number,
    secondCycleId: number,
    secondCycleNewOrder: number,
  ) => {
    try {
      await cycleService.updateCycleOrder(
        programId,
        firstCycleId,
        firstCycleNewOrder,
        secondCycleId,
        secondCycleNewOrder,
      );

      setCycles((prevCycles) => {
        const updatedCycles = prevCycles.map((cycle) =>
          cycle.id === firstCycleId
            ? { ...cycle, order: firstCycleNewOrder }
            : cycle.id === secondCycleId
              ? { ...cycle, order: secondCycleNewOrder }
              : cycle,
        );
        return updatedCycles.sort((a, b) => a.order - b.order);
      });
    } catch (error) {
      logger.error('Error updating cycle order:', error);
      throw error;
    }
  };

  const cycleOperations: CycleOperations = {
    fetchCycle: async (cycleId: number) => {
      try {
        const data = await cycleService.fetchCycle(programId, cycleId);
        return data;
      } catch (error) {
        logger.error('Error fetching cycle data:', error);
        throw error;
      }
    },

    updateCycleName: async (cycleId: number, newName: string) => {
      try {
        await cycleService.updateCycleName(programId, cycleId, newName);
      } catch (error) {
        logger.error('Error updating cycle name:', error);
        throw error;
      }
    },

    deleteCycle: async (cycleId: number) => {
      if (window.confirm('Are you sure you want to remove this cycle?')) {
        try {
          await cycleService.deleteCycle(programId, cycleId);
        } catch (error) {
          logger.error('Error deleting cycle:', error);
          throw error;
        }
      }
    },

    getInitialWeeks: (days: ProgramDay[] = []) => {
      if (days.length > 0) {
        return Math.max(2, Math.max(...days.map((day) => day.weekNumber)));
      }
      return 2;
    },

    handleNameSubmit: async (cycleId: number, newName: string, oldName: string) => {
      if (newName.trim() && newName !== oldName) {
        try {
          await cycleService.updateCycleName(programId, cycleId, newName.trim());
          updateCycleName(cycleId, newName.trim());
        } catch (error) {
          logger.error('Error updating cycle name:', error);
          throw error;
        }
      }
    },

    handleCycleRemove: async (cycleId: number) => {
      if (window.confirm('Are you sure you want to remove this cycle?')) {
        try {
          await cycleService.deleteCycle(programId, cycleId);
          removeCycle(cycleId);
        } catch (error) {
          logger.error('Error deleting cycle:', error);
          throw error;
        }
      }
    },
  };

  return {
    cycles,
    isLoading,
    cycleOperations,
    addCycle,
    updateCycleName,
    removeCycle,
    fetchProgramCycles,
    updateCycleOrder,
  };
};
