import { IRange, languages } from 'monaco-editor';

import { Movement } from '../../../services/movementsService';
import { WorkoutContext } from '../utils/contextUtils';
import { STRINGS } from '../utils/documentationUtils';

/**
 * Configuration for a movement variant.
 */
export type VariantConfig = {
  label: string;
  insertText: string;
  conditions?: ((m: Movement) => boolean)[];
  documentation?: string;
  sortText: string;
  attributes: string[];
};

/**
 * Predefined variant configurations for different movement patterns.
 */
export const MOVEMENT_VARIANTS: VariantConfig[] = [
  {
    label: 'sets & reps & load',
    insertText: ` \${1:[sets]} x \${2:[reps]} - \${3:[load]} \${4|${STRINGS.LOAD}|}`,
    conditions: [(m) => m.has_reps, (m) => m.has_load],
    documentation: 'Sets, reps and load\nExample: Deadlift: 3 x 5 - 225 lbs',
    sortText: 'a',
    attributes: ['sets', 'reps', 'load'],
  },
  {
    label: 'reps & load',
    insertText: ` x \${1:[reps]} - \${2:[load]} \${3|${STRINGS.LOAD}|}`,
    conditions: [(m) => m.has_reps, (m) => m.has_load],
    documentation: 'Reps and load\nExample: Bench Press: x 5 - 135 lbs',
    sortText: 'b',
    attributes: ['reps', 'load'],
  },
  {
    label: 'reps & load & tempo',
    insertText: ` x \${1:[reps]} - \${2:[load]} \${3|${STRINGS.LOAD}|} @ \${4:[tempo]}`,
    conditions: [(m) => m.has_reps, (m) => m.has_load],
    documentation: 'Reps, load and tempo\nExample: Squat: x 5 - 185 lbs @ 30X1',
    sortText: 'c',
    attributes: ['reps', 'load', 'tempo'],
  },
  {
    label: 'sets & reps & load & tempo',
    insertText: ` \${1:[sets]} x \${2:[reps]} - \${3:[load]} \${4|${STRINGS.LOAD}|} @ \${5:[tempo]}`,
    conditions: [(m) => m.has_reps, (m) => m.has_load],
    documentation: 'Sets, reps, load and tempo\nExample: Deadlift: 3 x 5 - 225 lbs @ 30X1',
    sortText: 'd',
    attributes: ['sets', 'reps', 'load', 'tempo'],
  },
  {
    label: 'reps only',
    insertText: ' x ${1:[reps]}',
    conditions: [(m) => m.has_reps],
    documentation: 'Repetitions only\nExample: Push-ups: x 10',
    sortText: 'e',
    attributes: ['reps'],
  },
  {
    label: 'sets & reps',
    insertText: ' ${1:[sets]} x ${2:[reps]}',
    conditions: [(m) => m.has_reps],
    documentation: 'Sets and reps\nExample: Deadlift: 3 x 5',
    sortText: 'f',
    attributes: ['sets', 'reps'],
  },
  {
    label: 'height & reps',
    insertText: ' at ${1:[height]} cm x ${2:[reps]}',
    conditions: [(m) => m.has_height, (m) => m.has_reps],
    documentation: 'Height and reps\nExample: Box Jump: at 24 cm x 10',
    sortText: 'h',
    attributes: ['height', 'reps'],
  },
  {
    label: 'sets & reps & height',
    insertText: ' ${1:[sets]} x ${2:[reps]} at ${3:[height]} cm',
    conditions: [(m) => m.has_reps, (m) => m.has_height],
    documentation: 'Sets, reps and height\nExample: Box Jump: 3 x 5 at 24 cm',
    sortText: 'i',
    attributes: ['sets', 'reps', 'height'],
  },
  {
    label: 'duration',
    insertText: ` in \${1:[duration]} \${2|${STRINGS.DURATION}|}`,
    conditions: [(m) => m.has_duration],
    documentation: 'Duration\nExample: Plank: in 30 s',
    sortText: 'j',
    attributes: ['duration'],
  },
  {
    label: 'sets & duration',
    insertText: ` \${1:[sets]} in \${2:[duration]} \${3|${STRINGS.DURATION}|}`,
    conditions: [(m) => m.has_duration],
    documentation: 'Sets and duration\nExample: Run: 3 in 5 min',
    sortText: 'k',
    attributes: ['sets', 'duration'],
  },
  {
    label: 'distance',
    insertText: ` for \${1:[distance]} \${2|${STRINGS.DISTANCE}|}`,
    conditions: [(m) => m.has_distance],
    documentation: 'Distance\nExample: Run: for 400 m',
    sortText: 'l',
    attributes: ['distance'],
  },
  {
    label: 'sets & distance',
    insertText: ` \${1:[sets]} for \${2:[distance]} \${3|${STRINGS.DISTANCE}|}`,
    conditions: [(m) => m.has_distance],
    documentation: 'Sets and distance\nExample: Run: 3 for 400 m',
    sortText: 'm',
    attributes: ['sets', 'distance'],
  },
  {
    label: 'duration & calories',
    insertText: ` in \${1:[duration]} \${2|${STRINGS.DURATION}|} \${3:calories} cal`,
    conditions: [(m) => m.has_duration, (m) => m.has_calories],
    documentation: 'Duration and calories\nExample: Row: in 2 min 50 cal',
    sortText: 'n',
    attributes: ['duration', 'calories'],
  },
  {
    label: 'sets & duration & calories',
    insertText: ` \${1:[sets]} in \${2:[duration]} \${3|${STRINGS.DURATION}|} \${4:calories} cal`,
    conditions: [(m) => m.has_duration, (m) => m.has_calories],
    documentation: 'Sets, duration and calories\nExample: Row: 3 in 2 min 50 cal',
    sortText: 'o',
    attributes: ['sets', 'duration', 'calories'],
  },
  {
    label: 'calories',
    insertText: ' ${1:[calories]} cal',
    conditions: [(m) => m.has_calories],
    documentation: 'Calories only\nExample: Row: 20 cal',
    sortText: 'p',
    attributes: ['calories'],
  },
  {
    label: 'power',
    insertText: ' ${1:[power]} W',
    conditions: [(m) => m.has_power],
    documentation: 'Power in watts\nExample: Bike: 200 W',
    sortText: 'q',
    attributes: ['power'],
  },
];

/**
 * Converts snippet syntax to human-readable examples.
 * For example: `x ${1:[reps]} - ${2:[load]} ${3|lbs,kg,%rm,%bodyweight|}`
 * becomes: `x 5 - 135 lbs`
 */
function convertSnippetToExample(snippetText: string): string {
  // Replace ${n:[name]} with a sensible default value based on the name
  let result = snippetText.replace(/\$\{\d+:\[([^\]]+)\]\}/g, (_, name) => {
    switch (name.toLowerCase()) {
      case 'reps':
        return '5';
      case 'sets':
        return '3';
      case 'load':
        return '135';
      case 'duration':
        return '30';
      case 'distance':
        return '400';
      case 'calories':
        return '20';
      case 'power':
        return '200';
      case 'height':
        return '24';
      case 'tempo':
        return '30X1';
      default:
        return name;
    }
  });

  // Replace ${n|option1,option2|} with the first option
  result = result.replace(/\$\{\d+\|([^,\}]+),[^\}]*\|}/g, '$1');

  return result;
}

/**
 * Creates a completion item from a variant configuration.
 */
function createCompletionItem(variant: VariantConfig, range: IRange): languages.CompletionItem {
  const exampleText = convertSnippetToExample(variant.insertText);
  let documentation = variant.documentation;

  if (!documentation) {
    documentation = `${variant.label}\nExample: ${variant.label.includes('tempo') ? 'Back Squat' : 'Deadlift'}:${exampleText}`;
  }

  return {
    label: variant.label,
    kind: languages.CompletionItemKind.Value,
    insertText: variant.insertText,
    insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
    range: range,
    documentation: documentation,
    sortText: variant.sortText,
  };
}

/**
 * Creates movement variant proposals based on the movement, range, and workout context.
 */
export function createMovementVariantProposals(
  movement: Movement,
  range: IRange,
  context: WorkoutContext = 'standard',
): languages.CompletionItem[] {
  const validVariantTypes: Record<WorkoutContext, Set<string>> = {
    standard: new Set([
      'sets',
      'reps',
      'load',
      'tempo',
      'height',
      'duration',
      'distance',
      'calories',
      'power',
    ]),
    amrap: new Set(['reps', 'load', 'height', 'duration', 'distance', 'calories', 'power']),
    emom: new Set(['reps', 'load', 'height', 'duration', 'distance', 'calories', 'power']),
    fortime: new Set(['reps', 'load', 'height', 'duration', 'distance', 'calories', 'power']),
    every: new Set(['reps', 'load', 'height', 'duration', 'distance', 'calories', 'power']),
    superset: new Set([
      'reps',
      'load',
      'tempo',
      'height',
      'duration',
      'distance',
      'calories',
      'power',
    ]),
  };

  const validTypes = validVariantTypes[context];

  const filtered = MOVEMENT_VARIANTS.filter((variant) => {
    const hasValidAttributes = variant.attributes.every((attr) => validTypes.has(attr));

    const hasCapabilities =
      !variant.conditions || variant.conditions.every((condition) => condition(movement));

    return hasValidAttributes && hasCapabilities;
  });

  return filtered.map((variant) => createCompletionItem(variant, range));
}
