import { logger } from '../Logger';

interface OpenCyclesMap {
  [programId: string]: number[];
}

class UserPreferencesService {
  private STORAGE_KEY = 'userPreferences';

  getOpenCycles(programId: string): number[] {
    try {
      const preferences = this.getPreferences();
      return preferences.openCycles?.[programId] || [];
    } catch (error) {
      logger.error('Error getting open cycles:', error);
      return [];
    }
  }

  addOpenCycle(programId: string, cycleId: number): void {
    try {
      const preferences = this.getPreferences();

      if (!preferences.openCycles) {
        preferences.openCycles = {};
      }

      if (!preferences.openCycles[programId]) {
        preferences.openCycles[programId] = [];
      }

      if (!preferences.openCycles[programId].includes(cycleId)) {
        preferences.openCycles[programId].push(cycleId);
        localStorage.setItem(this.STORAGE_KEY, JSON.stringify(preferences));
      }
    } catch (error) {
      logger.error('Error adding open cycle:', error);
    }
  }

  removeOpenCycle(programId: string, cycleId: number): void {
    try {
      const preferences = this.getPreferences();

      if (preferences.openCycles?.[programId]) {
        preferences.openCycles[programId] = preferences.openCycles[programId].filter(
          (id) => id !== cycleId,
        );
        localStorage.setItem(this.STORAGE_KEY, JSON.stringify(preferences));
      }
    } catch (error) {
      logger.error('Error removing open cycle:', error);
    }
  }

  private getPreferences(): { openCycles: OpenCyclesMap } {
    const storedPreferences = localStorage.getItem(this.STORAGE_KEY);
    if (storedPreferences) {
      return JSON.parse(storedPreferences);
    }
    return { openCycles: {} };
  }
}

export const userPreferencesService = new UserPreferencesService();
