import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Typography, Button, TextField, Paper, CircularProgress } from '@mui/material';
import React, { useCallback, useEffect } from 'react';

import MovementDialog from '../components/MovementLibraryDialog/MovementLibraryDialog';
import { useMovementSearch } from '../hooks/useMovementsSearch';
import { logger } from '../Logger';
import { createMovementsService, Movement } from '../services/movementsService';

import { styles } from './MovementLibrary.styles';

const MovementLibrary: React.FC = () => {
  const [movements, setMovements] = React.useState<Movement[]>([]);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [selectedMovement, setSelectedMovement] = React.useState<Movement | undefined>();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalResults, setTotalResults] = React.useState(0);
  const limit = 30;

  const movementsService = createMovementsService();

  const loadMovements = useCallback(async () => {
    try {
      const data = await movementsService.getAll(currentPage, limit);
      setMovements(data);
      const totalCount = await movementsService.getTotalCount();
      setTotalResults(totalCount);
    } catch (error) {
      logger.error('Failed to load movements:', error);
    }
  }, [currentPage, limit, movementsService]);

  const handleSearchResults = useCallback((results: Movement[], totalCount: number) => {
    setMovements(results);
    setTotalResults(totalCount);
    setCurrentPage(1);
  }, []);

  const handleEmptySearch = useCallback(() => {
    loadMovements();
  }, [loadMovements]);

  const { isSearching, searchQuery, handleSearch } = useMovementSearch({
    onSearch: useCallback(
      (query: string) => {
        let page = 1;
        if (isSearching) {
          page = currentPage;
        }
        return movementsService.search(query, page, limit).then((response) => {
          return response.results;
        });
      },
      [movementsService, currentPage],
    ),
    onSearchResults: handleSearchResults,
    onEmpty: handleEmptySearch,
  });

  useEffect(() => {
    if (!searchQuery) {
      loadMovements();
    }
  }, [currentPage, searchQuery, loadMovements]);

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedMovement(undefined);
  };

  const handleSave = async (movementData: Movement) => {
    try {
      if (selectedMovement) {
        const updatedMovement = await movementsService.update(selectedMovement.id, movementData);
        setMovements((prev) => {
          const index = prev.findIndex((m) => m.id === updatedMovement.id);
          if (index !== -1) {
            const newMovements = [...prev];
            newMovements[index] = updatedMovement;
            return newMovements;
          }
          return prev;
        });
      } else {
        const newMovement = await movementsService.create(movementData);
        setMovements((prev) => [...prev, newMovement]);
      }
    } catch (error) {
      logger.error('Failed to save movement:', error);
    }
  };

  const handleDelete = async () => {
    if (selectedMovement) {
      try {
        await movementsService.delete(selectedMovement.id);
        loadMovements();
        handleCloseDialog();
      } catch (error) {
        logger.error('Failed to delete movement:', error);
      }
    }
  };

  const handleMovementClick = (movement: Movement) => {
    setSelectedMovement(movement);
    setDialogOpen(true);
  };

  const handleNextPage = async () => {
    const nextPage = currentPage + 1;
    if (searchQuery) {
      const results = await movementsService.search(searchQuery, nextPage, limit);
      handleSearchResults(results.results, results.totalCount);
      setCurrentPage(nextPage);
    } else {
      setCurrentPage(nextPage);
      loadMovements();
    }
  };

  const handlePreviousPage = async () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      if (searchQuery) {
        const results = await movementsService.search(searchQuery, prevPage, limit);
        handleSearchResults(results.results, results.totalCount);
        setCurrentPage(prevPage);
      } else {
        setCurrentPage(prevPage);
        handleEmptySearch();
      }
    }
  };

  const start = (currentPage - 1) * limit + 1;
  const end = Math.min(currentPage * limit, totalResults);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.headerBox}>
        <Typography variant="h4" sx={styles.title}>
          Movement Library
        </Typography>
        <Box>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            sx={styles.addButton}
            onClick={() => setDialogOpen(true)}
          >
            Add movement
          </Button>
        </Box>
      </Box>

      <Box sx={{ position: 'relative' }}>
        <TextField
          fullWidth
          placeholder="Find a movement..."
          variant="outlined"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
          sx={styles.searchField}
          InputProps={{
            startAdornment: (
              <SearchIcon sx={{ color: 'action.active', mr: 1 }} />
            ),
          }}
        />
        {isSearching && (
          <CircularProgress size={24} sx={styles.searchLoader} />
        )}
      </Box>

      <Paper sx={styles.listPaper}>
        <Box sx={styles.listHeader}>
          <Typography sx={styles.listHeaderText}>Name</Typography>
          <Typography sx={styles.listHeaderText}>Available attributes</Typography>
        </Box>

        {movements.length === 0 ? (
          <Box sx={styles.emptyState}>
            <Typography>You have no saved movements.</Typography>
          </Box>
        ) : (
          movements.map((movement) => (
            <Box
              key={movement.id}
              sx={styles.movementRow}
              onClick={() => handleMovementClick(movement)}
            >
              <Typography>{movement.name}</Typography>
              <Typography>
                {Object.entries(movement)
                  .filter(([key, value]) => key.startsWith('has_') && value)
                  .map(([key]) => key.replace('has_', ''))
                  .join(', ')}
              </Typography>
            </Box>
          ))
        )}
      </Paper>

      <Box sx={styles.paginationContainer}>
        <Typography variant="body1">
          Showing <strong>{start}</strong> to <strong>{end}</strong> of{' '}
          <strong>{totalResults}</strong> results
        </Typography>
        <Box>
          <Button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            variant="outlined"
            sx={styles.button}
          >
            Previous
          </Button>
          <Button
            onClick={handleNextPage}
            disabled={currentPage * limit >= totalResults}
            variant="outlined"
            sx={styles.button}
          >
            Next
          </Button>
        </Box>
      </Box>

      <MovementDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onSave={handleSave}
        onDelete={selectedMovement ? handleDelete : undefined}
        movement={selectedMovement}
      />
    </Box>
  );
};

export default MovementLibrary;
