import { Box, Button, CircularProgress } from '@mui/material';
import React from 'react';

import { UWLError } from '../../../@monaco-uwl/UWLErrorHandler';
import { useErrorCorrection } from '../../../hooks/useErrorCorrection';

import { styles } from './ErrorHelp.styles';

interface ErrorHelpProps {
  hasError?: UWLError | false;
  content: string;
  onContentChange: (content: string) => void;
  onCorrectionLengthChange: (length: number) => void;
}

export const ErrorHelp: React.FC<ErrorHelpProps> = ({
  hasError,
  content,
  onContentChange,
  onCorrectionLengthChange,
}) => {
  const {
    showCorrection,
    isValidationError,
    explanation,
    handleGetErrorHelp,
    handleAcceptCorrection,
    handleRejectCorrection,
    isLoading,
  } = useErrorCorrection(content, onContentChange, onCorrectionLengthChange);

  if (!hasError) return null;

  if (!showCorrection) {
    return (
      <Button
        variant="contained"
        color="error"
        sx={styles.helpButton}
        onClick={() => handleGetErrorHelp(hasError)}
        disabled={isLoading}
        data-testid="error-help-button"
      >
        {isLoading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          'Need Help?'
        )}
      </Button>
    );
  }

  return (
    <Box sx={styles.correctionBox}>
      <Box
        sx={{
          ...styles.explanationBox,
          backgroundColor: isValidationError
            ? styles.explanationBox.backgroundColor.error
            : styles.explanationBox.backgroundColor.default,
        }}
      >
        {isValidationError
          ? "AI was unable to provide a fully accurate correction. You can still accept the proposed changes. You can either accept or reject the changes and attempt to fix the issue manually. Once you've made some adjustments, you can try using AI again. If the issue persists, please contact the development team for further assistance."
          : explanation}
      </Box>

      <Box sx={styles.actionButtons}>
        <Button variant="contained" onClick={handleAcceptCorrection} sx={styles.acceptButton}>
          Accept
        </Button>

        <Button variant="outlined" onClick={handleRejectCorrection} sx={styles.rejectButton}>
          Reject
        </Button>
      </Box>
    </Box>
  );
};
