import { validate } from '../@monaco-uwl/UWLErrorHandler';
import api from '../api/base';

interface ErrorContext {
  startLineNumber: number;
  endLineNumber: number;
  startColumn: number;
  endColumn: number;
  message: string;
  severity: number;
}

interface CorrectionContext {
  error: ErrorContext | false;
  lineContent: string;
}

export interface CorrectionsRequest {
  content: string;
  context: CorrectionContext;
  threadId?: string;
}

export const correctionsService = {
  getErrorHelp: async (content: string, context: CorrectionContext, signal?: AbortSignal, threadId?: string) => {
    const response = await api.post('/api/corrections', {
      content,
      context,
      threadId,
    }, { signal });
    const validationResult = validate(response.data.correctedComponent + '\n');
    if (validationResult.errors.length > 0 && validationResult.errors[0].severity === 8) {
      return { ...response.data, error: true };
    }
    return { ...response.data, error: false };
  },
};
