import { editor } from 'monaco-editor';
import { useCallback } from 'react';

import { logger } from '../../../Logger';
import { SnippetController } from '../ExpandedViewDialog.types';
import {
  isCursorInsideSuperset,
  isBeforeSupersetClosingBracket,
  findClosingBracketLine,
  formatTemplateAndSetInsertPosition,
} from '../TemplateTooltip/insertTemplateUtils';
import { TEMPLATES } from '../TemplateTooltip/templates';

export const useTemplateInsertion = (
  editorRef: React.MutableRefObject<editor.IStandaloneCodeEditor | null>,
  setEditorState: (state: any) => void,
  setTooltipVisible: (visible: boolean) => void,
) => {
  const insertTemplate = useCallback(
    (template: string) => {
      const editor = editorRef.current;
      if (!editor) return;

      const model = editor.getModel();
      if (!model) return;

      const position = editor.getPosition();
      const totalLines = model.getLineCount();
      const snippetController = editor.getContribution('snippetController2') as SnippetController;

      if (!position) {
        logger.warn('Editor position is null. Cannot insert template.');
        return;
      }

      const { insideSuperset, upperClosingBracketLine } = isCursorInsideSuperset(model, position);

      if (insideSuperset && template === TEMPLATES.SUPERSET?.template) {
        logger.warn('Cannot insert a SUPERSET block inside another SUPERSET block.');
        setEditorState((prev: any) => ({
          ...prev,
          errorSnackbarOpen: true,
        }));
        return;
      }

      let updatedTemplate = template;
      let insertPosition = position.lineNumber;

      if (
        insideSuperset &&
        isBeforeSupersetClosingBracket(model, position, upperClosingBracketLine)
      ) {
        const lowerClosingBracketLine = findClosingBracketLine(
          model,
          position.lineNumber,
          totalLines,
        );
        const result = formatTemplateAndSetInsertPosition(
          template,
          model,
          position,
          totalLines,
          lowerClosingBracketLine,
        );
        updatedTemplate = result.updatedTemplate;
        insertPosition = result.insertPosition;
      } else {
        const result = formatTemplateAndSetInsertPosition(template, model, position, totalLines);
        updatedTemplate = result.updatedTemplate;
        insertPosition = result.insertPosition;
      }

      editor.setPosition({ lineNumber: insertPosition, column: 1 });
      editor.revealPositionInCenter({ lineNumber: insertPosition, column: 1 });

      snippetController.insert(`${updatedTemplate}`);

      setTooltipVisible(false);
      editor.focus();
    },
    [editorRef, setEditorState, setTooltipVisible],
  );

  return { insertTemplate };
};
