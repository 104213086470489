import { DebouncedFunc } from 'lodash';
import debounce from 'lodash/debounce';
import { useCallback, useRef } from 'react';

import api from '../api/base';
import { logger } from '../Logger';

// Type for the save function
type SaveFunction = (week: number, day: number, text: string) => Promise<void>;

export const useDebouncedSave = (programId: string | undefined, cycleId: number, delay = 1000) => {
  const debouncedRef = useRef<DebouncedFunc<SaveFunction>>();

  const debouncedSave = useCallback(
    (weekNumber: number, dayNumber: number, content: string) => {
      if (!programId) return;

      if (!debouncedRef.current) {
        debouncedRef.current = debounce(async (week: number, day: number, text: string) => {
          try {
            await api.post(`/api/programs/${programId}/cycles/${cycleId}/days`, {
              weekNumber: week,
              dayNumber: day,
              content: text,
            });
            logger.info('Day content saved successfully');
          } catch (error: unknown) {
            if (error instanceof Error) {
              logger.error('Error saving day content:', error.message);
            } else {
              logger.error('Error saving day content:', error);
            }
          }
        }, delay);
      }

      debouncedRef.current(weekNumber, dayNumber, content);
    },
    [programId, cycleId, delay],
  );

  return debouncedSave;
};
