import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Typography, TextField, Button, Paper, Link } from '@mui/material';
import React, { useState } from 'react';

import { logger } from '../../Logger';
import { programService } from '../../services/programService';
import { ProgramExtended } from '../../types/program';

import { styles } from './ProgramForm.styles';

interface CreateProgramFormProps {
  onSubmit: (program: ProgramExtended) => void;
  onCancel: () => void;
  initialProgram?: ProgramExtended | null;
}

const CreateProgramForm: React.FC<CreateProgramFormProps> = ({
  onSubmit,
  onCancel,
  initialProgram,
}) => {
  const [name, setName] = useState(initialProgram?.name || '');
  const [description, setDescription] = useState(initialProgram?.description || '');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const programData = { name, description };
      let response;
      
      if (initialProgram) {
        response = await programService.updateProgram(initialProgram.id, programData);
      } else {
        response = await programService.createProgram(programData);
      }
      onSubmit(response);
    } catch (error) {
      logger.error('Error saving program:', error);
    }
  };

  return (
    <>
      <Link
        component="button"
        variant="body1"
        onClick={onCancel}
        sx={styles.backButton}
      >
        <ArrowBackIcon sx={styles.backIcon} />
        Back to Programs
      </Link>
      <Paper elevation={3} sx={styles.paper}>
        <Box sx={styles.headerBox}>
          <Typography variant="h5" sx={styles.title}>
            {initialProgram ? `Update program "${initialProgram.name}"` : 'Create new program'}
          </Typography>
        </Box>
        <Box sx={styles.formBox}>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              placeholder="Program name*"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              sx={styles.nameField}
            />
            <TextField
              fullWidth
              placeholder="Description (Optional)"
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows={4}
              sx={styles.descriptionField}
            />
            <Box sx={styles.buttonContainer}>
              <Button
                type="submit"
                variant="contained"
                sx={styles.saveButton}
              >
                {initialProgram ? 'Save' : 'Save & add workouts'}
              </Button>
              <Button
                variant="text"
                onClick={onCancel}
                sx={styles.cancelButton}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Paper>
    </>
  );
};

export default CreateProgramForm;
