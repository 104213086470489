import { languages, Position, IMarkdownString, editor } from 'monaco-editor';

import { movementCache } from '../../services/MovementCache';
import { Movement } from '../../services/movementsService';

export class MovementHoverProvider implements languages.HoverProvider {
  private async getMovementFromText(word: string): Promise<Movement | undefined> {
    const normalizedName = word.toLowerCase();
    const movements = await movementCache.getMovements()
    return movements.find(
        (movement) =>
          movement.name.toLowerCase() === normalizedName ||
          movement.aliases?.some((alias) => alias.alias.toLowerCase() === normalizedName ),
      );
  }

  private createHoverContent(movement: Movement): IMarkdownString {
    const parts: string[] = [];

    parts.push(`**${movement.name}**`);

    if (movement.aliases?.length) {
      parts.push(`\n\nAliases: ${movement.aliases.map((a) => `*${a.alias}*`).join(', ')}`);
    }

    if(movement.complex_details) {
      parts.push(`\n\n${movement.complex_details}`)
    }

    if (movement.video_url) {
      parts.push('\n\n**Watch demonstration video:**\n\n');
      if (movement.video_url.includes('youtube.com')) {
        const videoId = movement.video_url.split('v=')[1];
        const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
        parts.push(`[![video](${thumbnailUrl})](${movement.video_url})`);
      } else {
        parts.push(movement.video_url);
      }
    }

    return {
      value: parts.join('\n'),
      isTrusted: true,
    };
  }

  private getMovementString(model: editor.ITextModel, position: Position) 
  : {name: string, startColumn: number, endColumn: number} | null {
    //get current line
    const lineContent = model.getLineContent(position.lineNumber);

    //get bounds of current movement
    const precedingSeparator = lineContent.substring(0, position.column - 1).match(/.*(\+|>)/)?.[0].length ?? null;
    const succeedingSeparator = lineContent.substring(position.column-1).match(/.*?(\+|:)/)?.[0].length ?? null;

    if (precedingSeparator === null || succeedingSeparator === null){
      return null;
    }

    //get the current movement
    const currentMovement = lineContent.substring(precedingSeparator, position.column + succeedingSeparator - 2);

    return {name: currentMovement.trim(), startColumn: precedingSeparator + 1, endColumn: position.column + succeedingSeparator  - 1};
  }

  provideHover(
    model: editor.ITextModel,
    position: Position,
  ): languages.ProviderResult<languages.Hover> {
    return ( async () => {
      const movementName = this.getMovementString(model, position);
      if (!movementName) return null;

      const range = {
          startLineNumber: position.lineNumber,
          startColumn: movementName.startColumn,
          endLineNumber: position.lineNumber,
          endColumn: movementName.endColumn,
      };

      const movement = await this.getMovementFromText(movementName.name);
      if (!movement) return null; // unknown movements are handled via a warning in parsers/MovementParser.ts

      return {
        range: range,
        contents: [this.createHoverContent(movement)],
      };
    })();

  }
}
