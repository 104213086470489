import { UNIT_RULES } from '../@monaco-uwl/rules';
import { Movement } from '../@monaco-uwl/UWLVisitor/Movement';
import { DistanceUnit, DurationUnit, LoadUnit } from '../wodupParser/wodup.types';

export function prepareNotes(notes: string): string {
  if (notes === '') return notes;
  return notes.replaceAll('*', '');
}

export function getLongestArrayLength(movement: Movement): number {
  if (!movement?.attributes) return 0;
  const arrayKeys = [
    'reps',
    'load',
    'distance',
    'duration',
    'tempo',
    'power',
    'calories',
    'height',
  ] as const;

  const lengths = arrayKeys.map((key) => {
    const value = movement.attributes[key];
    return Array.isArray(value) ? value.length : 0;
  });

  return Math.max(...lengths, 0);
}

export function normalizeUnit(movement: Movement): void {
  if (!movement?.attributes) return;
  const attrs = movement.attributes;

  const convertToEnum = <T extends LoadUnit | DurationUnit | DistanceUnit>(
    value: string | undefined,
    unitType: keyof typeof UNIT_RULES,
  ): T | undefined => {
    if (!value) return undefined;
    const normalizedValue = value.toLowerCase().trim();
    return UNIT_RULES[unitType].mappings[normalizedValue] as T;
  };

  if (attrs.load_unit) {
    attrs.load_unit = convertToEnum<LoadUnit>(attrs.load_unit, 'LOAD');
  }
  if (attrs.duration_unit) {
    attrs.duration_unit = convertToEnum<DurationUnit>(attrs.duration_unit, 'TIME');
  }
  if (attrs.distance_unit) {
    attrs.distance_unit = convertToEnum<DistanceUnit>(attrs.distance_unit, 'DISTANCE');
  }
}
