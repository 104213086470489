import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import GenericIcon from '@mui/icons-material/Description';
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';
import SupersetIcon from '@mui/icons-material/SwapHoriz';
import { SvgIconProps } from '@mui/material/SvgIcon';

const generateSupersetTemplate = (movementCount: number): string => {
  let template = '${1:3} supersets {\n';

  for (let i = 0; i < movementCount; i++) {
    const baseIndex = i * 3;
    template += `>\${${baseIndex + 2}:[movementName]:} ; rest \${${baseIndex + 3}}\n*\${${baseIndex + 4}:notes}`;

    if (i < movementCount - 1) {
      template += '\n+\n';
    }
  }

  template += '\n}';
  return template;
};

const generateForTimeNoRestTemplate = (movementCount: number): string => {
  let template = '${1:3} rounds for time\n';

  for (let i = 0; i < movementCount; i++) {
    const placeholderIndex = i + 2; // Start placeholders from 2
    template += `>\${${placeholderIndex}:[movementName]:}\n`;
  }

  template += `*\${${movementCount + 2}:notes}`;
  return template;
};

const generateForTimeRestAllTemplate = (movementCount: number): string => {
  let template = '${1:4} rounds for time\n';

  for (let i = 0; i < movementCount; i++) {
    const placeholderIndex = i + 2; // Start placeholders from 2
    template += `>\${${placeholderIndex}:[movementName]:}\n`;
  }

  template += `rest \${${movementCount + 2}:2 min} into\n*\${${movementCount + 3}:notes}`;
  return template;
};

const generateForTimeRestMovementsTemplate = (movementCount: number): string => {
  let template = 'for time\n';

  for (let i = 0; i < movementCount; i++) {
    const moveIndex = i * 2 + 1;
    const restIndex = i * 2 + 2;

    template += `>\${${moveIndex}:[movementName]:}\n`;

    // Add rest after all movements except the last one
    if (i < movementCount - 1) {
      template += `rest \${${restIndex}:30 sec}\n`;
    }
  }

  template += `*\${${movementCount * 2 + 1}:notes}`;
  return template;
};

const generateForTimeRestRoundsTemplate = (movementCount: number): string => {
  let template = '${1:3} rounds for time\n';

  for (let i = 0; i < movementCount; i++) {
    const placeholderIndex = i + 2;
    template += `>\${${placeholderIndex}:[movementName]:}\n`;
  }

  template += `rest \${${movementCount + 2}:3 min}\n*\${${movementCount + 3}:notes}`;
  return template;
};

const generateAmrapTemplate = (movementCount: number): string => {
  let template = '${1:20} ${2|min,sec,s,m|} AMRAP\n';

  for (let i = 0; i < movementCount; i++) {
    const placeholderIndex = i + 3;
    template += `>\${${placeholderIndex}:[movementName]:}\n`;
  }

  template += `*\${${movementCount + 3}:notes}`;
  return template;
};

const generateEmomTemplate = (movementCount: number): string => {
  let template = 'EMOM ${1:' + movementCount + '} min:\n';

  for (let i = 0; i < movementCount; i++) {
    template += `Min-${i + 1}: >\${${i + 2}:[movementName]:}\n`;
  }

  template += `*\${${movementCount + 2}:notes}`;
  return template;
};

const generateForTimeRestRoundsSetsTemplate = (movementCount: number): string => {
  let template = 'for time\n';

  if (movementCount === 2) {
    template +=
      '>${1:[movementName]:}\nrest ${3:30 sec}\n>${4:[movementName]:}\nrest ${6:3 min}\n${7:5} sets\n*${8:notes}';
  } else if (movementCount === 3) {
    template +=
      '>${1:[movementName]:}\nrest ${3:30 sec}\n>${4:[movementName]:}\nrest ${6:30 sec}\n>${7:[movementName]:}\nrest ${9:3 min}\n${10:5} sets\n*${11:notes}';
  } else if (movementCount === 4) {
    template +=
      '>${1:[movementName]:}\nrest ${3:30 sec}\n>${4:[movementName]:}\nrest ${6:30 sec}\n>${7:[movementName]:}\nrest ${9:45 sec}\n>${10:[movementName]:}\nrest ${12:3 min}\n${13:5} sets\n*${14:notes}';
  } else if (movementCount === 5) {
    template +=
      '>${1:[movementName]:}\nrest ${3:30 sec}\n>${4:[movementName]:}\nrest ${6:30 sec}\n>${7:[movementName]:}\nrest ${9:30 sec}\n>${10:[movementName]:}\nrest ${12:45 sec}\n>${13:[movementName]:}\nrest ${15:3 min}\n${16:5} sets\n*${17:notes}';
  } else if (movementCount === 6) {
    template +=
      '>${1:[movementName]:}\nrest ${3:30 sec}\n>${4:[movementName]:}\nrest ${6:30 sec}\n>${7:[movementName]:}\nrest ${9:30 sec}\n>${10:[movementName]:}\nrest ${12:30 sec}\n>${13:[movementName]:}\nrest ${15:45 sec}\n>${16:[movementName]:}\nrest ${18:3 min}\n${19:5} sets\n*${20:notes}';
  } else if (movementCount === 7) {
    template +=
      '>${1:[movementName]:}\nrest ${3:30 sec}\n>${4:[movementName]:}\nrest ${6:30 sec}\n>${7:[movementName]:}\nrest ${9:30 sec}\n>${10:[movementName]:}\nrest ${12:30 sec}\n>${13:[movementName]:}\nrest ${15:30 sec}\n>${16:[movementName]:}\nrest ${18:45 sec}\n>${19:[movementName]:}\nrest ${21:3 min}\n${22:5} sets\n*${23:notes}';
  } else if (movementCount === 8) {
    template +=
      '>${1:[movementName]:}\nrest ${3:30 sec}\n>${4:[movementName]:}\nrest ${6:30 sec}\n>${7:[movementName]:}\nrest ${9:30 sec}\n>${10:[movementName]:}\nrest ${12:30 sec}\n>${13:[movementName]:}\nrest ${15:30 sec}\n>${16:[movementName]:}\nrest ${18:30 sec}\n>${19:[movementName]:}\nrest ${21:45 sec}\n>${22:[movementName]:}\nrest ${24:3 min}\n${25:5} sets\n*${26:notes}';
  }

  return template;
};

export const TEMPLATES: {
  [key: string]: {
    id: string;
    icon: React.ComponentType<SvgIconProps>;
    label: string;
    template: string;
    tooltip: string;
    showMovementCountSelector?: boolean;
    generateTemplate?: (movementCount: number) => string;
    subTemplates?: Array<{
      id: string;
      label: string;
      template: string;
      tooltip: string;
      generateTemplate?: (movementCount: number) => string;
      showMovementCountSelector?: boolean;
    }>;
  };
} = {
  SUPERSET: {
    id: 'superset',
    icon: SupersetIcon,
    label: 'Superset',
    template: '',
    tooltip: `Creates an superset workout block with multiple movements.<br /><br />Format:<br />3 supersets {<br />>movementName: ; rest 1 min<br />*notes<br />+<br />>movementName: ; rest 1 min<br />*notes<br />}<br /><br />Example:<br />3 supersets {<br />>Front Squat: 10 ; rest 1 min<br />* 8/10 RPE - 2nd set heavier than first<br />+<br />>Back Squat: 5 - 185 lbs @ 3110 ; rest 1 min<br />*Keep core tight<br />}<br /><br />Use for: Superset between several movements.<br /><br />Shortcut: Type 'a'`,
    showMovementCountSelector: true,
    generateTemplate: generateSupersetTemplate,
  },
  FOR_TIME: {
    id: 'forTime',
    icon: AccessAlarmIcon,
    label: 'For Time',
    template: '',
    tooltip:
      'Various "For Time" workout formats including standard rounds, rest periods, and interval work.',
    subTemplates: [
      {
        id: 'forTime-standard',
        label: 'No Rest',
        template: '',
        tooltip:
          'Classic CrossFit-style "For Time" format with no prescribed rest.<br /><br />Example:<br />4 rounds for time<br />>Deadlift: 3 - 15 lbs<br />>Air Squat: 3<br />>Run: for 300 m<br />*Notes',
        showMovementCountSelector: true,
        generateTemplate: generateForTimeNoRestTemplate,
      },
      {
        id: 'forTime-restAfter',
        label: 'Rest After All',
        template: '',
        tooltip:
          'For Time format with rest period after completing all rounds.<br /><br />Example:<br />4 rounds for time<br />>Deadlift: 3 - 15 lbs<br />>Air Squat: 3<br />rest "2 min" into<br />*Notes',
        showMovementCountSelector: true,
        generateTemplate: generateForTimeRestAllTemplate,
      },
      {
        id: 'forTime-restBetweenMovements',
        label: 'Rest Between Movements',
        template: '',
        tooltip:
          'For Time format with rest periods between each movement.<br /><br />Example:<br />For time<br />>Deadlift: 3 - 15 lbs<br />rest "30 sec"<br />>Air Squat: 3<br />rest "30 sec"<br />>Bench Dip: 3<br />rest "45 sec"<br />>Plank Walkout: 3<br />*my notes',
        showMovementCountSelector: true,
        generateTemplate: generateForTimeRestMovementsTemplate,
      },
      {
        id: 'forTime-restAfterRounds',
        label: 'Rest Between Rounds',
        template: '',
        tooltip:
          'For Time format with rest periods after each round.<br /><br />Example:<br />3 rounds, for time<br />>Deadlift: 3 - 15 lbs<br />>Air Squat: 3<br />rest "3 min"<br />*Notes',
        showMovementCountSelector: true,
        generateTemplate: generateForTimeRestRoundsTemplate,
      },
      {
        id: 'forTime-restBetweenSets',
        label: 'Rest Between Rounds & Sets',
        template: '',
        tooltip:
          'For Time format with rest periods between movements and sets.<br /><br />Example:<br />For time<br />>Deadlift: 3 - 15 lbs<br />rest "30 sec"<br />>Air Squat: 3<br />rest "30 sec"<br />>Bench Dip: 5<br />rest "45 sec"<br />>Walk: for 300 m in 30 sec<br />rest "3 min"<br />5 sets<br />*Notes',
        showMovementCountSelector: true,
        generateTemplate: generateForTimeRestRoundsSetsTemplate,
      },
    ],
  },
  AMRAP: {
    id: 'amrap',
    icon: AccessAlarmIcon,
    label: 'AMRAP',
    template: '',
    tooltip: `Creates an AMRAP workout block.<br /><br />Format:<br />## min AMRAP<br />>movementName:<br />>movementName:<br />>movementName:<br />*notes<br /><br />Example:<br />10 min AMRAP<br />>Run: for 200m<br />>Chest-to-bar Pull-up: 13<br />>Abmat Wall Ball: 13 - 30 lbs<br />*Compare to previous scores<br /><br />Use for: Fixed-time workouts where the goal is to complete as many rounds as possible.<br /><br />Shortcut: Type 'amrap'`,
    showMovementCountSelector: true,
    generateTemplate: generateAmrapTemplate,
  },
  EMOM: {
    id: 'emom',
    icon: AvTimerIcon,
    label: 'EMOM',
    template: '',
    tooltip: `Creates an EMOM workout block.<br /><br />Format:<br />EMOM ## min:<br />Min-1: >movementName:<br />Min-2: >movementName:<br />*notes<br /><br />Example:<br />EMOM 6 min:<br />Min-1: >Burpee: 10<br />Min-2: >Double-under: 40<br />*Maintain consistent pace throughout<br /><br />Use for: Every Minute On the Minute workouts with different movements each minute.<br /><br />Shortcut: Type 'emom'`,
    showMovementCountSelector: true,
    generateTemplate: generateEmomTemplate,
  },
  EVERY: {
    id: 'every',
    icon: AvTimerIcon,
    label: 'EVERY',
    template: '',
    tooltip: `Creates an "Every X seconds" workout block.<br /><br />Format:<br />Every XX sec x N sets<br />>movementName:<br />>movementName:<br />>movementName:<br />*notes<br /><br />Example:<br />Every 30 sec x 5 sets<br />>Push-up: 10<br />>Air Squat: 15<br />>Sit-up: 20<br />*Focus on form while maintaining speed<br /><br />Use for: Time-based interval training with specific rest periods.<br /><br />Shortcut: Type 'every'`,
    showMovementCountSelector: true,
    generateTemplate: (movementCount: number): string => {
      let template = 'Every ${1:2.5} ${2|min,sec|} x ${3:5} sets\n';

      for (let i = 0; i < movementCount; i++) {
        const placeholderIndex = i + 5; // Start placeholders from 5
        template += `>\${${placeholderIndex}:[movementName]:}\n`;
      }

      template += `*\${${movementCount + 5}:notes}`;
      return template;
    },
  },
  GENERIC: {
    id: 'generic',
    icon: GenericIcon,
    label: 'Something Else',
    template: '`\n${1:description}\n`',
    tooltip: `Creates a generic workout block.<br /><br />Format:<br /> \`write content here\`<br /><br />Example:<br />\`Find a Max of the following Complex: 3 x Snatch + 2 x OHS + 1 x Hang Squat Snatch\`<br /><br />Use for: Generic workout blocks that don't fit into the other categories.<br /><br />Shortcut: Type anything between \`\` (backticks)`,
  },
  MOVEMENT: {
    id: 'movement',
    icon: SportsGymnasticsIcon,
    label: 'Movement',
    template: '>${1:[movementName]:} ; rest ${2}\n*${3:notes}',
    tooltip: `Creates a movement.<br /><br />Format:<br />>movementName: <br /><br /> Example:<br /> >Air Squat: 25, 25, 25<br /><br />Use for: Single movement<br /><br />Shortcut: Type '>' and then the movement name`,
  },
  COMPLEX_MOVEMENT: {
    id: 'complexMovement',
    icon: SportsGymnasticsIcon,
    label: 'Complex Movement',
    template:
      '>${1:1}x ${2:[movementName]} + ${3:1}x ${4:[movementName]} + ${5:1}x ${6:[movementName]}: ${7} ; rest ${8}\n*${9:notes}',
    tooltip: `Creates a complex movement combination.<br /><br />Format:<br />>NUMBERx movement + NUMBERx movement + NUMBERx movement: attributes ; rest <br />*notes<br /><br />Example:<br />>3x Snatch + 2x OHS + 1x Hang Squat Snatch: 3 - 155 lbs @ 30X1<br /><br />Use for: Complex movements or movement combinations.<br /><br />Shortcut: Type '>' followed by the complex movement pattern`,
  },
};
