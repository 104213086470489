export const styles = {
  paper: {
    maxWidth: 600,
    mx: 'auto',
    mt: 4,
    borderRadius: 1,
    position: 'relative',
    boxShadow: 'none',
    border: '1px solid #d6d6d6',
    overflow: 'hidden',
  },
  backButton: {
    display: 'flex',
    alignItems: 'center',
    color: 'text.secondary',
    textDecoration: 'none',
    fontSize: '18px',
    mb: 2,
    ml: 3,
    mt: 2,
    transition: 'color 0.2s',
    '&:hover': {
      color: '#203F00',
    },
  },
  backIcon: {
    mr: 1,
    fontSize: 20,
  },
  headerBox: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#000000',
    color: '#ffffff',
    pl: 4,
    pr: 4,
    pt: 3,
    pb: 2,  
  },
  title: {
    fontFamily: '"Bebas Neue", sans-serif',
    fontWeight: 'bold',
    fontSize: '34px',
    // mb: 3,
  },
  subtitle: {
    mb: 1,
  },
  nameField: {
    mb: 3,
    borderRadius: 1,
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#333333',
      },
    },
  },
  descriptionField: {
    mb: 4,
    borderRadius: 1,    
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#333333',
      },
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  saveButton: {
    mr: 2,
    fontSize: '16px',
    backgroundColor: '#203F00',
    fontFamily: '"Bebas Neue", sans-serif',
    '&:hover': {
      backgroundColor: '#2a5400'
    },
    textTransform: 'none',
  },
  cancelButton: {
    backgroundColor: '#7E0012',
    fontSize: '16px',   
    fontFamily: '"Bebas Neue", sans-serif',
    '&:hover': {
      backgroundColor: '#9E0016'
    },
    color: '#ffffff',   
    textTransform: 'none',
  },
  formBox: {
    p: 4,
  },
} as const;
