import { IRange, languages } from 'monaco-editor';

import movementsData from '../../../assets/basic_movements.json';
import { logger } from '../../../Logger';
import { movementCache } from '../../../services/MovementCache';
import { Movement } from '../../../services/movementsService';
import { mapToMovementModels } from '../../utils/getMovementsFromFE';
import { generateMovementDocumentation } from '../utils/documentationUtils';

import { createMovementVariantProposals } from './variantProposals';

// Track available movements for proposal generation
let availableMovements: Movement[] = [];

/**
 * Updates the list of available movements from the appropriate source.
 */
export async function updateAvailableMovements(): Promise<void> {
  try {
    availableMovements =
      process.env.REACT_APP_USE_MOVEMENTS_FROM_FE === 'true'
        ? mapToMovementModels(movementsData)
        : await movementCache.getMovements();
  } catch (error) {
    logger.error('Failed to update available movements:', error);
  }
}

// Initialize available movements
updateAvailableMovements();

/**
 * Creates completion proposals for movements.
 */
export function createMovementProposals(range: IRange): languages.CompletionItem[] {
  if (availableMovements.length === 0) {
    updateAvailableMovements().catch((error) =>
      logger.error('Failed to update movements data:', error),
    );
  }

  const movements = availableMovements;
  const proposals: languages.CompletionItem[] = [];

  movements.forEach((movement) => {
    // Get all possible variants for this movement
    const variants = createMovementVariantProposals(movement, range);

    // Create base proposal for the movement name
    const baseProposal = {
      label: movement.name,
      kind: languages.CompletionItemKind.Keyword,
      range: range,
      filterText: [movement.name, ...(movement.aliases?.map((a) => a.alias) || [])].join(' '),
      sortText: `a_${movement.name}`,
      documentation: generateMovementDocumentation(movement),
    };

    // If movement has exactly one variant option, auto-insert it
    if (variants.length === 1) {
      proposals.push({
        ...baseProposal,
        insertText: `${movement.name}:${variants[0].insertText}`,
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      });
    } else {
      // Default case for movements with multiple variants
      proposals.push({
        ...baseProposal,
        insertText: `${movement.name}:`,
        command: {
          id: 'editor.action.triggerSuggest',
          title: 'Suggest',
        },
      });
    }

    // Add alias proposals that redirect to the main movement name
    if (movement.aliases && movement.aliases.length > 0) {
      movement.aliases.forEach((aliasObj) => {
        proposals.push({
          label: aliasObj.alias,
          kind: languages.CompletionItemKind.Keyword,
          insertText: `${movement.name}:`,
          range: range,
          filterText: aliasObj.alias,
          sortText: `b_${aliasObj.alias}`,
          documentation: {
            value: `Alias for ${movement.name}\n\n${generateMovementDocumentation(movement).value}`,
            isTrusted: true,
          },
          command: {
            id: 'editor.action.triggerSuggest',
            title: 'Suggest',
          },
        });
      });
    }
  });

  return proposals;
}
