import { IMarkdownString } from 'monaco-editor';

import { Movement } from '../../../services/movementsService';
import { UNIT_RULES } from '../../rules';

/**
 * Generates a documentation string for movement capabilities.
 */
export function generateCapabilitiesSection(movement: Movement): string[] {
  const parts: string[] = [];
  const capabilities = [
    'sets',
    movement.has_reps && 'repetitions',
    movement.has_load && 'load/weight',
    movement.has_duration && 'duration (time)',
    movement.has_distance && 'distance',
    movement.has_calories && 'calories',
    movement.has_power && 'power (watts)',
    movement.has_height && 'height',
    movement.has_reps && movement.has_load && 'tempo',
  ].filter(Boolean);

  if (capabilities.length > 0) {
    parts.push(`This movement supports attributes such as: ${capabilities.join(', ')}\n`);
    parts.push('Each attribute can be used independently and in any order.\n');
  }
  return parts;
}

/**
 * Generates documentation for supported patterns of a movement.
 */
export function generatePatternsSection(movement: Movement): string[] {
  const parts: string[] = [];

  parts.push('Available patterns:\n');
  parts.push(`\`sets:\` 3\n`);

  // Only show patterns for capabilities that this movement has
  if (movement.has_reps) parts.push(`\`reps:\` x 3\n`);
  if (movement.has_load) parts.push(`\`load:\` - 3 lbs, - 3 kg, - 30 %rm, - 15 %bodyweight\n`);
  if (movement.has_duration) parts.push(`\`duration:\` in 30 s, in 5 min\n`);
  if (movement.has_distance)
    parts.push(`\`distance:\` for 400 m, for 1 km, for 100 ft, for 100 yards\n`);
  if (movement.has_calories) parts.push(`\`calories:\` 20 cal\n`);
  if (movement.has_power) parts.push(`\`power:\` 200 W\n`);
  if (movement.has_height) parts.push(`\`height:\` at 24 cm\n`);

  // Only include tempo pattern if both reps and load are supported
  if (movement.has_reps && movement.has_load) parts.push(`\`tempo:\` @ 30X1\n`);

  parts.push('\n');
  return parts;
}

/**
 * Generates example section for movement documentation.
 */
export function generateExampleSection(movement: Movement): string[] {
  const parts: string[] = [];
  parts.push('Examples: \n\n');
  parts.push(buildExampleString(movement));
  return parts;
}

/**
 * Builds example string for a movement.
 */
export function buildExampleString(movement: Movement): string {
  const examples: string[] = [];

  if (movement.has_reps && movement.has_load) {
    examples.push('x 3 - 30 %rm @ 30X1');
  }

  if (examples.length === 0) {
    if (movement.has_reps) examples.push('x 3');
    if (movement.has_load) examples.push('- 30 %rm');
    if (movement.has_distance) examples.push('for 300 yards');
    if (movement.has_duration) examples.push('in 30 s');
    if (movement.has_calories) examples.push('30 cal');
    if (movement.has_power) examples.push('500 W');
    if (movement.has_height) examples.push('at 50 cm');
  }

  // Handle specific combinations of attributes
  if (movement.has_calories && movement.has_duration) {
    examples.push(`300 cal in 30 min`);
  }
  if (movement.has_calories && movement.has_distance) {
    examples.push(`300 cal in 4 km`);
  }
  if (movement.has_distance && movement.has_duration) {
    examples.push(`1 km in 20 min`);
  }

  return examples.map((example) => `\`${movement.name}: ${example}\``).join('\n\n');
}

/**
 * Generates video section for movement documentation.
 */
export function generateVideoSection(movement: Movement): string[] {
  const parts: string[] = [];
  if (movement.video_url) {
    parts.push('\n**Watch demonstration video:**');
    if (movement.video_url.includes('youtube.com')) {
      const videoId = movement.video_url.split('v=')[1];
      const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
      parts.push(`[![video](${thumbnailUrl})](${movement.video_url})`);
    } else {
      parts.push(`\nWatch demonstration video:\n${movement.video_url}`);
    }
  }
  return parts;
}

/**
 * Checks if movement has any attribute.
 */
export function hasAnyAttribute(movement: Movement): boolean {
  return (
    movement.has_reps ||
    movement.has_load ||
    movement.has_duration ||
    movement.has_distance ||
    movement.has_calories ||
    movement.has_power ||
    movement.has_height
  );
}

/**
 * Generates complete movement documentation.
 */
export function generateMovementDocumentation(movement: Movement): IMarkdownString {
  const parts: string[] = [
    ...generateCapabilitiesSection(movement),
    ...generatePatternsSection(movement),
    ...generateExampleSection(movement),
    ...generateVideoSection(movement),
  ];

  return {
    value: parts.join('\n'),
    isTrusted: true,
  };
}

/**
 * Gets documentation for load unit.
 */
export function getLoadUnitDocumentation(label: string): string {
  switch (label) {
    case '%RM':
      return 'Percentage of 1 Rep Max\nExample: Back Squat: 5 x 75 %RM';
    case 'lbs':
      return 'Weight in pounds\nExample: Snatch: 2 - 135 lbs';
    case 'kg':
      return 'Weight in kilograms\nExample: Clean: 3 - 60 kg';
    case 'pood':
      return 'Traditional kettlebell weight measurement (1 pood ≈ 16kg/35lbs)\nExample: Kettlebell Swing: 20 x 2 pood';
    case '%Bodyweight':
      return "Percentage of athlete's bodyweight\nExample: Deadlift: 3 x 150 %Bodyweight";
    default:
      return '';
  }
}

/**
 * Gets documentation for distance unit.
 */
export function getDistanceUnitDocumentation(label: string): string {
  switch (label) {
    case 'ft':
      return 'Distance in feet\nExample: Walking Lunge: 10 x BW for 100 ft';
    case 'yards':
      return 'Distance in yards\nExample: Running: for 400 yards';
    case 'miles':
      return 'Distance in miles\nExample: Running: for 1 miles';
    case 'm':
      return 'Distance in meters\nExample: Running: for 400 m';
    case 'km':
      return 'Distance in kilometers\nExample: Running: for 5 km';
    default:
      return '';
  }
}

/**
 * Gets documentation for time unit.
 */
export function getTimeUnitDocumentation(label: string): string {
  switch (label) {
    case 's':
      return 'Time in seconds\nExample: Plank: in 30 s';
    case 'min':
      return 'Time in minutes\nExample: Running: in 5 min';
    default:
      return '';
  }
}

export const STRINGS = {
  LOAD: UNIT_RULES.LOAD.suggested.join(','),
  DISTANCE: UNIT_RULES.DISTANCE.suggested.join(','),
  DURATION: UNIT_RULES.TIME.suggested.join(','),
};
