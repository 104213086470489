import CachedIcon from '@mui/icons-material/Cached';
import { Box, Typography } from '@mui/material';
import React from 'react';

import { StrengthComponent, SessionType } from '../../../generalParser/parser.types';
import { styles } from '../WodUpPreview.styles';

import { StrengthPreview } from './StrengthPreview/StrengthPreview';

export const SupersetPreview: React.FC<{
  components: StrengthComponent[];
  sessionType: SessionType;
}> = ({ components, sessionType }) => {
  return (
    <Box
      sx={{
        ...styles.genericComponent,
        ...styles.supersetComponent,
        ...(sessionType === SessionType.AM
          ? styles.supersetComponentAM
          : styles.supersetComponentPM),
      }}
    >
      <Box sx={styles.genericHeaderWrapper}>
        <Box sx={sessionType === SessionType.AM ? styles.headerLineAM : styles.headerLinePM} />
        <Typography sx={styles.genericHeader}>
          <CachedIcon sx={styles.supersetIcon} />
          {`${components[0].numberOfSets} supersets of:`}
        </Typography>
      </Box>

      <Box sx={styles.contentWrapper}>
        {components.map((component, index) => (
          <Box key={index} sx={styles.supersetSubComponent}>
            <StrengthPreview component={component} sessionType={sessionType} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
