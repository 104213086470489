import { useCallback, useState } from 'react';

import { logger } from '../Logger';
import { programSettingsService } from '../services/settingsService';
import { MonacoSettings, ProgramSettings, UserSettings } from '../types/settings';

export function useMonacoSettings(defaultSettings: MonacoSettings) {
  const [monacoSettings, setMonacoSettings] = useState<MonacoSettings>(defaultSettings);

  const updateUserSettings = useCallback((settings: Partial<UserSettings>) => {
    setMonacoSettings((prev) => ({
      ...prev,
      ...settings,
    }));
  }, []);

  const updateProgramSettings = useCallback(
    async (program_id: number, newSettings: ProgramSettings) => {
      const updatedSettings: MonacoSettings = { ...monacoSettings, ...newSettings };
      try {
        await programSettingsService.setProgramSettings(program_id, newSettings);
      } catch (error: unknown) {
        if (error instanceof Error) {
          logger.error('Error saving program settings: ', error.message);
        } else {
          logger.error('Error saving program settings: ', error);
        }
      }
      setMonacoSettings(updatedSettings);
    },
    [monacoSettings, setMonacoSettings],
  );

  return {
    monacoSettings,
    setMonacoSettings,
    updateUserSettings,
    updateProgramSettings,
  };
}
