import { Monaco } from '@monaco-editor/react';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Paper,
  Button,
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
} from '@mui/material';
import { editor, IDisposable } from 'monaco-editor';
import React, { useEffect, useState, useRef, useCallback } from 'react';

import MonacoEditor, { customLangId } from '../../@monaco-uwl/MonacoEditor';
import { UWLError, validate } from '../../@monaco-uwl/UWLErrorHandler';
// eslint-disable-next-line import/order
import { Workout } from '../../@monaco-uwl/UWLVisitor/Workout';

import { DebugWindow } from './components/DebugWindow/DebugWindow';
import { DialogHeader } from './components/DialogHeader/DialogHeader';
import { PreviewSection } from './components/PreviewSection/PreviewSection';
import { ErrorHelp } from './ErrorHelp/ErrorHelp';
import { styles } from './ExpandedViewDialog.styles';
import { ExpandedViewDialogProps } from './ExpandedViewDialog.types';
import { useDebugTools } from './hooks/useDebugTools';
import { useDialogHandlers } from './hooks/useDialogHandlers';
import { useTemplateInsertion } from './hooks/useTemplateInsertion';
import TemplateItem from './TemplateTooltip/TemplateItem';
import { TEMPLATES } from './TemplateTooltip/templates';
import { useTooltip } from './TemplateTooltip/useTooltip';
import { createCodeActionProvider, updateDecorations } from './utils/editorUtils';

const ExpandedViewDialog: React.FC<ExpandedViewDialogProps> = ({
  open,
  onClose,
  weekNumber,
  dayNumber,
  initialCursorPosition,
  initialContent,
  monacoSettings,
}) => {
  const [content, setContent] = useState(initialContent);
  const [uwlContent, setUwlContent] = useState<Workout[][]>([]);
  const [editorState, setEditorState] = useState({
    hasError: false as UWLError | false,
    correctionLength: 0,
    errorSnackbarOpen: false,
  });

  const tooltipState = useTooltip();
  const { debugState, handleDebugParse, setShowDebugWindow } = useDebugTools();

  const refs = {
    expandedInstance: useRef<Monaco | null>(null),
    expandedEditor: useRef<editor.IStandaloneCodeEditor | null>(null),
    decorationsCollection: useRef<editor.IEditorDecorationsCollection | null>(null),
    codeActionDisposable: useRef<IDisposable | null>(null),
    lastEscapePress: useRef<number | null>(null),
    dialog: useRef<HTMLDivElement | null>(null),
    content: useRef(content),
    editorState: useRef(editorState),
  };

  const { handleDialogClose, handleEscapePress } = useDialogHandlers(
    onClose,
    refs.content,
    refs.codeActionDisposable,
    refs.editorState,
  );

  const handleEditorMount = (editor: editor.IStandaloneCodeEditor, monacoInstance: Monaco) => {
    refs.expandedEditor.current = editor;
    refs.expandedInstance.current = monacoInstance;
    refs.decorationsCollection.current = editor.createDecorationsCollection([]);
    refs.lastEscapePress.current = null;
    refs.codeActionDisposable.current = monacoInstance.languages.registerCodeActionProvider(
      customLangId,
      createCodeActionProvider(),
    );

    const model = editor.getModel();
    if (model) {
      if (initialCursorPosition !== null) {
        const position = model.getPositionAt(initialCursorPosition);
        editor.setPosition(position);
      } else {
        const lastLine = model.getLineCount();
        const lastColumn = model.getLineMaxColumn(lastLine);
        editor.setPosition({ lineNumber: lastLine, column: lastColumn });
      }
      editor.focus();
    }

    editor.addCommand(monacoInstance.KeyCode.Escape, () => {
      const selection = editor.getSelection();
      if (selection && !selection.isEmpty()) {
        editor.setSelection({
          startLineNumber: selection.endLineNumber,
          startColumn: selection.endColumn,
          endLineNumber: selection.endLineNumber,
          endColumn: selection.endColumn,
        });
      }
      handleEscapePress();
      return null;
    });
  };

  const validateAndUpdateContent = (contentToValidate: string) => {
    const content = contentToValidate + '\n';
    const c = validate(content);
    setUwlContent(c.wods);

    const hasError = c.errors.length > 0 && c.errors[0].severity === 8 ? c.errors[0] : false;
    setEditorState(prevState => ({
      ...prevState,
      hasError,
    }));

    return c;
  };

  const handleContentChange = (newContent: string) => {
    setContent(newContent);
    const c = validateAndUpdateContent(newContent);

    refs.expandedInstance.current?.editor.setModelMarkers(
      refs.expandedEditor.current!.getModel()!,
      'Owner',
      c.errors,
    );
  };

  const handleIconClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    tooltipState.setTooltipVisible(!tooltipState.tooltipVisible);
  };

  const { insertTemplate } = useTemplateInsertion(
    refs.expandedEditor,
    setEditorState,
    tooltipState.setTooltipVisible,
  );

  const handleCorrectionLengthChange = useCallback((length: number) => {
    setEditorState((prevState) => ({
      ...prevState,
      correctionLength: length,
    }));
  }, []);

  useEffect(() => {
    const editor = refs.expandedEditor.current;
    const monacoInstance = refs.expandedInstance.current;
    const decorationsCollection = refs.decorationsCollection.current;

    if (editor && monacoInstance && decorationsCollection) {
      if (editorState.correctionLength > 0) {
        updateDecorations(
          editor,
          monacoInstance,
          decorationsCollection,
          editorState.correctionLength,
        );
        setEditorState((prevState) => ({
          ...prevState,
          correctionLength: 0,
        }));
      } else if (editorState.correctionLength === -1) {
        decorationsCollection.set([]);
      }
    }
  }, [
    content,
    editorState.correctionLength,
    refs.decorationsCollection,
    refs.expandedEditor,
    refs.expandedInstance,
  ]);

  useEffect(() => {
    if (open) {
      setContent(initialContent);
      validateAndUpdateContent(initialContent);
    }
  }, [open, initialContent]);

  useEffect(() => {
    refs.content.current = content;
  }, [content]);

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      maxWidth={false}
      fullWidth
      disableEscapeKeyDown
      sx={styles.dialog}
      ref={refs.dialog}
    >
      <DialogHeader 
        weekNumber={weekNumber} 
        dayNumber={dayNumber} 
      />
      {process.env.REACT_APP_SHOW_DEBUG_TOOLS === 'true' && (
        <Button
          variant="contained"
          onClick={() => handleDebugParse(uwlContent)}
          sx={styles.debugButton}
        >
          Debug Parse
        </Button>
      )}
      <DialogContent sx={styles.dialogContent}>
        <Box sx={styles.contentWrapper}>
          <Paper elevation={3} sx={styles.editPaper}>
            <Box sx={styles.editorTitleBox}>
              <Typography variant="body1" sx={{ ...styles.sectionTitle, marginBottom: '0px' }}>
                Edit Content
              </Typography>
              <Tooltip title="Show content templates" placement="right">
                <IconButton id="library-icon" onClick={handleIconClick} sx={styles.editorTitleIcon}>
                  <LibraryBooksIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={styles.editorBox}>
              <Box sx={styles.editorInnerBox}>
                <MonacoEditor
                  value={content}
                  onChange={(value: string | undefined) => handleContentChange(value ?? '')}
                  onMount={handleEditorMount}
                  enableHover={monacoSettings.enableHover}
                />
                <ErrorHelp
                  hasError={editorState.hasError}
                  content={content}
                  onContentChange={handleContentChange}
                  onCorrectionLengthChange={handleCorrectionLengthChange}
                />
              </Box>
            </Box>
            {tooltipState.tooltipVisible && (
              <Box id="tooltip" sx={styles.tooltip}>
                {Object.values(TEMPLATES).map((template) => (
                  <TemplateItem
                    key={template.id}
                    template={template}
                    onInsert={insertTemplate}
                    hoveredTemplate={tooltipState.hoveredTemplate}
                    setHoveredTemplate={tooltipState.setHoveredTemplate}
                  />
                ))}
              </Box>
            )}
            <Snackbar
              open={editorState.errorSnackbarOpen}
              autoHideDuration={6000}
              onClose={() => setEditorState({ ...editorState, errorSnackbarOpen: false })}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              <Alert
                onClose={() => setEditorState({ ...editorState, errorSnackbarOpen: false })}
                severity="error"
                sx={{ width: '100%' }}
              >
                Cannot insert a Superset block inside another Superset block.
              </Alert>
            </Snackbar>
          </Paper>
          <PreviewSection
            Uwlcontent={uwlContent}
            content={content}
            onContentChange={handleContentChange}
          />
          {process.env.REACT_APP_SHOW_DEBUG_TOOLS === 'true' && (
            <DebugWindow {...debugState} setShowDebugWindow={setShowDebugWindow} />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ExpandedViewDialog;
