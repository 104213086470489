import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import React, { useState, useEffect } from 'react';

import MovementCountSelector from './MovementCountSelector';
import { styles } from './TemplateTooltip.styles';
import { TemplateItemProps, SubTemplate } from './types';

const InfoTooltip: React.FC<{ tooltip: string }> = ({ tooltip }) => (
  <Tooltip title={<div dangerouslySetInnerHTML={{ __html: tooltip }} />} placement="right">
    <span>
      <IconButton size="small" sx={styles.infoButton}>
        <InfoIcon fontSize="small" />
      </IconButton>
    </span>
  </Tooltip>
);

const SubTemplateItem: React.FC<{
  subTemplate: SubTemplate;
  onSelect: (template: string, subTemplate: SubTemplate) => void;
  showMovementCountSelector: boolean;
  onMovementCountSelect: (count: number) => void;
}> = ({ subTemplate, onSelect, showMovementCountSelector, onMovementCountSelect }) => (
  <Box key={subTemplate.id}>
    <Box
      sx={styles.subItem}
      onClick={(e) => {
        e.stopPropagation();
        onSelect(subTemplate.template, subTemplate);
      }}
    >
      <Typography variant="body2" sx={styles.itemText}>
        {subTemplate.label}
      </Typography>
      <InfoTooltip tooltip={subTemplate.tooltip} />
    </Box>
    {showMovementCountSelector && (
      <Box sx={{ paddingLeft: '8px', paddingRight: '8px' }}>
        <MovementCountSelector onSelectCount={onMovementCountSelect} />
      </Box>
    )}
  </Box>
);

const SubTemplateList: React.FC<{
  subTemplates: SubTemplate[];
  onInsert: (template: string, subTemplate: SubTemplate) => void;
  selectedSubTemplate: SubTemplate | null;
  showMovementCountSelector: boolean;
  onMovementCountSelect: (count: number) => void;
}> = ({
  subTemplates,
  onInsert,
  selectedSubTemplate,
  showMovementCountSelector,
  onMovementCountSelect,
}) => (
  <Box sx={styles.subItemsContainer}>
    {subTemplates.map((subTemplate) => (
      <SubTemplateItem
        key={subTemplate.id}
        subTemplate={subTemplate}
        onSelect={onInsert}
        showMovementCountSelector={
          showMovementCountSelector && selectedSubTemplate?.id === subTemplate.id
        }
        onMovementCountSelect={onMovementCountSelect}
      />
    ))}
  </Box>
);

const TemplateItem: React.FC<TemplateItemProps> = ({
  template,
  onInsert,
  hoveredTemplate,
  setHoveredTemplate,
}) => {
  const [showSubTemplates, setShowSubTemplates] = useState(false);
  const [showMovementCountSelector, setShowMovementCountSelector] = useState(false);
  const [selectedSubTemplate, setSelectedSubTemplate] = useState<SubTemplate | null>(null);

  // Don't reset selected subtemplate or movement selector states when cursor moves
  // This allows the movement count selector to stay visible when navigating within the menu
  useEffect(() => {
    if (!hoveredTemplate) {
      // Neither of these should reset when hoveredTemplate changes
      // setShowSubTemplates(false);
      // setSelectedSubTemplate(null);
    }
  }, [hoveredTemplate]);

  const handleClick = () => {
    if (template.subTemplates) {
      // Toggle submenu
      if (showSubTemplates) {
        // If already open, clicking again should close everything
        setShowSubTemplates(false);
        setShowMovementCountSelector(false);
        setSelectedSubTemplate(null);
      } else {
        // Just open the submenu
        setShowSubTemplates(true);
      }
    } else if (template.showMovementCountSelector) {
      // Toggle movement count selector for top-level templates
      setShowMovementCountSelector(!showMovementCountSelector);
    } else {
      // Insert the template directly
      onInsert(template.template);
    }
  };

  const handleMovementCountSelect = (count: number) => {
    if (selectedSubTemplate && selectedSubTemplate.generateTemplate) {
      // If a subtemplate is selected, use its generator
      const generatedTemplate = selectedSubTemplate.generateTemplate(count);
      onInsert(generatedTemplate);
    } else if (template.generateTemplate) {
      // Otherwise use the template's generator
      const generatedTemplate = template.generateTemplate(count);
      onInsert(generatedTemplate);
    }
    // Don't reset these states after selecting a count - they'll be reset when the template is inserted
    // setShowMovementCountSelector(false);
    // setSelectedSubTemplate(null);
  };

  const handleSubTemplateSelect = (templateString: string, subTemplate: SubTemplate) => {
    // If the same subtemplate is clicked again, toggle the movement count selector
    if (selectedSubTemplate?.id === subTemplate.id) {
      setShowMovementCountSelector(!showMovementCountSelector);
      return;
    }

    // For movement count selector enabled templates
    if (subTemplate && subTemplate.showMovementCountSelector) {
      setSelectedSubTemplate(subTemplate);
      setShowMovementCountSelector(true);
    } else {
      // For regular templates, just insert
      onInsert(templateString);
    }
  };

  return (
    <Box
      sx={styles.itemContainer}
      onMouseEnter={() => setHoveredTemplate(template.id)}
      onMouseLeave={() => setHoveredTemplate(null)}
    >
      <Box
        sx={{
          ...styles.mainItem,
          backgroundColor: hoveredTemplate === template.id ? '#e0e0e0' : 'transparent',
          marginBottom: template.subTemplates || showMovementCountSelector ? '0px' : '4px',
        }}
        onClick={handleClick}
      >
        <template.icon fontSize="small" sx={styles.icon} />
        <Typography variant="body2" sx={styles.itemText}>
          {template.label}
        </Typography>
        {template.subTemplates ? (
          <ExpandMoreIcon fontSize="small" sx={styles.expandIcon(showSubTemplates)} />
        ) : (
          <InfoTooltip tooltip={template.tooltip} />
        )}
      </Box>

      {template.subTemplates && showSubTemplates && (
        <SubTemplateList
          subTemplates={template.subTemplates}
          onInsert={handleSubTemplateSelect}
          selectedSubTemplate={selectedSubTemplate}
          showMovementCountSelector={showMovementCountSelector}
          onMovementCountSelect={handleMovementCountSelect}
        />
      )}

      {/* Show movement count selector at the top level only if no subtemplate is selected */}
      {showMovementCountSelector && !selectedSubTemplate && template.showMovementCountSelector && (
        <MovementCountSelector onSelectCount={handleMovementCountSelect} />
      )}
    </Box>
  );
};

export default TemplateItem;
