export const styles = {
  container: {
    m: 4,
    boxShadow: 'none',
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 6,
  },
  addButton: {
    mr: 2,
    textTransform: 'none',
    fontFamily: '"Bebas Neue", sans-serif',
    fontSize: '20px',
    backgroundColor: '#203F00',
    '&:hover': {
      backgroundColor: '#2a5400',
    },
  },
  searchField: {
    mb: 3,
    '& .MuiOutlinedInput-root': {
      borderRadius: 1,
      '&.Mui-focused fieldset': {
        borderColor: '#000000',
      },
    },
  },
  searchLoader: {
    position: 'absolute',
    right: '16px',
    top: '50%',
    transform: 'translateY(-50%)',
    marginTop: '-12px',
    color: 'primary.main',
    opacity: 0.8,
    animation: 'fadeIn 0.2s ease-in',
    '@keyframes fadeIn': {
      '0%': { opacity: 0 },
      '100%': { opacity: 0.8 },
    },
  },
  listPaper: {
    borderRadius: 1,
    overflow: 'hidden',
    boxShadow: 'none',
    border: '1px solid #f5f5f5',
  },
  listHeader: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    backgroundColor: '#000000',
    color: '#ffffff',
    fontFamily: '"Bebas Neue", sans-serif',
    fontSize: '24px',
    py: 2,
    px: 3,
  },
  movementRow: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    py: 2,
    px: 3,
    borderBottom: '1px solid',
    borderColor: 'grey.200',
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: '#fafafa',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  emptyState: {
    p: 3,
    textAlign: 'center',
    color: 'text.secondary',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mt: 2,
  },
  button: {
    mx: 0.5,
    textTransform: 'none',
    fontFamily: '"Bebas Neue", sans-serif',
    fontSize: '18px',
    color: '#203F00',
    '&.MuiButton-outlined': {
      borderColor: '#d0d0d0',
      '&:hover': {
        borderColor: '#203F00',
        color: '#203F00',
      },
    },
  },
  title: {
    fontWeight: 600,
  },
  listHeaderText: {
    fontWeight: 'bold',
    fontSize: '24px',
    fontFamily: '"Bebas Neue", sans-serif',
  },
} as const;
