import { CancellationToken, editor, IRange, languages, Position } from 'monaco-editor';

import { logger } from '../../Logger';
import { movementCache } from '../../services/MovementCache';

// Import from new modular structure
import {
  createParameterProposals,
  createWorkoutProposals,
  createEmomMinuteCompletion,
  createComplexMovementVariantProposals,
  createAmrapMovementVariantProposals,
  createSupersetVariantProposals,
  createEmomVariantProposals,
  createForTimeRestRoundsSetsVariantProposals,
  createForTimeRestRoundsVariantProposals,
  createForTimeNoRestVariantProposals,
  createForTimeRestMovementsVariantProposals,
  createForTimeRestAllVariantProposals,
  createEveryVariantProposals,
} from './proposals';
import { createMovementProposals } from './proposals/movementProposals';
import {
  createDistanceUnitProposals,
  createLoadProposals,
  createRestProposals,
  createTimeProposals,
  createRestTimeProposals,
} from './proposals/unitProposals';
import { createMovementVariantProposals } from './proposals/variantProposals';
import { PATTERNS } from './types';
import { detectWorkoutContext } from './utils/contextUtils';

/**
 * Creates a range for autocompletion
 *
 * @param {Position} position
 * @param {editor.IWordAtPosition} word
 * @return {*}  {IRange}
 */
function createRange(position: Position, word: editor.IWordAtPosition): IRange {
  return {
    startLineNumber: position.lineNumber,
    endLineNumber: position.lineNumber,
    startColumn: word.startColumn,
    endColumn: word.endColumn,
  };
}

/**
 * Returns the value of a line from its beginning to the **beginning** of the word under the caret
 *
 * @param {editor.ITextModel} model Model from which to extract text
 * @param {Position} position Position of the caret
 * @param {editor.IWordAtPosition} word Word pointed to by the caret
 * @return {*}  {string} The text in the specified range
 */
function getTextUntilPosition(
  model: editor.ITextModel,
  position: Position,
  word: editor.IWordAtPosition,
): string {
  return model.getValueInRange({
    startLineNumber: position.lineNumber,
    startColumn: 1,
    endLineNumber: position.lineNumber,
    endColumn: word.startColumn,
  });
}

function getPrecedingText(
  model: editor.ITextModel,
  position: Position,
  word: editor.IWordAtPosition,
): string {
  return model.getValueInRange({
    startLineNumber: 1,
    endLineNumber: position.lineNumber,
    startColumn: 1,
    endColumn: word.endColumn,
  });
}

function getWithPrevLine(
  model: editor.ITextModel,
  position: Position,
  word: editor.IWordAtPosition,
): string {
  return model.getValueInRange({
    startLineNumber: Math.max(1, position.lineNumber - 1),
    startColumn: 1,
    endLineNumber: position.lineNumber,
    endColumn: word.startColumn,
  });
}

function getIndexOfFirstNonWhitespaceAfterSymbol(text: string, symbol: string) {
  if (symbol.length === 0) {
    throw new Error('Passed empty string as symbol');
  }
  const symbolIndex = text.indexOf(symbol[0]);

  const index = (text.substring(symbolIndex + 1).match(/\S/)?.index ?? 0) + symbolIndex;
  return index;
}

/**
 * Context for pattern matchers containing all the information needed for completion
 */
interface CompletionContext {
  model: editor.ITextModel;
  position: Position;
  word: editor.IWordAtPosition;
  range: IRange;
  textUntilPosition: string;
  currentLine: string;
  precedingText: string;
  withPrevLine: string;
}

/**
 * Interface for pattern matchers
 */
interface PatternMatcher {
  /**
   * Name of the matcher for debugging
   */
  name: string;

  /**
   * Tests if this matcher applies to the current context
   */
  test: (context: CompletionContext) => boolean;

  /**
   * Provides completion suggestions when the pattern matches
   */
  getCompletions: (context: CompletionContext) => Promise<languages.CompletionItem[]>;
}

/**
 * Registry of pattern matchers in order of precedence
 */
const patternMatchers: PatternMatcher[] = [
  // Variant matchers (must come first as they override other patterns)
  {
    name: 'Complex Movement Variant',
    test: (context) => PATTERNS.COMPLEX_MOVEMENT_VARIANT.test(context.currentLine),
    getCompletions: async (context) => createComplexMovementVariantProposals(context.range),
  },
  {
    name: 'AMRAP Movement Variant',
    test: (context) => PATTERNS.AMRAP_MOVEMENT_VARIANT.test(context.currentLine),
    getCompletions: async (context) => createAmrapMovementVariantProposals(context.range),
  },
  {
    name: 'Superset Variant',
    test: (context) => PATTERNS.SUPERSET_VARIANT.test(context.currentLine),
    getCompletions: async (context) => createSupersetVariantProposals(context.range),
  },
  {
    name: 'EMOM Variant',
    test: (context) => PATTERNS.EMOM_VARIANT.test(context.currentLine),
    getCompletions: async (context) => createEmomVariantProposals(context.range),
  },
  {
    name: 'Every Variant',
    test: (context) => PATTERNS.EVERY_VARIANT.test(context.currentLine),
    getCompletions: async (context) => createEveryVariantProposals(context.range),
  },
  {
    name: 'ForTime No Rest Variant',
    test: (context) => PATTERNS.FORTIME_NO_REST_VARIANT.test(context.currentLine),
    getCompletions: async (context) => createForTimeNoRestVariantProposals(context.range),
  },
  {
    name: 'ForTime Rest All Variant',
    test: (context) => PATTERNS.FORTIME_REST_ALL_VARIANT.test(context.currentLine),
    getCompletions: async (context) => createForTimeRestAllVariantProposals(context.range),
  },
  {
    name: 'ForTime Rest Movements Variant',
    test: (context) => PATTERNS.FORTIME_REST_MOVEMENTS_VARIANT.test(context.currentLine),
    getCompletions: async (context) => createForTimeRestMovementsVariantProposals(context.range),
  },
  {
    name: 'ForTime Rest Rounds Variant',
    test: (context) => PATTERNS.FORTIME_REST_ROUNDS_VARIANT.test(context.currentLine),
    getCompletions: async (context) => createForTimeRestRoundsVariantProposals(context.range),
  },
  {
    name: 'ForTime Rest Rounds Sets Variant',
    test: (context) => PATTERNS.FORTIME_REST_ROUNDS_SETS_VARIANT.test(context.currentLine),
    getCompletions: async (context) => createForTimeRestRoundsSetsVariantProposals(context.range),
  },

  // Unit matchers
  {
    name: 'Distance Unit',
    test: (context) => !!context.textUntilPosition.match(PATTERNS.UNIT.DISTANCE),
    getCompletions: async (context) => createDistanceUnitProposals(context.range),
  },
  {
    name: 'Load Unit',
    test: (context) => !!context.textUntilPosition.match(PATTERNS.UNIT.LOAD),
    getCompletions: async (context) => createLoadProposals(context.range),
  },
  {
    name: 'Time Unit',
    test: (context) => !!context.textUntilPosition.match(PATTERNS.UNIT.TIME),
    getCompletions: async (context) => createTimeProposals(context.range),
  },

  // Other pattern matchers
  {
    name: 'Rest Time Units',
    test: (context) => !!context.textUntilPosition.match(PATTERNS.REST_NUMBER),
    getCompletions: async (context) => createRestTimeProposals(context.range),
  },
  {
    name: 'Rest',
    test: (context) => {
      // Check for "rest" keyword but ensure we don't already have a valid rest term
      if (!context.textUntilPosition.match(PATTERNS.REST)) {
        return false;
      }

      // Avoid suggesting if we already have a valid rest term
      const restTerms = ['As Needed', 'superset', 'ratio'];
      const afterRest = context.currentLine.match(/[Rr]est:?\s+(.*)/);
      if (afterRest && afterRest[1]) {
        const restTerm = afterRest[1].trim();
        // If we already have a valid rest term or a number, don't suggest
        if (restTerms.some((term) => restTerm.startsWith(term)) || /^[0-9]+/.test(restTerm)) {
          return false;
        }
      }

      return true;
    },
    getCompletions: async (context) => createRestProposals(context.range),
  },
  {
    name: 'Movement',
    test: (context) => {
      // Only trigger for movement name completion if we're after '>' and before ':'
      const line = context.currentLine;
      const lastMarkerIndex = line.lastIndexOf('>');
      if (lastMarkerIndex === -1) return false;

      const colonIndex = line.indexOf(':', lastMarkerIndex);
      const cursorColumn = context.position.column;

      // Only show suggestions if we're between '>' and ':' and there's no '*' in the line
      if (colonIndex === -1 && cursorColumn > lastMarkerIndex && !line.includes('*')) {
        return true;
      }

      // For movement variants (after the colon), use the original pattern
      return (
        colonIndex !== -1 &&
        cursorColumn > colonIndex &&
        !line.includes('*') &&
        !!context.textUntilPosition.match(PATTERNS.MOVEMENT)
      );
    },
    getCompletions: async (context) => {
      // Check if we're after a movement name with colon
      const movementWithColon = context.currentLine.match(/>\s*([^:]+):/);
      if (movementWithColon) {
        const movementName = movementWithColon[1].trim();
        const movements = await movementCache.getMovements();
        const movement = movements.find((m) => m.name === movementName);
        if (movement) {
          const movementIndex = context.currentLine.indexOf(`>${movementName}`);
          const colonPosition = context.currentLine.indexOf(':', movementIndex);

          const newRange: IRange = {
            startLineNumber: context.position.lineNumber,
            endLineNumber: context.position.lineNumber,
            startColumn: colonPosition + 2,
            endColumn: context.position.column,
          };

          const workoutContext = detectWorkoutContext(context.model, context.position);
          return createMovementVariantProposals(movement, newRange, workoutContext);
        }
      }

      // For movement name completion
      const movRange: IRange = {
        startLineNumber: context.position.lineNumber,
        startColumn: getIndexOfFirstNonWhitespaceAfterSymbol(context.textUntilPosition, '>') + 2,
        endLineNumber: context.position.lineNumber,
        endColumn: context.word.endColumn,
      };

      return createMovementProposals(movRange);
    },
  },
  {
    name: 'Parameter',
    test: (context) => !!context.precedingText.match(PATTERNS.PARAMETER),
    getCompletions: async (context) =>
      createParameterProposals(context.textUntilPosition, context.range),
  },
  {
    name: 'Short Generic',
    test: (context) => !!context.precedingText.match(PATTERNS.SHORT_GENERIC),
    getCompletions: async () => [],
  },
  {
    name: 'EMOM Add',
    test: (context) => {
      const emomMatch = context.withPrevLine.match(PATTERNS.EMOM_ADD);
      return !!emomMatch;
    },
    getCompletions: async (context) => {
      const emomMatch = context.withPrevLine.match(PATTERNS.EMOM_ADD);
      if (emomMatch) {
        return [createEmomMinuteCompletion(emomMatch, context.range)];
      }
      return [];
    },
  },
  {
    name: 'Default Workout',
    test: (context) => {
      // Don't show proposals if line starts with a comment marker "*"
      if (context.currentLine.trim().startsWith('*')) {
        return false;
      }

      // Get the trimmed line content
      const trimmedLine = context.currentLine.trim().toLowerCase();

      // Special case for multi-word triggers with spaces
      const MULTI_WORD_TRIGGERS = ['for ', 'complex ', 'something '];
      if (
        MULTI_WORD_TRIGGERS.some(
          (trigger) => trimmedLine === trigger.trim() || trimmedLine.startsWith(trigger),
        )
      ) {
        return true;
      }

      // Only show proposals if:
      // 1. Line is completely empty, or
      // 2. Line only contains whitespace before cursor
      const textBeforeCursor = context.textUntilPosition.trim();
      return textBeforeCursor.length === 0;
    },
    getCompletions: async (context) => createWorkoutProposals(context.range),
  },
];

export const UWLAutocompletionProvider: languages.CompletionItemProvider = {
  triggerCharacters: ['>', ' ', ':'],
  provideCompletionItems: function (
    model: editor.ITextModel,
    position: Position,
    context: languages.CompletionContext,
    token: CancellationToken,
  ): languages.ProviderResult<languages.CompletionList> {
    return (async () => {
      if (token.isCancellationRequested) {
        return { suggestions: [] };
      }

      // Get the current line content and text until cursor
      const currentLine = model.getLineContent(position.lineNumber);
      const textUntilCursor = currentLine.substring(0, position.column - 1);

      // Check for comments:
      // 1. Line starts with * (standalone comment)
      // 2. There's a * before the cursor (inline comment)
      if (currentLine.trim().startsWith('*') || textUntilCursor.includes('*')) {
        return {
          suggestions: [],
          incomplete: false,
          // This tells Monaco to not show any other suggestions (including built-in ones)
          noSuggestions: true,
        };
      }

      const word = model.getWordUntilPosition(position);
      const currentLineContent = model.getLineContent(position.lineNumber);
      const range = getRangeForCompletion(position, word, currentLineContent);

      const completionContext: CompletionContext = {
        model,
        position,
        word,
        range,
        textUntilPosition: getTextUntilPosition(model, position, word),
        currentLine: currentLineContent,
        precedingText: getPrecedingText(model, position, word),
        withPrevLine: getWithPrevLine(model, position, word),
      };

      for (const matcher of patternMatchers) {
        try {
          if (matcher.test(completionContext)) {
            const suggestions = await matcher.getCompletions(completionContext);
            return { suggestions };
          }
        } catch (error) {
          logger.error('Error in pattern matcher:', error);
        }
      }

      return { suggestions: [] };
    })();
  },
};

function getRangeForCompletion(
  position: Position,
  word: editor.IWordAtPosition,
  currentLineContent: string,
): IRange {
  const MULTI_WORD_TRIGGERS = ['for', 'complex', 'something'];

  // Convert line to lowercase for case-insensitive matching
  const trimmedLowerLine = currentLineContent.trim().toLowerCase();

  // Approach 1: Check if line matches exact triggers with space
  for (const trigger of MULTI_WORD_TRIGGERS) {
    if (trimmedLowerLine.startsWith(trigger + ' ') || trimmedLowerLine === trigger) {
      return {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        startColumn: 1,
        endColumn: position.column,
      };
    }
  }

  // Approach 2: Check for partial trigger matches at beginning
  const partialTriggerMatch = MULTI_WORD_TRIGGERS.some((trigger) => {
    return trigger.startsWith(trimmedLowerLine) || trimmedLowerLine.startsWith(trigger);
  });

  // Approach 3: Use regex pattern for exact word matching
  const multiWordPattern = new RegExp(`^\\s*(${MULTI_WORD_TRIGGERS.join('|')})\\s*$`, 'i');
  const isExactMultiWordTrigger = multiWordPattern.test(currentLineContent);

  if (partialTriggerMatch || isExactMultiWordTrigger) {
    return {
      startLineNumber: position.lineNumber,
      endLineNumber: position.lineNumber,
      startColumn: 1,
      endColumn: position.column,
    };
  }

  return createRange(position, word);
}
