import { Box, Typography } from '@mui/material';
import React from 'react';

import { styles as previewStyles } from '../../WodUpPreview.styles';
import { TimeType } from '../../WodUpPreview.types';
import { areArrayValuesEqual } from '../utils/useMovementsCalculations';

import { styles } from './SetRow.styles';
import { SetRowProps } from './SetRow.types';

export const SetRow: React.FC<SetRowProps> = ({
  index,
  numberOfSets,
  attributes,
  exerciseName,
  isComplex = false,
  isForTime = false,
  roundNumber,
  hasUniformRepsForTime = false,
}) => {
  const {
    reps = [],
    load = [],
    load_female = [],
    tempo = [],
    duration = [],
    distance = [],
    calories = [],
    power = [],
    height = [],
    load_unit: loadUnit = '',
    distance_unit: distanceUnit = '',
    duration_unit: durationUnit = '',
    height_unit: heightUnit = '',
  } = attributes;

  const parts: Array<string | JSX.Element> = [];

  if (reps.length > 0 && !(isForTime && hasUniformRepsForTime)) {
    if (!isComplex) {
      const repCount = index !== null ? reps[index] : reps[0];
      const isAmrap = typeof repCount === 'string' && repCount.toUpperCase().startsWith('AMRAP');

      if (isAmrap || repCount === 'X') {
        const timeMatch = repCount.match(/\(([-\d]+)\)/);
        parts.push(timeMatch ? `AMRAP(${timeMatch[1]})` : 'AMRAP');
      } else {
        parts.push(`${repCount} ${repCount === '1' ? 'rep' : 'reps '}`);
      }
    }
  }

  if (duration.length > 0) {
    const currentDuration = index !== null ? duration[index] : duration[0];
    parts.push(
      currentDuration === 'X' ? TimeType.MAX_TIME : `for ${currentDuration} ${durationUnit}`,
    );
  }

  if (distance.length > 0) {
    const currentDistance = index !== null ? distance[index] : distance[0];
    parts.push(
      currentDistance === 'X' ? TimeType.MAX_DISTANCE : `${currentDistance} ${distanceUnit}`,
    );
  }

  if (load.length > 0) {
    const currentLoad = index !== null ? load[index] : load[0];
    const currentFemaleLoad =
      load_female.length > 0 ? (index !== null ? load_female[index] : load_female[0]) : null;

    if (currentFemaleLoad) {
      parts.push(
        currentLoad === 'X' ? (
          '@ max load'
        ) : (
          <React.Fragment key="load">
            @ <span style={styles.maleLoadText}>{currentLoad}</span> /{' '}
            <span style={styles.femaleLoadText}>{currentFemaleLoad}</span> {loadUnit}
          </React.Fragment>
        ),
      );
    } else {
      parts.push(currentLoad === 'X' ? '@ max load' : `@ ${currentLoad} ${loadUnit}`);
    }
  }

  if (tempo.length > 0 && !areArrayValuesEqual(tempo)) {
    const currentTempo = index !== null ? tempo[index] : tempo[0];
    parts.push(`(${currentTempo} tempo)`);
  }

  if (calories.length > 0) {
    const currentCalories = index !== null ? calories[index] : calories[0];
    if (parts.length !== 0) {
      parts.push('-');
    }
    parts.push(currentCalories === 'X' ? 'Max Calories' : `${currentCalories} Calories`);
  }

  if (power.length > 0) {
    const currentPower = index !== null ? power[index] : power[0];
    if (parts.length !== 0) {
      parts.push('-');
    }
    parts.push(currentPower === 'X' ? 'Max Power' : `${currentPower} Watts`);
  }

  if (height.length > 0) {
    const currentHeight = index !== null ? height[index] : height[0];
    if (parts.length !== 0) {
      parts.push('at');
    }
    parts.push(currentHeight === 'X' ? 'Max Height' : `${currentHeight} ${heightUnit}`);
  }

  const renderContent = () => {
    if (isForTime) {
      return (
        <>
          <span className="round-header">
            {roundNumber !== undefined ? `Round ${roundNumber + 1}` : 'Each round'}
          </span>
          : {parts}
        </>
      );
    }

    if (isComplex) {
      if (index !== null) {
        return (
          <>
            Set {index + 1}
            {parts.length > 0 ? ` - ${parts}` : ''}
          </>
        );
      }
      if (reps.length > 0) {
        return (
          <>
            {reps[0]} {reps[0] === '1' ? 'set' : 'sets'}
            {parts.length > 0 ? ` of ${parts}` : ''}
          </>
        );
      }
      return (
        <>
          {numberOfSets} {numberOfSets === 1 ? 'set' : 'sets'}
          {parts.length > 0 ? ` of ${parts}` : ''}
        </>
      );
    }

    if (index !== null) {
      return (
        <>
          Set {index + 1} - {parts}
        </>
      );
    }

    if (numberOfSets) {
      return (
        <>
          {numberOfSets} {numberOfSets === 1 ? 'set' : 'sets'} of {parts}
        </>
      );
    }

    return parts;
  };

  return (
    <Box sx={previewStyles.setRowWrapper}>
      <Typography
        sx={{
          ...previewStyles.sectionContent,
          ...previewStyles.setRowContent,
          ...(isForTime && previewStyles.forTimeSetRow),
        }}
      >
        {exerciseName && !isForTime && <span>{exerciseName}:</span>} {renderContent()}
      </Typography>
    </Box>
  );
};
