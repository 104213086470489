import { MarkerSeverity } from 'monaco-editor';

import { logger } from '../../Logger';
import { RestType } from '../../wodupParser/wodup.types';
import {
  AlternationContext,
  Any_workoutContext,
  DayContext,
  Option_blockContext,
  OptionContext,
  Shorthand_genericContext,
  Shorthand_fortimeContext,
  Shorthand_strengthContext,
  WodContext,
  Workout_attributeContext,
  Workout_commentContext,
  WorkoutContext,
  Shorthand_amrapContext,
  EmomContext,
  Rest_valueContext,
  Shorthand_everyContext,
  Rest_time_valueContext,
} from '../antlr/.antlr/UniversalWorkoutLanguageParser';
import { UniversalWorkoutLanguageParserVisitor } from '../antlr/.antlr/UniversalWorkoutLanguageParserVisitor';
import { UWLError } from '../UWLErrorHandler';
import { createParser } from '../UWLParserFacade';

import { workoutDefs } from './constants';
import {
  addDuplicateAttributeError,
  addMissingAttributeErrors,
  addUnrecognizedWorkoutError,
  validateAttribute,
  validateEmomBounds,
} from './errorHandlers';
import { Movement } from './Movement';
import { MovementParser } from './parsers/MovementParser';
import { Dict, AttributeMessageOverride } from './types';
import { normalizeName } from './utils';
import { Workout } from './Workout';

interface RestComponents {
  rest_type: string;
  rest_duration: string;
  cluster_rest_type: string;
  cluster_rest_duration: string;
}

export class UWLVisitor_a extends UniversalWorkoutLanguageParserVisitor<Workout[]> {
  private movementParser: MovementParser;
  errors: UWLError[] = [];

  constructor() {
    super();
    this.movementParser = new MovementParser(this.errors);
  }

  /*
   * Somewhat better performance, though I'm still not sure as to what's causing the issues
   * The parser itself does a decent job, and w/o the Visitor the performance drop is alost unnoticeable from just writing text
   * (The profiler claims <= 1ms per run, which seems to agree w/ the timers in code)
   * Maybe the visit() function is slow?
   */
  public GetDay(ctx: DayContext): Workout[][] {
    const d: Workout[][] = [];

    for (const element of ctx._wods) {
      const w = this.visit(element);
      if (w !== null) {
        d.push(w);
      }
    }
    return d;
  }

  /**
   * ParseDay
   */
  public ParseDay(content: string): Workout[][] {
    const parser = createParser(content);

    return this.GetDay(parser.day());
  }

  private prependName(w: Workout[], s: string): Workout[] {
    w.forEach((_, i: number, a: Workout[]): void => {
      a[i].name = s + a[i].name;
    });
    return w;
  }

  visitWod?: ((ctx: WodContext) => Workout[]) | undefined = (ctx) => {
    const w: Workout[] = [];

    logger.debug('WOD');

    for (let i = 0; i < ctx.getChildCount(); i++) {
      const component = this.visit(ctx.children[i]);
      if (component === null) {
        continue;
      }
      w.push(...component);
    }
    return w;
  };

  visitOption_block?: ((ctx: Option_blockContext) => Workout[]) | undefined = (ctx) => {
    let block_name: string = ctx.CHOICE_NAME()?.toString().trim() || '';

    if (block_name !== null) {
      block_name = block_name + ' - ';
    }

    const o: Workout[] = [];

    for (const opt of ctx._options) {
      const option = this.visit(opt);
      if (option) {
        o.push(...this.prependName(option, block_name));
      }
    }
    return o;
  };

  visitOption?: ((ctx: OptionContext) => Workout[]) | undefined = (ctx) => {
    const o: Workout[] = [];

    const option_title: string = ctx.CHOICE_NAME().getText().trim() + ' | ';

    for (let i = 0; i < ctx.children.length; i++) {
      const element: Workout[] | null = this.visit(ctx.children[i]);
      if (element == null) {
        continue;
      }
      o.push(...this.prependName(element, option_title));
    }
    return o;
  };

  visitAlternation?: ((ctx: AlternationContext) => Workout[]) | undefined = (ctx) => {
    const w: Workout[] = [];
    const alternationNumber = parseInt(ctx.NUMBER().getText());

    for (const workout of ctx._workouts) {
      const c = this.visit(workout);
      if (!c || c.length === 0) continue;

      c.forEach((workout: Workout): void => {
        if (workout) {
          workout.is_superset = true;
          workout.setsFromAlternation = alternationNumber;
        }
      });
      w.push(...c);
    }

    // Only set last workout's is_superset to false if we have workouts
    if (w.length > 0) {
      w[w.length - 1].is_superset = false;
    }

    return w;
  };

  visitAny_workout?: ((ctx: Any_workoutContext) => Workout[]) | undefined = (ctx) => {
    // any_workout is an alternation of possible workout syntaxes and will always have exactly one child
    logger.debug('Children ==>>', ctx.children);

    const result = this.visitChildren(ctx);
    return result ?? [];
  };

  private parseWorkoutMovements(
    ctx:
      | Shorthand_strengthContext
      // | Shorthand_fortimeContext
      | Shorthand_amrapContext
      | WorkoutContext
      | EmomContext
      | Shorthand_everyContext,
  ): Movement[] {
    return (
      ctx._movements
        ?.map((movement) => this.movementParser.parseMovement(movement))
        .filter((movement): movement is Movement => movement !== undefined) ?? []
    );
  }

  private parseRest(restValue: Rest_valueContext | null | undefined): RestComponents {
    if (!restValue) {
      return {
        rest_type: '',
        rest_duration: '',
        cluster_rest_type: '',
        cluster_rest_duration: '',
      };
    }

    // Initialize result with default values
    const result: RestComponents = {
      rest_type: '',
      rest_duration: '',
      cluster_rest_type: '',
      cluster_rest_duration: '',
    };

    // Parse primary rest value
    if (restValue.REST_AS_NEEDED()) {
      result.rest_type = RestType.AsNeeded;
    } else if (restValue.REST_SUPERSET()) {
      result.rest_type = RestType.Superset;
    } else if (restValue.emom_rest_value()) {
      result.rest_type = RestType.Emom;
      const emomRestValue = restValue.emom_rest_value();
      if (emomRestValue && emomRestValue.REST_NUMBER() && emomRestValue.REST_UNIT()) {
        const number = emomRestValue.REST_NUMBER();
        const unit = emomRestValue.REST_UNIT();
        result.rest_duration = this.convertTimeToSeconds(number.getText(), unit.getText());
      }
    } else if (restValue.ratio_rest_value()) {
      result.rest_type = RestType.Ratio;
      const ratioRestValue = restValue.ratio_rest_value();
      if (ratioRestValue && ratioRestValue.REST_NUMBER()) {
        result.rest_duration = `${ratioRestValue.REST_NUMBER()}`;
      }
    } else if (restValue.rest_time_value()) {
      result.rest_type = RestType.Fixed;
      const timeValue = restValue.rest_time_value();
      if (timeValue) {
        result.rest_duration = this.parseTimeValue(timeValue);
      }
    } else {
      // Default to "as needed" for standalone rest
      result.rest_type = RestType.AsNeeded;
    }

    // Parse secondary rest value
    const secondaryRestValue = restValue.secondary_rest_value();
    if (secondaryRestValue) {
      if (secondaryRestValue.REST_AS_NEEDED()) {
        result.cluster_rest_type = RestType.AsNeeded;
      } else if (secondaryRestValue.REST_SUPERSET()) {
        result.cluster_rest_type = RestType.Superset;
      } else if (secondaryRestValue.emom_rest_value()) {
        result.cluster_rest_type = RestType.Emom;
        const emomRestValue = secondaryRestValue.emom_rest_value();
        if (emomRestValue && emomRestValue.REST_NUMBER() && emomRestValue.REST_UNIT()) {
          const number = emomRestValue.REST_NUMBER();
          const unit = emomRestValue.REST_UNIT();
          result.cluster_rest_duration = this.convertTimeToSeconds(
            number.getText(),
            unit.getText(),
          );
        }
      } else if (secondaryRestValue.ratio_rest_value()) {
        result.cluster_rest_type = RestType.Ratio;
        const ratioRestValue = secondaryRestValue.ratio_rest_value();
        if (ratioRestValue && ratioRestValue.REST_NUMBER()) {
          result.cluster_rest_duration = `${ratioRestValue.REST_NUMBER()}`;
        }
      } else if (secondaryRestValue.rest_time_value()) {
        result.cluster_rest_type = RestType.Fixed;
        const timeValue = secondaryRestValue.rest_time_value();
        if (timeValue) {
          result.cluster_rest_duration = this.parseTimeValue(timeValue);
        }
      }
    }

    // Swap rest values if both are present
    return this.swapRestValuesIfNeeded(result);
  }

  private swapRestValuesIfNeeded(attrs: RestComponents): RestComponents {
    // If cluster rest type is not provided or is empty, return original values
    if (!attrs?.cluster_rest_type || attrs.cluster_rest_type.trim() === '') {
      return {
        rest_type: attrs?.rest_type ?? '',
        rest_duration: attrs?.rest_duration ?? '',
        cluster_rest_type: attrs?.cluster_rest_type ?? '',
        cluster_rest_duration: attrs?.cluster_rest_duration ?? '',
      };
    }

    // Swap the values
    return {
      rest_type: attrs.cluster_rest_type,
      rest_duration: attrs.cluster_rest_duration,
      cluster_rest_type: attrs.rest_type,
      cluster_rest_duration: attrs.rest_duration,
    };
  }

  private parseTimeValue(timeValue: Rest_time_valueContext): string {
    const number = timeValue.REST_NUMBER();
    const unit = timeValue.REST_UNIT();
    if (number && unit) {
      return this.convertTimeToSeconds(number.getText(), unit.getText());
    }
    return '';
  }

  private convertTimeToSeconds(duration: string, unit: string): string {
    const unitText = unit.toLowerCase();
    const isMinutes = ['min', 'mins', 'minutes', 'm'].includes(unitText);
    return isMinutes ? (parseFloat(duration) * 60).toString() : duration;
  }

  visitShorthand_strength?: ((ctx: Shorthand_strengthContext) => Workout[]) | undefined = (ctx) => {
    if (!ctx) {
      logger.warn('Received undefined context in visitShorthand_strength');
      return [];
    }

    logger.debug('sh Strength');

    // Get the first rest value if it exists
    const restComponents = this.parseRest(ctx.rest_value(0));

    const attrs: Dict<string> = {
      rest_type: restComponents.rest_type,
      rest_duration: restComponents.rest_duration,
      cluster_rest_type: restComponents.cluster_rest_type,
      cluster_rest_duration: restComponents.cluster_rest_duration,
    };

    return [
      this.createWorkoutInstance(
        '', // name
        'strength',
        ctx.getText() ?? '',
        this.parseComments(ctx._comments),
        attrs,
        this.parseWorkoutMovements(ctx),
      ),
    ];
  };

  private createStrengthWorkout(data: {
    movements: Movement[];
    rest: string;
    comments: string;
    text: string;
  }): Workout {
    const attrs: Dict<string> = { rest: data.rest };
    return this.createWorkoutInstance(
      '', // name
      'strength',
      data.text,
      data.comments,
      attrs,
      data.movements,
    );
  }

  visitShorthand_generic?: ((ctx: Shorthand_genericContext) => Workout[]) | undefined = (ctx) => {
    const name = ctx.STRING()?.getText().trim() ?? '';

    const override: AttributeMessageOverride = {
      name: 'description',
      suppressOtherErrors: true,
      severity: MarkerSeverity.Warning,
      message: 'Description already provided, this will be ignored',
    };

    const [attrs, errors] = this.parseWorkoutAttrs(ctx._attrs, 'generic', [], [override]);

    attrs['description'] = ctx.GENERIC_STRING().getText().replace(/`/g, '');

    this.errors.push(...errors);

    const movements = ctx._movements.map((movement) => this.movementParser.parseMovement(movement));
    const comments = this.parseComments(ctx._comments);

    return [this.createWorkoutInstance(name, 'generic', ctx.getText(), comments, attrs, movements)];
  };

  visitWorkout?: ((ctx: WorkoutContext) => Workout[]) | undefined = (ctx) => {
    const name: string | null = ctx.WORKOUT_STRING()?.getText().trim() || null;
    const type: string = ctx.workout_iden().WORKOUT_IDENTIFIER().getText().trim();

    const workout_attr_list = Object.keys(workoutDefs[normalizeName(type)] ?? {});

    const [attrs, errors] = this.parseWorkoutAttrs(ctx._attrs, type, workout_attr_list);

    addMissingAttributeErrors(ctx, workout_attr_list, normalizeName(type), errors);

    this.errors.push(...errors);

    const m: Movement[] = [];
    for (const mo of ctx._movements) {
      m.push(this.movementParser.parseMovement(mo));
    }

    if (!(normalizeName(type) in workoutDefs)) {
      addUnrecognizedWorkoutError(ctx, type, this.errors);
    }

    const comments = this.parseComments(ctx._comments);

    return [this.createWorkoutInstance(name, type, ctx.getText(), comments, attrs, m)];
  };

  private parseComments(ctx: Workout_commentContext[] | undefined): string {
    if (!ctx) return '';

    let text = '';
    for (const c of ctx) {
      text += c.getText();
      text += '\n';
    }
    return text.trimEnd();
  }

  private parseWorkoutAttrs(
    ctx: Workout_attributeContext[],
    type: string,
    workout_attr_list: string[],
    overrides: AttributeMessageOverride[] | null = null,
  ): [Dict<string>, UWLError[]] {
    const d: Dict<string> = {};
    const e: UWLError[] = [];

    const norm_type = normalizeName(type);

    const existing_attrs: string[] = [];

    for (const attr of ctx) {
      const name: string = attr.workout_attr_id().getText().trim();
      const val: string = attr.workout_attr_val().getText().trim();

      const wati = workout_attr_list.indexOf(name);

      if (wati !== -1) {
        workout_attr_list.splice(wati, 1);
      }

      if (existing_attrs.indexOf(name) === -1) {
        d[name] = val;
        existing_attrs.push(name);
      } else {
        addDuplicateAttributeError(attr, name, e);
      }

      validateAttribute(name, norm_type, val, attr, e, overrides);
    }

    return [d, e];
  }

  protected createWorkoutInstance(
    name: string | null,
    type: string,
    rawText: string,
    comments: string,
    attributes: Dict<string>,
    movements: Movement[],
  ): Workout {
    return new Workout(name, type, rawText, comments, attributes, movements);
  }

  // Fortime
  visitShorthand_fortime?: ((ctx: Shorthand_fortimeContext) => Workout[]) | undefined = (ctx) => {
    if (!ctx) {
      return [];
    }
    const fortimeWorkout = this.parseForTimeWorkoutComponents(ctx);
    return [this.createForTimeWorkout(fortimeWorkout)];
  };

  private parseForTimeWorkoutComponents(ctx: Shorthand_fortimeContext) {
    // Parse rep sequence if present
    const repSequence = ctx._rep_sequence?.text
      ? ctx._rep_sequence.text.split('-').filter((s) => s.length > 0)
      : undefined;

    const intermediateMovements =
      ctx.intermediate_movement()?.map((im) => {
        const movement = this.movementParser.parseMovement(im);
        const restComponents = this.parseRest(im.rest_value());
        movement.restAfter_type = restComponents.rest_type;
        movement.restAfter_duration = restComponents.rest_duration
          ? Number(restComponents.rest_duration)
          : undefined;
        // If rep sequence exists, override the reps in attributes
        if (repSequence) {
          movement.attributes.reps = repSequence;
        }
        return movement;
      }) ?? [];

    const finalMovementCtx = ctx.final_movement();
    let finalMovement: Movement | undefined;
    let componentRest;
    let roundRest;

    if (finalMovementCtx) {
      finalMovement = this.movementParser.parseMovement(finalMovementCtx);
      // If rep sequence exists, override the reps in attributes for final movement too
      if (repSequence && finalMovement) {
        finalMovement.attributes.reps = repSequence;
      }
      componentRest = this.parseRest(finalMovementCtx._component_rest);
      roundRest = this.parseRest(finalMovementCtx._round_rest);
    }

    const movements = [...intermediateMovements, ...(finalMovement ? [finalMovement] : [])];

    const roundsFromText = ctx._rounds_num?.text || ctx._sets_num?.text;
    const rounds = roundsFromText
      ? roundsFromText
      : repSequence
        ? repSequence.length.toString()
        : undefined;

    return {
      rounds,
      movements,
      componentRest,
      roundRest,
      comments: this.parseComments(ctx._comments),
      text: ctx.getText() ?? '',
    };
  }

  private createForTimeWorkout(data: {
    rounds?: string;
    movements: Movement[];
    componentRest?: { rest_type: string; rest_duration: string };
    roundRest?: { rest_type: string; rest_duration: string };
    comments: string;
    text: string;
  }): Workout {
    const attrs: Dict<string> = {
      rounds: data.rounds ?? '1',
      component_rest_type: data.componentRest?.rest_type ?? '',
      component_rest_duration: data.componentRest?.rest_duration ?? '',
      round_rest_type: data.roundRest?.rest_type ?? '',
      round_rest_duration: data.roundRest?.rest_duration ?? '',
    };

    return this.createWorkoutInstance(
      '', // name
      'ForTime',
      data.text,
      data.comments,
      attrs,
      data.movements,
    );
  }

  visitShorthand_amrap?: ((ctx: Shorthand_amrapContext) => Workout[]) | undefined = (ctx) => {
    if (!ctx) {
      return [];
    }
    const amrapWorkout = this.parseAmrapWorkoutComponents(ctx);
    return [this.createAmrapWorkout(amrapWorkout)];
  };

  private parseAmrapWorkoutComponents(ctx: Shorthand_amrapContext) {
    return {
      duration: ctx.NUMBER().getText(),
      duration_unit: ctx.TIME_UNIT().getText().trim(),
      movements: this.parseWorkoutMovements(ctx),
      comments: this.parseComments(ctx._comments),
      text: ctx.getText() ?? '',
    };
  }

  private createAmrapWorkout(data: {
    duration: string;
    duration_unit: string;
    movements: Movement[];
    comments: string;
    text: string;
  }): Workout {
    const attrs: Dict<string> = {
      duration: data.duration,
      duration_unit: data.duration_unit,
    };

    return this.createWorkoutInstance(
      '', // name
      'Amrap',
      data.text,
      data.comments,
      attrs,
      data.movements,
    );
  }

  visitEmom?: ((ctx: EmomContext) => Workout[]) | undefined = (ctx) => {
    // Note: Right now we are under the assumption that there is only one movement per minute
    // If this changes we will likely need to add a new attribute

    const errors: UWLError[] = [];

    const emom_low_bound: number | null =
      ctx._emom_low_bound?.text !== undefined ? Number.parseInt(ctx._emom_low_bound.text) : null;
    const emom_high_bound: number | null =
      ctx._emom_high_bound?.text !== undefined ? Number.parseInt(ctx._emom_high_bound.text) : null;
    logger.debug('EMOM::', emom_low_bound, '--', emom_high_bound);

    const attrs: Dict<string> = {
      emom_low_bound: ctx._emom_low_bound?.text ?? '',
      emom_high_bound: ctx._emom_high_bound?.text ?? '',
    };

    const w = new Workout(
      null,
      'emom',
      ctx.getText() ?? '',
      this.parseComments(ctx._comments),
      attrs,
      this.parseWorkoutMovements(ctx),
    );

    const no_mins = w.movements.length;

    validateEmomBounds(no_mins, ctx._emom_low_bound, ctx._emom_high_bound, errors);

    this.errors.push(...errors);

    logger.debug(w);

    return [w];
  };

  visitShorthand_every?: ((ctx: Shorthand_everyContext) => Workout[]) | undefined = (ctx) => {
    if (!ctx) {
      return [];
    }

    // Convert time to seconds if unit is minutes
    const timeValue = ctx._time_value?.text ?? '';
    const timeUnit = ctx._time_unit?.text?.toLowerCase() ?? '';
    const timeInSeconds = timeUnit.startsWith('m')
      ? (parseFloat(timeValue) * 60).toString()
      : timeValue;

    const attrs: Dict<string> = {
      time_value: timeInSeconds,
      sets: ctx._sets_every?.text ?? '',
      goal: ctx._goal?.text ?? '',
    };

    return [
      this.createWorkoutInstance(
        '', // name
        'Every',
        ctx.getText() ?? '',
        this.parseComments(ctx._comments),
        attrs,
        this.parseWorkoutMovements(ctx),
      ),
    ];
  };
}
