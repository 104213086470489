import { Box, Paper, Typography } from '@mui/material';
import React from 'react';

import WodUpPreview from '../../../WodUpPreview/WodUpPreview';

import { styles } from './PreviewSection.styles';
import { PreviewSectionProps } from './PreviewSection.types';

export const PreviewSection: React.FC<PreviewSectionProps> = ({
  Uwlcontent,
  content,
  onContentChange,
}) => (
  <Paper elevation={3} sx={styles.previewPaper}>
    <Typography variant="body1" sx={styles.sectionTitle}>
      Preview
    </Typography>
    <Box sx={styles.previewBox}>
      <WodUpPreview Uwlcontent={Uwlcontent} content={content} onContentChange={onContentChange} />
    </Box>
  </Paper>
);
