import { Typography, Box, Paper, Grid } from '@mui/material';
import React, { useState, useRef } from 'react';

import ExpandedViewDialog from '../ExpandedViewDialog/ExpandedViewDialog';

import { headerStyles, monacoMimicStyles, paperStyles, monacoContentStyles } from './Day.styles';
import { DayProps, DayCellContentProps } from './Day.types';

const DayHeader: React.FC<DayProps> = ({ weekNumber, dayNumber, isFirstInRow }) => (
  <Box sx={headerStyles.wrapper}>
    <Typography variant="subtitle2" sx={headerStyles.text}>
      {isFirstInRow ? `W ${weekNumber}` : ''}
    </Typography>
    <Typography variant="subtitle2" sx={headerStyles.dayText}>
      Day {dayNumber}
    </Typography>
  </Box>
);

const DayCellContent: React.FC<DayCellContentProps> = ({ content, onClick }) => {
  const handleClick = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const startOffset = range.startOffset;
      onClick(startOffset);
    }
  };

  return (
    <Box sx={monacoContentStyles.wrapper} onClick={handleClick}>
      <pre style={monacoMimicStyles}>{content}</pre>
    </Box>
  );
};

const Day: React.FC<DayProps> = ({ initialContent = '', onContentChange, ...props }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);
  const [content, setContent] = useState(initialContent);
  const dayRef = useRef<HTMLDivElement>(null);

  const handleDialogOpen = (position: number) => {
    setCursorPosition(position);
    setIsDialogOpen(true);
  };

  const handleDialogClose = (updatedContent: string) => {
    setContent(updatedContent);
    if (onContentChange) {
      onContentChange(updatedContent);
    }
    setIsDialogOpen(false);
  };

  return (
    <Grid item xs={12} sm={6} md={1.7142} ref={dayRef}>
      <Paper elevation={0} sx={paperStyles.root}>
        <DayHeader {...props} />
        <DayCellContent content={content} onClick={handleDialogOpen} />
      </Paper>
      <ExpandedViewDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        weekNumber={props.weekNumber}
        dayNumber={props.dayNumber}
        initialCursorPosition={cursorPosition}
        initialContent={content}
        monacoSettings={props.monacoSettings}
      />
    </Grid>
  );
};

export default Day;
