export const styles = {
  previewPaper: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    padding: '4px',
    border: '1px solid #ccc',
    borderRadius: '0px',
    backgroundColor: '#f5f5f5',
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  editorBox: {
    flex: 1,
    overflow: 'hidden',
    marginTop: '10px',
  },
  previewBox: {
    width: '100%',
    flex: 1,
    overflow: 'auto',
    marginTop: '10px',
    backgroundColor: '#f5f5f5',
  },
} as const;
