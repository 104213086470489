import { useMemo } from 'react';

import { Movement } from '../../../../@monaco-uwl/UWLVisitor/Movement';

import { areMovementsRepsEqual } from './repUtils';

type MovementCalculation = {
  hasSets: boolean;
  allSetsEqual: boolean;
  hasUniformTempo: boolean;
};

/**
 * useMovementCalculations accepts an extra flag (isForTime) so that the logic for ignoring
 * reps in equality calculations is only applied in For Time workouts.
 *
 * When isForTime === true and all movements have identical reps,
 * the reps attribute will be excluded from the equality check.
 */
export const useMovementCalculations = (
  movements: Movement | Movement[] | undefined,
  isForTime = false,
): MovementCalculation | MovementCalculation[] => {
  return useMemo(() => {
    if (!movements) return [];

    // Only for For Time workouts, if all movements have identical reps, ignore reps in the equality check.
    const ignoreReps =
      isForTime && Array.isArray(movements) ? areMovementsRepsEqual(movements) : false;

    const calculateForMovement = (movement: Movement): MovementCalculation => {
      const attributes = movement?.attributes || {};
      const {
        reps = [],
        load = [],
        load_female = [],
        tempo = [],
        duration = [],
        distance = [],
        calories = [],
        power = [],
        height = [],
      } = attributes;

      const hasSets = [
        reps,
        load,
        load_female,
        tempo,
        duration,
        distance,
        calories,
        power,
        height,
      ].some((arr) => arr.length > 0);

      // Build a set of arrays for comparison.
      // Exclude reps if we're in For Time mode and all reps are identical.
      const comparisonAttributes: Record<string, (string | number)[]> = {
        load,
        load_female,
        tempo,
        duration,
        distance,
        calories,
        power,
        height,
      };
      if (!ignoreReps) {
        comparisonAttributes.reps = reps;
      }

      const allSetsEqual = areAllSetsEqual(comparisonAttributes);
      const hasUniformTempo = tempo.length > 0 && areArrayValuesEqual(tempo);

      return {
        hasSets,
        allSetsEqual,
        hasUniformTempo,
      };
    };

    return Array.isArray(movements)
      ? movements.map(calculateForMovement)
      : calculateForMovement(movements);
  }, [movements, isForTime]);
};

export const areArrayValuesEqual = (arr: (string | number)[]): boolean =>
  arr.length > 0 && arr.every((val) => val === arr[0]);

export const areAllSetsEqual = (attributes: Record<string, (string | number)[]>): boolean => {
  const nonEmptyArrays = Object.values(attributes).filter((arr) => arr && arr.length > 0);
  if (nonEmptyArrays.length === 0) return true;
  return nonEmptyArrays.every((arr) => areArrayValuesEqual(arr));
};
