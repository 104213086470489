export const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 2,
  },
  weekManagerContainer: {
    mt: 2,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    mt: 2,
  },
  addCycleButton: {
    minWidth: 180,
    height: 65,
    backgroundColor: '#203F00',
    borderRadius: '5px',
    fontSize: '30px',
    fontFamily: '"Bebas Neue", sans-serif',
    marginLeft: 'auto',
    marginBottom: 2,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#2a5400',
    },
  },
};
