export const styles = {
  debugWindow: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: '300px',
    height: '100%',
    backgroundColor: '#f5f5f5',
    padding: '20px',
    boxShadow: '-2px 0 5px rgba(0,0,0,0.1)',
    overflowY: 'auto',
    zIndex: 1000,
  },
  debugOutput: {
    whiteSpace: 'pre-wrap',
    fontFamily: 'monospace',
    fontSize: '12px',
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 2,
  },
  closeButton: {
    padding: '4px',
  },
} as const;
