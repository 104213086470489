import { Box, Typography } from '@mui/material';
import React from 'react';

import { Movement } from '../../../../@monaco-uwl/UWLVisitor/Movement';
import { SessionType } from '../../../../generalParser/parser.types';
import { styles } from '../../WodUpPreview.styles';
import { MovementDefaults } from '../../WodUpPreview.types';
import { SetRow } from '../SetRow/SetRow';
import { formatSecondsToTime } from '../utils/timeUtils';

import { AmrapPreviewProps } from './AmrapPreview.types';

const getMovementName = (movement: Movement): string => {
  if (!movement?.names?.length) return MovementDefaults.UNNAMED_MOVEMENT;
  return movement.names.join(' + ');
};

export const AmrapPreview: React.FC<AmrapPreviewProps> = ({ component, sessionType }) => {
  const renderMovement = (movement: Movement, index: number) => {
    const movementName = getMovementName(movement);

    return (
      <Box key={index} sx={styles.movementItem}>
        <SetRow index={null} exerciseName={movementName} attributes={movement.attributes} />
      </Box>
    );
  };

  return (
    <Box
      sx={{
        ...styles.genericComponent,
        ...(sessionType === SessionType.AM ? styles.amComponent : styles.pmComponent),
      }}
    >
      <Box sx={styles.genericHeaderWrapper}>
        <Box sx={sessionType === SessionType.AM ? styles.headerLineAM : styles.headerLinePM} />
        <Typography sx={styles.genericHeader}>
          {`${component.prefix || ''}${component.prefix ? '. ' : ''}AMRAP in ${formatSecondsToTime(component.duration)}`}
        </Typography>
      </Box>

      <Box sx={styles.contentWrapper}>
        <Typography sx={styles.sectionTitle}>Movements:</Typography>
        <Box sx={styles.sectionContent}>
          {component.movements && component.movements.length > 0 ? (
            component.movements.map(renderMovement)
          ) : (
            <Typography sx={styles.noMovements}>No movements specified</Typography>
          )}
        </Box>

        <Typography sx={styles.sectionTitle}>Notes</Typography>
        <Typography sx={styles.sectionContent}>
          {component.notes || 'No additional notes'}
        </Typography>
      </Box>
    </Box>
  );
};
