import { logger } from '../../Logger';

import { WorkoutAttrTypes } from './types';

export function typeCheck(value: string, type: WorkoutAttrTypes): boolean {
  switch (type & ~WorkoutAttrTypes.Optional) {
    case WorkoutAttrTypes.Number:
      return !isNaN(Number(value));
    case WorkoutAttrTypes.String:
      return value[0] === '"' && value[value.length - 1] === '"';
    case WorkoutAttrTypes.Time:
      const split = value.split(':');
      return split.length === 2 && !isNaN(Number(split[0])) && !isNaN(Number(split[1]));
    default:
      return true;
  }
}

export function normalizeName(name: string): string {
  return name.replace(' ', '_').toLowerCase();
}

function jaroWinklerDistance(s1: string, s2: string): number {
  const m = new Map();
  const s1Len = s1.length;
  const s2Len = s2.length;
  const matchDistance = Math.floor(Math.max(s1Len, s2Len) / 2) - 1;

  let matches = 0;
  let transpositions = 0;
  let prefix = 0;

  const s1Matches = Array(s1Len).fill(false);
  const s2Matches = Array(s2Len).fill(false);

  for (let i = 0; i < s1Len; i++) {
    const start = Math.max(0, i - matchDistance);
    const end = Math.min(i + matchDistance + 1, s2Len);

    for (let j = start; j < end; j++) {
      if (s2Matches[j]) continue;
      if (s1[i] !== s2[j]) continue;
      s1Matches[i] = true;
      s2Matches[j] = true;
      matches++;
      break;
    }
  }

  if (matches === 0) return 0;

  let k = 0;
  for (let i = 0; i < s1Len; i++) {
    if (!s1Matches[i]) continue;
    while (!s2Matches[k]) k++;
    if (s1[i] !== s2[k]) transpositions++;
    k++;
  }

  transpositions /= 2;

  for (let i = 0; i < Math.min(4, s1Len, s2Len); i++) {
    if (s1[i] === s2[i]) prefix++;
    else break;
  }

  const jaro = (matches / s1Len + matches / s2Len + (matches - transpositions) / matches) / 3;
  const jaroWinkler = jaro + prefix * 0.1 * (1 - jaro);

  return jaroWinkler;
}

const closestNameCache = new Map<string, string>();

export function getClosestMovementName(inputName: string, availableMovements: string[]): string {
  const cachedResult = closestNameCache.get(inputName);
  if (cachedResult !== undefined) {
    return cachedResult;
  }

  let closestName = '';
  let maxSimilarity = -1;

  for (const movement of availableMovements) {
    const similarity = jaroWinklerDistance(inputName.toLowerCase(), movement.toLowerCase());
    if (similarity > maxSimilarity) {
      maxSimilarity = similarity;
      closestName = movement;
    }
  }

  closestNameCache.set(inputName, closestName);

  return closestName;
}
