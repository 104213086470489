import { Movement } from '../../../../@monaco-uwl/UWLVisitor/Movement';

export const areMovementsRepsEqual = (movements: Movement[]): boolean => {
  if (!movements || movements.length === 0) return false;

  const baselineReps = movements[0].attributes?.reps;
  if (!baselineReps || baselineReps.length === 0) return false;

  return movements.every((movement) => {
    const currentReps = movement.attributes?.reps;

    if (!currentReps || currentReps.length !== baselineReps.length) return false;

    for (let i = 0; i < baselineReps.length; i++) {
      if (currentReps[i] !== baselineReps[i]) return false;
    }
    return true;
  });
};
