import api from '../api/base';
import { logger } from '../Logger';
import { ProgramExtended, ProgramData } from '../types/program';

const BASE_PATH = '/api/programs';

export const programService = {
  async getPrograms(): Promise<ProgramExtended[]> {
    try {
      const response = await api.get(BASE_PATH);
      logger.debug('Fetched programs:', response.data);
      return response.data;
    } catch (error) {
      logger.error('Error fetching programs:', error);
      throw error;
    }
  },

  async createProgram(programData: ProgramData): Promise<ProgramExtended> {
    try {
      const response = await api.post(BASE_PATH, programData);
      logger.debug('Created program:', response.data);
      return response.data;
    } catch (error) {
      logger.error('Error creating program:', error);
      throw error;
    }
  },

  async updateProgram(id: number, programData: ProgramData): Promise<ProgramExtended> {
    try {
      const response = await api.put(`${BASE_PATH}/${id}`, programData);
      logger.debug('Updated program:', response.data);
      return response.data;
    } catch (error) {
      logger.error('Error updating program:', error);
      throw error;
    }
  },

  async deleteProgram(id: number): Promise<void> {
    try {
      await api.delete(`${BASE_PATH}/${id}`);
      logger.debug('Deleted program:', id);
    } catch (error) {
      logger.error('Error deleting program:', error);
      throw error;
    }
  },
};
