import axios, { AxiosError } from 'axios';

interface LoginResponse {
  code: number;
  access_token: string;
  error?: string;
}

interface RegistrationData {
  username: string;
  email: string;
  password: string;
}

interface RegistrationResponse {
  code: number;
  error?: string;
}

export class AuthService {
  private baseUrl: string;

  constructor() {
    this.baseUrl = process.env.REACT_APP_SERVER_URL || '';
  }

  async login(username: string, password: string): Promise<string> {
    try {
      const response = await axios.post<LoginResponse>(`${this.baseUrl}/api/login`, {
        username,
        password,
      });

      if (response.data.code === 200) {
        return response.data.access_token;
      }
      throw new Error(response.data.error || 'Login failed');
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 401) {
        throw new Error('Invalid username or password');
      }
      throw new Error('An unexpected error occurred. Please try again.');
    }
  }

  async register(data: RegistrationData): Promise<void> {
    try {
      const response = await axios.post<RegistrationResponse>(`${this.baseUrl}/api/register`, data);

      if (response.data.code !== 201) {
        throw new Error(response.data.error || 'Registration failed');
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 409) {
          throw new Error('Username or email already exists');
        }
        if (error.response?.data?.error) {
          throw new Error(error.response.data.error);
        }
      }
      throw new Error('Registration failed. Please try again.');
    }
  }
}

export const authService = new AuthService();
