import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, TextField, Button } from '@mui/material';
import { useState } from 'react';

import { styles } from './WeekManager.styles';
import { WeekManagerProps } from './WeekManager.types';

export const WeekManager: React.FC<WeekManagerProps> = ({ onWeeksChange }) => {
  const [weeksInput, setWeeksInput] = useState(1);
  const weekText = weeksInput === 1 ? 'WEEK' : 'WEEKS';

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.innerBox}>
        <TextField
          size="medium"
          type="number"
          value={weeksInput}
          onChange={(e) => setWeeksInput(Math.max(1, parseInt(e.target.value) || 1))}
          inputProps={{
            min: '1',
            style: styles.textField,
          }}
        />
        <Box sx={styles.buttonContainer}>
          <Button
            startIcon={<AddIcon sx={{ fontSize: 20 }} />}
            onClick={() => onWeeksChange(weeksInput)}
            sx={styles.addButton}
          >
            ADD {weekText}
          </Button>
          <Button
            startIcon={<RemoveIcon sx={{ fontSize: 20 }} />}
            onClick={() => onWeeksChange(-weeksInput)}
            sx={styles.removeButton}
          >
            REMOVE {weekText}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
