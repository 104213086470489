import { Box } from '@mui/material';
import React from 'react';

import { parseUWLContent } from '../../generalParser/sessionParser';
import { useComponentsDragDrop } from '../../hooks/useComponentsDragDrop';
import { logger } from '../../Logger';

import { SessionPreview } from './components/SessionPreview';
import { styles } from './WodUpPreview.styles';
import { WodUpPreviewProps } from './WodUpPreview.types';

const WodUpPreview: React.FC<WodUpPreviewProps> = ({ Uwlcontent, content, onContentChange }) => {
  logger.debug('=>> Uwlcontent', JSON.parse(JSON.stringify(Uwlcontent)));
  const parsedUWL = parseUWLContent(Uwlcontent);
  logger.debug('=>> parsedUWL', parsedUWL);

  const handleComponentsChange = useComponentsDragDrop({ content, onContentChange });

  return (
    <Box sx={styles.container}>
      {parsedUWL.map((session, index) => (
        <SessionPreview key={index} session={session} onComponentsChange={handleComponentsChange} />
      ))}
    </Box>
  );
};

export default WodUpPreview;
