import { Workout } from '../@monaco-uwl/UWLVisitor/Workout';

import { AmrapComponent } from './parser.types';
import { normalizeUnit, prepareNotes } from './utils';

export function parseUWLAmrap(w: Workout, prefix: string): AmrapComponent {
  // Normalize units for each movement
  w.movements.forEach((movement) => normalizeUnit(movement));

  return {
    type: 'Amrap',
    is_superset: w.is_superset,
    should_be_inside_superset: false,
    prefix: prefix,
    duration: parseDurationToSeconds(w.attrs.duration, w.attrs.duration_unit),
    duration_unit: w.attrs.duration_unit,
    name: '',
    movements: w.movements,
    notes: prepareNotes(w.notes),
  };
}

export function parseDurationToSeconds(duration: string, durationUnit: string): number {
  const durationNumber = parseInt(duration);
  const normalizedUnit = durationUnit.toLowerCase();

  if (normalizedUnit === 'min' || normalizedUnit === 'm') {
    return durationNumber * 60;
  }

  if (normalizedUnit === 'sec' || normalizedUnit === 's') {
    return durationNumber;
  }

  return durationNumber;
}
