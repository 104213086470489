import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { Box, Typography } from '@mui/material';
import React from 'react';

import { SessionType } from '../../../../generalParser/parser.types';
import { RestType } from '../../../../wodupParser/wodup.types';
import { styles } from '../../WodUpPreview.styles';
import { SetRow } from '../SetRow/SetRow';
import { getMovementName } from '../utils/nameUtils';
import { formatRestType } from '../utils/restUtils';
import { useMovementCalculations } from '../utils/useMovementsCalculations';

import { StrengthPreviewProps } from './StrengthPreview.types';

export const StrengthPreview: React.FC<StrengthPreviewProps> = ({ component, sessionType }) => {
  const { movement } = component;
  const calculations = useMovementCalculations(movement);
  const { allSetsEqual, hasUniformTempo } = Array.isArray(calculations)
    ? calculations[0]
    : calculations;

  const renderSets = () => {
    const { numberOfSets } = component;
    const baseProps = {
      numberOfSets,
      attributes: movement.attributes,
      isComplex: movement.isComplex,
    };

    return allSetsEqual ? (
      <SetRow key={0} index={null} {...baseProps} />
    ) : (
      [...Array(numberOfSets)].map((_, index) => (
        <SetRow key={index} index={index} {...baseProps} />
      ))
    );
  };

  const movementName = getMovementName(
    movement,
    hasUniformTempo,
    movement?.attributes?.tempo || [],
  );

  const headerText = `${component.prefix || ''}${component.prefix ? '. ' : ''}${
    component.name ? `${component.name} ${movementName}` : movementName
  }`.replace(/"/g, '');

  return (
    <Box
      sx={{
        ...styles.genericComponent,
        ...(sessionType === SessionType.AM ? styles.amComponent : styles.pmComponent),
      }}
    >
      <Box sx={styles.genericHeaderWrapper}>
        <Box sx={sessionType === SessionType.AM ? styles.headerLineAM : styles.headerLinePM} />
        <Typography sx={styles.genericHeader}>
          {headerText}
          <FitnessCenterIcon sx={styles.strengthIcon} />
        </Typography>
      </Box>

      <Box sx={styles.contentWrapper}>
        <Typography sx={styles.sectionTitle}>
          Sets
          {movement.attributes.sets &&
          typeof movement.attributes.sets === 'string' &&
          movement.attributes.sets.includes('-')
            ? ` (${movement.attributes.sets})`
            : ''}
          :
        </Typography>
        {renderSets()}

        <Typography sx={styles.sectionTitle}>Rest Between Sets:</Typography>
        <Typography sx={styles.sectionContent}>
          {formatRestType(component.rest_type as RestType, component.rest_duration)}
        </Typography>

        {(component.cluster_rest_type || component.cluster_rest_duration) && (
          <>
            <Typography sx={styles.sectionTitle}>Rest between cluster reps:</Typography>
            <Typography sx={styles.sectionContent}>
              {formatRestType(
                component.cluster_rest_type as RestType,
                component.cluster_rest_duration,
              )}
            </Typography>
          </>
        )}

        <Typography sx={styles.sectionTitle}>Notes</Typography>
        <Typography sx={styles.sectionContent}>
          {component.notes || 'No additional notes'}
        </Typography>
      </Box>
    </Box>
  );
};
