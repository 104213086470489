import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { useMonacoSettings } from '../hooks/useMonacoSettings';
import { logger } from '../Logger';
import { userSettingsService } from '../services/settingsService';
import { DEFAULT_MONACO_SETTINGS, UserSettings } from '../types/settings';

const styles = {
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 4,
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    mt: 2,
  },
  sectionHeaderBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 2,
  },
};

export const Settings: React.FC = () => {
  const { monacoSettings, updateUserSettings } = useMonacoSettings(DEFAULT_MONACO_SETTINGS);
  const [isLoading, setIsLoading] = useState(false);

  const handleSettingsUpdate = async (settings: Partial<UserSettings>) => {
    setIsLoading(true);
    try {
      const updatedSettings = {
        ...monacoSettings,
        ...settings,
      };
      await userSettingsService.setUserSettings(updatedSettings);
      updateUserSettings(settings);
    } catch (error: unknown) {
      if (error instanceof Error) {
        logger.error('Error updating settings:', error.message);
      } else {
        logger.error('Error updating settings:', error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSettings = useCallback(async () => {
    setIsLoading(true);
    try {
      const userSettings = await userSettingsService.fetchUserSettings();
      updateUserSettings(userSettings);
    } catch (error: unknown) {
      if (error instanceof Error) {
        logger.error('Error fetching settings:', error.message);
      } else {
        logger.error('Error fetching settings:', error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [updateUserSettings]);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Box>
        <Typography variant="h4" fontWeight="bold" sx={styles.headerBox}>
          Settings
        </Typography>
      </Box>

      <Box>
        <Typography variant="h5" sx={styles.sectionHeaderBox}>
          Editor settings
        </Typography>
      </Box>

      <FormControlLabel
        label="Enable hover"
        control={
          <Checkbox
            checked={monacoSettings.enableHover}
            inputProps={{ 'aria-label': 'controlled' }}
            onChange={(e): void => {
              handleSettingsUpdate({ enableHover: e.target.checked });
            }}
          />
        }
      />

      {isLoading && (
        <Box sx={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
};

export default Settings;
