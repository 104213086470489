import { Typography, Box, Container, Button } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Cycle } from '../../components/Cycle/Cycle';
import { useCycle } from '../../hooks/useCycle';
import { useMonacoSettings } from '../../hooks/useMonacoSettings';
import { logger } from '../../Logger';
import { programService } from '../../services/programService';
import { programSettingsService, userSettingsService } from '../../services/settingsService';
import { userPreferencesService } from '../../services/userPreferencesService';
import { DEFAULT_MONACO_SETTINGS } from '../../types/settings';

import { styles } from './WorkoutPlanner.styles';

function WorkoutPlanner() {
  const { programId } = useParams<{ programId: string }>();
  const { monacoSettings, setMonacoSettings } = useMonacoSettings(DEFAULT_MONACO_SETTINGS);
  const [isLoading, setIsLoading] = useState(false);
  const [openCycles, setOpenCycles] = useState<number[]>([]);
  const [programName, setProgramName] = useState('');
  const {
    cycles,
    cycleOperations,
    addCycle,
    updateCycleName,
    removeCycle,
    fetchProgramCycles,
    updateCycleOrder,
  } = useCycle(programId || '');

  const fetchProgramDetails = useCallback(async (id: string) => {
    try {
      const programs = await programService.getPrograms();
      const program = programs.find((p) => p.id === parseInt(id, 10));
      if (program) {
        setProgramName(program.name);
      }
    } catch (error) {
      logger.error('Error fetching program details:', error);
    }
  }, []);

  const fetchSettings = useCallback(
    async (program_id: number) => {
      setIsLoading(true);
      try {
        const userSettings = await userSettingsService.fetchUserSettings();
        const programSettings = await programSettingsService.fetchProgramSettings(program_id);
        setMonacoSettings({ ...userSettings, ...programSettings });
      } catch (error: unknown) {
        if (error instanceof Error) {
          logger.error('Error fetching settings:', error.message);
        } else {
          logger.error('Error fetching settings:', error);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [setMonacoSettings],
  );

  useEffect(() => {
    if (programId) {
      fetchProgramCycles(programId);
      fetchProgramDetails(programId);

      const programIdNum = parseInt(programId, 10);
      if (!isNaN(programIdNum)) {
        fetchSettings(programIdNum);
      }

      const cyclesForProgram = userPreferencesService.getOpenCycles(programId);
      setOpenCycles(cyclesForProgram);
    }
  }, [programId, fetchProgramCycles, fetchSettings, fetchProgramDetails]);

  const handleMoveUp = (index: number) => {
    if (index > 0) {
      updateCycleOrder(
        cycles[index].id,
        cycles[index].order - 1,
        cycles[index - 1].id,
        cycles[index - 1].order + 1,
      );
    }
  };

  const handleMoveDown = (index: number) => {
    if (index < cycles.length - 1) {
      updateCycleOrder(
        cycles[index].id,
        cycles[index].order + 1,
        cycles[index + 1].id,
        cycles[index + 1].order - 1,
      );
    }
  };

  return (
    <Container maxWidth="xl">
      <Box sx={styles.header}>
        <Typography
          variant="h4"
          sx={{
            fontFamily: '"Bebas Neue", sans-serif',
            letterSpacing: '1px',
            color: '#000000',
          }}
        >
          {programName ? `${programName} - Cycles` : 'Cycles'}
        </Typography>
      </Box>
      <Box>
        {programId &&
          cycles.map((cycle, index) => (
            <Box key={cycle.id} sx={{ mb: 2 }}>
              <Cycle
                programId={programId}
                cycleId={cycle.id}
                cycleName={cycle.name}
                onCycleNameChange={updateCycleName}
                onCycleRemove={removeCycle}
                cycleOperations={cycleOperations}
                index={cycle.order - 1}
                isFirst={index === 0}
                isLast={index === cycles.length - 1}
                onMoveUp={() => handleMoveUp(index)}
                onMoveDown={() => handleMoveDown(index)}
                monacoSettings={monacoSettings}
                initiallyExpanded={openCycles.includes(cycle.id)}
              />
            </Box>
          ))}
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Button
          variant="contained"
          onClick={addCycle}
          disabled={isLoading}
          sx={styles.addCycleButton}
        >
          {isLoading ? 'Adding...' : 'ADD CYCLE'}
        </Button>
      </Box>
    </Container>
  );
}

export default WorkoutPlanner;
