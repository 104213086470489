import { Box, Typography } from '@mui/material';
import React from 'react';

import { Movement } from '../../../../@monaco-uwl/UWLVisitor/Movement';
import { SessionType } from '../../../../generalParser/parser.types';
import { RestType } from '../../../../wodupParser/wodup.types';
import { styles } from '../../WodUpPreview.styles';
import { SetRow } from '../SetRow/SetRow';
import { getMovementName } from '../utils/nameUtils';
import { areMovementsRepsEqual } from '../utils/repUtils';
import { formatRestType } from '../utils/restUtils';
import { useMovementCalculations } from '../utils/useMovementsCalculations';

import { ForTimePreviewProps } from './ForTimePreview.types';

export const ForTimePreview: React.FC<ForTimePreviewProps> = ({ component, sessionType }) => {
  const calculations = useMovementCalculations(component.movements, true);

  // Determine if all movements have identical reps arrays using our repUtils.ts helper.
  const hasUniformReps = component.movements && areMovementsRepsEqual(component.movements);
  let repsSequence = '';
  if (hasUniformReps) {
    const reps = component.movements[0].attributes?.reps;
    if (reps && reps.length > 0) {
      repsSequence = ` (${reps.join('-')})`;
    }
  }

  // Update the header name by adding the reps sequence (in braces) if available.
  const headerName = `${component.prefix || ''}${component.prefix ? '. ' : ''}${
    component.name || 'For Time'
  }${repsSequence}`;

  const renderMovement = (movement: Movement, index: number) => {
    const { allSetsEqual, hasUniformTempo } = Array.isArray(calculations)
      ? calculations[index]
      : calculations;
    const movementName = getMovementName(
      movement,
      hasUniformTempo,
      movement.attributes?.tempo || [],
    );

    return (
      <Box sx={styles.movementContainer}>
        <Typography sx={styles.movementName}>{movementName}</Typography>
        <Box sx={styles.movementRow}>
          {allSetsEqual ? (
            <SetRow
              index={null}
              exerciseName=""
              attributes={movement.attributes}
              hasUniformRepsForTime={hasUniformReps}
              isForTime={true}
            />
          ) : (
            Array.from({ length: component.rounds }).map((_, idx) => (
              <SetRow
                key={idx}
                index={idx}
                exerciseName=""
                attributes={movement.attributes}
                hasUniformRepsForTime={hasUniformReps}
                isForTime={true}
                roundNumber={idx}
              />
            ))
          )}
        </Box>
        {movement.restAfter_type && (
          <Typography sx={styles.restAfterMovement}>
            Rest: {formatRestType(movement.restAfter_type as RestType, movement.restAfter_duration)}
          </Typography>
        )}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        ...styles.genericComponent,
        ...(sessionType === SessionType.AM ? styles.amComponent : styles.pmComponent),
      }}
    >
      <Box sx={styles.genericHeaderWrapper}>
        <Box sx={sessionType === SessionType.AM ? styles.headerLineAM : styles.headerLinePM} />
        <Typography sx={styles.genericHeader}>{headerName}</Typography>
      </Box>

      <Box sx={styles.contentWrapper}>
        <Typography sx={styles.sectionTitle}>Movements:</Typography>
        <Box sx={styles.sectionContent}>
          {component.movements && component.movements.length > 0 ? (
            component.movements.map(renderMovement)
          ) : (
            <Typography sx={styles.noMovements}>No movements specified</Typography>
          )}
        </Box>

        {component.roundRestType && (
          <>
            <Typography sx={styles.sectionTitle}>Rest after each round:</Typography>
            <Typography sx={styles.sectionContent}>
              {formatRestType(
                component.roundRestType as RestType,
                component.roundRestDuration ? parseInt(component.roundRestDuration) : undefined,
              )}
            </Typography>
          </>
        )}

        {component.componentRestType && (
          <>
            <Typography sx={styles.sectionTitle}>Rest after all rounds:</Typography>
            <Typography sx={styles.sectionContent}>
              {formatRestType(
                component.componentRestType as RestType,
                component.componentRestDuration
                  ? parseInt(component.componentRestDuration)
                  : undefined,
              )}
            </Typography>
          </>
        )}

        <Typography sx={styles.sectionTitle}>Notes</Typography>
        <Typography sx={styles.sectionContent}>
          {component.notes || 'No additional notes'}
        </Typography>
      </Box>
    </Box>
  );
};
