import { Workout } from '../@monaco-uwl/UWLVisitor/Workout';

import { parseUWLAmrap } from './amrapParser';
import { parseUWLEmom } from './parseEmom';
import { parseUWLEvery } from './parseEvery';
import { parseUWLForTime } from './parseForTime';
import { parseUWLGeneric } from './parseGeneric';
import { Component } from './parser.types';
import { parseUWLStrength } from './parseStrength';

function assignPrefixes(components: Component[]): Component[] {
  let currentLetter = 0;
  let currentNumber = 1;
  let groupId = 0;
  let currentGroupSize = 0;

  // First pass: count group sizes and assign group IDs
  for (let i = 0; i < components.length; i++) {
    const current = components[i];
    const prev = i > 0 ? components[i - 1] : null;

    if (current.should_be_inside_superset && current.type === 'Strength') {
      // Start of new group if:
      // 1. It's the first component OR
      // 2. Previous component wasn't superset OR
      // 3. Previous component was the end of a group (is_superset: false)
      const isNewGroup =
        i === 0 ||
        !prev?.should_be_inside_superset ||
        (prev?.should_be_inside_superset && !prev?.is_superset);

      if (isNewGroup) {
        currentGroupSize = 1;
        groupId++;
      } else {
        currentGroupSize++;
      }

      // If this is a single component, remove superset
      if (currentGroupSize === 1 && !current.is_superset) {
        current.should_be_inside_superset = false;
        continue;
      }

      current.superset_group_id = `group_${groupId}`;
    }
  }

  // Second pass: assign prefixes
  for (let i = 0; i < components.length; i++) {
    const current = components[i];
    const prev = i > 0 ? components[i - 1] : null;

    if (current.should_be_inside_superset) {
      // Reset number for new superset group
      const isNewGroup =
        i === 0 ||
        !prev?.should_be_inside_superset ||
        (prev?.should_be_inside_superset && !prev?.is_superset);

      if (isNewGroup) {
        currentNumber = 1;
      }

      current.prefix = `${String.fromCharCode(65 + currentLetter)}${currentNumber}`;
      currentNumber++;

      // Increment letter after group ends
      if (!current.is_superset) {
        currentLetter++;
      }
    } else {
      current.prefix = String.fromCharCode(65 + currentLetter);
      currentLetter++;
      currentNumber = 1;
    }
  }

  return components;
}

// Helper function to determine if a strength workout should be superset
function determineIfSuperset(
  current: Workout,
  previous: Workout | null,
  next: Workout | null,
): boolean {
  if (current.is_superset) {
    return next?.type.toLowerCase() === 'strength';
  }
  return previous?.type.toLowerCase() === 'strength' && previous?.is_superset;
}

export function parseUWLComponents(wod: Workout[]): Component[] {
  const components: Component[] = [];

  for (let i = 0; i < wod.length; i++) {
    const workout = wod[i];
    const workoutType = workout.type.toLowerCase();

    // Handle generic workouts
    if (workoutType === 'generic') {
      components.push(parseUWLGeneric(workout, ''));
      continue;
    }

    // Handle strength workouts
    if (workoutType === 'strength') {
      const should_be_inside_superset = determineIfSuperset(workout, wod[i - 1], wod[i + 1]);
      components.push(parseUWLStrength(workout, '', should_be_inside_superset));
      continue;
    }

    // Handle for time workouts
    if (workoutType === 'fortime') {
      components.push(parseUWLForTime(workout, ''));
      continue;
    }

    // Handle amrap workouts
    if (workoutType === 'amrap') {
      components.push(parseUWLAmrap(workout, ''));
      continue;
    }

    if (workoutType === 'emom') {
      components.push(parseUWLEmom(workout, ''));
      continue;
    }

    if (workoutType === 'every') {
      components.push(parseUWLEvery(workout, ''));
      continue;
    }
  }

  return assignPrefixes(components);
}
