export const styles = {
  container: {
    mt: 4,
    boxShadow: 'none',
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 6,
  },
  createButton: {
    mr: 2,
    textTransform: 'none',
    fontFamily: '"Bebas Neue", sans-serif',
    fontSize: '20px',
    backgroundColor: '#203F00',
    '&:hover': {
      backgroundColor: '#2a5400',
    },
  },
  listHeader: {
    backgroundColor: '#000000',
    color: '#ffffff',
    fontFamily: '"Bebas Neue", sans-serif',
    fontSize: '24px',
    py: 2,
    px: 3,
  },
  listItem: {
    borderBottom: '1px solid',
    borderColor: 'grey.200',
    '&:nth-of-type(odd)': {
      backgroundColor: '#fafafa', 
    },
  },
  listItemButton: {
    py: 2,
    px: 3,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)', 
    },
  },
  cyclesColumn: {
    width: '10%',
    display: 'flex',
    alignItems: 'center',
    borderRight: '1px solid',
    borderColor: 'divider',
    px: 2,
  },
  daysColumn: {
    width: '10%',
    display: 'flex',
    alignItems: 'center',
    borderRight: '1px solid',
    borderColor: 'divider',
    pr: 2,
  },
  nameColumn: {
    alignItems: 'center',
    width: '75%',
    pl: 2,
  },
  actionsColumn: {
    width: '15%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  listPaper: {
    borderRadius: 1,
    overflow: 'hidden',
    boxShadow: 'none',
    border: '1px solid #f5f5f5',
  },
};
