import { Workout } from '../../@monaco-uwl/UWLVisitor/Workout';

export interface WodUpPreviewProps {
  Uwlcontent: Workout[][];
  content: string;
  onContentChange: (newContent: string) => void;
}

export enum TimeType {
  MAX_TIME = 'max time',
  MAX_DISTANCE = 'max distance',
}

export enum MovementDefaults {
  UNNAMED_MOVEMENT = 'Unnamed Movement',
}
