export const styles = {
  dialog: {
    '& .MuiDialog-paper': {
      maxHeight: '90vh',
      minWidth: '70vw',
      mt: 2,
    },
  },

  dialogTitle: {
    bgcolor: '#000000',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: '"Bebas Neue", sans-serif',
    fontSize: '28px',
    alignItems: 'center',
    py: 2,
  },

  closeIcon: {
    color: 'white',
  },

  dialogContent: {
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    mt: 3,
  },

  dialogActions: {
    p: 2,
    display: 'flex',
    justifyContent: 'space-between',
  },

  section: {
    mb: 1,
  },

  sectionLabel: {
    mb: 0.5,
    fontWeight: 600,
  },

  textField: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#000000',
      },
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#1976d2',
    },
  },

  attributesGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 0.5,
  },

  priorityLabel: {
    display: 'flex',
    flexDirection: 'column',
  },

  aliasContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },

  aliasChips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1,
  },

  aliasChip: {
    backgroundColor: '#F0F2F5',
    borderRadius: '4px',
    height: '32px',
    '& .MuiChip-label': {
      fontSize: '14px',
      padding: '0 12px',
      fontWeight: 500,
    },
    '& .MuiChip-deleteIcon': {
      fontSize: '18px',
      margin: '0 6px 0 -4px',
      color: '#666',
      '&:hover': {
        color: '#E31B23',
      },
    },
    '&:hover': {
      backgroundColor: '#E8EAF0',
    },
  },

  aliasInputContainer: {
    display: 'flex',
    gap: 0.5,
  },

  aliasInput: {
    flex: 1,
    '& .MuiOutlinedInput-root': {
      height: '36px',
      borderRadius: '4px',
      '&.Mui-focused fieldset': {
        borderColor: '#000000',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '8px 12px',
      fontSize: '14px',
    },
  },

  aliasButton: {
    height: '36px',
    minWidth: '64px',
    borderRadius: '4px',
    color: 'white',
    fontSize: '16px',
    fontWeight: 500,
    textTransform: 'none',
    padding: '0 16px',
    backgroundColor: '#203F00',
    fontFamily: '"Bebas Neue", sans-serif',
    '&:hover': {
      backgroundColor: '#2a5400'
    },
  },

  removeButton: {
    color: '#ffffff',
    pl: 2,
    pr: 2,
    fontSize: '18px',
    backgroundColor: '#7E0012',
    fontFamily: '"Bebas Neue", sans-serif',
    '&:hover': {
      backgroundColor: '#9E0016'
    }
  },

  cancelButton: {
    mr: 1,
    textTransform: 'none',
    color: '#70000F', 
    borderColor: '#70000F',
    fontSize: '16px',
    fontFamily: '"Bebas Neue", sans-serif',
    borderRadius: '5px',
    border: 2,
    height: 40,
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#70000F',
      color: 'white',
    }
  },

  saveButton: {
    textTransform: 'none',
    fontSize: '16px',
    backgroundColor: '#203F00',
    fontFamily: '"Bebas Neue", sans-serif',
    '&:hover': {
      backgroundColor: '#2a5400'
    },
    '&.Mui-disabled': {
      bgcolor: 'rgba(32, 63, 0, 0.12)',
    },
  },

  videoUrlContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },

  videoUrlField: {
    flex: 1,
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#000000',
      },
    },
  },

  descriptionField: {
    mt: 2,
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#000000',
      },
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#000000',
    },
  },

  prioritySwitch: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#203F00',
      '&:hover': {
        backgroundColor: 'rgba(32, 63, 0, 0.04)',
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#203F00',
    },
  },

  urlEditButton: {
    color: '#203F00',
    '&:hover': {
      backgroundColor: 'rgba(32, 63, 0, 0.04)',
    },
  },

  checkbox: {
    color: '#203F00',
    '&.Mui-checked': {
      color: '#203F00',
    },
    '&:hover': {
      backgroundColor: 'rgba(32, 63, 0, 0.04)',
    },
  },
} as const;
