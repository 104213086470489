import api from '../api/base';
import { logger } from '../Logger';
import { ProgramCycle } from '../types/program';

export const cycleService = {
  async fetchCycle(programId: string, cycleId: number): Promise<ProgramCycle> {
    try {
      const response = await api.get(`/api/programs/${programId}/cycles/${cycleId}`);
      logger.debug('Fetched cycle data:', response.data);
      return response.data;
    } catch (error) {
      logger.error('Error fetching cycle data:', error);
      throw error;
    }
  },

  async createCycle(programId: string, name: string): Promise<ProgramCycle> {
    try {
      const response = await api.post(`/api/programs/${programId}/cycles`, { name });
      logger.debug('New cycle created successfully');
      return response.data;
    } catch (error) {
      logger.error('Error creating new cycle:', error);
      throw error;
    }
  },

  async updateCycleName(programId: string, cycleId: number, name: string): Promise<void> {
    try {
      await api.put(`/api/programs/${programId}/cycles/${cycleId}`, { name });
      logger.debug('Cycle name updated successfully');
    } catch (error) {
      logger.error('Error updating cycle name:', error);
      throw error;
    }
  },

  async deleteCycle(programId: string, cycleId: number): Promise<void> {
    try {
      await api.delete(`/api/programs/${programId}/cycles/${cycleId}`);
      logger.debug('Cycle removed successfully');
    } catch (error) {
      logger.error('Error removing cycle:', error);
      throw error;
    }
  },

  async fetchProgram(programId: string): Promise<{ cycles: ProgramCycle[] }> {
    try {
      const response = await api.get(`/api/programs/${programId}`);
      logger.debug('Fetched program data:', response.data);
      return response.data;
    } catch (error) {
      logger.error('Error fetching program data:', error);
      throw error;
    }
  },

  async updateCycleOrder(
    programId: string,
    firstCycleId: number,
    firstCycleNewOrder: number,
    secondCycleId: number,
    secondCycleNewOrder: number,
  ): Promise<void> {
    try {
      await api.put(`/api/programs/${programId}/cycles/${firstCycleId}`, {
        order: firstCycleNewOrder,
      });
      await api.put(`/api/programs/${programId}/cycles/${secondCycleId}`, {
        order: secondCycleNewOrder,
      });
      logger.debug('Cycle order updated successfully');
    } catch (error) {
      logger.error('Error updating cycle order:', error);
      throw error;
    }
  },
};
