
export const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  innerBox: {
    display: 'flex',
    borderRadius: '4px',
    overflow: 'hidden',
    gap: 1,
  },
  buttonContainer: {
    display: 'flex',
    gap: 1,
  },
  textField: {
    width: '36px',
    height: '50px',
    padding: '0px 0px 0px 14px',
    fontSize: '20px',
    fontFamily: '"Bebas Neue", sans-serif',
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    border: '2px solid #000000'
  },
  addButton: {
    color: 'white',
    fontSize: '24px',
    padding: '8px 16px',
    borderRadius: '5px',
    height: '54px',
    minWidth: '165px',
    backgroundColor: '#203F00',
    fontFamily: '"Bebas Neue", sans-serif',
    '&:hover': {
      backgroundColor: '#2a5400'
    }
  },
  removeButton: {
    color: 'white',
    fontSize: '24px',
    padding: '8px 16px',
    borderRadius: '5px',
    height: '54px',
    minWidth: '165px',
    backgroundColor: '#7E0012',
    fontFamily: '"Bebas Neue", sans-serif',
    '&:hover': {
      backgroundColor: '#9E0016'
    }
  },
};
