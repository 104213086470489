import { IDisposable} from 'monaco-editor';
import { useRef, useCallback } from 'react';

import { UWLError } from '../../../@monaco-uwl/UWLErrorHandler';

export const useDialogHandlers = (onClose: (content: string) => void, contentRef: React.MutableRefObject<string>, 
codeActionDisposableRef: React.MutableRefObject<IDisposable | null>, 
editorState: React.MutableRefObject<{hasError: UWLError | false, correctionLength: number}>) => {
  
  const lastEscapePress = useRef<number | null>(null);

  const handleDialogClose = useCallback(() => {
    codeActionDisposableRef.current?.dispose();
    editorState.current.hasError = false;
    editorState.current.correctionLength = -1;
    onClose(contentRef.current);
  }, [onClose, contentRef, codeActionDisposableRef]);

  const handleEscapePress = useCallback(() => {
    const now = Date.now();
    if (lastEscapePress.current !== null) {
      const timeDifference = now - lastEscapePress.current;
      if (timeDifference < 300) {
        handleDialogClose();
      } else {
        lastEscapePress.current = now;
      }
    } else {
      lastEscapePress.current = now;
    }
  }, [handleDialogClose]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (event.target instanceof Node && !event.target.contains(event.target)) {
        handleDialogClose();
      }
    },
    [handleDialogClose],
  );

  return {
    handleDialogClose,
    handleEscapePress,
    handleClickOutside,
  };
};
