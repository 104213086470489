import axios, { InternalAxiosRequestConfig, AxiosError } from 'axios';

import { AUTH_HEADER, BEARER_PREFIX } from '../constants/auth';
import { tokenService } from '../services/tokenService';

const PUBLIC_ROUTES = ['/api/login', '/api/register'];

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

api.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    if (PUBLIC_ROUTES.some((route) => config.url?.includes(route))) {
      return config;
    }

    const token = tokenService.getToken();

    if (!token) {
      return Promise.reject(new Error('No token found'));
    }

    if (tokenService.isTokenExpired(token)) {
      tokenService.removeToken();
      return Promise.reject(new Error('Token expired'));
    }

    config.headers[AUTH_HEADER] = `${BEARER_PREFIX}${token}`;
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.message === 'No token found' || error.message === 'Token expired') {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);

export default api;
