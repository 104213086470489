import { loader, Monaco, Editor } from '@monaco-editor/react';
import { editor, languages } from 'monaco-editor';

import { logger } from '../Logger';

import { MovementHoverProvider } from './MovementPreview/MovementHoverProvider';
import { UWLAutocompletionProvider } from './UWLAutocompletionProvider';
import { UWLTokensProvider } from './UWLTokensProvider';

export const customLangId = 'customLang';

function validate(model: editor.ITextModel, monacoInstance: Monaco): void {
  // TODO: add later
  const markers: editor.IMarkerData[] = [];
  return;
  // const errors = CrossValidate(model.getValue());
  // logger.info("validator fired")
  // for (const e of errors) {
  // 	markers.push({
  // 		severity: MarkerSeverity.Error,
  // 		message: e.message,
  // 		startLineNumber: e.startLine,
  // 		startColumn: e.startCol,
  // 		endLineNumber: e.endLine,
  // 		endColumn: e.endCol,
  // 	});
  // }

  monacoInstance.editor.setModelMarkers(model, 'owner', markers);

  return;
  /*
	for (let i = 1; i < model.getLineCount() + 1; i++) {
		const range = {
			startLineNumber: i,
			startColumn: 1,
			endLineNumber: i,
			endColumn: model.getLineLength(i) + 1,
		};
		const content = model.getValueInRange(range).trim();
		let match = content.match(
			/^((?:(?:>|Min-[0-9]*).*): *([0-9]+(?: *, *[0-9]+)*) *x *)([0-9]+(?: *, *[0-9]+)*)/
		)
		logger.info(match)
		if (match) {
			if (match[2].split(',').length !== match[3].split(',').length) {
				markers.push({
					severity: MarkerSeverity.Error,
					message: "Number of loads doesn't match the number of reps",
					startLineNumber: i,
					startColumn: match[1].length + 1,
					endLineNumber: i,
					endColumn: match[1].length + 1 + match[3].length
				});
			}
		}
		match = content.match(/^((?:(?:>|Min-[0-9]*).*): *.*@ *)([^ ]*(?: +|$))/)
		if (match) {
			if (match[2].length === 0 || match[2].length > 4) {
				markers.push({
					severity: MarkerSeverity.Error,
					message: "Tempo must consist of between 1 and 4 characters",
					startLineNumber: i,
					startColumn: match[1].length + 1,
					endLineNumber: i,
					endColumn: match[1].length + 1 + match[2].length
				});
			}
			if (match[2].match(/[0-9AX]{1,4}(?: +|$)/) == null) {
				markers.push({
					severity: MarkerSeverity.Error,
					message: "Tempo should consist only of numbers and the characters 'A' and 'X'",
					startLineNumber: i,
					startColumn: match[1].length + 1,
					endLineNumber: i,
					endColumn: match[1].length + 1 + match[2].length
				});
			}
		}
		// 	const number = Number(content);
		// 	if (Number.isNaN(number)) {
		// 		markers.push({
		// 			message: "not a number",
		// 			severity: MarkerSeverity.Error,
		// 			startLineNumber: range.startLineNumber,
		// 			startColumn: range.startColumn,
		// 			endLineNumber: range.endLineNumber,
		// 			endColumn: range.endColumn,
		// 		});
		// 	} else if (!Number.isInteger(number)) {
		// 		markers.push({
		// 			message: "not an integer",
		// 			severity: MarkerSeverity.Warning,
		// 			startLineNumber: range.startLineNumber,
		// 			startColumn: range.startColumn,
		// 			endLineNumber: range.endLineNumber,
		// 			endColumn: range.endColumn,
		// 		});
		// 	}
	}
	monacoInstance.editor.setModelMarkers(model, "owner", markers);
	*/
}

const customLang: languages.ILanguageExtensionPoint = {
  id: customLangId,
};

const customLangConfig: languages.LanguageConfiguration = {
  wordPattern: /(-?\d.\d\w)|([^`~!@#^&*()-=+[{\]}|;:'",.<>/?\s]+)/g,
  brackets: [
    ['(', ')'],
    ['{', '}'],
  ],
  autoClosingPairs: [
    {
      open: '"',
      close: '"',
    },
    {
      open: '(',
      close: ')',
    },
    {
      open: '{',
      close: '}',
    },
    {
      open: '"""',
      close: '"""',
    },
    {
      open: '`',
      close: '`',
    },
  ],
};

// const languageMonarchTokenProvider: languages.IMonarchLanguage = {
// 	tokenizer: {
// 		root: [
// 			[/>.*/, "custom-prompt"],
// 			[/Min-[0-9]+:.*/, "custom-prompt"],
// 			[/\/\/.*/, "custom-comment"],
// 			[/\*.*/, "custom-comment"],
// 			[/[Ii]n teams of.*/, "team-marker"],
// 		]
// 	}
// };

const customTheme: editor.IStandaloneThemeData = {
  base: 'vs',
  inherit: true,
  rules: [
    { token: 'custom-prompt', foreground: '4A4A4A', fontStyle: 'bold' },
    { token: 'custom-comment', foreground: '808080' },
    { token: 'team-marker', foreground: '8A2BE2', fontStyle: 'bold' },
    { token: 'crossfit.movement', foreground: 'ff0099' },
    { token: 'crossfit.movement_name', foreground: 'ff0099' },
    { token: 'crossfit.choice_name', foreground: '00ff00' },
    { token: 'crossfit.load_unit', foreground: 'ff00ff' },
    { token: 'crossfit.tempo', foreground: '33ff28' },
    { token: 'crossfit.time', foreground: 'f82f41' },
    { token: 'crossfit.error_char', foreground: 'ff0000' },
    { token: 'crossfit.movement_err', foreground: 'ffff00', fontStyle: 'italic underline' },
    { token: 'uwl.simple_movement', fontStyle: 'bold' },
    { token: 'uwl.wod_kw', fontStyle: 'bold', foreground: '770077' },
  ],
  colors: {
    'editor.foreground': '#000000',
  },
};

export const baseEditorMount = (
  editor: editor.IStandaloneCodeEditor,
  monacoInstance: Monaco,
): void => {
  validate(editor.getModel()!, monacoInstance);
  editor.onDidContentSizeChange(() => {
    editor.layout();
  });

  editor.addAction({
    id: 'uwl.action.showMovementHover',
    label: 'Show Movement Info',
    contextMenuGroupId: 'navigation',
    keybindings: [monacoInstance.KeyMod.CtrlCmd | monacoInstance.KeyCode.KeyH],
    run: function (editor: editor.ICodeEditor): void | Promise<void> {
      editor.trigger('keyboard', 'editor.action.showHover', {});
    },
  });
};

const handleEditorDidMount = (
  editor: editor.IStandaloneCodeEditor,
  monacoInstance: Monaco,
): void => {
  logger.info('mounted');

  editor.getModel()!.onDidChangeContent(() => {
    logger.info('u_content_change');
  });
  validate(editor.getModel()!, monacoInstance);
  editor.onDidContentSizeChange(() => {
    editor.layout();
  });

  editor.addAction({
    id: 'showMovementHover',
    label: 'Show Movement Info',
    keybindings: [monacoInstance.KeyMod.CtrlCmd | monacoInstance.KeyCode.KeyH],
    contextMenuGroupId: 'navigation',
    run: (ed) => {
      ed.trigger('keyboard', 'editor.action.showHover', {});
    },
  });

  logger.debug(editor.getSupportedActions());
};

export async function initMonaco() {
  loader.init().then((monaco) => {
    monaco.languages.register(customLang);
    monaco.languages.setLanguageConfiguration(customLangId, customLangConfig);
    monaco.languages.setTokensProvider(customLangId, new UWLTokensProvider());
    monaco.editor.defineTheme('t1', customTheme);
    monaco.languages.registerCompletionItemProvider(customLangId, UWLAutocompletionProvider);

    // Register the hover provider
    monaco.languages.registerHoverProvider(customLangId, new MovementHoverProvider());
  });
}

export default function MonacoEditor(attrs: {
  value: string;
  className?: string;
  fontSize?: number;
  onChange?: (value: string | undefined) => void;
  isEditing?: boolean;
  isExpanded?: boolean;
  onMount?: (editor: editor.IStandaloneCodeEditor, monaco: Monaco) => void;
  enableHover?: boolean;
}): React.JSX.Element {
  const opts: editor.IStandaloneEditorConstructionOptions = {
    fontSize: attrs.fontSize,
    lineNumbers: 'off',
    fontFamily: 'Arial, sans-serif',
    scrollBeyondLastLine: false,
    scrollbar: { vertical: 'hidden', horizontal: 'hidden' },
    minimap: { enabled: false },
    automaticLayout: true,
    lineDecorationsWidth: 0,
    lineNumbersMinChars: 0,
    folding: false,
    glyphMargin: false,
    renderLineHighlight: 'none',
    hideCursorInOverviewRuler: true,
    overviewRulerBorder: false,
    overviewRulerLanes: 0,
    suggest: {
      snippetsPreventQuickSuggestions: false,
      showFiles: false,
      showWords: false,
    },
    wordBasedSuggestions: 'off',
    unicodeHighlight: {
      invisibleCharacters: false,
    },
    wordSeparators: '`~!@#$^&*()-=+[{]}\\|;:\'",.<>/?',
    fixedOverflowWidgets: true,
    hover: {
      enabled: attrs.enableHover ?? true,
      delay: 500,
      sticky: true,
    },
  };

  return (
    <Editor
      className={`${attrs.className} monaco-editor-container`}
      language={customLangId}
      value={attrs.value}
      theme="t1"
      onMount={attrs.onMount || handleEditorDidMount}
      options={opts}
      onChange={attrs.onChange}
      loading={''}
    />
  );
}

const styles = `
  .monaco-editor-container {
    position: relative;
    z-index: 1;
  }
  .monaco-editor .monaco-editor-overlaymessage {
    z-index: 100;
  }
  .greenBackground {
    background-color: rgba(0, 128, 0, 0.5) !important; 
  }
  .redBackground {
    background-color: rgba(255, 0, 0, 0.5) !important;
  }
`;

const styleElement = document.createElement('style');
styleElement.textContent = styles;
document.head.appendChild(styleElement);
