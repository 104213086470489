// Generated from UniversalWorkoutLanguageLexer.g4 by ANTLR 4.13.1

import * as antlr from "antlr4ng";
import { Token } from "antlr4ng";


export class UniversalWorkoutLanguageLexer extends antlr.Lexer {
    public static readonly IGNORE_CHARS = 1;
    public static readonly WS = 2;
    public static readonly NL = 3;
    public static readonly NUMBER = 4;
    public static readonly FORTIME_KW = 5;
    public static readonly EVERY_KW = 6;
    public static readonly WORKOUT_SEPARATOR = 7;
    public static readonly INTO_KW = 8;
    public static readonly MOVEMENT_MARKER = 9;
    public static readonly CHOICE_KW = 10;
    public static readonly OPTION_KW = 11;
    public static readonly EMOM_KW = 12;
    public static readonly WOD_KW = 13;
    public static readonly DEF_KW = 14;
    public static readonly NEW_MOV_KW = 15;
    public static readonly REST_KW = 16;
    public static readonly MIN_KW = 17;
    public static readonly STRING = 18;
    public static readonly GENERIC_STRING = 19;
    public static readonly PAREN_L = 20;
    public static readonly LBRACE = 21;
    public static readonly RBRACE = 22;
    public static readonly COLON = 23;
    public static readonly INLINE_COMMENT = 24;
    public static readonly MULTILINE_COMMENT = 25;
    public static readonly WORKOUT_IDENTIFIER = 26;
    public static readonly GENERIC_SEPARATOR = 27;
    public static readonly ERROR_CHAR = 28;
    public static readonly WORKOUT_WS = 29;
    public static readonly WORKOUT_PAREN_L = 30;
    public static readonly WORKOUT_PAREN_R = 31;
    public static readonly WORKOUT_COLON = 32;
    public static readonly WORKOUT_COMMA = 33;
    public static readonly WORKOUT_QUOTE = 34;
    public static readonly WORKOUT_STRING = 35;
    public static readonly WORKOUT_ML_STRING = 36;
    public static readonly WORKOUT_TIME = 37;
    public static readonly WORKOUT_NUMBER = 38;
    public static readonly WORKOUT_UNIT_TIME = 39;
    public static readonly WORKOUT_IDEN = 40;
    public static readonly WORKOUT_ERR = 41;
    public static readonly CHOICE_WS = 42;
    public static readonly CHOICE_NAME = 43;
    public static readonly CHOICE_ERR = 44;
    public static readonly MOVEMENT_NAME_REP_MARKER = 45;
    public static readonly SIMPLE_MOVEMENT = 46;
    public static readonly MOVEMENT_NAME_REPS = 47;
    public static readonly MOVEMENT_NAME_COMPOENENT_SEPARATOR = 48;
    public static readonly COMPLEX_MOVEMENT = 49;
    public static readonly MOVEMENT_NAME_TERMINATOR = 50;
    public static readonly MOVEMENT_NAME_WS = 51;
    public static readonly MOVEMENT_ERR = 52;
    public static readonly MC_WS = 53;
    public static readonly MC_RANGE = 54;
    public static readonly MC_CLUSTER = 55;
    public static readonly MC_NUMBER = 56;
    public static readonly MC_MAX = 57;
    public static readonly AMRAP_UNIT_KW = 58;
    public static readonly MC_PAREN_L = 59;
    public static readonly MC_PAREN_R = 60;
    public static readonly MC_DASH = 61;
    public static readonly MC_CAL_KW = 62;
    public static readonly MC_POWER_KW = 63;
    public static readonly MC_TIME = 64;
    public static readonly MC_SET_SEPARATOR = 65;
    public static readonly MC_SLASH = 66;
    public static readonly REP_MARKER = 67;
    public static readonly TEMPO_MARKER = 68;
    public static readonly DURATION_MARKER = 69;
    public static readonly DISTANCE_MARKER = 70;
    public static readonly HEIGHT_MARKER = 71;
    public static readonly MOVEMENT_TERM = 72;
    public static readonly MC_PLACEHOLDER = 73;
    public static readonly MC_CONTENT = 74;
    public static readonly MOV_ERR = 75;
    public static readonly MC_TEMPO_WS = 76;
    public static readonly MC_TEMPO_PATTERN = 77;
    public static readonly DEF_WS = 78;
    public static readonly DEF_COMMENT = 79;
    public static readonly DEF_REGEX = 80;
    public static readonly DEF_IDENTIFIER = 81;
    public static readonly DEF_QUOTE = 82;
    public static readonly DEF_COLON = 83;
    public static readonly DEF_OPTIONAL = 84;
    public static readonly DEF_COMMA = 85;
    public static readonly DEF_PAREN_L = 86;
    public static readonly DEF_PAREN_R = 87;
    public static readonly DEF_BRACE_L = 88;
    public static readonly DEF_BRACE_R = 89;
    public static readonly DEF_SUPER_KW = 90;
    public static readonly DEF_TYPES = 91;
    public static readonly DEF_ENUM_KW = 92;
    public static readonly NEW_MOV_WS = 93;
    public static readonly NEW_MOV_QUOTE = 94;
    public static readonly NEW_MOV_IDENTIFIER = 95;
    public static readonly NEW_MOV_COLON = 96;
    public static readonly NEW_MOV_COMMA = 97;
    public static readonly NEW_MOV_SEMI = 98;
    public static readonly NEW_MOV_PARAM = 99;
    public static readonly NUMBER_WS = 100;
    public static readonly ROUNDS_WORD = 101;
    public static readonly SUPERSET_KW = 102;
    public static readonly FORTIME_SET_KW = 103;
    public static readonly TIME_UNIT = 104;
    public static readonly NUMBER_CONTEXT_ERR = 105;
    public static readonly FORTIME_WS = 106;
    public static readonly FORTIME_REPS = 107;
    public static readonly FORTIME_ERR = 108;
    public static readonly AMRAP_WS = 109;
    public static readonly AMRAP_KW = 110;
    public static readonly AMRAP_ERR = 111;
    public static readonly EMOM_X_SEP = 112;
    public static readonly EMOM_DASH = 113;
    public static readonly EMOM_COLON = 114;
    public static readonly EMOM_MIN_KW = 115;
    public static readonly EMOM_NUM = 116;
    public static readonly EMOM_WS = 117;
    public static readonly EMOM_NL = 118;
    public static readonly EMOM_ERR = 119;
    public static readonly REST_WS = 120;
    public static readonly REST_NL = 121;
    public static readonly REST_AS_NEEDED = 122;
    public static readonly REST_NUMBER = 123;
    public static readonly REST_UNIT = 124;
    public static readonly REST_SLASH = 125;
    public static readonly REST_SUPERSET = 126;
    public static readonly REST_EMOM = 127;
    public static readonly REST_RATIO = 128;
    public static readonly REST_ERR = 129;
    public static readonly REST_AFTER_UNIT_WS = 130;
    public static readonly REST_AFTER_UNIT_NL = 131;
    public static readonly REST_AFTER_UNIT_OTHER = 132;
    public static readonly REST_AFTER_VALUE_WS = 133;
    public static readonly REST_AFTER_VALUE_NL = 134;
    public static readonly REST_AFTER_VALUE_OTHER = 135;
    public static readonly REST_EMOM_WS = 136;
    public static readonly REST_EMOM_ERR = 137;
    public static readonly REST_RATIO_WS = 138;
    public static readonly REST_RATIO_ERR = 139;
    public static readonly EVERY_WS = 140;
    public static readonly EVERY_NL = 141;
    public static readonly EVERY_NUMBER = 142;
    public static readonly EVERY_X = 143;
    public static readonly EVERY_UNIT = 144;
    public static readonly EVERY_FOR = 145;
    public static readonly EVERY_SETS = 146;
    public static readonly EVERY_ERR = 147;
    public static readonly EVERY_AFTER_SETS_WS = 148;
    public static readonly EVERY_AFTER_SETS_FOR = 149;
    public static readonly EVERY_AFTER_SETS_NL = 150;
    public static readonly EVERY_AFTER_SETS_ERR = 151;
    public static readonly EVERY_GOAL_STRING_WS = 152;
    public static readonly EVERY_GOAL_STRING = 153;
    public static readonly EVERY_GOAL_STRING_NL = 154;
    public static readonly EVERY_GOAL_STRING_ERR = 155;
    public static readonly M_WORKOUT = 1;
    public static readonly CH_NAME = 2;
    public static readonly M_MOVEMENT_NAME = 3;
    public static readonly M_MOVEMENT_CONTENT = 4;
    public static readonly M_TEMPO_MODE = 5;
    public static readonly M_DEF = 6;
    public static readonly M_NEW_MOV = 7;
    public static readonly M_NUMBER_CONTEXT = 8;
    public static readonly M_FORTIME = 9;
    public static readonly M_AMRAP = 10;
    public static readonly M_EMOM = 11;
    public static readonly M_REST = 12;
    public static readonly M_REST_AFTER_UNIT = 13;
    public static readonly M_REST_AFTER_VALUE = 14;
    public static readonly M_REST_EMOM = 15;
    public static readonly M_REST_RATIO = 16;
    public static readonly M_EVERY = 17;
    public static readonly M_EVERY_AFTER_SETS = 18;
    public static readonly M_EVERY_GOAL_STRING = 19;

    public static readonly channelNames = [
        "DEFAULT_TOKEN_CHANNEL", "HIDDEN", "CH_WS", "CH_ERR", "CH_COMM", 
                                           "CH_IGNORE"
    ];

    public static readonly literalNames = [
        null, null, null, null, null, null, null, null, "'into'", null, 
        null, null, null, null, "'define'", "'newMovement'", null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, "'M'", null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        "'?'", null, null, null, null, null, null, null, "'enum'", null, 
        null, null, null, null, "';'"
    ];

    public static readonly symbolicNames = [
        null, "IGNORE_CHARS", "WS", "NL", "NUMBER", "FORTIME_KW", "EVERY_KW", 
        "WORKOUT_SEPARATOR", "INTO_KW", "MOVEMENT_MARKER", "CHOICE_KW", 
        "OPTION_KW", "EMOM_KW", "WOD_KW", "DEF_KW", "NEW_MOV_KW", "REST_KW", 
        "MIN_KW", "STRING", "GENERIC_STRING", "PAREN_L", "LBRACE", "RBRACE", 
        "COLON", "INLINE_COMMENT", "MULTILINE_COMMENT", "WORKOUT_IDENTIFIER", 
        "GENERIC_SEPARATOR", "ERROR_CHAR", "WORKOUT_WS", "WORKOUT_PAREN_L", 
        "WORKOUT_PAREN_R", "WORKOUT_COLON", "WORKOUT_COMMA", "WORKOUT_QUOTE", 
        "WORKOUT_STRING", "WORKOUT_ML_STRING", "WORKOUT_TIME", "WORKOUT_NUMBER", 
        "WORKOUT_UNIT_TIME", "WORKOUT_IDEN", "WORKOUT_ERR", "CHOICE_WS", 
        "CHOICE_NAME", "CHOICE_ERR", "MOVEMENT_NAME_REP_MARKER", "SIMPLE_MOVEMENT", 
        "MOVEMENT_NAME_REPS", "MOVEMENT_NAME_COMPOENENT_SEPARATOR", "COMPLEX_MOVEMENT", 
        "MOVEMENT_NAME_TERMINATOR", "MOVEMENT_NAME_WS", "MOVEMENT_ERR", 
        "MC_WS", "MC_RANGE", "MC_CLUSTER", "MC_NUMBER", "MC_MAX", "AMRAP_UNIT_KW", 
        "MC_PAREN_L", "MC_PAREN_R", "MC_DASH", "MC_CAL_KW", "MC_POWER_KW", 
        "MC_TIME", "MC_SET_SEPARATOR", "MC_SLASH", "REP_MARKER", "TEMPO_MARKER", 
        "DURATION_MARKER", "DISTANCE_MARKER", "HEIGHT_MARKER", "MOVEMENT_TERM", 
        "MC_PLACEHOLDER", "MC_CONTENT", "MOV_ERR", "MC_TEMPO_WS", "MC_TEMPO_PATTERN", 
        "DEF_WS", "DEF_COMMENT", "DEF_REGEX", "DEF_IDENTIFIER", "DEF_QUOTE", 
        "DEF_COLON", "DEF_OPTIONAL", "DEF_COMMA", "DEF_PAREN_L", "DEF_PAREN_R", 
        "DEF_BRACE_L", "DEF_BRACE_R", "DEF_SUPER_KW", "DEF_TYPES", "DEF_ENUM_KW", 
        "NEW_MOV_WS", "NEW_MOV_QUOTE", "NEW_MOV_IDENTIFIER", "NEW_MOV_COLON", 
        "NEW_MOV_COMMA", "NEW_MOV_SEMI", "NEW_MOV_PARAM", "NUMBER_WS", "ROUNDS_WORD", 
        "SUPERSET_KW", "FORTIME_SET_KW", "TIME_UNIT", "NUMBER_CONTEXT_ERR", 
        "FORTIME_WS", "FORTIME_REPS", "FORTIME_ERR", "AMRAP_WS", "AMRAP_KW", 
        "AMRAP_ERR", "EMOM_X_SEP", "EMOM_DASH", "EMOM_COLON", "EMOM_MIN_KW", 
        "EMOM_NUM", "EMOM_WS", "EMOM_NL", "EMOM_ERR", "REST_WS", "REST_NL", 
        "REST_AS_NEEDED", "REST_NUMBER", "REST_UNIT", "REST_SLASH", "REST_SUPERSET", 
        "REST_EMOM", "REST_RATIO", "REST_ERR", "REST_AFTER_UNIT_WS", "REST_AFTER_UNIT_NL", 
        "REST_AFTER_UNIT_OTHER", "REST_AFTER_VALUE_WS", "REST_AFTER_VALUE_NL", 
        "REST_AFTER_VALUE_OTHER", "REST_EMOM_WS", "REST_EMOM_ERR", "REST_RATIO_WS", 
        "REST_RATIO_ERR", "EVERY_WS", "EVERY_NL", "EVERY_NUMBER", "EVERY_X", 
        "EVERY_UNIT", "EVERY_FOR", "EVERY_SETS", "EVERY_ERR", "EVERY_AFTER_SETS_WS", 
        "EVERY_AFTER_SETS_FOR", "EVERY_AFTER_SETS_NL", "EVERY_AFTER_SETS_ERR", 
        "EVERY_GOAL_STRING_WS", "EVERY_GOAL_STRING", "EVERY_GOAL_STRING_NL", 
        "EVERY_GOAL_STRING_ERR"
    ];

    public static readonly modeNames = [
        "DEFAULT_MODE", "M_WORKOUT", "CH_NAME", "M_MOVEMENT_NAME", "M_MOVEMENT_CONTENT", 
        "M_TEMPO_MODE", "M_DEF", "M_NEW_MOV", "M_NUMBER_CONTEXT", "M_FORTIME", 
        "M_AMRAP", "M_EMOM", "M_REST", "M_REST_AFTER_UNIT", "M_REST_AFTER_VALUE", 
        "M_REST_EMOM", "M_REST_RATIO", "M_EVERY", "M_EVERY_AFTER_SETS", 
        "M_EVERY_GOAL_STRING",
    ];

    public static readonly ruleNames = [
        "IGNORE_CHARS", "G_WS", "G_NL", "G_FULL_WS", "G_ML_COMMENT", "WS", 
        "NL", "INTEGER_LITERAL", "FLOAT_LITERAL", "G_TIME_LITERAL", "G_UNIT_TIME_LITERAL", 
        "REGEX_MARKER", "REGEX_LITERAL", "ERR_G", "G_PLACEHOLDER", "NUMBER", 
        "FORTIME_KW", "EVERY_KW", "WORKOUT_SEPARATOR", "INTO_KW", "MOVEMENT_MARKER", 
        "CHOICE_KW", "OPTION_KW", "EMOM_KW", "WOD_KW", "DEF_KW", "NEW_MOV_KW", 
        "REST_KW", "MIN_KW", "STRING", "GENERIC_STRING", "PAREN_L", "WORD_G", 
        "LBRACE", "RBRACE", "COLON", "INLINE_COMMENT", "MULTILINE_COMMENT", 
        "WORKOUT_IDENTIFIER", "GENERIC_SEPARATOR", "ERROR_CHAR", "WORKOUT_WS", 
        "WORKOUT_PAREN_L", "WORKOUT_PAREN_R", "WORKOUT_COLON", "WORKOUT_COMMA", 
        "WORKOUT_QUOTE", "WORKOUT_STRING", "WORKOUT_ML_STRING", "WORKOUT_TIME", 
        "WORKOUT_NUMBER", "WORKOUT_UNIT_TIME", "WORKOUT_IDEN", "WORKOUT_ERR", 
        "CHOICE_WS", "CHOICE_NAME", "CHOICE_ERR", "MOVEMENT_NAME_REP_MARKER", 
        "SIMPLE_MOVEMENT", "MOVEMENT_NAME_REPS", "MOVEMENT_NAME_COMPOENENT_SEPARATOR", 
        "COMPLEX_MOVEMENT", "MOVEMENT_NAME_TERMINATOR", "MOVEMENT_NAME_WS", 
        "MOVEMENT_ERR", "MC_WS", "MC_RANGE", "MC_CLUSTER", "MC_NUMBER", 
        "MC_MAX", "AMRAP_UNIT_KW", "MC_PAREN_L", "MC_PAREN_R", "MC_DASH", 
        "MC_CAL_KW", "MC_POWER_KW", "MC_TIME", "MC_SET_SEPARATOR", "MC_SLASH", 
        "REP_MARKER", "TEMPO_MARKER", "DURATION_MARKER", "DISTANCE_MARKER", 
        "HEIGHT_MARKER", "MOVEMENT_TERM", "MC_PLACEHOLDER", "MC_CONTENT", 
        "MOV_ERR", "MC_TEMPO_WS", "MC_TEMPO_ANY_CONTENT", "MC_TEMPO_COMMA", 
        "MC_TEMPO_WS_END", "MC_TEMPO_PATTERN", "MC_TEMPO_TERM", "DEF_WS", 
        "DEF_COMMENT", "DEF_REGEX", "DEF_IDENTIFIER", "DEF_QUOTE", "DEF_COLON", 
        "DEF_OPTIONAL", "DEF_COMMA", "DEF_PAREN_L", "DEF_PAREN_R", "DEF_BRACE_L", 
        "DEF_BRACE_R", "DEF_SUPER_KW", "DEF_TYPES", "DEF_ENUM_KW", "NEW_MOV_WS", 
        "NEW_MOV_QUOTE", "NEW_MOV_IDENTIFIER", "NEW_MOV_COLON", "NEW_MOV_COMMA", 
        "NEW_MOV_SEMI", "NEW_MOV_PARAM", "NUMBER_WS", "ROUNDS_WORD", "SUPERSET_KW", 
        "FORTIME_SET_KW", "TIME_UNIT", "NUMBER_CONTEXT_ERR", "FORTIME_WS", 
        "M_FORTIME_KW", "FORTIME_REPS", "M_MOVEMENT_MARKER", "FORTIME_ERR", 
        "AMRAP_WS", "AMRAP_KW", "AMRAP_ERR", "EMOM_X_SEP", "EMOM_DASH", 
        "EMOM_COLON", "EMOM_MIN_KW", "EMOM_NUM", "EMOM_WS", "EMOM_NL", "EMOM_ERR", 
        "REST_WS", "REST_NL", "REST_AS_NEEDED", "REST_NUMBER", "REST_UNIT", 
        "REST_SLASH", "REST_SUPERSET", "REST_EMOM", "REST_RATIO", "REST_INTO", 
        "REST_ERR", "REST_AFTER_UNIT_WS", "REST_AFTER_UNIT_NL", "REST_AFTER_UNIT_SLASH", 
        "REST_AFTER_UNIT_INTO", "REST_AFTER_UNIT_OTHER", "REST_AFTER_VALUE_WS", 
        "REST_AFTER_VALUE_NL", "REST_AFTER_VALUE_SLASH", "REST_AFTER_VALUE_INTO", 
        "REST_AFTER_VALUE_OTHER", "REST_EMOM_WS", "REST_EMOM_NUMBER", "REST_EMOM_UNIT", 
        "REST_EMOM_INTO", "REST_EMOM_ERR", "REST_RATIO_WS", "REST_RATIO_NUMBER", 
        "REST_RATIO_INTO", "REST_RATIO_ERR", "EVERY_WS", "EVERY_NL", "EVERY_NUMBER", 
        "EVERY_X", "EVERY_UNIT", "EVERY_FOR", "EVERY_SETS", "EVERY_ERR", 
        "EVERY_AFTER_SETS_WS", "EVERY_AFTER_SETS_FOR", "EVERY_AFTER_SETS_NL", 
        "EVERY_AFTER_SETS_ERR", "EVERY_GOAL_STRING_WS", "EVERY_GOAL_STRING", 
        "EVERY_GOAL_STRING_NL", "EVERY_GOAL_STRING_ERR",
    ];


    public constructor(input: antlr.CharStream) {
        super(input);
        this.interpreter = new antlr.LexerATNSimulator(this, UniversalWorkoutLanguageLexer._ATN, UniversalWorkoutLanguageLexer.decisionsToDFA, new antlr.PredictionContextCache());
    }

    public get grammarFileName(): string { return "UniversalWorkoutLanguageLexer.g4"; }

    public get literalNames(): (string | null)[] { return UniversalWorkoutLanguageLexer.literalNames; }
    public get symbolicNames(): (string | null)[] { return UniversalWorkoutLanguageLexer.symbolicNames; }
    public get ruleNames(): string[] { return UniversalWorkoutLanguageLexer.ruleNames; }

    public get serializedATN(): number[] { return UniversalWorkoutLanguageLexer._serializedATN; }

    public get channelNames(): string[] { return UniversalWorkoutLanguageLexer.channelNames; }

    public get modeNames(): string[] { return UniversalWorkoutLanguageLexer.modeNames; }

    public static readonly _serializedATN: number[] = [
        4,0,155,1679,6,-1,6,-1,6,-1,6,-1,6,-1,6,-1,6,-1,6,-1,6,-1,6,-1,6,
        -1,6,-1,6,-1,6,-1,6,-1,6,-1,6,-1,6,-1,6,-1,6,-1,2,0,7,0,2,1,7,1,
        2,2,7,2,2,3,7,3,2,4,7,4,2,5,7,5,2,6,7,6,2,7,7,7,2,8,7,8,2,9,7,9,
        2,10,7,10,2,11,7,11,2,12,7,12,2,13,7,13,2,14,7,14,2,15,7,15,2,16,
        7,16,2,17,7,17,2,18,7,18,2,19,7,19,2,20,7,20,2,21,7,21,2,22,7,22,
        2,23,7,23,2,24,7,24,2,25,7,25,2,26,7,26,2,27,7,27,2,28,7,28,2,29,
        7,29,2,30,7,30,2,31,7,31,2,32,7,32,2,33,7,33,2,34,7,34,2,35,7,35,
        2,36,7,36,2,37,7,37,2,38,7,38,2,39,7,39,2,40,7,40,2,41,7,41,2,42,
        7,42,2,43,7,43,2,44,7,44,2,45,7,45,2,46,7,46,2,47,7,47,2,48,7,48,
        2,49,7,49,2,50,7,50,2,51,7,51,2,52,7,52,2,53,7,53,2,54,7,54,2,55,
        7,55,2,56,7,56,2,57,7,57,2,58,7,58,2,59,7,59,2,60,7,60,2,61,7,61,
        2,62,7,62,2,63,7,63,2,64,7,64,2,65,7,65,2,66,7,66,2,67,7,67,2,68,
        7,68,2,69,7,69,2,70,7,70,2,71,7,71,2,72,7,72,2,73,7,73,2,74,7,74,
        2,75,7,75,2,76,7,76,2,77,7,77,2,78,7,78,2,79,7,79,2,80,7,80,2,81,
        7,81,2,82,7,82,2,83,7,83,2,84,7,84,2,85,7,85,2,86,7,86,2,87,7,87,
        2,88,7,88,2,89,7,89,2,90,7,90,2,91,7,91,2,92,7,92,2,93,7,93,2,94,
        7,94,2,95,7,95,2,96,7,96,2,97,7,97,2,98,7,98,2,99,7,99,2,100,7,100,
        2,101,7,101,2,102,7,102,2,103,7,103,2,104,7,104,2,105,7,105,2,106,
        7,106,2,107,7,107,2,108,7,108,2,109,7,109,2,110,7,110,2,111,7,111,
        2,112,7,112,2,113,7,113,2,114,7,114,2,115,7,115,2,116,7,116,2,117,
        7,117,2,118,7,118,2,119,7,119,2,120,7,120,2,121,7,121,2,122,7,122,
        2,123,7,123,2,124,7,124,2,125,7,125,2,126,7,126,2,127,7,127,2,128,
        7,128,2,129,7,129,2,130,7,130,2,131,7,131,2,132,7,132,2,133,7,133,
        2,134,7,134,2,135,7,135,2,136,7,136,2,137,7,137,2,138,7,138,2,139,
        7,139,2,140,7,140,2,141,7,141,2,142,7,142,2,143,7,143,2,144,7,144,
        2,145,7,145,2,146,7,146,2,147,7,147,2,148,7,148,2,149,7,149,2,150,
        7,150,2,151,7,151,2,152,7,152,2,153,7,153,2,154,7,154,2,155,7,155,
        2,156,7,156,2,157,7,157,2,158,7,158,2,159,7,159,2,160,7,160,2,161,
        7,161,2,162,7,162,2,163,7,163,2,164,7,164,2,165,7,165,2,166,7,166,
        2,167,7,167,2,168,7,168,2,169,7,169,2,170,7,170,2,171,7,171,2,172,
        7,172,2,173,7,173,2,174,7,174,2,175,7,175,2,176,7,176,2,177,7,177,
        2,178,7,178,2,179,7,179,2,180,7,180,2,181,7,181,2,182,7,182,2,183,
        7,183,1,0,1,0,1,0,1,0,1,1,4,1,394,8,1,11,1,12,1,395,1,2,3,2,399,
        8,2,1,2,1,2,3,2,403,8,2,1,3,1,3,3,3,407,8,3,1,3,1,3,3,3,411,8,3,
        4,3,413,8,3,11,3,12,3,414,1,4,1,4,1,4,1,4,5,4,421,8,4,10,4,12,4,
        424,9,4,1,4,1,4,1,4,1,5,4,5,430,8,5,11,5,12,5,431,1,5,1,5,1,6,3,
        6,437,8,6,1,6,1,6,3,6,441,8,6,1,6,1,6,1,7,1,7,1,7,5,7,448,8,7,10,
        7,12,7,451,9,7,3,7,453,8,7,1,8,1,8,1,8,5,8,458,8,8,10,8,12,8,461,
        9,8,3,8,463,8,8,1,8,1,8,4,8,467,8,8,11,8,12,8,468,3,8,471,8,8,1,
        9,5,9,474,8,9,10,9,12,9,477,9,9,1,9,1,9,1,9,3,9,482,8,9,1,10,4,10,
        485,8,10,11,10,12,10,486,1,10,3,10,490,8,10,1,10,5,10,493,8,10,10,
        10,12,10,496,9,10,1,11,1,11,1,12,1,12,4,12,502,8,12,11,12,12,12,
        503,1,12,1,12,1,13,1,13,1,14,1,14,5,14,512,8,14,10,14,12,14,515,
        9,14,1,14,1,14,1,15,4,15,520,8,15,11,15,12,15,521,1,15,1,15,1,16,
        1,16,1,16,1,16,5,16,530,8,16,10,16,12,16,533,9,16,1,16,1,16,1,16,
        1,16,1,16,1,16,1,16,1,17,1,17,1,17,1,17,1,17,1,17,1,17,1,17,1,18,
        1,18,1,19,1,19,1,19,1,19,1,19,1,20,1,20,1,20,1,20,1,21,1,21,1,21,
        1,21,1,21,1,21,1,21,1,21,1,21,1,22,1,22,1,22,1,22,1,22,1,22,1,22,
        1,22,1,22,1,23,1,23,1,23,1,23,1,23,1,23,1,23,1,24,1,24,1,24,1,24,
        1,24,1,24,1,24,1,24,1,24,1,24,1,24,1,24,1,24,1,24,1,24,1,24,1,24,
        1,24,1,24,1,24,1,24,1,24,1,24,3,24,609,8,24,1,24,3,24,612,8,24,1,
        25,1,25,1,25,1,25,1,25,1,25,1,25,1,25,1,25,1,26,1,26,1,26,1,26,1,
        26,1,26,1,26,1,26,1,26,1,26,1,26,1,26,1,26,1,26,1,27,1,27,1,27,1,
        27,1,27,1,27,1,27,1,28,1,28,1,28,1,28,5,28,648,8,28,10,28,12,28,
        651,9,28,1,28,1,28,5,28,655,8,28,10,28,12,28,658,9,28,1,28,4,28,
        661,8,28,11,28,12,28,662,3,28,665,8,28,1,28,5,28,668,8,28,10,28,
        12,28,671,9,28,1,28,1,28,1,29,1,29,5,29,677,8,29,10,29,12,29,680,
        9,29,1,29,1,29,1,30,1,30,5,30,686,8,30,10,30,12,30,689,9,30,1,30,
        1,30,1,31,1,31,1,31,1,31,1,32,4,32,698,8,32,11,32,12,32,699,1,33,
        1,33,1,34,1,34,1,35,1,35,1,36,1,36,5,36,710,8,36,10,36,12,36,713,
        9,36,1,36,1,36,1,37,1,37,1,37,1,37,1,38,1,38,5,38,723,8,38,10,38,
        12,38,726,9,38,1,38,1,38,1,39,1,39,1,39,1,39,1,40,1,40,1,41,1,41,
        1,41,1,41,1,42,1,42,1,43,1,43,1,43,1,43,1,44,1,44,1,45,1,45,1,46,
        1,46,1,47,1,47,5,47,754,8,47,10,47,12,47,757,9,47,1,47,1,47,1,48,
        1,48,1,48,1,48,1,48,5,48,766,8,48,10,48,12,48,769,9,48,1,48,1,48,
        1,48,1,48,1,49,1,49,1,50,1,50,1,51,1,51,1,52,1,52,5,52,783,8,52,
        10,52,12,52,786,9,52,1,53,1,53,1,54,1,54,1,54,1,54,1,55,1,55,5,55,
        796,8,55,10,55,12,55,799,9,55,1,55,1,55,1,56,1,56,1,57,1,57,4,57,
        807,8,57,11,57,12,57,808,1,58,4,58,812,8,58,11,58,12,58,813,1,58,
        4,58,817,8,58,11,58,12,58,818,3,58,821,8,58,1,58,1,58,5,58,825,8,
        58,10,58,12,58,828,9,58,1,59,1,59,5,59,832,8,59,10,59,12,59,835,
        9,59,1,59,1,59,1,60,1,60,1,61,3,61,842,8,61,1,61,1,61,1,61,1,61,
        3,61,848,8,61,1,61,1,61,5,61,852,8,61,10,61,12,61,855,9,61,1,62,
        1,62,1,62,1,62,1,63,1,63,1,63,1,63,1,64,1,64,1,65,4,65,868,8,65,
        11,65,12,65,869,1,65,1,65,1,66,4,66,875,8,66,11,66,12,66,876,1,66,
        1,66,4,66,881,8,66,11,66,12,66,882,1,67,4,67,886,8,67,11,67,12,67,
        887,1,67,1,67,4,67,892,8,67,11,67,12,67,893,4,67,896,8,67,11,67,
        12,67,897,1,68,4,68,901,8,68,11,68,12,68,902,1,68,1,68,4,68,907,
        8,68,11,68,12,68,908,3,68,911,8,68,1,69,1,69,1,70,1,70,1,70,1,70,
        1,70,1,70,1,71,1,71,1,72,1,72,1,73,1,73,1,74,1,74,1,74,1,74,1,74,
        1,74,1,74,1,74,1,74,3,74,936,8,74,1,75,1,75,1,75,1,75,1,75,1,75,
        3,75,944,8,75,3,75,946,8,75,1,76,1,76,1,77,1,77,1,78,1,78,1,79,1,
        79,1,80,1,80,1,80,1,80,1,81,1,81,1,81,1,82,1,82,1,82,1,82,1,83,1,
        83,1,83,1,84,3,84,971,8,84,1,84,1,84,3,84,975,8,84,1,84,1,84,1,85,
        1,85,1,86,4,86,982,8,86,11,86,12,86,983,1,87,1,87,1,87,1,87,1,88,
        4,88,991,8,88,11,88,12,88,992,1,88,1,88,1,89,1,89,1,89,1,89,1,89,
        1,90,4,90,1003,8,90,11,90,12,90,1004,1,90,1,90,1,90,1,90,1,91,4,
        91,1012,8,91,11,91,12,91,1013,1,91,4,91,1017,8,91,11,91,12,91,1018,
        1,91,1,91,1,91,1,92,4,92,1025,8,92,11,92,12,92,1026,1,92,1,92,1,
        93,3,93,1032,8,93,1,93,1,93,3,93,1036,8,93,1,93,1,93,1,93,1,94,1,
        94,1,94,1,94,1,95,1,95,1,95,1,95,5,95,1049,8,95,10,95,12,95,1052,
        9,95,1,95,1,95,1,95,1,96,1,96,1,97,1,97,4,97,1061,8,97,11,97,12,
        97,1062,1,97,1,97,1,98,1,98,1,99,1,99,1,100,1,100,1,101,1,101,1,
        102,1,102,1,103,1,103,1,104,1,104,1,105,1,105,1,105,1,105,1,106,
        1,106,1,106,1,106,1,106,1,106,1,106,1,106,1,106,1,106,1,106,1,106,
        1,106,1,106,1,106,1,106,1,106,1,106,1,106,1,106,1,106,1,106,1,106,
        1,106,1,106,1,106,1,106,3,106,1112,8,106,1,107,1,107,1,107,1,107,
        1,107,1,107,1,107,1,107,1,107,1,107,1,107,1,107,1,107,1,107,1,107,
        1,107,1,107,1,107,1,107,1,107,1,107,1,107,1,107,1,107,1,107,1,107,
        1,107,3,107,1141,8,107,1,108,1,108,1,108,1,108,1,108,1,109,1,109,
        1,109,1,109,1,110,1,110,1,111,1,111,4,111,1156,8,111,11,111,12,111,
        1157,1,111,1,111,1,112,1,112,1,113,1,113,1,114,1,114,1,115,4,115,
        1169,8,115,11,115,12,115,1170,1,116,1,116,1,116,1,116,1,117,1,117,
        1,117,1,117,1,117,1,117,1,117,1,117,1,117,1,118,3,118,1187,8,118,
        1,118,1,118,1,118,1,118,1,118,1,118,1,118,1,118,1,118,1,118,1,118,
        1,118,1,119,3,119,1202,8,119,1,119,1,119,1,119,1,119,1,119,3,119,
        1209,8,119,1,119,1,119,1,120,3,120,1214,8,120,1,120,1,120,1,120,
        1,120,1,120,1,120,1,120,3,120,1223,8,120,1,120,1,120,1,121,1,121,
        1,121,1,121,1,121,1,122,1,122,1,122,1,122,1,123,1,123,1,123,1,123,
        5,123,1240,8,123,10,123,12,123,1243,9,123,1,123,1,123,1,123,1,123,
        1,123,1,123,1,123,1,124,1,124,1,124,5,124,1255,8,124,10,124,12,124,
        1258,9,124,1,124,3,124,1261,8,124,1,125,1,125,1,125,1,125,1,125,
        1,126,1,126,1,126,1,126,1,126,1,127,1,127,1,127,1,127,1,128,1,128,
        1,128,1,128,1,128,1,128,1,128,1,128,1,129,1,129,1,129,1,129,1,129,
        1,130,1,130,1,131,1,131,1,132,1,132,1,132,1,132,1,133,1,133,1,133,
        1,133,3,133,1302,8,133,1,134,1,134,1,135,1,135,1,135,1,135,1,136,
        1,136,1,136,1,136,1,137,1,137,1,137,1,137,1,137,1,138,1,138,1,138,
        1,138,1,139,1,139,1,139,1,139,1,139,1,140,1,140,1,140,5,140,1331,
        8,140,10,140,12,140,1334,9,140,1,140,1,140,4,140,1338,8,140,11,140,
        12,140,1339,3,140,1342,8,140,1,140,1,140,1,140,1,140,1,140,1,140,
        1,140,1,140,1,140,1,141,4,141,1354,8,141,11,141,12,141,1355,1,141,
        1,141,4,141,1360,8,141,11,141,12,141,1361,3,141,1364,8,141,1,142,
        1,142,1,142,1,142,1,142,1,142,1,142,1,142,1,142,1,142,1,142,1,142,
        1,142,1,142,1,142,1,142,1,142,1,142,1,142,1,142,1,142,1,142,1,142,
        1,142,1,142,1,142,1,142,1,142,1,142,1,142,3,142,1396,8,142,1,142,
        1,142,1,143,1,143,1,144,1,144,1,144,1,144,1,144,1,144,1,144,1,144,
        1,144,1,144,1,144,1,145,1,145,1,145,1,145,1,145,1,145,1,145,1,146,
        1,146,1,146,1,146,1,146,1,146,1,146,1,146,1,147,1,147,1,147,1,147,
        1,147,1,147,1,147,1,147,1,148,1,148,1,148,1,148,1,148,1,149,1,149,
        1,149,1,149,1,150,1,150,1,150,1,150,1,150,1,151,1,151,1,151,1,151,
        1,151,1,152,1,152,1,152,1,152,1,152,1,152,1,152,1,152,1,153,1,153,
        1,153,1,153,1,153,1,154,1,154,1,154,1,154,1,155,1,155,1,155,1,155,
        1,155,1,156,1,156,1,156,1,156,1,156,1,157,1,157,1,157,1,157,1,157,
        1,157,1,157,1,157,1,158,1,158,1,158,1,158,1,158,1,159,1,159,1,159,
        1,159,1,160,4,160,1500,8,160,11,160,12,160,1501,1,160,1,160,4,160,
        1506,8,160,11,160,12,160,1507,3,160,1510,8,160,1,160,1,160,1,161,
        1,161,1,161,1,161,1,161,1,161,1,161,1,161,1,161,1,161,1,161,1,161,
        1,161,1,161,1,161,1,161,1,161,1,161,1,161,1,161,1,161,1,161,1,161,
        1,161,1,161,1,161,1,161,1,161,1,161,1,161,3,161,1544,8,161,1,161,
        1,161,1,161,1,162,1,162,1,162,1,162,1,162,1,162,1,162,1,162,1,163,
        1,163,1,163,1,163,1,163,1,164,1,164,1,164,1,164,1,165,4,165,1567,
        8,165,11,165,12,165,1568,1,165,1,165,4,165,1573,8,165,11,165,12,
        165,1574,3,165,1577,8,165,1,165,1,165,1,165,1,166,1,166,1,166,1,
        166,1,166,1,166,1,166,1,166,1,167,1,167,1,167,1,167,1,167,1,168,
        1,168,1,168,1,168,1,169,1,169,1,169,1,169,1,169,1,170,1,170,1,170,
        3,170,1607,8,170,1,171,1,171,1,172,1,172,1,172,1,172,1,172,1,172,
        1,172,3,172,1618,8,172,1,173,1,173,1,173,1,173,1,173,1,173,1,174,
        1,174,1,174,1,174,3,174,1630,8,174,1,174,1,174,1,175,1,175,1,175,
        1,175,1,175,1,176,1,176,1,176,1,176,1,177,1,177,1,177,1,177,1,177,
        1,177,1,178,1,178,1,178,1,178,1,178,1,179,1,179,1,179,1,179,1,179,
        1,180,1,180,1,180,1,180,1,181,4,181,1664,8,181,11,181,12,181,1665,
        1,181,1,181,1,182,1,182,1,182,1,182,1,182,1,183,1,183,1,183,1,183,
        1,183,8,422,503,513,678,687,755,767,1050,0,184,20,1,22,0,24,0,26,
        0,28,0,30,2,32,3,34,0,36,0,38,0,40,0,42,0,44,0,46,0,48,0,50,4,52,
        5,54,6,56,7,58,8,60,9,62,10,64,11,66,12,68,13,70,14,72,15,74,16,
        76,17,78,18,80,19,82,20,84,0,86,21,88,22,90,23,92,24,94,25,96,26,
        98,27,100,28,102,29,104,30,106,31,108,32,110,33,112,34,114,35,116,
        36,118,37,120,38,122,39,124,40,126,41,128,42,130,43,132,44,134,45,
        136,46,138,47,140,48,142,49,144,50,146,51,148,52,150,53,152,54,154,
        55,156,56,158,57,160,58,162,59,164,60,166,61,168,62,170,63,172,64,
        174,65,176,66,178,67,180,68,182,69,184,70,186,71,188,72,190,73,192,
        74,194,75,196,76,198,0,200,0,202,0,204,77,206,0,208,78,210,79,212,
        80,214,81,216,82,218,83,220,84,222,85,224,86,226,87,228,88,230,89,
        232,90,234,91,236,92,238,93,240,94,242,95,244,96,246,97,248,98,250,
        99,252,100,254,101,256,102,258,103,260,104,262,105,264,106,266,0,
        268,107,270,0,272,108,274,109,276,110,278,111,280,112,282,113,284,
        114,286,115,288,116,290,117,292,118,294,119,296,120,298,121,300,
        122,302,123,304,124,306,125,308,126,310,127,312,128,314,0,316,129,
        318,130,320,131,322,0,324,0,326,132,328,133,330,134,332,0,334,0,
        336,135,338,136,340,0,342,0,344,0,346,137,348,138,350,0,352,0,354,
        139,356,140,358,141,360,142,362,143,364,144,366,145,368,146,370,
        147,372,148,374,149,376,150,378,151,380,152,382,153,384,154,386,
        155,20,0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,40,2,0,
        8202,8205,8288,8293,2,0,9,9,32,32,1,0,49,57,1,0,48,57,2,0,65,90,
        97,122,2,0,70,70,102,102,2,0,79,79,111,111,2,0,82,82,114,114,2,0,
        84,84,116,116,2,0,73,73,105,105,2,0,77,77,109,109,2,0,69,69,101,
        101,2,0,86,86,118,118,2,0,89,89,121,121,1,0,62,62,2,0,67,67,99,99,
        2,0,65,65,97,97,2,0,80,80,112,112,2,0,87,87,119,119,2,0,68,68,100,
        100,2,0,78,78,110,110,3,0,48,57,65,90,97,122,2,0,10,10,13,13,4,0,
        45,45,65,90,95,95,97,122,5,0,45,45,48,57,65,90,95,95,97,122,1,0,
        59,59,6,0,32,32,45,45,48,57,65,90,95,95,97,122,2,0,88,88,120,120,
        5,0,45,45,47,47,65,91,95,95,97,122,9,0,32,32,40,41,45,45,47,57,65,
        91,93,93,95,95,97,123,125,125,1,0,32,32,1,0,58,58,2,0,13,13,59,59,
        9,0,9,10,13,13,32,32,40,41,43,45,47,47,59,59,64,64,120,120,5,0,48,
        57,65,65,88,88,97,97,120,120,3,0,65,90,95,95,97,122,2,0,83,83,115,
        115,4,0,77,77,83,83,109,109,115,115,2,0,85,85,117,117,3,0,9,10,13,
        13,32,32,1773,0,20,1,0,0,0,0,30,1,0,0,0,0,32,1,0,0,0,0,50,1,0,0,
        0,0,52,1,0,0,0,0,54,1,0,0,0,0,56,1,0,0,0,0,58,1,0,0,0,0,60,1,0,0,
        0,0,62,1,0,0,0,0,64,1,0,0,0,0,66,1,0,0,0,0,68,1,0,0,0,0,70,1,0,0,
        0,0,72,1,0,0,0,0,74,1,0,0,0,0,76,1,0,0,0,0,78,1,0,0,0,0,80,1,0,0,
        0,0,82,1,0,0,0,0,86,1,0,0,0,0,88,1,0,0,0,0,90,1,0,0,0,0,92,1,0,0,
        0,0,94,1,0,0,0,0,96,1,0,0,0,0,98,1,0,0,0,0,100,1,0,0,0,1,102,1,0,
        0,0,1,104,1,0,0,0,1,106,1,0,0,0,1,108,1,0,0,0,1,110,1,0,0,0,1,112,
        1,0,0,0,1,114,1,0,0,0,1,116,1,0,0,0,1,118,1,0,0,0,1,120,1,0,0,0,
        1,122,1,0,0,0,1,124,1,0,0,0,1,126,1,0,0,0,2,128,1,0,0,0,2,130,1,
        0,0,0,2,132,1,0,0,0,3,134,1,0,0,0,3,136,1,0,0,0,3,138,1,0,0,0,3,
        140,1,0,0,0,3,142,1,0,0,0,3,144,1,0,0,0,3,146,1,0,0,0,3,148,1,0,
        0,0,4,150,1,0,0,0,4,152,1,0,0,0,4,154,1,0,0,0,4,156,1,0,0,0,4,158,
        1,0,0,0,4,160,1,0,0,0,4,162,1,0,0,0,4,164,1,0,0,0,4,166,1,0,0,0,
        4,168,1,0,0,0,4,170,1,0,0,0,4,172,1,0,0,0,4,174,1,0,0,0,4,176,1,
        0,0,0,4,178,1,0,0,0,4,180,1,0,0,0,4,182,1,0,0,0,4,184,1,0,0,0,4,
        186,1,0,0,0,4,188,1,0,0,0,4,190,1,0,0,0,4,192,1,0,0,0,4,194,1,0,
        0,0,5,196,1,0,0,0,5,198,1,0,0,0,5,200,1,0,0,0,5,202,1,0,0,0,5,204,
        1,0,0,0,5,206,1,0,0,0,6,208,1,0,0,0,6,210,1,0,0,0,6,212,1,0,0,0,
        6,214,1,0,0,0,6,216,1,0,0,0,6,218,1,0,0,0,6,220,1,0,0,0,6,222,1,
        0,0,0,6,224,1,0,0,0,6,226,1,0,0,0,6,228,1,0,0,0,6,230,1,0,0,0,6,
        232,1,0,0,0,6,234,1,0,0,0,6,236,1,0,0,0,7,238,1,0,0,0,7,240,1,0,
        0,0,7,242,1,0,0,0,7,244,1,0,0,0,7,246,1,0,0,0,7,248,1,0,0,0,7,250,
        1,0,0,0,8,252,1,0,0,0,8,254,1,0,0,0,8,256,1,0,0,0,8,258,1,0,0,0,
        8,260,1,0,0,0,8,262,1,0,0,0,9,264,1,0,0,0,9,266,1,0,0,0,9,268,1,
        0,0,0,9,270,1,0,0,0,9,272,1,0,0,0,10,274,1,0,0,0,10,276,1,0,0,0,
        10,278,1,0,0,0,11,280,1,0,0,0,11,282,1,0,0,0,11,284,1,0,0,0,11,286,
        1,0,0,0,11,288,1,0,0,0,11,290,1,0,0,0,11,292,1,0,0,0,11,294,1,0,
        0,0,12,296,1,0,0,0,12,298,1,0,0,0,12,300,1,0,0,0,12,302,1,0,0,0,
        12,304,1,0,0,0,12,306,1,0,0,0,12,308,1,0,0,0,12,310,1,0,0,0,12,312,
        1,0,0,0,12,314,1,0,0,0,12,316,1,0,0,0,13,318,1,0,0,0,13,320,1,0,
        0,0,13,322,1,0,0,0,13,324,1,0,0,0,13,326,1,0,0,0,14,328,1,0,0,0,
        14,330,1,0,0,0,14,332,1,0,0,0,14,334,1,0,0,0,14,336,1,0,0,0,15,338,
        1,0,0,0,15,340,1,0,0,0,15,342,1,0,0,0,15,344,1,0,0,0,15,346,1,0,
        0,0,16,348,1,0,0,0,16,350,1,0,0,0,16,352,1,0,0,0,16,354,1,0,0,0,
        17,356,1,0,0,0,17,358,1,0,0,0,17,360,1,0,0,0,17,362,1,0,0,0,17,364,
        1,0,0,0,17,366,1,0,0,0,17,368,1,0,0,0,17,370,1,0,0,0,18,372,1,0,
        0,0,18,374,1,0,0,0,18,376,1,0,0,0,18,378,1,0,0,0,19,380,1,0,0,0,
        19,382,1,0,0,0,19,384,1,0,0,0,19,386,1,0,0,0,20,388,1,0,0,0,22,393,
        1,0,0,0,24,402,1,0,0,0,26,412,1,0,0,0,28,416,1,0,0,0,30,429,1,0,
        0,0,32,440,1,0,0,0,34,452,1,0,0,0,36,462,1,0,0,0,38,475,1,0,0,0,
        40,484,1,0,0,0,42,497,1,0,0,0,44,499,1,0,0,0,46,507,1,0,0,0,48,509,
        1,0,0,0,50,519,1,0,0,0,52,525,1,0,0,0,54,541,1,0,0,0,56,549,1,0,
        0,0,58,551,1,0,0,0,60,556,1,0,0,0,62,560,1,0,0,0,64,569,1,0,0,0,
        66,578,1,0,0,0,68,608,1,0,0,0,70,613,1,0,0,0,72,622,1,0,0,0,74,636,
        1,0,0,0,76,643,1,0,0,0,78,674,1,0,0,0,80,683,1,0,0,0,82,692,1,0,
        0,0,84,697,1,0,0,0,86,701,1,0,0,0,88,703,1,0,0,0,90,705,1,0,0,0,
        92,707,1,0,0,0,94,716,1,0,0,0,96,720,1,0,0,0,98,729,1,0,0,0,100,
        733,1,0,0,0,102,735,1,0,0,0,104,739,1,0,0,0,106,741,1,0,0,0,108,
        745,1,0,0,0,110,747,1,0,0,0,112,749,1,0,0,0,114,751,1,0,0,0,116,
        760,1,0,0,0,118,774,1,0,0,0,120,776,1,0,0,0,122,778,1,0,0,0,124,
        780,1,0,0,0,126,787,1,0,0,0,128,789,1,0,0,0,130,793,1,0,0,0,132,
        802,1,0,0,0,134,804,1,0,0,0,136,820,1,0,0,0,138,829,1,0,0,0,140,
        838,1,0,0,0,142,841,1,0,0,0,144,856,1,0,0,0,146,860,1,0,0,0,148,
        864,1,0,0,0,150,867,1,0,0,0,152,874,1,0,0,0,154,885,1,0,0,0,156,
        900,1,0,0,0,158,912,1,0,0,0,160,914,1,0,0,0,162,920,1,0,0,0,164,
        922,1,0,0,0,166,924,1,0,0,0,168,926,1,0,0,0,170,937,1,0,0,0,172,
        947,1,0,0,0,174,949,1,0,0,0,176,951,1,0,0,0,178,953,1,0,0,0,180,
        955,1,0,0,0,182,959,1,0,0,0,184,962,1,0,0,0,186,966,1,0,0,0,188,
        974,1,0,0,0,190,978,1,0,0,0,192,981,1,0,0,0,194,985,1,0,0,0,196,
        990,1,0,0,0,198,996,1,0,0,0,200,1002,1,0,0,0,202,1011,1,0,0,0,204,
        1024,1,0,0,0,206,1035,1,0,0,0,208,1040,1,0,0,0,210,1044,1,0,0,0,
        212,1056,1,0,0,0,214,1058,1,0,0,0,216,1066,1,0,0,0,218,1068,1,0,
        0,0,220,1070,1,0,0,0,222,1072,1,0,0,0,224,1074,1,0,0,0,226,1076,
        1,0,0,0,228,1078,1,0,0,0,230,1080,1,0,0,0,232,1111,1,0,0,0,234,1140,
        1,0,0,0,236,1142,1,0,0,0,238,1147,1,0,0,0,240,1151,1,0,0,0,242,1153,
        1,0,0,0,244,1161,1,0,0,0,246,1163,1,0,0,0,248,1165,1,0,0,0,250,1168,
        1,0,0,0,252,1172,1,0,0,0,254,1176,1,0,0,0,256,1186,1,0,0,0,258,1201,
        1,0,0,0,260,1213,1,0,0,0,262,1226,1,0,0,0,264,1231,1,0,0,0,266,1235,
        1,0,0,0,268,1251,1,0,0,0,270,1262,1,0,0,0,272,1267,1,0,0,0,274,1272,
        1,0,0,0,276,1276,1,0,0,0,278,1284,1,0,0,0,280,1289,1,0,0,0,282,1291,
        1,0,0,0,284,1293,1,0,0,0,286,1301,1,0,0,0,288,1303,1,0,0,0,290,1305,
        1,0,0,0,292,1309,1,0,0,0,294,1313,1,0,0,0,296,1318,1,0,0,0,298,1322,
        1,0,0,0,300,1327,1,0,0,0,302,1353,1,0,0,0,304,1395,1,0,0,0,306,1399,
        1,0,0,0,308,1401,1,0,0,0,310,1412,1,0,0,0,312,1419,1,0,0,0,314,1427,
        1,0,0,0,316,1435,1,0,0,0,318,1440,1,0,0,0,320,1444,1,0,0,0,322,1449,
        1,0,0,0,324,1454,1,0,0,0,326,1462,1,0,0,0,328,1467,1,0,0,0,330,1471,
        1,0,0,0,332,1476,1,0,0,0,334,1481,1,0,0,0,336,1489,1,0,0,0,338,1494,
        1,0,0,0,340,1499,1,0,0,0,342,1543,1,0,0,0,344,1548,1,0,0,0,346,1556,
        1,0,0,0,348,1561,1,0,0,0,350,1566,1,0,0,0,352,1581,1,0,0,0,354,1589,
        1,0,0,0,356,1594,1,0,0,0,358,1598,1,0,0,0,360,1603,1,0,0,0,362,1608,
        1,0,0,0,364,1617,1,0,0,0,366,1619,1,0,0,0,368,1625,1,0,0,0,370,1633,
        1,0,0,0,372,1638,1,0,0,0,374,1642,1,0,0,0,376,1648,1,0,0,0,378,1653,
        1,0,0,0,380,1658,1,0,0,0,382,1663,1,0,0,0,384,1669,1,0,0,0,386,1674,
        1,0,0,0,388,389,7,0,0,0,389,390,1,0,0,0,390,391,6,0,0,0,391,21,1,
        0,0,0,392,394,7,1,0,0,393,392,1,0,0,0,394,395,1,0,0,0,395,393,1,
        0,0,0,395,396,1,0,0,0,396,23,1,0,0,0,397,399,5,13,0,0,398,397,1,
        0,0,0,398,399,1,0,0,0,399,400,1,0,0,0,400,403,5,10,0,0,401,403,5,
        13,0,0,402,398,1,0,0,0,402,401,1,0,0,0,403,25,1,0,0,0,404,413,7,
        1,0,0,405,407,5,13,0,0,406,405,1,0,0,0,406,407,1,0,0,0,407,408,1,
        0,0,0,408,411,5,10,0,0,409,411,5,13,0,0,410,406,1,0,0,0,410,409,
        1,0,0,0,411,413,1,0,0,0,412,404,1,0,0,0,412,410,1,0,0,0,413,414,
        1,0,0,0,414,412,1,0,0,0,414,415,1,0,0,0,415,27,1,0,0,0,416,417,5,
        47,0,0,417,418,5,42,0,0,418,422,1,0,0,0,419,421,9,0,0,0,420,419,
        1,0,0,0,421,424,1,0,0,0,422,423,1,0,0,0,422,420,1,0,0,0,423,425,
        1,0,0,0,424,422,1,0,0,0,425,426,5,42,0,0,426,427,5,47,0,0,427,29,
        1,0,0,0,428,430,7,1,0,0,429,428,1,0,0,0,430,431,1,0,0,0,431,429,
        1,0,0,0,431,432,1,0,0,0,432,433,1,0,0,0,433,434,6,5,1,0,434,31,1,
        0,0,0,435,437,5,13,0,0,436,435,1,0,0,0,436,437,1,0,0,0,437,438,1,
        0,0,0,438,441,5,10,0,0,439,441,5,13,0,0,440,436,1,0,0,0,440,439,
        1,0,0,0,441,442,1,0,0,0,442,443,6,6,1,0,443,33,1,0,0,0,444,453,5,
        48,0,0,445,449,7,2,0,0,446,448,7,3,0,0,447,446,1,0,0,0,448,451,1,
        0,0,0,449,447,1,0,0,0,449,450,1,0,0,0,450,453,1,0,0,0,451,449,1,
        0,0,0,452,444,1,0,0,0,452,445,1,0,0,0,453,35,1,0,0,0,454,463,5,48,
        0,0,455,459,7,2,0,0,456,458,7,3,0,0,457,456,1,0,0,0,458,461,1,0,
        0,0,459,457,1,0,0,0,459,460,1,0,0,0,460,463,1,0,0,0,461,459,1,0,
        0,0,462,454,1,0,0,0,462,455,1,0,0,0,463,470,1,0,0,0,464,466,5,46,
        0,0,465,467,7,3,0,0,466,465,1,0,0,0,467,468,1,0,0,0,468,466,1,0,
        0,0,468,469,1,0,0,0,469,471,1,0,0,0,470,464,1,0,0,0,470,471,1,0,
        0,0,471,37,1,0,0,0,472,474,7,3,0,0,473,472,1,0,0,0,474,477,1,0,0,
        0,475,473,1,0,0,0,475,476,1,0,0,0,476,478,1,0,0,0,477,475,1,0,0,
        0,478,479,5,58,0,0,479,481,7,3,0,0,480,482,7,3,0,0,481,480,1,0,0,
        0,481,482,1,0,0,0,482,39,1,0,0,0,483,485,7,3,0,0,484,483,1,0,0,0,
        485,486,1,0,0,0,486,484,1,0,0,0,486,487,1,0,0,0,487,489,1,0,0,0,
        488,490,3,22,1,0,489,488,1,0,0,0,489,490,1,0,0,0,490,494,1,0,0,0,
        491,493,7,4,0,0,492,491,1,0,0,0,493,496,1,0,0,0,494,492,1,0,0,0,
        494,495,1,0,0,0,495,41,1,0,0,0,496,494,1,0,0,0,497,498,5,47,0,0,
        498,43,1,0,0,0,499,501,3,42,11,0,500,502,9,0,0,0,501,500,1,0,0,0,
        502,503,1,0,0,0,503,504,1,0,0,0,503,501,1,0,0,0,504,505,1,0,0,0,
        505,506,3,42,11,0,506,45,1,0,0,0,507,508,9,0,0,0,508,47,1,0,0,0,
        509,513,5,91,0,0,510,512,9,0,0,0,511,510,1,0,0,0,512,515,1,0,0,0,
        513,514,1,0,0,0,513,511,1,0,0,0,514,516,1,0,0,0,515,513,1,0,0,0,
        516,517,5,93,0,0,517,49,1,0,0,0,518,520,7,3,0,0,519,518,1,0,0,0,
        520,521,1,0,0,0,521,519,1,0,0,0,521,522,1,0,0,0,522,523,1,0,0,0,
        523,524,6,15,2,0,524,51,1,0,0,0,525,526,7,5,0,0,526,527,7,6,0,0,
        527,531,7,7,0,0,528,530,3,30,5,0,529,528,1,0,0,0,530,533,1,0,0,0,
        531,529,1,0,0,0,531,532,1,0,0,0,532,534,1,0,0,0,533,531,1,0,0,0,
        534,535,7,8,0,0,535,536,7,9,0,0,536,537,7,10,0,0,537,538,7,11,0,
        0,538,539,1,0,0,0,539,540,6,16,3,0,540,53,1,0,0,0,541,542,7,11,0,
        0,542,543,7,12,0,0,543,544,7,11,0,0,544,545,7,7,0,0,545,546,7,13,
        0,0,546,547,1,0,0,0,547,548,6,17,4,0,548,55,1,0,0,0,549,550,5,43,
        0,0,550,57,1,0,0,0,551,552,5,105,0,0,552,553,5,110,0,0,553,554,5,
        116,0,0,554,555,5,111,0,0,555,59,1,0,0,0,556,557,7,14,0,0,557,558,
        1,0,0,0,558,559,6,20,5,0,559,61,1,0,0,0,560,561,7,15,0,0,561,562,
        5,104,0,0,562,563,5,111,0,0,563,564,5,105,0,0,564,565,5,99,0,0,565,
        566,5,101,0,0,566,567,1,0,0,0,567,568,6,21,6,0,568,63,1,0,0,0,569,
        570,7,6,0,0,570,571,5,112,0,0,571,572,5,116,0,0,572,573,5,105,0,
        0,573,574,5,111,0,0,574,575,5,110,0,0,575,576,1,0,0,0,576,577,6,
        22,6,0,577,65,1,0,0,0,578,579,7,11,0,0,579,580,7,10,0,0,580,581,
        7,6,0,0,581,582,7,10,0,0,582,583,1,0,0,0,583,584,6,23,7,0,584,67,
        1,0,0,0,585,586,7,16,0,0,586,609,7,10,0,0,587,588,7,10,0,0,588,589,
        5,111,0,0,589,590,5,114,0,0,590,591,5,110,0,0,591,592,5,105,0,0,
        592,593,5,110,0,0,593,609,5,103,0,0,594,595,7,17,0,0,595,609,7,10,
        0,0,596,597,7,16,0,0,597,598,5,102,0,0,598,599,5,116,0,0,599,600,
        5,101,0,0,600,601,5,114,0,0,601,602,5,110,0,0,602,603,5,111,0,0,
        603,604,5,111,0,0,604,609,5,110,0,0,605,606,7,18,0,0,606,607,7,6,
        0,0,607,609,7,19,0,0,608,585,1,0,0,0,608,587,1,0,0,0,608,594,1,0,
        0,0,608,596,1,0,0,0,608,605,1,0,0,0,609,611,1,0,0,0,610,612,5,58,
        0,0,611,610,1,0,0,0,611,612,1,0,0,0,612,69,1,0,0,0,613,614,5,100,
        0,0,614,615,5,101,0,0,615,616,5,102,0,0,616,617,5,105,0,0,617,618,
        5,110,0,0,618,619,5,101,0,0,619,620,1,0,0,0,620,621,6,25,8,0,621,
        71,1,0,0,0,622,623,5,110,0,0,623,624,5,101,0,0,624,625,5,119,0,0,
        625,626,5,77,0,0,626,627,5,111,0,0,627,628,5,118,0,0,628,629,5,101,
        0,0,629,630,5,109,0,0,630,631,5,101,0,0,631,632,5,110,0,0,632,633,
        5,116,0,0,633,634,1,0,0,0,634,635,6,26,9,0,635,73,1,0,0,0,636,637,
        7,7,0,0,637,638,5,101,0,0,638,639,5,115,0,0,639,640,5,116,0,0,640,
        641,1,0,0,0,641,642,6,27,10,0,642,75,1,0,0,0,643,644,7,10,0,0,644,
        645,7,9,0,0,645,649,7,20,0,0,646,648,3,22,1,0,647,646,1,0,0,0,648,
        651,1,0,0,0,649,647,1,0,0,0,649,650,1,0,0,0,650,664,1,0,0,0,651,
        649,1,0,0,0,652,656,5,45,0,0,653,655,3,22,1,0,654,653,1,0,0,0,655,
        658,1,0,0,0,656,654,1,0,0,0,656,657,1,0,0,0,657,660,1,0,0,0,658,
        656,1,0,0,0,659,661,7,3,0,0,660,659,1,0,0,0,661,662,1,0,0,0,662,
        660,1,0,0,0,662,663,1,0,0,0,663,665,1,0,0,0,664,652,1,0,0,0,664,
        665,1,0,0,0,665,669,1,0,0,0,666,668,3,22,1,0,667,666,1,0,0,0,668,
        671,1,0,0,0,669,667,1,0,0,0,669,670,1,0,0,0,670,672,1,0,0,0,671,
        669,1,0,0,0,672,673,5,58,0,0,673,77,1,0,0,0,674,678,5,34,0,0,675,
        677,9,0,0,0,676,675,1,0,0,0,677,680,1,0,0,0,678,679,1,0,0,0,678,
        676,1,0,0,0,679,681,1,0,0,0,680,678,1,0,0,0,681,682,5,34,0,0,682,
        79,1,0,0,0,683,687,5,96,0,0,684,686,9,0,0,0,685,684,1,0,0,0,686,
        689,1,0,0,0,687,688,1,0,0,0,687,685,1,0,0,0,688,690,1,0,0,0,689,
        687,1,0,0,0,690,691,5,96,0,0,691,81,1,0,0,0,692,693,5,40,0,0,693,
        694,1,0,0,0,694,695,6,31,11,0,695,83,1,0,0,0,696,698,7,21,0,0,697,
        696,1,0,0,0,698,699,1,0,0,0,699,697,1,0,0,0,699,700,1,0,0,0,700,
        85,1,0,0,0,701,702,5,123,0,0,702,87,1,0,0,0,703,704,5,125,0,0,704,
        89,1,0,0,0,705,706,5,58,0,0,706,91,1,0,0,0,707,711,5,42,0,0,708,
        710,8,22,0,0,709,708,1,0,0,0,710,713,1,0,0,0,711,709,1,0,0,0,711,
        712,1,0,0,0,712,714,1,0,0,0,713,711,1,0,0,0,714,715,3,24,2,0,715,
        93,1,0,0,0,716,717,3,28,4,0,717,718,1,0,0,0,718,719,6,37,12,0,719,
        95,1,0,0,0,720,724,7,23,0,0,721,723,7,24,0,0,722,721,1,0,0,0,723,
        726,1,0,0,0,724,722,1,0,0,0,724,725,1,0,0,0,725,727,1,0,0,0,726,
        724,1,0,0,0,727,728,6,38,11,0,728,97,1,0,0,0,729,730,7,25,0,0,730,
        731,1,0,0,0,731,732,6,39,13,0,732,99,1,0,0,0,733,734,3,46,13,0,734,
        101,1,0,0,0,735,736,3,26,3,0,736,737,1,0,0,0,737,738,6,41,1,0,738,
        103,1,0,0,0,739,740,5,40,0,0,740,105,1,0,0,0,741,742,5,41,0,0,742,
        743,1,0,0,0,743,744,6,43,14,0,744,107,1,0,0,0,745,746,5,58,0,0,746,
        109,1,0,0,0,747,748,5,44,0,0,748,111,1,0,0,0,749,750,5,34,0,0,750,
        113,1,0,0,0,751,755,5,34,0,0,752,754,9,0,0,0,753,752,1,0,0,0,754,
        757,1,0,0,0,755,756,1,0,0,0,755,753,1,0,0,0,756,758,1,0,0,0,757,
        755,1,0,0,0,758,759,5,34,0,0,759,115,1,0,0,0,760,761,5,34,0,0,761,
        762,5,34,0,0,762,763,5,34,0,0,763,767,1,0,0,0,764,766,9,0,0,0,765,
        764,1,0,0,0,766,769,1,0,0,0,767,768,1,0,0,0,767,765,1,0,0,0,768,
        770,1,0,0,0,769,767,1,0,0,0,770,771,5,34,0,0,771,772,5,34,0,0,772,
        773,5,34,0,0,773,117,1,0,0,0,774,775,3,38,9,0,775,119,1,0,0,0,776,
        777,3,36,8,0,777,121,1,0,0,0,778,779,3,40,10,0,779,123,1,0,0,0,780,
        784,7,24,0,0,781,783,7,26,0,0,782,781,1,0,0,0,783,786,1,0,0,0,784,
        782,1,0,0,0,784,785,1,0,0,0,785,125,1,0,0,0,786,784,1,0,0,0,787,
        788,3,46,13,0,788,127,1,0,0,0,789,790,3,26,3,0,790,791,1,0,0,0,791,
        792,6,54,1,0,792,129,1,0,0,0,793,797,3,84,32,0,794,796,3,84,32,0,
        795,794,1,0,0,0,796,799,1,0,0,0,797,795,1,0,0,0,797,798,1,0,0,0,
        798,800,1,0,0,0,799,797,1,0,0,0,800,801,6,55,14,0,801,131,1,0,0,
        0,802,803,3,46,13,0,803,133,1,0,0,0,804,806,7,27,0,0,805,807,3,146,
        63,0,806,805,1,0,0,0,807,808,1,0,0,0,808,806,1,0,0,0,808,809,1,0,
        0,0,809,135,1,0,0,0,810,812,7,3,0,0,811,810,1,0,0,0,812,813,1,0,
        0,0,813,811,1,0,0,0,813,814,1,0,0,0,814,816,1,0,0,0,815,817,7,1,
        0,0,816,815,1,0,0,0,817,818,1,0,0,0,818,816,1,0,0,0,818,819,1,0,
        0,0,819,821,1,0,0,0,820,811,1,0,0,0,820,821,1,0,0,0,821,822,1,0,
        0,0,822,826,7,28,0,0,823,825,7,29,0,0,824,823,1,0,0,0,825,828,1,
        0,0,0,826,824,1,0,0,0,826,827,1,0,0,0,827,137,1,0,0,0,828,826,1,
        0,0,0,829,833,3,34,7,0,830,832,7,30,0,0,831,830,1,0,0,0,832,835,
        1,0,0,0,833,831,1,0,0,0,833,834,1,0,0,0,834,836,1,0,0,0,835,833,
        1,0,0,0,836,837,7,27,0,0,837,139,1,0,0,0,838,839,5,43,0,0,839,141,
        1,0,0,0,840,842,3,34,7,0,841,840,1,0,0,0,841,842,1,0,0,0,842,843,
        1,0,0,0,843,844,5,120,0,0,844,853,3,136,58,0,845,847,5,43,0,0,846,
        848,3,34,7,0,847,846,1,0,0,0,847,848,1,0,0,0,848,849,1,0,0,0,849,
        850,5,120,0,0,850,852,3,136,58,0,851,845,1,0,0,0,852,855,1,0,0,0,
        853,851,1,0,0,0,853,854,1,0,0,0,854,143,1,0,0,0,855,853,1,0,0,0,
        856,857,7,31,0,0,857,858,1,0,0,0,858,859,6,62,15,0,859,145,1,0,0,
        0,860,861,3,22,1,0,861,862,1,0,0,0,862,863,6,63,1,0,863,147,1,0,
        0,0,864,865,3,46,13,0,865,149,1,0,0,0,866,868,7,1,0,0,867,866,1,
        0,0,0,868,869,1,0,0,0,869,867,1,0,0,0,869,870,1,0,0,0,870,871,1,
        0,0,0,871,872,6,65,1,0,872,151,1,0,0,0,873,875,7,3,0,0,874,873,1,
        0,0,0,875,876,1,0,0,0,876,874,1,0,0,0,876,877,1,0,0,0,877,878,1,
        0,0,0,878,880,5,45,0,0,879,881,7,3,0,0,880,879,1,0,0,0,881,882,1,
        0,0,0,882,880,1,0,0,0,882,883,1,0,0,0,883,153,1,0,0,0,884,886,7,
        3,0,0,885,884,1,0,0,0,886,887,1,0,0,0,887,885,1,0,0,0,887,888,1,
        0,0,0,888,895,1,0,0,0,889,891,5,46,0,0,890,892,7,3,0,0,891,890,1,
        0,0,0,892,893,1,0,0,0,893,891,1,0,0,0,893,894,1,0,0,0,894,896,1,
        0,0,0,895,889,1,0,0,0,896,897,1,0,0,0,897,895,1,0,0,0,897,898,1,
        0,0,0,898,155,1,0,0,0,899,901,7,3,0,0,900,899,1,0,0,0,901,902,1,
        0,0,0,902,900,1,0,0,0,902,903,1,0,0,0,903,910,1,0,0,0,904,906,5,
        46,0,0,905,907,7,3,0,0,906,905,1,0,0,0,907,908,1,0,0,0,908,906,1,
        0,0,0,908,909,1,0,0,0,909,911,1,0,0,0,910,904,1,0,0,0,910,911,1,
        0,0,0,911,157,1,0,0,0,912,913,5,77,0,0,913,159,1,0,0,0,914,915,7,
        16,0,0,915,916,7,10,0,0,916,917,7,7,0,0,917,918,7,16,0,0,918,919,
        7,17,0,0,919,161,1,0,0,0,920,921,5,40,0,0,921,163,1,0,0,0,922,923,
        5,41,0,0,923,165,1,0,0,0,924,925,5,45,0,0,925,167,1,0,0,0,926,927,
        7,15,0,0,927,928,5,97,0,0,928,929,5,108,0,0,929,935,1,0,0,0,930,
        931,5,111,0,0,931,932,5,114,0,0,932,933,5,105,0,0,933,934,5,101,
        0,0,934,936,5,115,0,0,935,930,1,0,0,0,935,936,1,0,0,0,936,169,1,
        0,0,0,937,945,7,18,0,0,938,939,5,97,0,0,939,940,5,116,0,0,940,941,
        5,116,0,0,941,943,1,0,0,0,942,944,5,115,0,0,943,942,1,0,0,0,943,
        944,1,0,0,0,944,946,1,0,0,0,945,938,1,0,0,0,945,946,1,0,0,0,946,
        171,1,0,0,0,947,948,3,38,9,0,948,173,1,0,0,0,949,950,5,44,0,0,950,
        175,1,0,0,0,951,952,5,47,0,0,952,177,1,0,0,0,953,954,5,120,0,0,954,
        179,1,0,0,0,955,956,5,64,0,0,956,957,1,0,0,0,957,958,6,80,16,0,958,
        181,1,0,0,0,959,960,7,9,0,0,960,961,5,110,0,0,961,183,1,0,0,0,962,
        963,7,5,0,0,963,964,5,111,0,0,964,965,5,114,0,0,965,185,1,0,0,0,
        966,967,7,16,0,0,967,968,7,8,0,0,968,187,1,0,0,0,969,971,5,13,0,
        0,970,969,1,0,0,0,970,971,1,0,0,0,971,972,1,0,0,0,972,975,5,10,0,
        0,973,975,7,32,0,0,974,970,1,0,0,0,974,973,1,0,0,0,975,976,1,0,0,
        0,976,977,6,84,17,0,977,189,1,0,0,0,978,979,3,48,14,0,979,191,1,
        0,0,0,980,982,8,33,0,0,981,980,1,0,0,0,982,983,1,0,0,0,983,981,1,
        0,0,0,983,984,1,0,0,0,984,193,1,0,0,0,985,986,3,46,13,0,986,987,
        1,0,0,0,987,988,6,87,18,0,988,195,1,0,0,0,989,991,7,1,0,0,990,989,
        1,0,0,0,991,992,1,0,0,0,992,990,1,0,0,0,992,993,1,0,0,0,993,994,
        1,0,0,0,994,995,6,88,1,0,995,197,1,0,0,0,996,997,3,48,14,0,997,998,
        1,0,0,0,998,999,6,89,19,0,999,1000,6,89,14,0,1000,199,1,0,0,0,1001,
        1003,7,34,0,0,1002,1001,1,0,0,0,1003,1004,1,0,0,0,1004,1002,1,0,
        0,0,1004,1005,1,0,0,0,1005,1006,1,0,0,0,1006,1007,5,44,0,0,1007,
        1008,1,0,0,0,1008,1009,6,90,19,0,1009,201,1,0,0,0,1010,1012,7,34,
        0,0,1011,1010,1,0,0,0,1012,1013,1,0,0,0,1013,1011,1,0,0,0,1013,1014,
        1,0,0,0,1014,1016,1,0,0,0,1015,1017,7,1,0,0,1016,1015,1,0,0,0,1017,
        1018,1,0,0,0,1018,1016,1,0,0,0,1018,1019,1,0,0,0,1019,1020,1,0,0,
        0,1020,1021,6,91,19,0,1021,1022,6,91,14,0,1022,203,1,0,0,0,1023,
        1025,7,34,0,0,1024,1023,1,0,0,0,1025,1026,1,0,0,0,1026,1024,1,0,
        0,0,1026,1027,1,0,0,0,1027,1028,1,0,0,0,1028,1029,6,92,14,0,1029,
        205,1,0,0,0,1030,1032,5,13,0,0,1031,1030,1,0,0,0,1031,1032,1,0,0,
        0,1032,1033,1,0,0,0,1033,1036,5,10,0,0,1034,1036,7,32,0,0,1035,1031,
        1,0,0,0,1035,1034,1,0,0,0,1036,1037,1,0,0,0,1037,1038,6,93,20,0,
        1038,1039,6,93,17,0,1039,207,1,0,0,0,1040,1041,3,26,3,0,1041,1042,
        1,0,0,0,1042,1043,6,94,1,0,1043,209,1,0,0,0,1044,1045,5,47,0,0,1045,
        1046,5,42,0,0,1046,1050,1,0,0,0,1047,1049,9,0,0,0,1048,1047,1,0,
        0,0,1049,1052,1,0,0,0,1050,1051,1,0,0,0,1050,1048,1,0,0,0,1051,1053,
        1,0,0,0,1052,1050,1,0,0,0,1053,1054,5,42,0,0,1054,1055,5,47,0,0,
        1055,211,1,0,0,0,1056,1057,3,44,12,0,1057,213,1,0,0,0,1058,1060,
        5,34,0,0,1059,1061,7,26,0,0,1060,1059,1,0,0,0,1061,1062,1,0,0,0,
        1062,1060,1,0,0,0,1062,1063,1,0,0,0,1063,1064,1,0,0,0,1064,1065,
        5,34,0,0,1065,215,1,0,0,0,1066,1067,5,34,0,0,1067,217,1,0,0,0,1068,
        1069,5,58,0,0,1069,219,1,0,0,0,1070,1071,5,63,0,0,1071,221,1,0,0,
        0,1072,1073,5,44,0,0,1073,223,1,0,0,0,1074,1075,5,40,0,0,1075,225,
        1,0,0,0,1076,1077,5,41,0,0,1077,227,1,0,0,0,1078,1079,5,123,0,0,
        1079,229,1,0,0,0,1080,1081,5,125,0,0,1081,1082,1,0,0,0,1082,1083,
        6,105,14,0,1083,231,1,0,0,0,1084,1085,5,98,0,0,1085,1086,5,97,0,
        0,1086,1087,5,115,0,0,1087,1112,5,101,0,0,1088,1089,5,112,0,0,1089,
        1090,5,114,0,0,1090,1091,5,111,0,0,1091,1092,5,103,0,0,1092,1093,
        5,114,0,0,1093,1094,5,101,0,0,1094,1095,5,115,0,0,1095,1096,5,115,
        0,0,1096,1097,5,105,0,0,1097,1098,5,110,0,0,1098,1112,5,103,0,0,
        1099,1100,5,110,0,0,1100,1101,5,111,0,0,1101,1102,5,95,0,0,1102,
        1103,5,109,0,0,1103,1104,5,111,0,0,1104,1105,5,118,0,0,1105,1106,
        5,101,0,0,1106,1107,5,109,0,0,1107,1108,5,101,0,0,1108,1109,5,110,
        0,0,1109,1110,5,116,0,0,1110,1112,5,115,0,0,1111,1084,1,0,0,0,1111,
        1088,1,0,0,0,1111,1099,1,0,0,0,1112,233,1,0,0,0,1113,1114,5,105,
        0,0,1114,1115,5,110,0,0,1115,1141,5,116,0,0,1116,1117,5,100,0,0,
        1117,1118,5,111,0,0,1118,1119,5,117,0,0,1119,1120,5,98,0,0,1120,
        1121,5,108,0,0,1121,1141,5,101,0,0,1122,1123,5,115,0,0,1123,1124,
        5,116,0,0,1124,1125,5,114,0,0,1125,1126,5,105,0,0,1126,1127,5,110,
        0,0,1127,1141,5,103,0,0,1128,1129,5,119,0,0,1129,1130,5,111,0,0,
        1130,1131,5,114,0,0,1131,1132,5,107,0,0,1132,1133,5,111,0,0,1133,
        1134,5,117,0,0,1134,1135,5,116,0,0,1135,1141,5,115,0,0,1136,1137,
        5,116,0,0,1137,1138,5,105,0,0,1138,1139,5,109,0,0,1139,1141,5,101,
        0,0,1140,1113,1,0,0,0,1140,1116,1,0,0,0,1140,1122,1,0,0,0,1140,1128,
        1,0,0,0,1140,1136,1,0,0,0,1141,235,1,0,0,0,1142,1143,5,101,0,0,1143,
        1144,5,110,0,0,1144,1145,5,117,0,0,1145,1146,5,109,0,0,1146,237,
        1,0,0,0,1147,1148,3,26,3,0,1148,1149,1,0,0,0,1149,1150,6,109,1,0,
        1150,239,1,0,0,0,1151,1152,5,34,0,0,1152,241,1,0,0,0,1153,1155,5,
        34,0,0,1154,1156,7,26,0,0,1155,1154,1,0,0,0,1156,1157,1,0,0,0,1157,
        1155,1,0,0,0,1157,1158,1,0,0,0,1158,1159,1,0,0,0,1159,1160,5,34,
        0,0,1160,243,1,0,0,0,1161,1162,5,58,0,0,1162,245,1,0,0,0,1163,1164,
        5,44,0,0,1164,247,1,0,0,0,1165,1166,5,59,0,0,1166,249,1,0,0,0,1167,
        1169,7,35,0,0,1168,1167,1,0,0,0,1169,1170,1,0,0,0,1170,1168,1,0,
        0,0,1170,1171,1,0,0,0,1171,251,1,0,0,0,1172,1173,3,26,3,0,1173,1174,
        1,0,0,0,1174,1175,6,116,1,0,1175,253,1,0,0,0,1176,1177,7,7,0,0,1177,
        1178,5,111,0,0,1178,1179,5,117,0,0,1179,1180,5,110,0,0,1180,1181,
        5,100,0,0,1181,1182,5,115,0,0,1182,1183,1,0,0,0,1183,1184,6,117,
        21,0,1184,255,1,0,0,0,1185,1187,3,22,1,0,1186,1185,1,0,0,0,1186,
        1187,1,0,0,0,1187,1188,1,0,0,0,1188,1189,7,36,0,0,1189,1190,5,117,
        0,0,1190,1191,5,112,0,0,1191,1192,5,101,0,0,1192,1193,5,114,0,0,
        1193,1194,5,115,0,0,1194,1195,5,101,0,0,1195,1196,5,116,0,0,1196,
        1197,5,115,0,0,1197,1198,1,0,0,0,1198,1199,6,118,17,0,1199,257,1,
        0,0,0,1200,1202,3,22,1,0,1201,1200,1,0,0,0,1201,1202,1,0,0,0,1202,
        1203,1,0,0,0,1203,1204,7,36,0,0,1204,1205,5,101,0,0,1205,1206,5,
        116,0,0,1206,1208,1,0,0,0,1207,1209,7,36,0,0,1208,1207,1,0,0,0,1208,
        1209,1,0,0,0,1209,1210,1,0,0,0,1210,1211,6,119,17,0,1211,259,1,0,
        0,0,1212,1214,3,22,1,0,1213,1212,1,0,0,0,1213,1214,1,0,0,0,1214,
        1222,1,0,0,0,1215,1216,7,10,0,0,1216,1217,7,9,0,0,1217,1223,7,20,
        0,0,1218,1219,7,36,0,0,1219,1220,7,11,0,0,1220,1223,7,15,0,0,1221,
        1223,7,37,0,0,1222,1215,1,0,0,0,1222,1218,1,0,0,0,1222,1221,1,0,
        0,0,1223,1224,1,0,0,0,1224,1225,6,120,22,0,1225,261,1,0,0,0,1226,
        1227,9,0,0,0,1227,1228,1,0,0,0,1228,1229,6,121,18,0,1229,1230,6,
        121,17,0,1230,263,1,0,0,0,1231,1232,3,26,3,0,1232,1233,1,0,0,0,1233,
        1234,6,122,1,0,1234,265,1,0,0,0,1235,1236,7,5,0,0,1236,1237,7,6,
        0,0,1237,1241,7,7,0,0,1238,1240,3,30,5,0,1239,1238,1,0,0,0,1240,
        1243,1,0,0,0,1241,1239,1,0,0,0,1241,1242,1,0,0,0,1242,1244,1,0,0,
        0,1243,1241,1,0,0,0,1244,1245,7,8,0,0,1245,1246,7,9,0,0,1246,1247,
        7,10,0,0,1247,1248,7,11,0,0,1248,1249,1,0,0,0,1249,1250,6,123,23,
        0,1250,267,1,0,0,0,1251,1256,3,34,7,0,1252,1253,5,45,0,0,1253,1255,
        3,34,7,0,1254,1252,1,0,0,0,1255,1258,1,0,0,0,1256,1254,1,0,0,0,1256,
        1257,1,0,0,0,1257,1260,1,0,0,0,1258,1256,1,0,0,0,1259,1261,5,45,
        0,0,1260,1259,1,0,0,0,1260,1261,1,0,0,0,1261,269,1,0,0,0,1262,1263,
        7,14,0,0,1263,1264,1,0,0,0,1264,1265,6,125,5,0,1265,1266,6,125,24,
        0,1266,271,1,0,0,0,1267,1268,9,0,0,0,1268,1269,1,0,0,0,1269,1270,
        6,126,18,0,1270,1271,6,126,17,0,1271,273,1,0,0,0,1272,1273,3,22,
        1,0,1273,1274,1,0,0,0,1274,1275,6,127,1,0,1275,275,1,0,0,0,1276,
        1277,7,16,0,0,1277,1278,7,10,0,0,1278,1279,7,7,0,0,1279,1280,7,16,
        0,0,1280,1281,7,17,0,0,1281,1282,1,0,0,0,1282,1283,6,128,17,0,1283,
        277,1,0,0,0,1284,1285,9,0,0,0,1285,1286,1,0,0,0,1286,1287,6,129,
        18,0,1287,1288,6,129,17,0,1288,279,1,0,0,0,1289,1290,7,27,0,0,1290,
        281,1,0,0,0,1291,1292,5,45,0,0,1292,283,1,0,0,0,1293,1294,5,58,0,
        0,1294,1295,1,0,0,0,1295,1296,6,132,14,0,1296,285,1,0,0,0,1297,1298,
        7,10,0,0,1298,1299,7,9,0,0,1299,1302,7,20,0,0,1300,1302,7,10,0,0,
        1301,1297,1,0,0,0,1301,1300,1,0,0,0,1302,287,1,0,0,0,1303,1304,3,
        34,7,0,1304,289,1,0,0,0,1305,1306,3,22,1,0,1306,1307,1,0,0,0,1307,
        1308,6,135,1,0,1308,291,1,0,0,0,1309,1310,3,24,2,0,1310,1311,1,0,
        0,0,1311,1312,6,136,1,0,1312,293,1,0,0,0,1313,1314,3,46,13,0,1314,
        1315,1,0,0,0,1315,1316,6,137,18,0,1316,1317,6,137,17,0,1317,295,
        1,0,0,0,1318,1319,3,22,1,0,1319,1320,1,0,0,0,1320,1321,6,138,1,0,
        1321,297,1,0,0,0,1322,1323,3,24,2,0,1323,1324,1,0,0,0,1324,1325,
        6,139,1,0,1325,1326,6,139,17,0,1326,299,1,0,0,0,1327,1328,7,16,0,
        0,1328,1332,7,36,0,0,1329,1331,7,1,0,0,1330,1329,1,0,0,0,1331,1334,
        1,0,0,0,1332,1330,1,0,0,0,1332,1333,1,0,0,0,1333,1341,1,0,0,0,1334,
        1332,1,0,0,0,1335,1342,5,45,0,0,1336,1338,7,1,0,0,1337,1336,1,0,
        0,0,1338,1339,1,0,0,0,1339,1337,1,0,0,0,1339,1340,1,0,0,0,1340,1342,
        1,0,0,0,1341,1335,1,0,0,0,1341,1337,1,0,0,0,1342,1343,1,0,0,0,1343,
        1344,7,20,0,0,1344,1345,7,11,0,0,1345,1346,7,11,0,0,1346,1347,7,
        19,0,0,1347,1348,7,11,0,0,1348,1349,7,19,0,0,1349,1350,1,0,0,0,1350,
        1351,6,140,25,0,1351,301,1,0,0,0,1352,1354,7,3,0,0,1353,1352,1,0,
        0,0,1354,1355,1,0,0,0,1355,1353,1,0,0,0,1355,1356,1,0,0,0,1356,1363,
        1,0,0,0,1357,1359,5,46,0,0,1358,1360,7,3,0,0,1359,1358,1,0,0,0,1360,
        1361,1,0,0,0,1361,1359,1,0,0,0,1361,1362,1,0,0,0,1362,1364,1,0,0,
        0,1363,1357,1,0,0,0,1363,1364,1,0,0,0,1364,303,1,0,0,0,1365,1366,
        7,36,0,0,1366,1367,7,11,0,0,1367,1396,7,15,0,0,1368,1369,7,10,0,
        0,1369,1370,7,9,0,0,1370,1396,7,20,0,0,1371,1372,7,36,0,0,1372,1373,
        7,11,0,0,1373,1374,7,15,0,0,1374,1396,7,36,0,0,1375,1376,7,10,0,
        0,1376,1377,7,9,0,0,1377,1378,7,20,0,0,1378,1396,7,36,0,0,1379,1396,
        7,36,0,0,1380,1381,7,36,0,0,1381,1382,7,11,0,0,1382,1383,7,15,0,
        0,1383,1384,7,6,0,0,1384,1385,7,20,0,0,1385,1386,7,19,0,0,1386,1396,
        7,36,0,0,1387,1396,7,10,0,0,1388,1389,7,10,0,0,1389,1390,7,9,0,0,
        1390,1391,7,20,0,0,1391,1392,7,38,0,0,1392,1393,7,8,0,0,1393,1394,
        7,11,0,0,1394,1396,7,36,0,0,1395,1365,1,0,0,0,1395,1368,1,0,0,0,
        1395,1371,1,0,0,0,1395,1375,1,0,0,0,1395,1379,1,0,0,0,1395,1380,
        1,0,0,0,1395,1387,1,0,0,0,1395,1388,1,0,0,0,1396,1397,1,0,0,0,1397,
        1398,6,142,26,0,1398,305,1,0,0,0,1399,1400,5,47,0,0,1400,307,1,0,
        0,0,1401,1402,7,36,0,0,1402,1403,7,38,0,0,1403,1404,7,17,0,0,1404,
        1405,7,11,0,0,1405,1406,7,7,0,0,1406,1407,7,36,0,0,1407,1408,7,11,
        0,0,1408,1409,7,8,0,0,1409,1410,1,0,0,0,1410,1411,6,144,25,0,1411,
        309,1,0,0,0,1412,1413,7,11,0,0,1413,1414,7,10,0,0,1414,1415,7,6,
        0,0,1415,1416,7,10,0,0,1416,1417,1,0,0,0,1417,1418,6,145,27,0,1418,
        311,1,0,0,0,1419,1420,7,7,0,0,1420,1421,7,16,0,0,1421,1422,7,8,0,
        0,1422,1423,7,9,0,0,1423,1424,7,6,0,0,1424,1425,1,0,0,0,1425,1426,
        6,146,28,0,1426,313,1,0,0,0,1427,1428,7,9,0,0,1428,1429,7,20,0,0,
        1429,1430,7,8,0,0,1430,1431,7,6,0,0,1431,1432,1,0,0,0,1432,1433,
        6,147,29,0,1433,1434,6,147,17,0,1434,315,1,0,0,0,1435,1436,8,22,
        0,0,1436,1437,1,0,0,0,1437,1438,6,148,18,0,1438,1439,6,148,17,0,
        1439,317,1,0,0,0,1440,1441,3,22,1,0,1441,1442,1,0,0,0,1442,1443,
        6,149,1,0,1443,319,1,0,0,0,1444,1445,3,24,2,0,1445,1446,1,0,0,0,
        1446,1447,6,150,1,0,1447,1448,6,150,17,0,1448,321,1,0,0,0,1449,1450,
        5,47,0,0,1450,1451,1,0,0,0,1451,1452,6,151,30,0,1452,1453,6,151,
        31,0,1453,323,1,0,0,0,1454,1455,7,9,0,0,1455,1456,7,20,0,0,1456,
        1457,7,8,0,0,1457,1458,7,6,0,0,1458,1459,1,0,0,0,1459,1460,6,152,
        29,0,1460,1461,6,152,17,0,1461,325,1,0,0,0,1462,1463,9,0,0,0,1463,
        1464,1,0,0,0,1464,1465,6,153,18,0,1465,1466,6,153,17,0,1466,327,
        1,0,0,0,1467,1468,3,22,1,0,1468,1469,1,0,0,0,1469,1470,6,154,1,0,
        1470,329,1,0,0,0,1471,1472,3,24,2,0,1472,1473,1,0,0,0,1473,1474,
        6,155,1,0,1474,1475,6,155,17,0,1475,331,1,0,0,0,1476,1477,5,47,0,
        0,1477,1478,1,0,0,0,1478,1479,6,156,30,0,1479,1480,6,156,31,0,1480,
        333,1,0,0,0,1481,1482,7,9,0,0,1482,1483,7,20,0,0,1483,1484,7,8,0,
        0,1484,1485,7,6,0,0,1485,1486,1,0,0,0,1486,1487,6,157,29,0,1487,
        1488,6,157,17,0,1488,335,1,0,0,0,1489,1490,9,0,0,0,1490,1491,1,0,
        0,0,1491,1492,6,158,18,0,1492,1493,6,158,17,0,1493,337,1,0,0,0,1494,
        1495,3,22,1,0,1495,1496,1,0,0,0,1496,1497,6,159,1,0,1497,339,1,0,
        0,0,1498,1500,7,3,0,0,1499,1498,1,0,0,0,1500,1501,1,0,0,0,1501,1499,
        1,0,0,0,1501,1502,1,0,0,0,1502,1509,1,0,0,0,1503,1505,5,46,0,0,1504,
        1506,7,3,0,0,1505,1504,1,0,0,0,1506,1507,1,0,0,0,1507,1505,1,0,0,
        0,1507,1508,1,0,0,0,1508,1510,1,0,0,0,1509,1503,1,0,0,0,1509,1510,
        1,0,0,0,1510,1511,1,0,0,0,1511,1512,6,160,32,0,1512,341,1,0,0,0,
        1513,1514,7,36,0,0,1514,1515,7,11,0,0,1515,1544,7,15,0,0,1516,1517,
        7,10,0,0,1517,1518,7,9,0,0,1518,1544,7,20,0,0,1519,1520,7,36,0,0,
        1520,1521,7,11,0,0,1521,1522,7,15,0,0,1522,1544,7,36,0,0,1523,1524,
        7,10,0,0,1524,1525,7,9,0,0,1525,1526,7,20,0,0,1526,1544,7,36,0,0,
        1527,1544,7,36,0,0,1528,1529,7,36,0,0,1529,1530,7,11,0,0,1530,1531,
        7,15,0,0,1531,1532,7,6,0,0,1532,1533,7,20,0,0,1533,1534,7,19,0,0,
        1534,1544,7,36,0,0,1535,1544,7,10,0,0,1536,1537,7,10,0,0,1537,1538,
        7,9,0,0,1538,1539,7,20,0,0,1539,1540,7,38,0,0,1540,1541,7,8,0,0,
        1541,1542,7,11,0,0,1542,1544,7,36,0,0,1543,1513,1,0,0,0,1543,1516,
        1,0,0,0,1543,1519,1,0,0,0,1543,1523,1,0,0,0,1543,1527,1,0,0,0,1543,
        1528,1,0,0,0,1543,1535,1,0,0,0,1543,1536,1,0,0,0,1544,1545,1,0,0,
        0,1545,1546,6,161,33,0,1546,1547,6,161,26,0,1547,343,1,0,0,0,1548,
        1549,7,9,0,0,1549,1550,7,20,0,0,1550,1551,7,8,0,0,1551,1552,7,6,
        0,0,1552,1553,1,0,0,0,1553,1554,6,162,29,0,1554,1555,6,162,17,0,
        1555,345,1,0,0,0,1556,1557,9,0,0,0,1557,1558,1,0,0,0,1558,1559,6,
        163,18,0,1559,1560,6,163,17,0,1560,347,1,0,0,0,1561,1562,3,22,1,
        0,1562,1563,1,0,0,0,1563,1564,6,164,1,0,1564,349,1,0,0,0,1565,1567,
        7,3,0,0,1566,1565,1,0,0,0,1567,1568,1,0,0,0,1568,1566,1,0,0,0,1568,
        1569,1,0,0,0,1569,1576,1,0,0,0,1570,1572,5,46,0,0,1571,1573,7,3,
        0,0,1572,1571,1,0,0,0,1573,1574,1,0,0,0,1574,1572,1,0,0,0,1574,1575,
        1,0,0,0,1575,1577,1,0,0,0,1576,1570,1,0,0,0,1576,1577,1,0,0,0,1577,
        1578,1,0,0,0,1578,1579,6,165,32,0,1579,1580,6,165,25,0,1580,351,
        1,0,0,0,1581,1582,7,9,0,0,1582,1583,7,20,0,0,1583,1584,7,8,0,0,1584,
        1585,7,6,0,0,1585,1586,1,0,0,0,1586,1587,6,166,29,0,1587,1588,6,
        166,17,0,1588,353,1,0,0,0,1589,1590,9,0,0,0,1590,1591,1,0,0,0,1591,
        1592,6,167,18,0,1592,1593,6,167,17,0,1593,355,1,0,0,0,1594,1595,
        3,22,1,0,1595,1596,1,0,0,0,1596,1597,6,168,1,0,1597,357,1,0,0,0,
        1598,1599,3,24,2,0,1599,1600,1,0,0,0,1600,1601,6,169,1,0,1601,1602,
        6,169,17,0,1602,359,1,0,0,0,1603,1606,3,34,7,0,1604,1605,5,46,0,
        0,1605,1607,3,34,7,0,1606,1604,1,0,0,0,1606,1607,1,0,0,0,1607,361,
        1,0,0,0,1608,1609,7,27,0,0,1609,363,1,0,0,0,1610,1611,7,36,0,0,1611,
        1612,7,11,0,0,1612,1618,7,15,0,0,1613,1614,7,10,0,0,1614,1615,7,
        9,0,0,1615,1618,7,20,0,0,1616,1618,7,37,0,0,1617,1610,1,0,0,0,1617,
        1613,1,0,0,0,1617,1616,1,0,0,0,1618,365,1,0,0,0,1619,1620,7,5,0,
        0,1620,1621,7,6,0,0,1621,1622,7,7,0,0,1622,1623,1,0,0,0,1623,1624,
        6,173,34,0,1624,367,1,0,0,0,1625,1626,7,36,0,0,1626,1627,7,11,0,
        0,1627,1629,7,8,0,0,1628,1630,7,36,0,0,1629,1628,1,0,0,0,1629,1630,
        1,0,0,0,1630,1631,1,0,0,0,1631,1632,6,174,35,0,1632,369,1,0,0,0,
        1633,1634,9,0,0,0,1634,1635,1,0,0,0,1635,1636,6,175,18,0,1636,1637,
        6,175,17,0,1637,371,1,0,0,0,1638,1639,3,22,1,0,1639,1640,1,0,0,0,
        1640,1641,6,176,1,0,1641,373,1,0,0,0,1642,1643,7,5,0,0,1643,1644,
        7,6,0,0,1644,1645,7,7,0,0,1645,1646,1,0,0,0,1646,1647,6,177,34,0,
        1647,375,1,0,0,0,1648,1649,3,24,2,0,1649,1650,1,0,0,0,1650,1651,
        6,178,1,0,1651,1652,6,178,17,0,1652,377,1,0,0,0,1653,1654,9,0,0,
        0,1654,1655,1,0,0,0,1655,1656,6,179,18,0,1656,1657,6,179,17,0,1657,
        379,1,0,0,0,1658,1659,3,22,1,0,1659,1660,1,0,0,0,1660,1661,6,180,
        1,0,1661,381,1,0,0,0,1662,1664,8,39,0,0,1663,1662,1,0,0,0,1664,1665,
        1,0,0,0,1665,1663,1,0,0,0,1665,1666,1,0,0,0,1666,1667,1,0,0,0,1667,
        1668,6,181,17,0,1668,383,1,0,0,0,1669,1670,3,24,2,0,1670,1671,1,
        0,0,0,1671,1672,6,182,1,0,1672,1673,6,182,17,0,1673,385,1,0,0,0,
        1674,1675,9,0,0,0,1675,1676,1,0,0,0,1676,1677,6,183,18,0,1677,1678,
        6,183,17,0,1678,387,1,0,0,0,126,0,1,2,3,4,5,6,7,8,9,10,11,12,13,
        14,15,16,17,18,19,395,398,402,406,410,412,414,422,431,436,440,449,
        452,459,462,468,470,475,481,486,489,494,503,513,521,531,608,611,
        649,656,662,664,669,678,687,699,711,724,755,767,784,797,808,813,
        818,820,826,833,841,847,853,869,876,882,887,893,897,902,908,910,
        935,943,945,970,974,983,992,1004,1013,1018,1026,1031,1035,1050,1062,
        1111,1140,1157,1170,1186,1201,1208,1213,1222,1241,1256,1260,1301,
        1332,1339,1341,1355,1361,1363,1395,1501,1507,1509,1543,1568,1574,
        1576,1606,1617,1629,1665,36,0,5,0,0,2,0,5,8,0,5,9,0,5,17,0,2,3,0,
        5,2,0,5,11,0,5,6,0,5,7,0,5,12,0,5,1,0,0,4,0,6,0,0,4,0,0,2,4,0,5,
        5,0,2,0,0,0,3,0,7,77,0,7,72,0,2,9,0,5,10,0,7,5,0,7,9,0,5,14,0,5,
        13,0,5,15,0,5,16,0,7,8,0,7,125,0,2,12,0,7,123,0,7,124,0,5,19,0,2,
        18,0
    ];

    private static __ATN: antlr.ATN;
    public static get _ATN(): antlr.ATN {
        if (!UniversalWorkoutLanguageLexer.__ATN) {
            UniversalWorkoutLanguageLexer.__ATN = new antlr.ATNDeserializer().deserialize(UniversalWorkoutLanguageLexer._serializedATN);
        }

        return UniversalWorkoutLanguageLexer.__ATN;
    }


    private static readonly vocabulary = new antlr.Vocabulary(UniversalWorkoutLanguageLexer.literalNames, UniversalWorkoutLanguageLexer.symbolicNames, []);

    public override get vocabulary(): antlr.Vocabulary {
        return UniversalWorkoutLanguageLexer.vocabulary;
    }

    private static readonly decisionsToDFA = UniversalWorkoutLanguageLexer._ATN.decisionToState.map( (ds: antlr.DecisionState, index: number) => new antlr.DFA(ds, index) );
}