import { MarkerSeverity } from 'monaco-editor';

export class WodComponent {}

export interface Dict<T> {
  [Key: string]: T;
}

export enum WorkoutAttrTypes {
  None = 0,
  Optional = 1 << 0,
  String = 1 << 1,
  Time = 1 << 2,
  Number = 1 << 3,
}

export interface AttributeMessageOverride {
  name: string;
  expectedType?: WorkoutAttrTypes;
  severity?: MarkerSeverity;
  message?: string;
  suppressOtherErrors?: boolean;
}

export type MovementAttrs = {
  reps?: string[];
  load?: string[];
  load_female?: string[];
  load_unit?: string;
  height?: string[];
  height_unit?: string;
  distance?: string[];
  distance_unit?: string;
  calories?: string[];
  duration?: string[];
  duration_unit?: string;
  tempo?: string[];
  power?: string[];
  sets?: string | string[];
};
