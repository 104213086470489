import { Movement } from '../@monaco-uwl/UWLVisitor/Movement';
import { Workout } from '../@monaco-uwl/UWLVisitor/Workout';

import { StrengthComponent } from './parser.types';
import { getLongestArrayLength, normalizeUnit, prepareNotes } from './utils';

function extractNumberFromSetsValue(setsValue: string | string[]): number | undefined {
  if (typeof setsValue === 'string') {
    // Extract the first number from ranges like "3-5"
    const match = setsValue.match(/^(\d+)/);
    if (match && match[1]) {
      return parseInt(match[1]);
    }
    // If it's just a number as a string
    const numValue = parseInt(setsValue);
    if (!isNaN(numValue)) {
      return numValue;
    }
  }

  if (Array.isArray(setsValue) && setsValue.length > 0) {
    return extractNumberFromSetsValue(setsValue[0]);
  }

  return undefined;
}

function normalizeMovementAttributes(
  movement: Movement,
  shouldBeInsideSuperset: boolean,
  setsFromAlternation: number,
): Movement {
  if (!movement || !movement.attributes) {
    return movement;
  }

  const attributes = movement.attributes;
  const arrayKeys = [
    'reps',
    'load',
    'load_female',
    'distance',
    'duration',
    'tempo',
    'power',
    'calories',
  ] as const;

  const nonEmptyArrays = arrayKeys
    .filter((key) => {
      const value = attributes[key];
      return Array.isArray(value) && value.length > 0;
    })
    .map((key) => ({
      key,
      value: attributes[key] as string[],
    }));

  if (nonEmptyArrays.length === 0) {
    return movement;
  }

  // Determine the target length based on the hierarchy
  let targetLength: number;

  if (shouldBeInsideSuperset) {
    targetLength = setsFromAlternation;
  } else if (movement.attributes.sets) {
    // Convert sets to number if it's a string or string[]
    const numericSets = extractNumberFromSetsValue(movement.attributes.sets);
    targetLength = numericSets ?? getLongestArrayLength(movement);
  } else {
    targetLength = getLongestArrayLength(movement);
  }

  nonEmptyArrays.forEach(({ key, value }) => {
    if (value.length < targetLength) {
      const lastValue = value[value.length - 1];
      const extension = new Array(targetLength - value.length).fill(lastValue);
      attributes[key] = [...value, ...extension];
    }
  });

  return movement;
}

function determineNumberOfSets(
  movement: Movement,
  shouldBeInsideSuperset: boolean,
  setsFromAlternation: number,
): number {
  // First priority: if shouldBeInsideSuperset is true, always use setsFromAlternation
  if (shouldBeInsideSuperset) {
    return setsFromAlternation;
  }

  // Second priority: use movement.sets if available
  if (movement?.attributes?.sets) {
    const numericSets = extractNumberFromSetsValue(movement.attributes.sets);
    if (numericSets !== undefined) {
      return numericSets;
    }
  }

  // Third priority: use normalization (getLongestArrayLength)
  return getLongestArrayLength(movement);
}

/**
 * Swaps primary and secondary rest values if secondary rest is present.
 * This ensures that if both rest values are provided, the primary rest will be set to the secondary rest values.
 */
function swapRestValuesIfNeeded(attrs: {
  rest_type?: string;
  rest_duration?: string;
  cluster_rest_type?: string;
  cluster_rest_duration?: string;
}): {
  rest_type: string;
  rest_duration?: number;
  cluster_rest_type?: string;
  cluster_rest_duration?: number;
} {
  // If secondary rest type is not provided or is empty, return original values
  if (!attrs?.cluster_rest_type || attrs.cluster_rest_type.trim() === '') {
    return {
      rest_type: attrs?.rest_type ?? 'none',
      rest_duration: attrs?.rest_duration ? parseFloat(attrs.rest_duration) : 0,
      cluster_rest_type: attrs?.cluster_rest_type ?? '',
      cluster_rest_duration: attrs?.cluster_rest_duration
        ? parseFloat(attrs.cluster_rest_duration)
        : undefined,
    };
  }

  // Swap the values
  return {
    rest_type: attrs.cluster_rest_type,
    rest_duration: attrs?.cluster_rest_duration ? parseFloat(attrs.cluster_rest_duration) : 0,
    cluster_rest_type: attrs.rest_type ?? 'none',
    cluster_rest_duration: attrs?.rest_duration ? parseFloat(attrs.rest_duration) : undefined,
  };
}

export function parseUWLStrength(
  w: Workout,
  prefix: string,
  shouldBeInsideSuperset: boolean,
): StrengthComponent {
  const movement = normalizeMovementAttributes(
    w.movements?.[0] ?? new Movement(['Unknown Movement'], [], {}),
    shouldBeInsideSuperset,
    w.setsFromAlternation,
  );

  normalizeUnit(movement);

  // Convert rest values to appropriate types
  const restValues = {
    rest_type: w.attrs?.rest_type ?? 'none',
    rest_duration: w.attrs?.rest_duration ? parseFloat(w.attrs.rest_duration) : 0,
    cluster_rest_type: w.attrs?.cluster_rest_type ?? '',
    cluster_rest_duration: w.attrs?.cluster_rest_duration
      ? parseFloat(w.attrs.cluster_rest_duration)
      : undefined,
  };

  return {
    type: 'Strength',
    is_superset: w.is_superset,
    should_be_inside_superset: shouldBeInsideSuperset,
    name: w.name ?? '',
    prefix,
    rest_type: restValues.rest_type,
    rest_duration: restValues.rest_duration,
    cluster_rest_type: restValues.cluster_rest_type,
    cluster_rest_duration: restValues.cluster_rest_duration,
    movement: movement,
    notes: prepareNotes(w.notes),
    numberOfSets: determineNumberOfSets(movement, shouldBeInsideSuperset, w.setsFromAlternation),
  };
}
