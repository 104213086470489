import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Button, TextField, Container, Typography, Box, Paper, Avatar, Link } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

import logo from '../../assets/red_logo.jpg';
import { useAuth } from '../../auth/context/AuthContext';

import { styles } from './Login.styles';

const isRegistrationEnabled = process.env.REACT_APP_ENABLE_REGISTRATION === 'true';

const Login: React.FC = () => {
  const [input, setInput] = useState({
    username: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (input.username && input.password) {
      setLoading(true);
      try {
        await auth.loginAction(input.username, input.password);
        enqueueSnackbar('Login successful!', { variant: 'success' });
      } catch (err: any) {
        enqueueSnackbar(err.message || 'Login failed. Please check your username and password.', {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    } else {
      enqueueSnackbar('Please provide username and password', { variant: 'warning' });
    }
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ ...styles.paper, cursor: loading ? 'wait' : 'default' }}>
        <img src={logo} alt="Logo" style={styles.logo} />
        <Avatar sx={styles.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" sx={styles.title}>
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={styles.form}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            onChange={handleInput}
            sx={styles.textField}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleInput}
            sx={styles.textField}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={styles.submitButton(loading)}
            disabled={loading}
          >
            {loading ? 'Signing In...' : 'Sign In'}
          </Button>
          {isRegistrationEnabled && (
            <Box sx={styles.registerLink}>
              <Typography variant="body2">
                Don't have an account? <Link href="/register">Register here</Link>
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default Login;
