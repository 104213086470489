import React from 'react';

import { LoadingPopupContainer, LoadingIconContainer } from './LoadingPopup.styles';

const LoadingPopup: React.FC = () => {
  return (
    <LoadingPopupContainer>
      <LoadingIconContainer>🔄</LoadingIconContainer>
      <p>Movements list for autocomplete is loading...</p>
    </LoadingPopupContainer>
  );
};

export default LoadingPopup;