import { Dict, WorkoutAttrTypes } from './types';

export function cleanTempoString(tempo: string): string {
  return tempo.replace(/[,\s]+$/, '');
}

export const PATTERNS = {
  INT: /[0-9]+/,
  MAX: /[Mm]/,
  TEMPO: /^[0-9AaXx]{1,4}$/,
  FLOAT: /[0-9]+|([0-9]*\.[0-9]*)/,
  TIME: /[0-9]*:[0-9][0-9]?/,
  TIME_EXT: /[0-9]+(\.[0-9]+)?[^@ ,\t\n\r]+/,
  PLACEHOLDER: /\[.*?\]/,
};

// For missing attributes error messages
export const workoutDefs: Dict<Dict<WorkoutAttrTypes>> = {
  generic: {
    //score_by: WorkoutAttrTypes.String,
    description: WorkoutAttrTypes.String,
    //time_cap: WorkoutAttrTypes.Time | WorkoutAttrTypes.Optional,
  },
  strength: {
    rest_type: WorkoutAttrTypes.String,
    rest_duration: WorkoutAttrTypes.Number,
    time_cap: WorkoutAttrTypes.Time | WorkoutAttrTypes.Optional,
  },
  warmup: {
    description: WorkoutAttrTypes.String,
  },
  fortime: {
    rounds: WorkoutAttrTypes.Number | WorkoutAttrTypes.Optional,
    component_rest_type: WorkoutAttrTypes.String,
    component_rest_duration: WorkoutAttrTypes.Number,
    round_rest_type: WorkoutAttrTypes.String,
    round_rest_duration: WorkoutAttrTypes.Number,
  },
  amrap: {
    duration: WorkoutAttrTypes.Number,
    duration_unit: WorkoutAttrTypes.String,
  },
};
