import { StrengthComponent } from '../generalParser/parser.types';

import { getMovementIdByName } from './utils';
import {
  WorkoutComponent,
  Rest,
  RestType,
  Movement,
  LoadUnit,
  DurationUnit,
  DistanceUnit,
} from './wodup.types';

export function mapStrengthComponentToWodUp(component: StrengthComponent): WorkoutComponent {
  return {
    is_superset: component.is_superset,
    notes: component.notes,
    order: 0, //number of component in session
    prefix: component.prefix,
    workout: {
      type: 'Strength',
      details: {
        type: 'Strength',
        movements: mapMovementsForStrength(component.movement, component.numberOfSets),
        rest: mapRestForStrength(component.rest_type, component.rest_duration),
      },
    },
  };
}

function mapMovementsForStrength(
  movement: StrengthComponent['movement'],
  numberOfSets: number,
): Movement[] {
  const movements: Movement[] = [];
  const movementId = getMovementIdByName(movement.names[0]);

  for (let i = 0; i < numberOfSets; i++) {
    const movementObj: Movement = {
      id: movementId,
    };

    if (movement.attributes.reps?.[i]) {
      movementObj.reps = movement.attributes.reps[i];
    }
    if (movement.attributes.load?.[i]) {
      movementObj.load = movement.attributes.load[i];
      movementObj.load_unit = movement.attributes.load_unit as LoadUnit;
    }
    if (movement.attributes.duration?.[i]) {
      movementObj.duration = movement.attributes.duration[i];
      movementObj.duration_unit = movement.attributes.duration_unit as DurationUnit;
    }
    if (movement.attributes.distance?.[i]) {
      movementObj.distance = movement.attributes.distance[i];
      movementObj.distance_unit = movement.attributes.distance_unit as DistanceUnit;
    }
    if (movement.attributes.tempo?.[i]) {
      movementObj.tempo = movement.attributes.tempo[i];
    }

    movements.push(movementObj);
  }

  return movements;
}

function mapRestForStrength(
  restType: StrengthComponent['rest_type'],
  restDuration: StrengthComponent['rest_duration'],
): Rest {
  const rest: Rest = {
    type: restType as RestType, // Assuming restType is a valid RestType
  };

  if (restDuration) {
    rest.duration = restDuration;
  }

  return rest;
}
