export const cycleStyles = {
  cycleContainer: {
    mb: 4,
    p: 0,
    borderRadius: 0,
  },
  cycleHeader: (shade: number) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    bgcolor: `rgb(${shade}, ${shade}, ${shade})`, 
    fontSize: '30px',  
    height: 100,
    px: 3,
  }),
  cycleContent: {
    width: '100%',
    bgcolor: '#DFDFDF', 
    p: 2,
    border: '1px solid black',
  },
  cycleFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    mt: 2,
    px: 2,
    pb: 2,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  editButton: {
    padding: 0.5,
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
  nameInput: {
    '& .MuiInputBase-input': {
      fontSize: '1.25rem',
      fontWeight: 500,
      padding: '4px 8px',
      color: 'white',
    },
    width: '200px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  },
  cycleTitle: {
    color: 'white',
    fontFamily: '"Bebas Neue", sans-serif',  
    fontSize: '30px',
  },
  expandButton: {
    color: 'white',
    fontSize: '30px',
  },
  weekGridContent: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gap: 1,
    p: 2,
  },
  dayCell: {
    bgcolor: 'white',
    border: '1px solid black',
    p: 2,
    minHeight: 274,
  },
  actionButton: {
  },
  deleteButton: {
    color: '#70000F', 
    borderColor: '#70000F',
    fontSize: '24px',
    fontFamily: '"Bebas Neue", sans-serif',
    borderRadius: '5px',
    border: 2,
    height: 54,
    width: 165,
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#70000F',
      color: 'white',
    }
  },
  editCycleButton: {
    color: '#205900', 
    borderColor: '#205900',
  },
  weekManageButton: {
    bgcolor: '#205900', 
    color: 'white',
    '&:hover': {
      bgcolor: '#184400',
    },
  },
  arrowButton: {
    padding: '4px',
    color: 'white',
    '&.Mui-disabled': {
      opacity: 0.3
    }
  },
};
