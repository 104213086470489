import { Box, Typography } from '@mui/material';
import React from 'react';

import { Movement } from '../../../../@monaco-uwl/UWLVisitor/Movement';
import { SessionType } from '../../../../generalParser/parser.types';
import { styles } from '../../WodUpPreview.styles';
import { SetRow } from '../SetRow/SetRow';
import { getMovementName } from '../utils/nameUtils';
import { formatSecondsToTime } from '../utils/timeUtils';
import { useMovementCalculations } from '../utils/useMovementsCalculations';

import { EveryPreviewProps } from './EveryPreview.types';

export const EveryPreview: React.FC<EveryPreviewProps> = ({ component, sessionType }) => {
  const calculations = useMovementCalculations(component.movements, true);

  const renderMovement = (movement: Movement, index: number) => {
    const { hasUniformTempo } = Array.isArray(calculations) ? calculations[index] : calculations;
    const movementName = getMovementName(
      movement,
      hasUniformTempo,
      movement.attributes?.tempo || [],
    );

    return (
      <Box key={index} sx={styles.movementItem}>
        <SetRow index={null} exerciseName={movementName} attributes={movement.attributes} />
      </Box>
    );
  };

  return (
    <Box
      sx={{
        ...styles.genericComponent,
        ...(sessionType === SessionType.AM ? styles.amComponent : styles.pmComponent),
      }}
    >
      <Box sx={styles.genericHeaderWrapper}>
        <Box sx={sessionType === SessionType.AM ? styles.headerLineAM : styles.headerLinePM} />
        <Typography sx={styles.genericHeader}>
          {`${component.prefix || ''}${component.prefix ? '. ' : ''}Every ${formatSecondsToTime(component.time_value)} for ${component.sets} sets`}
        </Typography>
      </Box>

      <Box sx={styles.contentWrapper}>
        {component.goal && (
          <>
            <Typography sx={styles.sectionTitle}>Focus on:</Typography>
            <Typography sx={styles.sectionContent}>{component.goal}</Typography>
          </>
        )}

        <Typography sx={styles.sectionTitle}>Movements:</Typography>
        <Box sx={styles.sectionContent}>
          {component.movements && component.movements.length > 0 ? (
            component.movements.map(renderMovement)
          ) : (
            <Typography sx={styles.noMovements}>No movements specified</Typography>
          )}
        </Box>

        <Typography sx={styles.sectionTitle}>Notes</Typography>
        <Typography sx={styles.sectionContent}>
          {component.notes || 'No additional notes'}
        </Typography>
      </Box>
    </Box>
  );
};
