import { useState, useCallback } from 'react';

import { Workout } from '../../../@monaco-uwl/UWLVisitor/Workout';
import { parseUWLContent } from '../../../generalParser/sessionParser';
import { parseSession } from '../../../wodupParser/sessionWodupParser';

export const useDebugTools = () => {
  const [debugState, setDebugState] = useState({
    debugOutput: '',
    showDebugWindow: false,
  });

  const handleDebugParse = useCallback((uwlContent: Workout[][]) => {
    try {
      const sessions = parseUWLContent(uwlContent);
      const wod = parseSession(sessions[0]);
      setDebugState({
        debugOutput: JSON.stringify(wod, null, 2),
        showDebugWindow: true,
      });
    } catch (error) {
      setDebugState({
        debugOutput: 'Error parsing content',
        showDebugWindow: true,
      });
    }
  }, []);

  const setShowDebugWindow = useCallback((show: boolean) => {
    setDebugState((prev) => ({
      ...prev,
      showDebugWindow: show,
    }));
  }, []);

  return {
    debugState,
    handleDebugParse,
    setShowDebugWindow,
  };
};
