import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Button, TextField, Container, Typography, Box, Paper, Avatar, Link } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

import logo from '../../assets/red_logo.jpg';
import { useAuth } from '../../auth/context/AuthContext';
import { authService } from '../../services/authService';

import { styles } from './Registration.styles';

interface RegistrationInput {
  username: string;
  email: string;
  password: string;
}

const initialInput: RegistrationInput = {
  username: '',
  email: '',
  password: '',
};

const Registration: React.FC = () => {
  const [input, setInput] = useState<RegistrationInput>(initialInput);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!input.username || !input.email || !input.password) {
      enqueueSnackbar('Please fill in all fields', { variant: 'warning' });
      return;
    }

    setIsSubmitting(true);
    try {
      await authService.register(input);
      enqueueSnackbar('Registration successful!', { variant: 'success' });
      await auth.loginAction(input.username, input.password);
    } catch (error) {
      enqueueSnackbar(
        error instanceof Error ? error.message : 'Registration failed. Please try again.',
        { variant: 'error' },
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={3}
        sx={styles.paper}
      >
        <img src={logo} alt="Logo" style={styles.logo} />
        <Avatar sx={styles.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" sx={styles.title}>
          Register
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={styles.form}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            onChange={handleInput}
            disabled={isSubmitting}
            sx={styles.textField}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={handleInput}
            disabled={isSubmitting}
            sx={styles.textField}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            onChange={handleInput}
            disabled={isSubmitting}
            sx={styles.textField}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isSubmitting}
            sx={styles.submitButton(isSubmitting)}
          >
            {isSubmitting ? 'Registering...' : 'Register'}
          </Button>
        </Box>
        <Typography variant="body2" sx={styles.loginLink}>
          Already have an account? <Link href="/login">Sign in here</Link>
        </Typography>
      </Paper>
    </Container>
  );
};

export default Registration;
