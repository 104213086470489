import AddIcon from '@mui/icons-material/Add';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LoopIcon from '@mui/icons-material/Loop';
// import SearchIcon from '@mui/icons-material/Search';
// import FilterListIcon from '@mui/icons-material/FilterList';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import SettingsIcon from '@mui/icons-material/Settings';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemButton,
  Paper,
  CircularProgress,
  Box,
  // TextField,
  Button,
  IconButton,
  // InputAdornment,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import CreateProgramForm from '../../components/ProgramForm/ProgramForm';
import { logger } from '../../Logger';
import { programService } from '../../services/programService';
import { ProgramExtended } from '../../types/program';

import { styles } from './ProgramList.styles';

const ProgramsList: React.FC = () => {
  const [programs, setPrograms] = useState<ProgramExtended[]>([]);
  const [loading, setLoading] = useState(true);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [editingProgram, setEditingProgram] = useState<ProgramExtended | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPrograms();
  }, []);

  const fetchPrograms = async () => {
    setLoading(true);
    try {
      const response = await programService.getPrograms();
      setPrograms(response);
    } catch (error) {
      logger.error('Error fetching programs:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleProgramSelect = (programId: number) => {
    navigate(`/workout-planner/${programId}`);
  };

  const handleCreateProgram = async (program: ProgramExtended) => {
    setPrograms([...programs, program]);
    setShowCreateForm(false);
    await fetchPrograms();
  };

  const handleEditProgram = (program: ProgramExtended) => {
    setEditingProgram(program);
    setShowCreateForm(true);
  };

  const handleUpdateProgram = async (updatedProgram: ProgramExtended) => {
    try {
      await programService.updateProgram(updatedProgram.id, updatedProgram);
      setPrograms(programs.map((p) => (p.id === updatedProgram.id ? updatedProgram : p)));
      setShowCreateForm(false);
      setEditingProgram(null);
    } catch (error) {
      logger.error('Error updating program:', error);
    }
  };

  const handleDeleteProgram = async (programId: number) => {
    if (window.confirm('Are you sure you want to delete this program?')) {
      try {
        await programService.deleteProgram(programId);
        setPrograms(programs.filter((p) => p.id !== programId));
      } catch (error) {
        logger.error('Error deleting program:', error);
      }
    }
  };

  if (showCreateForm) {
    return (
      <CreateProgramForm
        onSubmit={editingProgram ? handleUpdateProgram : handleCreateProgram}
        onCancel={() => {
          setShowCreateForm(false);
          setEditingProgram(null);
        }}
        initialProgram={editingProgram}
      />
    );
  }

  return (
    <Container maxWidth="xl" sx={styles.container}>
      <Box sx={styles.headerBox}>
        <Typography
          variant="h4"
          sx={{
            fontFamily: '"Bebas Neue", sans-serif',
            letterSpacing: '1px',
            color: '#000000',
          }}
        >
          Programs
        </Typography>
        <Box>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setShowCreateForm(true)}
            sx={styles.createButton}
          >
            Create program
          </Button>
        </Box>
      </Box>
      <Paper elevation={3} sx={styles.listPaper}>
        {loading ? (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress />
          </Box>
        ) : (
          <List disablePadding>
            <ListItem sx={styles.listHeader}>
              <Box width="10%" fontWeight="bold">
                Days
              </Box>
              <Box width="10%" fontWeight="bold">
                Cycles
              </Box>
              <Box width="65%" fontWeight="bold">
                Name
              </Box>
              <Box width="15%" />
            </ListItem>
            {programs.map((program) => (
              <ListItem disablePadding key={program.id} sx={styles.listItem}>
                <ListItemButton
                  onClick={() => handleProgramSelect(program.id)}
                  sx={styles.listItemButton}
                >
                  <Box sx={styles.daysColumn}>
                    <CalendarTodayIcon sx={{ mr: 1, color: '#000000' }} />
                    <Typography>{program.days}</Typography>
                  </Box>
                  <Box sx={styles.cyclesColumn}>
                    <LoopIcon sx={{ mr: 1, color: '#000000' }} />
                    <Typography>{program.cycles}</Typography>
                  </Box>
                  <Box sx={styles.nameColumn}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {program.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {program.description}
                    </Typography>
                  </Box>
                  <Box sx={styles.actionsColumn}>
                    <IconButton
                      size="small"
                      sx={{ color: '#000000' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditProgram(program);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      sx={{ color: '#000000' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteProgram(program.id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
      </Paper>
    </Container>
  );
};

export default ProgramsList;
