import { MovementAttrs } from './types';

export class Movement {
  names: string[];
  mults: number[];
  attributes: MovementAttrs;
  restAfter_type?: string;
  restAfter_duration?: number;
  isComplex: boolean;

  constructor(
    names: string[],
    mults: number[] | null,
    attrs: MovementAttrs,
    restAfter_type?: string,
    restAfter_duration?: number,
    isComplex = false,
  ) {
    this.attributes = attrs;
    this.restAfter_type = restAfter_type;
    this.restAfter_duration = restAfter_duration;
    this.isComplex = isComplex;

    if (mults === null || mults.length === 0) {
      if (names.length === 0) {
        throw new Error('Movement name may not be empty');
      }
      this.names = [names[0]];
      this.mults = [];
      return;
    }

    if (names.length !== mults.length) {
      const min = Math.min(names.length, mults.length) - 1;
      names = names.slice(0, min);
      mults = mults.slice(0, min);
    }

    this.names = names;
    this.mults = mults;
  }

  public getFullName(): string {
    if (this.mults === undefined || this.mults.length === 0) {
      return this.names[0];
    }
    let name = '';
    for (let i = 0; i < this.names.length; i++) {
      name += this.mults[i] + 'x ' + this.names[i] + ' + ';
    }
    return name.substring(0, name.length - 3);
  }

  public getAttributeString(): string {
    const parts: string[] = [];

    if (this.attributes.reps) {
      parts.push(this.attributes.reps.toString());
    }

    if (this.attributes.load) {
      parts.push(` x ${this.attributes.load.toString()}${this.attributes.load_unit || ''}`);
    }

    if (this.attributes.tempo) {
      parts.push(` @ ${this.attributes.tempo.toString()}`);
    }

    return parts.join('');
  }
}
