import { IRange, languages } from 'monaco-editor';

import { UNIT_RULES } from '../../rules';
import {
  getDistanceUnitDocumentation,
  getLoadUnitDocumentation,
  getTimeUnitDocumentation,
} from '../utils/documentationUtils';

/**
 * Creates proposals for load units based on the UNIT_RULES.
 */
export function createLoadProposals(range: IRange): languages.CompletionItem[] {
  return UNIT_RULES.LOAD.suggested.map((unit) => ({
    label: unit,
    kind: languages.CompletionItemKind.Keyword,
    insertText: unit,
    range: range,
    documentation: getLoadUnitDocumentation(unit),
  }));
}

/**
 * Creates proposals for distance units based on the UNIT_RULES.
 */
export function createDistanceUnitProposals(range: IRange): languages.CompletionItem[] {
  return UNIT_RULES.DISTANCE.suggested.map((unit) => ({
    label: unit,
    kind: languages.CompletionItemKind.Keyword,
    insertText: unit,
    range: range,
    documentation: getDistanceUnitDocumentation(unit),
  }));
}

/**
 * Creates proposals for time units based on the UNIT_RULES.
 */
export function createTimeProposals(range: IRange): languages.CompletionItem[] {
  return UNIT_RULES.TIME.suggested.map((unit) => ({
    label: unit,
    kind: languages.CompletionItemKind.Keyword,
    insertText: unit,
    range: range,
    documentation: getTimeUnitDocumentation(unit),
  }));
}

/**
 * Creates proposals for time units specifically after rest with a number
 */
export function createRestTimeProposals(range: IRange): languages.CompletionItem[] {
  return UNIT_RULES.TIME.suggested.map((unit) => ({
    label: unit,
    kind: languages.CompletionItemKind.Keyword,
    insertText: unit,
    range: range,
    documentation: getTimeUnitDocumentation(unit),
    preselect: unit === 's', // Preselect seconds as default
  }));
}

/**
 * Creates proposals for rest periods.
 */
export function createRestProposals(range: IRange): languages.CompletionItem[] {
  return [
    {
      label: 'None',
      kind: languages.CompletionItemKind.TypeParameter,
      insertText: 'superset',
      insertTextRules: languages.CompletionItemInsertTextRule.None,
      range: range,
      documentation: 'No rest period between sets\nExample: rest superset',
    },
    {
      label: 'As Needed',
      kind: languages.CompletionItemKind.TypeParameter,
      insertText: 'As Needed',
      insertTextRules: languages.CompletionItemInsertTextRule.None,
      range: range,
      documentation: 'Athlete determines rest period based on readiness\nExample: rest As Needed',
    },
    {
      label: 'Fixed',
      kind: languages.CompletionItemKind.TypeParameter,
      insertText: '${1:1} ${2|sec,min|}',
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
      documentation: 'Set rest period with value and unit\nExample: rest 1 min or rest 30 sec',
    },
    {
      label: 'Time taken to complete set',
      kind: languages.CompletionItemKind.TypeParameter,
      insertText: 'ratio ${1:1}',
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
      documentation: 'Rest period relative to work period\nExample: rest ratio 1.5',
    },
  ];
}
