import { IRange, languages } from 'monaco-editor';

import {
  PATTERNS,
  COMPLEX_MOVEMENT_MARKER,
  AMRAP_MOVEMENT_MARKER,
  SUPERSET_MARKER,
  EMOM_MARKER,
  FORTIME_NO_REST_MARKER,
  FORTIME_REST_ALL_MARKER,
  FORTIME_REST_MOVEMENTS_MARKER,
  FORTIME_REST_ROUNDS_MARKER,
  FORTIME_REST_ROUNDS_SETS_MARKER,
  EVERY_MARKER,
} from './types';

const GENERIC_BLOCK = {
  kind: languages.CompletionItemKind.Struct,
  insertText: '`\n${1:description}\n`',
  insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
  preselect: true,
  documentation:
    'Creates a workout block with a description\n\nExample:\n`\n15/11 Echo Bike Cals\n15 Wallball\n15 Power Snatch @ 75/55#\n15 TTB\n15/11 Cal Echo Bike Cals\nRest 90sec\n`',
};

const MOVEMENT_VARIANT_LIMIT = 8;

export function createWorkoutProposals(range: IRange): languages.CompletionItem[] {
  return [
    {
      label: 'Movement',
      kind: languages.CompletionItemKind.Struct,
      insertText: '>${1:[movementName]:} ; rest ${2}\n*${3:notes}',
      range: range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation: `Creates a movement.\n\nExample:\n >Air Squat: 25, 25, 25\n*Notes`,
    },
    {
      label: 'Generic',
      ...GENERIC_BLOCK,
      range: range,
    },
    {
      label: 'Something Else',
      ...GENERIC_BLOCK,
      range: range,
    },
    {
      label: 'For Time (No Rest)',
      kind: languages.CompletionItemKind.Struct,
      insertText: `${FORTIME_NO_REST_MARKER} `,
      range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation: 'Creates a For Time workout with no rest periods',
      command: { id: 'editor.action.triggerSuggest', title: 'Suggest' },
    },
    {
      label: 'For Time (Rest After All)',
      kind: languages.CompletionItemKind.Struct,
      insertText: `${FORTIME_REST_ALL_MARKER} `,
      range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation: 'Creates a For Time workout with rest after completing all rounds',
      command: { id: 'editor.action.triggerSuggest', title: 'Suggest' },
    },
    {
      label: 'For Time (Rest Between Movements)',
      kind: languages.CompletionItemKind.Struct,
      insertText: `${FORTIME_REST_MOVEMENTS_MARKER} `,
      range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation: 'Creates a For Time workout with rest between movements',
      command: { id: 'editor.action.triggerSuggest', title: 'Suggest' },
    },
    {
      label: 'For Time (Rest Between Rounds)',
      kind: languages.CompletionItemKind.Struct,
      insertText: `${FORTIME_REST_ROUNDS_MARKER} `,
      range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation: 'Creates a For Time workout with rest between rounds',
      command: { id: 'editor.action.triggerSuggest', title: 'Suggest' },
    },
    {
      label: 'For Time (Rest Between Rounds & Sets)',
      kind: languages.CompletionItemKind.Struct,
      insertText: `${FORTIME_REST_ROUNDS_SETS_MARKER} `,
      range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation: 'Creates a For Time workout with rest between rounds and sets',
      command: { id: 'editor.action.triggerSuggest', title: 'Suggest' },
    },
    {
      label: 'Superset',
      kind: languages.CompletionItemKind.Struct,
      insertText: `${SUPERSET_MARKER} `,
      range: range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation: 'Creates a superset with multiple movements',
      command: {
        id: 'editor.action.triggerSuggest',
        title: 'Suggest',
      },
    },
    {
      label: 'AMRAP',
      kind: languages.CompletionItemKind.Struct,
      insertText: `${AMRAP_MOVEMENT_MARKER} `,
      range: range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation:
        'Creates an AMRAP workout block\nExample:\n10 min AMRAP\nRun: for 200m\nChest-to-bar Pull-up: 13\nAbmat Wall Ball: 13 - 30 lbs\n*Compare to previous scores',
      command: {
        id: 'editor.action.triggerSuggest',
        title: 'Suggest',
      },
    },
    {
      label: 'EMOM',
      kind: languages.CompletionItemKind.Struct,
      insertText: `${EMOM_MARKER} `,
      range: range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation: 'Creates an EMOM workout block',
      command: {
        id: 'editor.action.triggerSuggest',
        title: 'Suggest',
      },
    },
    {
      label: 'EVERY',
      kind: languages.CompletionItemKind.Struct,
      insertText: `${EVERY_MARKER} `,
      range: range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation: 'Creates an EVERY workout block',
      command: {
        id: 'editor.action.triggerSuggest',
        title: 'Suggest',
      },
    },
    {
      label: 'Complex Movement',
      kind: languages.CompletionItemKind.Struct,
      insertText: `>${COMPLEX_MOVEMENT_MARKER} `,
      range: range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation: 'Creates a complex movement combination with multiple movements.',
      command: {
        id: 'editor.action.triggerSuggest',
        title: 'Suggest',
      },
    },
  ];
}

export function createParameterProposals(
  current_line: string,
  range: IRange,
): languages.CompletionItem[] {
  const match = current_line.toLowerCase().match(PATTERNS.PARAMETERS.SCORE_BY);
  if (match) {
    return [
      {
        label: 'score_by',
        kind: languages.CompletionItemKind.EnumMember,
        insertText:
          '"${1|Not Scored,Time (lower is better),Time (higher is better),Reps (higher is better),Reps (lower is better),Rounds and Reps (higher is better),Rounds and Reps (lower is better),Distance (higher is better),Distance (lower is better),Weight (higher is better),Weight (lower is better),Points / anything else (higher is better),Points / anything else (lower is better),Peak Watts,Max Speed|}"${0}',
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range: range,
      },
    ];
  }

  return [];
}

export function createEmomMinuteCompletion(
  match: RegExpMatchArray,
  range: IRange,
): languages.CompletionItem {
  const next_num: number = parseInt(match[1]!) + 1;
  return {
    label: `Min-${next_num}`,
    kind: languages.CompletionItemKind.Keyword,
    insertText: `Min-${next_num}: \${1:movement_name}`,
    range: range,
    insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
    preselect: true,
    sortText: '_',
  };
}

export function createComplexMovementVariantProposals(range: IRange): languages.CompletionItem[] {
  return [
    {
      label: 'Variant - 2 movements',
      kind: languages.CompletionItemKind.Struct,
      insertText:
        '${1:1}x ${2:[movementName]} + ${3:1}x ${4:[movementName]}: ${5} ; rest ${6}\n*${7:notes}',
      range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation:
        'Creates a complex movement with 2 movements.\n\nExample:\n>1x Snatch + 1x OHS: 3 - 155 lbs @ 30X1',
    },
    {
      label: 'Variant - 3 movements',
      kind: languages.CompletionItemKind.Struct,
      insertText:
        '${1:1}x ${2:[movementName]} + ${3:1}x ${4:[movementName]} + ${5:1}x ${6:[movementName]}: ${7} ; rest ${8}\n*${9:notes}',
      range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation:
        'Creates a complex movement with 3 movements.\n\nExample:\n>1x Snatch + 1x OHS + 1x Snatch Balance: 3 - 155 lbs @ 30X1',
    },
    {
      label: 'Variant - 4 movements',
      kind: languages.CompletionItemKind.Struct,
      insertText:
        '${1:1}x ${2:[movementName]} + ${3:1}x ${4:[movementName]} + ${5:1}x ${6:[movementName]} + ${7:1}x ${8:[movementName]}: ${9} ; rest ${10}\n*${11:notes}',
      range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation:
        'Creates a complex movement with 4 movements.\n\nExample:\n>1x Snatch + 1x OHS + 1x Snatch Balance + 1x Drop Snatch: 3 - 155 lbs @ 30X1',
    },
    {
      label: 'Variant - 5 movements',
      kind: languages.CompletionItemKind.Struct,
      insertText:
        '${1:1}x ${2:[movementName]} + ${3:1}x ${4:[movementName]} + ${5:1}x ${6:[movementName]} + ${7:1}x ${8:[movementName]} + ${9:1}x ${10:[movementName]}: ${11} ; rest ${12}\n*${13:notes}',
      range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation:
        'Creates a complex movement with 5 movements.\n\nExample:\n>1x Snatch + 1x OHS + 1x Snatch Balance + 1x Drop Snatch + 1x Hang Snatch: 3 - 155 lbs @ 30X1',
    },
  ];
}

export function createAmrapMovementVariantProposals(range: IRange): languages.CompletionItem[] {
  const variants: languages.CompletionItem[] = [];

  const docMovements = [
    '>Wall Ball: 30 - 20 lbs',
    '>Pull-ups: 15',
    '>Run: for 200m',
    '>Push-ups: 20',
    '>Wall Ball: 30 - 20 lbs',
    '>Pull-ups: 15',
    '>Run: for 200m',
    '>Push-ups: 20',
  ];

  for (let i = 1; i <= MOVEMENT_VARIANT_LIMIT; i++) {
    let tabstop = 3;
    let insertText = '${1:20} ${2|min,sec,s,m|} AMRAP';
    for (let j = 0; j < i; j++) {
      insertText += `\n>\${${tabstop}:[movementName]:}`;
      tabstop++;
    }
    insertText += `\n*\${${tabstop}:notes}`;

    variants.push({
      label: `Variant - ${i} ${i === 1 ? 'movement' : 'movements'}`,
      kind: languages.CompletionItemKind.Struct,
      insertText: insertText,
      range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation: `Creates an AMRAP with ${i} ${i === 1 ? 'movement' : 'movements'}.\n\nExample:\n20 min AMRAP\n${docMovements.slice(0, i).join('\n')}`,
    });
  }

  return variants;
}

export function createSupersetVariantProposals(range: IRange): languages.CompletionItem[] {
  const variants: languages.CompletionItem[] = [];

  for (let i = 2; i <= MOVEMENT_VARIANT_LIMIT; i++) {
    let tabstop = 2;
    let insertText = '${1:3} supersets {';
    for (let j = 0; j < i; j++) {
      insertText += `\n>\${${tabstop}:[movementName]:} ; rest\${${tabstop + 1}}\n*\${${tabstop + 2}:notes}\n+`;
      tabstop += 3;
    }
    insertText += `\n}`;

    variants.push({
      label: `Superset - ${i} ${i === 1 ? 'movement' : 'movements'}`,
      kind: languages.CompletionItemKind.Struct,
      insertText: insertText,
      range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation: `Creates supersets between ${i} ${i === 1 ? 'movement' : 'movements'}.`,
    });
  }

  return variants;
}

export function createEmomVariantProposals(range: IRange): languages.CompletionItem[] {
  const variants: languages.CompletionItem[] = [];

  const docMovements = [
    'Min-1: >Burpee: 10',
    'Min-2: >Double-under: 40',
    'Min-3: >Wall Ball: 20',
    'Min-4: >Box Jump: 15',
    'Min-5: >Push-up: 20',
    'Min-6: >Burpee: 10',
    'Min-6: >Double-under: 40',
    'Min-8: >Wall Ball: 20',
  ];
  for (let i = 2; i <= MOVEMENT_VARIANT_LIMIT; i++) {
    let tabstop = 2;
    let insertText = `EMOM \${1:${i * 2}} min:`;
    for (let j = 0; j < i; j++) {
      insertText += `\nMin-${j + 1}: >\${${tabstop}:[movementName]:}`;
      tabstop++;
    }
    insertText += `\n*\${${tabstop}:notes}`;

    variants.push({
      label: `EMOM - ${i} ${i === 1 ? 'movement' : 'movements'}`,
      kind: languages.CompletionItemKind.Struct,
      insertText: insertText,
      range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation: `Creates an EMOM with ${i} ${i === 1 ? 'movement' : 'movements'}.\n\nExample:\nEMOM ${i * 2} - ${i * 3} min:\n${docMovements.slice(0, i).join('\n')}`,
    });
  }

  return variants;
}

// ForTime with no rest
export function createForTimeNoRestVariantProposals(range: IRange): languages.CompletionItem[] {
  const variants: languages.CompletionItem[] = [];

  const docMovements = [
    '>Push-up: 20',
    '>Air Squat: 30',
    '>Sit-up: 40',
    '>Burpee: 15',
    '>Push-up: 20',
    '>Air Squat: 30',
    '>Sit-up: 40',
    '>Burpee: 15',
  ];
  for (let i = 2; i <= MOVEMENT_VARIANT_LIMIT; i++) {
    let tabstop = 2;
    let insertText = '${1:3} rounds for time';
    for (let j = 0; j < i; j++) {
      insertText += `\n>\${${tabstop}:[movementName]:}`;
      tabstop++;
    }
    insertText += `\n*\${${tabstop}:notes}`;

    variants.push({
      label: `ForTime No Rest - ${i} ${i === 1 ? 'movement' : 'movements'}`,
      kind: languages.CompletionItemKind.Struct,
      insertText: insertText,
      range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation: `Creates a ForTime workout with ${i} ${i === 1 ? 'movement' : 'movements'} (no rest).\n\nExample:\n3 rounds for time:\n${docMovements.slice(0, i).join('\n')}`,
    });
  }
  return variants;
}

// ForTime with rest after all rounds
export function createForTimeRestAllVariantProposals(range: IRange): languages.CompletionItem[] {
  const variants: languages.CompletionItem[] = [];

  const docMovements = [
    '>Push-up: 20',
    '>Air Squat: 30',
    '>Sit-up: 40',
    '>Burpee: 15',
    '>Push-up: 20',
    '>Air Squat: 30',
    '>Sit-up: 40',
    '>Burpee: 15',
  ];
  for (let i = 2; i <= MOVEMENT_VARIANT_LIMIT; i++) {
    let tabstop = 2;
    let insertText = '${1:4} rounds for time';
    for (let j = 0; j < i; j++) {
      insertText += `\n>\${${tabstop}:[movementName]:}`;
      tabstop++;
    }
    insertText += `\nrest \${${tabstop}:2 min} into\n*\${${tabstop + 1}:notes}`;

    variants.push({
      label: `ForTime Rest After All - ${i} ${i === 1 ? 'movement' : 'movements'}`,
      kind: languages.CompletionItemKind.Struct,
      insertText: insertText,
      range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation: `Creates a ForTime workout with ${i} ${i === 1 ? 'movement' : 'movements'} and rest after completing all rounds.\n\nExample:\n3 rounds for time:\n${docMovements.slice(0, i).join('\n')}\nrest 2 min into\n*Keep form strict`,
    });
  }
  return variants;
}

// ForTime with rest between movements
export function createForTimeRestMovementsVariantProposals(
  range: IRange,
): languages.CompletionItem[] {
  const variants: languages.CompletionItem[] = [];

  const docMovements = [
    '>Push-up: 20',
    '>Air Squat: 30',
    '>Sit-up: 40',
    '>Burpee: 15',
    '>Push-up: 20',
    '>Air Squat: 30',
    '>Sit-up: 40',
    '>Burpee: 15',
  ];
  for (let i = 2; i <= MOVEMENT_VARIANT_LIMIT; i++) {
    let tabstop = 2;
    let insertText = '${1:4} rounds for time';
    for (let j = 0; j < i; j++) {
      insertText += `\n>\${${tabstop}:[movementName]}:`;
      if (j !== i - 1) insertText += ` ; rest \${${tabstop + 1}:30 sec}`;
      tabstop += 2;
    }
    insertText += `\n*\${${tabstop}:notes}`;

    variants.push({
      label: `ForTime Rest Between Movements - ${i} ${i === 1 ? 'movement' : 'movements'}`,
      kind: languages.CompletionItemKind.Struct,
      insertText: insertText,
      range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation: `Creates a ForTime workout with ${i} ${i === 1 ? 'movement' : 'movements'} and rest between rounds.\n\nExample:\n3 rounds for time:\n${docMovements.slice(0, i).join(' ; rest 30 sec\n')}`,
    });
  }
  return variants;
}

// ForTime with rest between rounds
export function createForTimeRestRoundsVariantProposals(range: IRange): languages.CompletionItem[] {
  const variants: languages.CompletionItem[] = [];

  const docMovements = [
    '>Push-up: 20',
    '>Air Squat: 30',
    '>Sit-up: 40',
    '>Burpee: 15',
    '>Push-up: 20',
    '>Air Squat: 30',
    '>Sit-up: 40',
    '>Burpee: 15',
  ];
  for (let i = 2; i <= MOVEMENT_VARIANT_LIMIT; i++) {
    let tabstop = 2;
    let insertText = '${1:4} rounds for time';
    for (let j = 0; j < i; j++) {
      insertText += `\n>\${${tabstop}:[movementName]:}`;
      tabstop++;
    }
    insertText += `\nrest \${${tabstop}:2 min}\n*\${${tabstop + 1}:notes}`;

    variants.push({
      label: `ForTime Rest After All - ${i} ${i === 1 ? 'movement' : 'movements'}`,
      kind: languages.CompletionItemKind.Struct,
      insertText: insertText,
      range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation: `Creates a ForTime workout with ${i} ${i === 1 ? 'movement' : 'movements'} and rest between rounds.\n\nExample:\n3 rounds for time:\n${docMovements.slice(0, i).join('\n')}\nrest 2 min\n*Keep form strict`,
    });
  }
  return variants;
}

// ForTime with rest between rounds and sets
export function createForTimeRestRoundsSetsVariantProposals(
  range: IRange,
): languages.CompletionItem[] {
  const variants: languages.CompletionItem[] = [];

  const docMovements = [
    '>Push-up: 20',
    '>Air Squat: 30',
    '>Sit-up: 40',
    '>Burpee: 15',
    '>Push-up: 20',
    '>Air Squat: 30',
    '>Sit-up: 40',
    '>Burpee: 15',
  ];
  for (let i = 2; i <= MOVEMENT_VARIANT_LIMIT; i++) {
    let tabstop = 2;
    let insertText = 'for time';
    for (let j = 0; j < i; j++) {
      insertText += `\n>\${${tabstop}:[movementName]:}`;
      if (j !== i - 1) insertText += `\n\${${tabstop + 1}:rest 30 sec}`;
      tabstop += 2;
    }
    insertText += `\nrest \${${tabstop}:2 min}\n*\${${tabstop + 1}:notes}`;

    variants.push({
      label: `ForTime Rest After All - ${i} ${i === 1 ? 'movement' : 'movements'}`,
      kind: languages.CompletionItemKind.Struct,
      insertText: insertText,
      range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation: `Creates a ForTime workout with ${i} ${i === 1 ? 'movement' : 'movements'} and rest between movements and sets.\n\nExample:\n3 rounds for time:\n${docMovements.slice(0, i).join('\nrest 30 sec \n')}\nrest 3 min\n5 sets\n*Keep form strict`,
    });
  }
  return variants;
}

export function createEveryVariantProposals(range: IRange): languages.CompletionItem[] {
  const variants: languages.CompletionItem[] = [];

  const docMovements = [
    '>Push-up: 20',
    '>Air Squat: 30',
    '>Sit-up: 40',
    '>Burpee: 15',
    '>Push-up: 20',
    '>Air Squat: 30',
    '>Sit-up: 40',
    '>Burpee: 15',
  ];
  for (let i = 2; i <= MOVEMENT_VARIANT_LIMIT; i++) {
    let tabstop = 4;
    let insertText = 'Every ${1:2.5} ${2|min,sec|} x ${3:5}';
    for (let j = 0; j < i; j++) {
      insertText += `\n>\${${tabstop}:[movementName]:}`;
      tabstop++;
    }
    insertText += `\n*\${${tabstop}:notes}`;

    variants.push({
      label: `Every - ${i} ${i === 1 ? 'movement' : 'movements'}`,
      kind: languages.CompletionItemKind.Struct,
      insertText: insertText,
      range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation: `Creates an Every workout with ${i} ${i === 1 ? 'movement' : 'movements'}.\n\nExample:\nEvery 30 sec x 5 sets for speed:\n${docMovements.slice(0, i).join('\n')}`,
    });
  }
  return variants;
}
