import { useState, useRef, useEffect } from 'react';

import { UWLError } from '../@monaco-uwl/UWLErrorHandler';
import { logger } from '../Logger';
import { correctionsService } from '../services/correctionsService';

interface ErrorCorrectionState {
  correction: string | null;
  explanation: string;
  showCorrection: boolean;
  isValidationError: boolean;
  previousContent: string | null;
  isLoading: boolean;
}

export const useErrorCorrection = (
  content: string,
  onContentChange: (content: string) => void,
  onCorrectionLengthChange: (length: number) => void,
) => {
  const [state, setState] = useState<ErrorCorrectionState>({
    correction: null,
    explanation: '',
    showCorrection: false,
    isValidationError: false,
    previousContent: null,
    isLoading: false,
  });

  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const handleGetErrorHelp = async (error: UWLError) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();

    try {
      setState(prev => ({ ...prev, isLoading: true }));
      const context = { error, lineContent: content };
      const response = await correctionsService.getErrorHelp(
        content, 
        context,
        abortControllerRef.current.signal
      );
      setState((prev) => ({
        ...prev,
        isValidationError: !!response.error,
        explanation: response.explanation,
        correction: response.correctedComponent,
        previousContent: content,
        showCorrection: true,
        isLoading: false,
      }));

      const updatedContent = content + `\n${response.correctedComponent}\n`;
      onContentChange(updatedContent);
      onCorrectionLengthChange(response.correctedComponent.split('\n').length);
    } catch (error) {
      const err = error as { name: string };
      if (err.name === 'CanceledError') {
        setState(prev => ({ ...prev, isLoading: false }));
        return;
      }
      logger.error('Error getting chat help: ', error);
      setState((prev) => ({
        ...prev,
        isValidationError: true,
        explanation: 'An error occurred while getting help. Please try again.',
        isLoading: false,
      }));
    }
  };

  const handleAcceptCorrection = () => {
    if (state.correction) {
      onContentChange(state.correction);
      setState((prev) => ({ ...prev, correction: null, showCorrection: false }));
      onCorrectionLengthChange(-1);
    }
  };

  const handleRejectCorrection = () => {
    onContentChange(state.previousContent ?? '');
    setState((prev) => ({ ...prev, correction: null, showCorrection: false }));
    onCorrectionLengthChange(-1);
  };

  return {
    ...state,
    handleGetErrorHelp,
    handleAcceptCorrection,
    handleRejectCorrection,
  };
};
