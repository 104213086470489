export interface UserSettings {
  enableHover: boolean;
}

export interface ProgramSettings {
  fontSize: number;
}

export interface MonacoSettings extends UserSettings, ProgramSettings {}

export const DEFAULT_USER_SETTINGS: UserSettings = {
  enableHover: true,
};

export const DEFAULT_PROGRAM_SETTINGS: ProgramSettings = {
  fontSize: 14,
};

export const DEFAULT_MONACO_SETTINGS: MonacoSettings = {
  ...DEFAULT_USER_SETTINGS,
  ...DEFAULT_PROGRAM_SETTINGS,
};
