import {
  ANTLRErrorListener,
  ATNConfigSet,
  ATNSimulator,
  BitSet,
  ConsoleErrorListener,
  DefaultErrorStrategy,
  DFA,
  IntervalSet,
  Parser,
  RecognitionException,
  Recognizer,
  Token,
} from 'antlr4ng';
import { editor, MarkerSeverity, Uri } from 'monaco-editor';

import { logger, LogLevel } from '../Logger';

import { DayContext } from './antlr/.antlr/UniversalWorkoutLanguageParser';
import { createLexer, createParserFromLexer } from './UWLParserFacade';
import { UWLVisitor_a } from './UWLVisitor';
import { Workout } from './UWLVisitor/Workout';

export class UWLError implements editor.IMarkerData {
  startLineNumber: number;
  endLineNumber: number;
  startColumn: number;
  endColumn: number;
  message: string;
  severity: MarkerSeverity;
  code?:
    | string
    | {
        value: string;
        target: Uri;
      };
  propagates?: {
    offsetSubsequentErrors_lines: number;
  };
  constructor(
    startLineNumber: number,
    endLineNumber: number,
    startColumn: number,
    endColumn: number,
    message: string,
    severity: MarkerSeverity,
  ) {
    this.startLineNumber = startLineNumber;
    this.endLineNumber = endLineNumber;
    this.startColumn = startColumn;
    this.endColumn = endColumn;
    this.message = message;
    this.severity = severity;
  }
}

class UWLErrorListener implements ANTLRErrorListener {
  private errors: UWLError[] = [];

  constructor(errors: UWLError[]) {
    this.errors = errors;
  }

  syntaxError<S extends Token, T extends ATNSimulator>(
    recognizer: Recognizer<T>,
    offendingSymbol: S | null,
    line: number,
    charPositionInLine: number,
    msg: string,
    _e: RecognitionException | null,
  ): void {
    const errorEndColumn = this.calculateErrorEndColumn(charPositionInLine, offendingSymbol);
    const errorMessage = this.getErrorMessage(offendingSymbol, recognizer, msg);
    this.logTokenTypeInfo(offendingSymbol, recognizer);
    this.errors.push(
      new UWLError(
        line,
        line,
        charPositionInLine,
        errorEndColumn,
        errorMessage,
        MarkerSeverity.Error,
      ),
    );
  }

  private calculateErrorEndColumn(charPosition: number, symbol: Token | null): number {
    if (symbol?.text !== undefined && symbol?.text !== null) {
      return charPosition + symbol.text.length;
    }
    return charPosition + 1;
  }

  private getErrorMessage(
    _symbol: Token | null,
    _recognizer: Recognizer<ATNSimulator>,
    defaultMsg: string,
  ): string {
    if (logger.getLogLevel() >= LogLevel.DEBUG) {
      return defaultMsg;
    }
    // if (symbol?.type === recognizer.getTokenType('ERROR_CHAR')) {
    //   return 'Unrecognized symbol';
    // }
    // return defaultMsg;
    // TODO: Implement proper error messages based on context
    // For now, just show a friendly message to users
    return 'Invalid syntax. A more detailed error handling is coming soon';
  }

  private logTokenTypeInfo(symbol: Token | null, recognizer: Recognizer<ATNSimulator>): void {
    logger.debug(
      symbol?.type,
      ' -- ',
      [...recognizer.getTokenTypeMap()].filter(({ 1: v }) => v === symbol?.type).map(([k]) => k),
    );
  }
  reportAmbiguity(
    recognizer: Parser,
    dfa: DFA,
    startIndex: number,
    stopIndex: number,
    exact: boolean,
    ambigAlts: BitSet | undefined,
    configs: ATNConfigSet,
  ): void {
    //logger.info("reportAmbiguity")
  }
  reportAttemptingFullContext(
    recognizer: Parser,
    dfa: DFA,
    startIndex: number,
    stopIndex: number,
    conflictingAlts: BitSet | undefined,
    configs: ATNConfigSet,
  ): void {
    //logger.info("reportAttemptingFullContext")
  }
  reportContextSensitivity(
    recognizer: Parser,
    dfa: DFA,
    startIndex: number,
    stopIndex: number,
    prediction: number,
    configs: ATNConfigSet,
  ): void {
    //logger.info("reportContextSensitivity")
  }
}

class UWLErrorStrategy extends DefaultErrorStrategy {
  reportUnwantedToken(recognizer: Parser): void {
    // logger.debug('reportUnwantedToken');
    return super.reportUnwantedToken(recognizer);
  }

  singleTokenDeletion(recognizer: Parser): Token | null {
    // logger.debug('singleTokenDeletion');
    return super.singleTokenDeletion(recognizer);
    // let nextTokenType = recognizer.tokenStream.LA(2);
    // if (recognizer.tokenStream.LA(1) === UniversalWorkoutLanguageParser.MOVEMENT_TERM) {
    //     return null
    // }
    // let expecting = this.getExpectedTokens(recognizer);
    // if (expecting.contains(nextTokenType)) {
    //     this.reportUnwantedToken(recognizer);
    //     recognizer.consume();
    //     let matchedSymbol = recognizer.getCurrentToken();
    //     this.reportMatch(recognizer);
    //     return matchedSymbol;
    // }
    // return null;
  }

  getExpectedTokens(recognizer: Parser): IntervalSet {
    return recognizer.getExpectedTokens();
  }

  reportMatch(recognizer: Parser): void {
    // logger.debug('reportMatch');
    this.endErrorCondition(recognizer);
  }

  reportError(recognizer: Parser, e: RecognitionException): void {
    // logger.debug('reportError');
    super.reportError(recognizer, e);
  }

  recover(recognizer: Parser, e: RecognitionException): void {
    super.recover(recognizer, e);
  }

  sync(recognizer: Parser): void {
    // logger.debug('sync');
    // logger.debug('error rec? == ', this.inErrorRecoveryMode(recognizer));
    /* The default strategy seems to work well enough for now
        if (recognizer.tokenStream.LA(1) === recognizer.getTokenType("MOVEMENT_TERM")) {
            logger.info(recognizer.vocabulary.getDisplayName(recognizer.tokenStream.LA(-1)), "-- !!", );
            logger.info("MT::", recognizer.tokenStream.LT(1)?.text || "NO_!_TEXT");
            logger.info("MN::", recognizer.tokenStream.LT(2)?.text || "NO_!_TEXT");
            if (recognizer.tokenStream.LT(2)?.text === "<EOF>") {
                logger.info("MMM:::", recognizer.vocabulary.getDisplayName(recognizer.tokenStream.LT(2)?.type || 0));
            }
        }

        if (recognizer.tokenStream.LA(1) === recognizer.getTokenType("MOVEMENT_TERM") &&
            recognizer.tokenStream.LA(2) === recognizer.getTokenType("EOF")) {
            logger.info("act");
            // recognizer.
        }
        logger.info( "T::", recognizer.context?.getText())

        logger.info(recognizer.vocabulary.getDisplayName(recognizer.tokenStream.LA(1)));
        logger.info(recognizer.getExpectedTokens().toStringWithVocabulary(recognizer.vocabulary));
        recognizer.tokenStream.LA(1)
        */

    super.sync(recognizer);
  }

  recoverInline(recognizer: Parser): Token {
    // logger.debug('recoverInline');
    // logger.debug(recognizer.getRuleInvocationStack());

    return super.recoverInline(recognizer);
  }
}

export function validate(input: string): { wods: Workout[][]; errors: UWLError[] } {
  const errors: UWLError[] = [];

  let dayContext: DayContext;

  let recoveryIterationLimit = 100;

  const blockErrors: UWLError[] = [];

  do {
    errors.splice(0);
    const lexer = createLexer(input);
    lexer.removeErrorListeners();

    if (logger.getLogLevel() === LogLevel.DEBUG) {
      lexer.addErrorListener(new ConsoleErrorListener());
    }

    const parser = createParserFromLexer(lexer);
    parser.removeErrorListeners();
    parser.errorHandler = new UWLErrorStrategy();
    parser.addErrorListener(new UWLErrorListener(errors));

    dayContext = parser.day();

    logger.debug('Validate errors');
    logger.debug(errors);

    if (errors.length !== 0) {
      input = isolateErroneousBlock(errors[0], input, blockErrors);
    }
    logger.debug('Validate errors fin.');

    recoveryIterationLimit--;
    if (recoveryIterationLimit <= 0) {
      throw new Error('Exceeded correction limit!');
    }
  } while (errors.length !== 0);

  errors.splice(0);

  errors.push(...blockErrors);

  const v: UWLVisitor_a = new UWLVisitor_a();
  const res = v.GetDay(dayContext);

  errors.push(...v.errors);

  errors.sort((a: UWLError, b: UWLError) => {
    return a.startLineNumber - b.startLineNumber;
  });

  //shift errors into their proper places
  let runningOffset = 0;
  for (let i = 0; i < errors.length; i++) {
    errors[i].startLineNumber += runningOffset;
    errors[i].endLineNumber += runningOffset;

    runningOffset += errors[i].propagates?.offsetSubsequentErrors_lines ?? 0;
  }

  return { wods: res, errors: errors };
}

function isolateErroneousBlock(e: UWLError, input: string, errors?: UWLError[]): string {
  const separatorLinePattern = /^\s*\+/;
  const blockStartPattern = /^.*{/;
  const blockEndPattern = /^\s*}/;
  const genericSeparator = '`';
  const workoutSeparator = '+';

  let errorOffset = 0;

  const lines = input.split('\n');
  // error tokens count lines from 1, not 0
  //
  // the '+' after a valid block will never be pointed to by an error,
  // even if it (incorrectly) has text after it in the same line

  logger.debug(e);

  let start = e.startLineNumber - 2;
  let end = e.endLineNumber - 1;

  const matchBlock: { end: RegExpMatchArray | null; start: RegExpMatchArray | null } = {
    end: null,
    start: null,
  };

  if (lines[end].match(blockStartPattern)) {
    // this is getting unwieldy, but atm the block is the only thing that does this
    // start is proper
    // advance to block end
    while (end < lines.length && !lines[end].match(blockEndPattern)) {
      end++;
    }

    // advance to next '+' after erroneous block
    while (end < lines.length && !lines[end].match(separatorLinePattern)) {
      end++;
    }

    while (start >= 0 && !lines[start].match(separatorLinePattern)) {
      start--;
    }
  } else if (lines[end].match(blockEndPattern)) {
    while (start >= 0 && !lines[start].match(blockStartPattern)) {
      start--;
    }

    while (start >= 0 && !lines[start].match(separatorLinePattern)) {
      start--;
    }

    // advance to next '+' after erroneous block
    while (end < lines.length && !lines[end].match(separatorLinePattern)) {
      end++;
    }
  } else {
    while (
      end < lines.length &&
      !(
        lines[end].match(separatorLinePattern) ||
        (matchBlock.end = lines[end].match(blockEndPattern))
      )
    ) {
      end++;
    }

    lines[end] = '}';

    let blStart;

    while (
      start >= 0 &&
      !(
        lines[start].match(separatorLinePattern) ||
        (blStart = matchBlock.start = lines[start].match(blockStartPattern)) ||
        (matchBlock.start = lines[start].match(blockEndPattern))
      )
    ) {
      start--;
    }

    if (blStart) {
      lines[start] = blStart[0];
    }
  }

  logger.debug('s: ', start, '; e: ', end);

  const offender = lines.slice(start, end + 1).join('\n');

  if (matchBlock.end) {
    // we're at the end of a block; we did not hit a '+'
    /*
     * TEXT         TEXT
     * } <--   ==>  + <--
     * TEXT         }
     * TEXT         TEXT
     */
    lines.splice(end, 0, workoutSeparator);
    --errorOffset;
  }

  if (matchBlock.start) {
    // we're at the beginning of a block; we did not hit a '+'
    // we must also advance both pointers
    /*
     * TEXT                     TEXT                    TEXT
     * BLOCK_DECL { <--    ==>  BLOCK_DECL { <--   ==>  BLOCK_DECL {
     * TEXT                     +                       +             <--
     * TEXT                     TEXT                    TEXT
     */
    lines.splice(start + 1, 0, workoutSeparator);
    ++end;
    ++start;
    --errorOffset;
  }

  lines[start] = '+';
  lines[end] = '+';

  // wipe previous generic separators

  for (let i = start + 1; i < end; i++) {
    lines[i] = lines[i].replace('`', '');
  }

  //splice in new generic separators

  lines.splice(end, 0, genericSeparator);
  lines.splice(start + 1, 0, genericSeparator);

  //assemble error
  if (errors) {
    const e: UWLError = {
      startLineNumber: start + 1,
      endLineNumber: end + 1 + errorOffset,
      startColumn: 0,
      endColumn: lines[end].length,
      message: 'SYNTAX ERROR',
      severity: MarkerSeverity.Error,
      code: 'err_rec_syntax_error',
      propagates: { offsetSubsequentErrors_lines: -2 + errorOffset },
    };
    errors.push(e);
  }

  logger.debug(lines);

  return lines.join('\n');
}
