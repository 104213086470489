import { SxProps, Theme } from '@mui/material';

interface Styles {
  itemContainer: SxProps<Theme>;
  mainItem: SxProps<Theme>;
  icon: SxProps<Theme>;
  expandIcon: (isExpanded: boolean) => SxProps<Theme>;
  subItemsContainer: SxProps<Theme>;
  subItem: SxProps<Theme>;
  infoButton: SxProps<Theme>;
  itemText: SxProps<Theme>;
  tooltipContainer: SxProps<Theme>;
  iconWrapper: SxProps<Theme>;
}

export const styles: Styles = {
  itemContainer: {
    width: '100%',
    position: 'relative',
  },

  mainItem: {
    minHeight: '36px',
    padding: '8px 12px',
    marginBottom: '2px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      cursor: 'pointer',
    },
  },

  icon: {
    color: '#424242',
    flexShrink: 0,
    fontSize: '20px',
  },

  expandIcon: (isExpanded: boolean) => ({
    padding: '4px',
    transition: 'transform 0.2s ease',
    width: '24px',
    height: '24px',
    marginLeft: 'auto',
    transform: isExpanded ? 'rotate(180deg)' : 'rotate(0)',
  }),

  subItemsContainer: {
    marginLeft: '28px',
    borderLeft: '1px solid #e0e0e0',
    paddingLeft: '12px',
    marginTop: '2px',
    marginBottom: '4px',
  },

  subItem: {
    minHeight: '32px',
    padding: '6px 8px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      cursor: 'pointer',
    },
    '&:not(:last-child)': {
      marginBottom: '2px',
    },
  },

  infoButton: {
    padding: '4px',
    width: '24px',
    height: '24px',
    marginLeft: '4px',
    color: 'rgba(0, 0, 0, 0.54)',
    flexShrink: 0,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },

  itemText: {
    flex: '1 1 auto',
    fontSize: '0.875rem',
    lineHeight: '1.4',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'rgba(0, 0, 0, 0.87)',
  },

  tooltipContainer: {
    position: 'absolute',
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '8px',
    padding: '16px',
    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
    zIndex: 1000,
    width: '280px',
    maxHeight: '400px',
    overflowY: 'auto',
  },

  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '28px',
    height: '28px',
    position: 'relative',
    flexShrink: 0,
  },
};

// Add styles for movement count selector
export const movementCountStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '6px',
    backgroundColor: '#f5f5f5',
    borderRadius: '4px',
    marginTop: '4px',
    marginBottom: '6px',
  },
  title: {
    fontSize: '13px',
    fontWeight: 500,
    marginBottom: '6px',
    color: '#333',
  },
  countsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '6px',
  },
  countButton: {
    padding: '4px 8px',
    backgroundColor: '#ffffff',
    border: '1px solid #cccccc',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '13px',
    fontWeight: 400,
    color: '#333',
    minWidth: '24px',
    textAlign: 'center',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#f0f0f0',
      borderColor: '#999999',
    },
  },
  countButtonSelected: {
    backgroundColor: '#e0e0e0',
    borderColor: '#999999',
    fontWeight: 600,
  },
};
