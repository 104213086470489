import { UNIT_RULES } from '../rules';

export const PATTERNS = {
  REST: /[Rr]est:?\s*(?!([Aa]s\s+[Nn]eeded|superset|ratio|[0-9]+))/,
  REST_NUMBER: /[Rr]est:?\s+([0-9]+(\.[0-9]+)?)\s*$/,
  PARAMETER: /.*\([^)]*$/,
  PARAMETERS: {
    SCORE_BY: /^\s*score_by\s*:.*/,
  },
  EMOM_ADD: /^Min-([0-9]+)[ \t]*:.*\n/,
  MOVEMENT: /^\s*((>(.* \+ )*)|(\s*[Mm][Ii][Nn]\s*(-\s*)?[0-9]*\s*:)\s+)/,
  UNIT: {
    //LOAD_OLD: /.*x( )*(([0-9]+)(,( )*[0-9]+)*)|load( )?/,
    DISTANCE: /for\s+([0-9]+|[Mm])\s*$/,
    LOAD: /-\s+(([0-9]+(\.[0-9]+)?)|M|\[[^\]]*\]|([0-9]+-[0-9]+))(\s*,\s*(([0-9]+(\.[0-9]+)?)|M|\[[^\]]*\]|([0-9]+-[0-9]+)))*\s*$/,
    TIME: /in\s+((([0-9]+:)?[0-9]+)|[Mm])\s*$/,
  },
  SHORT_GENERIC: /(^|\+)[^`]*`[^`]*($|\+)/,
  COMPLEX_MOVEMENT_VARIANT: /^\s*>\u200C\s*$/,
  AMRAP_MOVEMENT_VARIANT: /^\s*\u200B\s*$/,
  SUPERSET_VARIANT: /^\s*\u200D\s*$/,
  EMOM_VARIANT: /^\s*\u2060\s*$/,
  FORTIME_NO_REST_VARIANT: /^\s*\u2065\s*$/,
  FORTIME_REST_ALL_VARIANT: /^\s*\u2061\s*$/,
  FORTIME_REST_MOVEMENTS_VARIANT: /^\s*\u2062\s*$/,
  FORTIME_REST_ROUNDS_VARIANT: /^\s*\u2063\s*$/,
  FORTIME_REST_ROUNDS_SETS_VARIANT: /^\s*\u2064\s*$/,
  EVERY_VARIANT: /^\s*\u200A\s*$/,

  // Add patterns for workout type detection
  WORKOUT_TYPES: {
    AMRAP: /\b\d+\s*(min|s|m|sec)?\s*[Aa][Mm][Rr][Aa][Pp]\b|\b[Aa][Mm][Rr][Aa][Pp]\b/i,
    EMOM: /\bEMOM\b/i,
    EVERY: /\bEvery\b/i,
    FORTIME:
      /\b[Ff][Oo][Rr]\s*[Tt][Ii][Mm][Ee]\b|\b\d+\s*[Rr]ounds\s+[Ff][Oo][Rr]\s*[Tt][Ii][Mm][Ee]\b/i,
    SUPERSET: /\b\d*\s*[Ss]uperset(s)?\s*\{|\([Ss]uperset\)|\bsuperset\b/i,
  },
} as const;

export const COMPLEX_MOVEMENT_MARKER = '\u200C';
export const AMRAP_MOVEMENT_MARKER = '\u200B';
export const SUPERSET_MARKER = '\u200D';
export const EMOM_MARKER = '\u2060';
export const FORTIME_NO_REST_MARKER = '\u2065';
export const FORTIME_REST_ALL_MARKER = '\u2061';
export const FORTIME_REST_MOVEMENTS_MARKER = '\u2062';
export const FORTIME_REST_ROUNDS_MARKER = '\u2063';
export const FORTIME_REST_ROUNDS_SETS_MARKER = '\u2064';
export const EVERY_MARKER = '\u200A';
