export const styles = {
  container: {
    padding: '0px 10px',
    backgroundColor: '#f5f5f5', 
    borderRadius: 2,
  },
  contentBox: {
    bgcolor: 'background.default',
    p: 2,
    borderRadius: 1,
  },
  exerciseTitle: {
    fontWeight: 'bold',
    color: 'primary.main',
  },
  description: {
    mb: 2,
  },
  sectionTitle: {
    fontWeight: 'bold',
    color: 'text.primary',
    mt: 0.5,
    mb: 0.25,
    fontSize: '0.8rem',
  },
  movementBox: {
    display: 'flex',
    alignItems: 'center',
    mb: 0.5,
  },
  infoIcon: {
    ml: 1,
    fontSize: 'small',
  },
  setBox: {
    display: 'flex',
    alignItems: 'center',
    mb: 1,
  },
  checkbox: {
    p: 0,
    mr: 1,
    color: 'secondary.main',
  },
  checkIcon: {
    mr: 1,
    fontSize: 'small',
  },
  sessionPaper: {
    p: 3,
    borderRadius: 2,
    bgcolor: 'background.paper',
  },
  sessionTitle: {
    mb: 2,
    pb: 1,
    borderBottom: '2px solid',
    borderColor: 'divider',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    fontFamily: '"Bebas Neue", sans-serif',
    fontSize: '24px',
  },
  sessionIcon: {
    marginRight: 1,
    fontSize: 'large',
  },
  componentBox: {
    margin: '8px 0',
    transition: 'transform 0.2s ease, opacity 0.2s ease',
    '&:hover': {
      transform: 'translateX(4px)',
    },
  },
  dragHandle: {
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
  dragHandleIcon: {
    position: 'absolute',
    bottom: 8,
    right: 8,
    color: 'text.secondary',
    opacity: 0.5,
    pointerEvents: 'none',
  },
  amSession: {
    border: '1.5px solid #b9b9b9',
  },
  pmSession: {
    border: '1px solid #b9b9b9',
  },
  amComponent: {
    border: '1.5px solid #b9b9b9',
  },
  pmComponent: {
    border: '1px solid #b9b9b9',
  },
  genericComponent: {
    bgcolor: 'background.paper',
    borderRadius: 2,
    p: 1.5,
    // boxShadow: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  genericHeaderWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    mb: 0.5,
  },
  headerLineAM: {
    width: 5,
    minHeight: 10,
    bgcolor: '#facf0f',
    mt: 0.5,
    mr: 0.5,
    borderRadius: 5,
    flexShrink: 0,
  },
  headerLinePM: {
    width: 5,
    minHeight: 10,
    bgcolor: '#1330eb',
    mt: 0.5,
    mr: 0.5,
    borderRadius: 5,
    flexShrink: 0,
  },
  genericHeader: {
    color: 'text.primary',
    fontWeight: 'bold',
    fontSize: '1rem',
    lineHeight: 1.2,
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    flex: 1,
  },
  contentWrapper: {
    overflow: 'auto',
    flexGrow: 1,
  },
  sectionContent: {
    ml: 0.5,
    mb: 0.5,
    color: 'text.primary',
    fontSize: '0.8rem',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
  },
  movementItem: {
    marginBottom: 1,
  },
  movementBulletAM: {
    width: 4,
    height: 4,
    borderRadius: '50%',
    bgcolor: '#FFA500',
    mr: 0.5,
    flexShrink: 0,
  },
  movementBulletPM: {
    width: 4,
    height: 4,
    borderRadius: '50%',
    bgcolor: '#1976D2',
    mr: 0.5,
    flexShrink: 0,
  },
  requiredIndicator: {
    color: 'red',
    marginLeft: '-1px',
  },
  strengthIcon: {
    fontSize: '1rem',
    marginLeft: '0.5rem',
    verticalAlign: 'middle',
    color: 'text.secondary',
  },
  warmupIcon: {
    fontSize: '1rem',
    marginLeft: '0rem',
    marginRight: '0.3rem',
    verticalAlign: 'middle',
    color: 'text.secondary',
  },
  setRow: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '4px',
  },
  setRowWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '6px',
    paddingLeft: '8px',
  },
  setRowContent: {
    lineHeight: 1.5,
    flex: 1,
    '& .round-header': {
      fontWeight: 500,
    },
  },
  forTimeSetRow: {
    paddingLeft: '4px',
  },
  supersetIcon: {
    fontSize: '1rem',
    marginLeft: '0rem',
    verticalAlign: 'middle',
    marginRight: '0.3rem',
    color: 'text.secondary',
  },
  supersetComponent: {
    border: '1.5px solid #b9b9b9',
    borderRadius: 2,
    pb: 3.5,
    p: 1.5,
    mb: 1,
  },
  supersetComponentAM: {
    // borderColor: 'warning.main',
  },
  supersetComponentPM: {
    // borderColor: 'info.main',
  },
  supersetSubComponent: {
    ml: 2,
    mt: 1,
    mb: 1,
  },
  noMovements: {
    fontSize: '0.8rem',
  },
  movementRow: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '4px',
  },
  restAfterMovement: {
    color: 'text.secondary',
    marginLeft: 3.5,
    fontSize: '0.75rem',
    fontStyle: 'italic',
  },
  movementName: {
    fontWeight: 'bold',
    fontSize: '0.75rem',
    color: 'text.primary',
    mb: 1,
  },
  movementContainer: {
    mb: 2,
  },
};
